/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import {
  Text,
  Label,
  Radio,
  Dropdown,
  Input,
  Icon,
  Checkbox,
  Button,
  Popover,
  Toast,
} from "@innovaccer/design-system";
import Reveal from "@datashop/reveal";
import { Mixpanel } from "../../../helper/mixpanelHelper";
import axios from "../../../helper/axios";
import Message from "../ContentEditable";
import OutreachReview from "./sendOutreachReview";
import "./style.css";
import "../patientDetail/style.css";

const radioBtns = [
  {
    label: "Assessment",
    value: "assessment",
    id: "assessment",
    mediumKey: "send_email",
    messageKey: "email_message",
  },
  {
    label: "Message",
    value: "message",
    id: "message",
    mediumKey: "send_sms",
    messageKey: "sms_message",
  },
];
const assessmentTypes = [{ label: "COVID-19", value: "COVID-19" }];
const outReachMediumTypes = [
  { title: "Text Message", icon: "message", key: "send_sms" },
  { title: "E-mail", icon: "drafts", value: "mail", key: "send_email" },
];
const initConfig = {
  name: "",
  category: "",
  send_sms: false,
  send_email: false,
};

const outreachMixpanelCategory = {
  patients: "TA-Patients",
  assessments: "TA-Assessments",
};

class OutreachReveal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outreachType: 0,
      selectedAssessment: [],
      assessmentPlaceholder: "Select",
      fetchingMessageTemplate: false,
      personalizeOpen: false,
      reviewMode: false,
      config: initConfig,
    };
    this.contentEditableRef = React.createRef();
    this.sms_message = "";
    this.assessmentIntro = "";
    this.messageIntro = "";
    this.assessmentMsgToSave = "";
    this.normalMsgToSave = "";
  }

  canReview = () => {
    const { config, outreachType } = this.state;
    let canReview = true;
    canReview = !!(canReview && config.name);
    canReview = !!(canReview && (config.send_email || config.send_sms));
    if (outreachType === 0) {
      canReview = !!(canReview && this.assessmentIntro);
      canReview = !!(canReview && config.category);
    } else {
      canReview = !!(canReview && this.messageIntro);
    }
    return canReview;
  };

  handleOutreachTypeChange = index => {
    const { config = {}, previousConfig = {} } = this.state;
    // eslint-disable-next-line camelcase
    const { name, send_email, send_sms } = config;
    const configToSave = {
      name,
      send_email,
      send_sms,
    };
    this.setState({
      outreachType: index,
      config: {
        ...this.state.config,
        name: previousConfig.name ? previousConfig.name : "",
        send_email: previousConfig.send_email
          ? previousConfig.send_email
          : false,
        send_sms: previousConfig.send_sms ? previousConfig.send_sms : false,
      },
      previousConfig: configToSave,
    });
  };

  handleAssessmentSelect = selected => {
    const newConfig = { ...this.state.config };
    newConfig.category = selected;
    this.setState({
      selectedAssessment: selected,
      assessmentPlaceholder: selected.label,
      config: newConfig,
    });
  };

  toggleReview = () => {
    this.setState({
      reviewMode: !this.state.reviewMode,
    });
  };

  sendToOutreach = () => {
    const { config, outreachType } = this.state;
    const { selectedRows = [], parent } = this.props;
    const recipients = selectedRows.map(row => ({
      firstName: row.patientFirstName,
      lastName: row.patientLastName,
      phoneNo: row.contactNumber,
      email: row.email,
    }));
    const payload = { ...config };
    payload.type = outreachType === 0 ? "ASSESSMENT" : "MESSAGE";
    if (outreachType === 0) {
      payload.sms_message = this.assessmentMsgToSave;
      payload.email_message = this.assessmentMsgToSave;
    } else {
      payload.sms_message = this.normalMsgToSave;
      payload.email_message = this.normalMsgToSave;
    }
    // payload.sms_message = this.state.messageToSend;
    // payload.email_message = this.state.messageToSend;
    payload.assessment_id =
      outreachType === 0 ? "5e7b840c98c5bf3ad547f6a2" : null;
    payload.recipients = recipients;
    this.setState({
      sending: true,
    });
    axios
      .post(`/outreach/send_outreach`, payload)
      .then(() => {
        const mixPanelPayload = {
          category: outreachMixpanelCategory[parent],
        };
        if (payload.sms_message) mixPanelPayload.text = payload.sms_message;
        if (payload.email_message)
          mixPanelPayload.email = payload.email_message;
        Mixpanel.track("Send Outreach", mixPanelPayload);
        this.resetSendToOutreach("success");
      })
      .catch(() => {
        this.setState({
          sending: false,
          sendingError: true,
        });
      });
  };

  resetSendToOutreach = type => {
    const { onSendSucess, onClose } = this.props;
    this.assessmentIntro = "";
    this.messageIntro = "";
    this.setState(
      {
        outreachType: 0,
        selectedAssessment: [],
        assessmentPlaceholder: "Select",
        sending: false,
        sendingError: false,
        config: initConfig,
        previousConfig: {},
        reviewMode: false,
      },
      () => {
        if (type === "success") {
          onSendSucess();
        } else {
          onClose();
        }
      }
    );
  };

  renderOutreachMedium = () => {
    const { config } = this.state;
    return (
      <div className='d-flex flex-row'>
        {outReachMediumTypes.map((medium, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className='medium-wrapper' key={index}>
            <div className='d-flex flex-row flex-grow-1'>
              <Icon
                className='medium-icon'
                appearance={config[medium.key] ? "default" : "subtle"}
                name={medium.icon}
              />
              <Text appearance='default'>{medium.title}</Text>
            </div>
            <Checkbox
              className='medium-checkbox'
              name={medium.name}
              checked={config[medium.key]}
              value={medium.value}
              id={index}
              onChange={() => {
                const newConfig = { ...config };
                newConfig[medium.key] = !newConfig[medium.key];
                this.setState({
                  config: newConfig,
                });
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  fetchMessageTemplate = () => {
    const { outreachType } = this.state;
    // eslint-disable-next-line react/prop-types
    const { parent } = this.props;
    this.setState({ fetchingMessageTemplate: true });

    // todo: fix hardcoded assessment id;
    axios
      .get(
        `/organizations/assessment-configurations/5e7b840c98c5bf3ad547f6a2/outReach/smsMssg?format_type=sample_text`
      )
      .then(({ data: { config } }) => {
        Mixpanel.track("Import Template Used", {
          category: outreachMixpanelCategory[parent],
        });
        if (outreachType === 0) {
          this.assessmentIntro = config.value;
          this.assessmentMsgToSave = config.value;
        } else {
          this.messageIntro = config.value;
          this.normalMsgToSave = config.value;
        }
        this.setState({
          fetchingMessageTemplate: false,
          // eslint-disable-next-line react/no-unused-state
          messageToSend: config.value,
        });
      })
      .catch(() => {
        this.setState({ fetchingMessageTemplate: false });
      });
  };

  handlePersonalise = placeholder => {
    const { outreachType } = this.state;
    let html = outreachType === 0 ? this.assessmentIntro : this.messageIntro;
    let message =
      outreachType === 0 ? this.assessmentMsgToSave : this.normalMsgToSave;
    html = `${html} <${placeholder}>`;
    message = `${message} <${placeholder}>`;
    this.updateMessage(html, message);
  };

  renderPersonalizeOptions = () => {
    const { personalizeOpen } = this.state;
    const btn = (
      <div className='d-flex flex-row' style={{ cursor: "pointer" }}>
        <Text className='font-12 mr-3' appearance='subtle'>
          PERSONALISE USING
        </Text>
        <Icon
          className='personalise-icon'
          appearance='subtle'
          name={personalizeOpen ? "expand_less" : "expand_more"}
        />
      </div>
    );
    return (
      <Popover
        className='popover-style'
        closeOnBackdropClick
        on='click'
        open={personalizeOpen}
        onToggle={open => this.setState({ personalizeOpen: open })}
        position='bottom-end'
        trigger={btn}>
        <div className='d-flex flex-row'>
          <div
            onClick={() => {
              this.handlePersonalise("firstName");
            }}>
            <Text className='popup-text font-14 mr-4'>Fisrt Name</Text>
          </div>
          <div
            onClick={() => {
              this.handlePersonalise("lastName");
            }}>
            <Text className='popup-text font-14'>Last Name</Text>
          </div>
        </div>
      </Popover>
    );
  };

  updateMessage = (el, value) => {
    const { outreachType } = this.state;
    if (outreachType === 0) {
      this.assessmentIntro = el;
      this.assessmentMsgToSave = value;
    } else {
      this.messageIntro = el;
      this.normalMsgToSave = value;
    }
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      refresh: true,
    });
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { open, selectedRows } = this.props;
    const {
      outreachType,
      selectedAssessment,
      assessmentPlaceholder,
      fetchingMessageTemplate,
      reviewMode,
      config = {},
      sending,
      sendingError,
    } = this.state;
    return (
      <Reveal
        open={open}
        closeButton
        containerStyle={{
          boxSizing: "border-box",
          overflow: "unset",
          height: "100%",
        }}
        onClose={() => this.resetSendToOutreach()}
        style={{
          height: "100%",
          width: "640px",
          borderRadius: "8px",
          backgroundColor: "#ffffff",
          boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
          padding: "16px",
        }}>
        <div className='d-flex flex-column h-100'>
          <Text appearance='default' className='mb-7 font-16'>
            {selectedRows &&
              // eslint-disable-next-line react/prop-types
              `Send outreach to ${selectedRows.length} ${
                // eslint-disable-next-line react/prop-types
                selectedRows.length > 1 ? "patients" : "patient"
              }`}
          </Text>
          {!reviewMode ? (
            <>
              <div className='d-flex flex-column mb-7'>
                <Label className='mb-4'>Type</Label>
                <div className='d-flex flex-row'>
                  {radioBtns.map((radio, index) => (
                    <Radio
                      className={index < radioBtns.length - 1 ? "mr-7" : "mr-0"}
                      key={radio.value}
                      value={radio.value}
                      label={radio.label}
                      name='Assessment'
                      defaultChecked={index === outreachType}
                      onChange={() => {
                        this.handleOutreachTypeChange(index);
                      }}
                    />
                  ))}
                </div>
              </div>
              {outreachType === 0 && (
                <div className='d-flex flex-column mb-7'>
                  <Label className='mb-2'>Assessment</Label>
                  <Dropdown
                    closeOnSelect
                    className='outreach-reveal-dropdown '
                    options={assessmentTypes}
                    placeholder={assessmentPlaceholder}
                    onUpdate={(action, option) => {
                      this.handleAssessmentSelect(option);
                    }}
                    selected={[selectedAssessment]}
                    triggerOptions={{}}
                  />
                </div>
              )}
              <div className='d-flex flex-column mb-7'>
                <Label className='mb-2'>Name</Label>
                <Input
                  className='outreach-reveal-input'
                  value={config.name}
                  placeholder='Give name to this outreach, e.g. Wellness Visit'
                  onChange={e => {
                    const newConfig = { ...config };
                    newConfig.name = e.target.value;
                    this.setState({
                      config: newConfig,
                    });
                  }}
                />
              </div>
              <div className='d-flex flex-column mb-7'>
                <Label className='mb-2'>Medium</Label>
                {this.renderOutreachMedium()}
              </div>
              <div className='d-flex flex-column mb-7'>
                <div className='d-flex flex-row justify-content-between'>
                  <Text className='interoductory-message'>
                    Introductory Message
                  </Text>
                  <Button
                    dimension='tiny'
                    disabled={outreachType === 1 || fetchingMessageTemplate}
                    onClick={this.fetchMessageTemplate}>
                    Import template
                  </Button>
                </div>
                <Message
                  updateMessage={this.updateMessage}
                  disabled={fetchingMessageTemplate}
                  message={
                    outreachType === 0
                      ? this.assessmentIntro
                      : this.messageIntro
                  }
                />
                <div className='d-flex flex-row justify-content-between popup'>
                  {this.renderPersonalizeOptions()}
                  <div className='d-flex flex-row'>
                    <Icon name='info' className='font-14' />
                    &nbsp;
                    <Text className='font-12' appearance='subtle'>
                      Do not share PHI via text/email outreach
                    </Text>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-row justify-content-end close-review-button-div mt-auto'>
                <Button
                  className='mr-3'
                  onClick={() => {
                    this.resetSendToOutreach();
                  }}>
                  Close
                </Button>
                <Button
                  disabled={!this.canReview()}
                  appearance='primary'
                  onClick={() => this.setState({ reviewMode: true })}>
                  Review
                </Button>
              </div>
            </>
          ) : (
            <OutreachReview
              toggleReview={this.toggleReview}
              config={config}
              sendToOutreach={this.sendToOutreach}
              sending={sending}
              resetSendToOutreach={this.resetSendToOutreach}
              html={
                outreachType === 0 ? this.assessmentIntro : this.messageIntro
              }
              outreachType={outreachType}
            />
          )}
          {sendingError && (
            <Toast
              appearance='alert'
              title='Failed to submit'
              onClose={() => this.setState({ sendingError: false })}
            />
          )}
        </div>
      </Reveal>
    );
  }
}

export default OutreachReveal;
