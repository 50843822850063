import axios from "helper/axios";

export const setConfig = (path = "", body, ...args) =>
  axios.patch(
    `/organizations/configurations${path.startsWith("/") ? "" : "/"}${path}`,
    { value: body },
    ...args
  );

export const getConfig = (path = "", ...args) =>
  axios.get(
    `/organizations/configurations${path.startsWith("/") ? "" : "/"}${path}`,
    ...args
  );

// =================== branding routes ========================

export const getNetworkInfo = (...args) =>
  axios.get("/organizations/branding-config", ...args);

export const updateNetworkInfo = (...args) =>
  axios.post("/organizations/update-branding-config", ...args);

export const getPracticeList = (...args) =>
  axios.get("/organization/practices", ...args);

export const setPracticeList = (practices, params) =>
  axios.patch(
    "/organizations/configurations/practice/names",
    { value: practices },
    { params }
  );
export const getPracticeDetails = (id, ...args) =>
  axios.get(`/organization/practices/${id}`, ...args);

export const updatePracticeDetails = (id, ...args) =>
  axios.post(`/organization/practices/${id}/update`, ...args);

export const createPractice = (...args) =>
  axios.post(`/organization/practices/`, ...args);

export const getProviderList = axios.get.bind(null, "/organizations/providers");

export const resetNetworkInfo = (args, instructionVideo) =>
  axios.delete("/organizations/branding-config", {
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      fields: args,
      instructionVideo: instructionVideo,
    },
  });
