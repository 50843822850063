import React from "react";
import { Card, Text } from "@innovaccer/design-system";
import PageLoader from "components/commons/PageLoader";
import Placeholder from "components/commons/Placeholder";
import styled from "styled-components";
import FlexBox from "components/commons/FlexBox";

import {
  default as Assessments,
  getXLSXData as getAssessmentsXLSX,
} from "./assessments";
import {
  default as AssessmentsZipMap,
  getXLSXData as getAssessmentsZipMapXLSX,
} from "./assessmentsZipMap";
import {
  default as CallSummary,
  getXLSXData as getCallSummaryXLSX,
} from "./callSummary";
import {
  default as VirtualVisits,
  getXLSXData as getVirtualVisitsXLSX,
} from "./virtualVisits";
import {
  default as DayWiseDistribution,
  getXLSXData as getDayWiseDistributionXLSX,
} from "./dayWiseDistribution";
import {
  default as RiskDonut,
  getXLSXData as getRiskDonutXLSX,
} from "./riskDonut";
import {
  default as ProvidersVirtualVisits,
  getXLSXData as getProvidersVirtualVisitsXLSX,
} from "./providersVirtualVisits";
import VisitsDistribution, {
  getXLSXData as getVirtualVisitsDistXLSX,
} from "./visitsDistribution";

export const AllWidgets = {
  assessments: Assessments,
  "risk-donut": RiskDonut,
  "day-wise-distribution": DayWiseDistribution,
  "assessments-zip-map": AssessmentsZipMap,
  "virtual-visits": VirtualVisits,
  "visits-distribution": VisitsDistribution,
  "call-duration": CallSummary,
  "providers-virtual-visits": ProvidersVirtualVisits,
};

export const allWidgetsXLSX = {
  assessments: getAssessmentsXLSX,
  "risk-donut": getRiskDonutXLSX,
  "day-wise-distribution": getDayWiseDistributionXLSX,
  "assessments-zip-map": getAssessmentsZipMapXLSX,
  "virtual-visits": getVirtualVisitsXLSX,
  "visits-distribution": getVirtualVisitsDistXLSX,
  "call-duration": getCallSummaryXLSX,
  "providers-virtual-visits": getProvidersVirtualVisitsXLSX,
};

const filtersKeyMap = {
  startDate: "start_date",
  endDate: "end_date",
  callType: "call_type",
};

export const transformFilters = filters =>
  Object.entries(filters).reduce((result, [key, value]) => {
    result[filtersKeyMap[key] ? filtersKeyMap[key] : key] = value;
    return result;
  }, {});

export const WidgetCard = styled(Card).attrs({
  shadow: "light",
})`
  display: flex;
  flex-direction: column;
  align-items: ${props =>
    props.isCardTitle && !props.isLoading && !props.isError
      ? "flex-start"
      : "center"};
  justify-content: center;
  padding: 16px 12px 13px 16px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  .Table {
    border: var(--border);
    overflow: hidden;
  }
`;

// todo: check if we need other loader
export const WidgetLoader = PageLoader;

export const WidgetError = ({ onRetry }) => (
  <Placeholder error title='Failed to load data' onRetry={onRetry} />
);

export const DivWrapper = FlexBox;

// todo: refactor this
export const Header = styled.p`
  display: flex;
  align-items: center;
  color: #151414;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

// todo: refactor this
export const LegendLabel = styled.div`
  height: ${props => (props.height ? props.height : "8px")};
  width: ${props => (props.height ? props.height : "8px")};
  background-color: ${props => (props.color ? props.color : "#67559b")};
  margin: ${props => (props.margin ? props.margin : "7px 7px 0 0")};
  border-radius: 2px;
  display: inline-block;
`;

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  :not(:last-child) {
    margin-right: 16px;
  }
`;

const LegendColor = styled.div`
  height: 12px;
  width: 12px;
  ${props => props.color && `background-color: ${props.color};`}
  margin-right: 8px;
`;

export const Legend = ({ color, title, children }) => {
  return (
    <LegendWrapper>
      <LegendColor color={color} />
      {title && <Text small>{title}</Text>}
      {children}
    </LegendWrapper>
  );
};
