const { REACT_APP_PROD, REACT_APP_API_ENDPOINT } = process.env;

export const isProd =
  typeof REACT_APP_PROD !== "undefined" && REACT_APP_PROD === "true";

export const API_URL = REACT_APP_API_ENDPOINT;

export const baseAppURL = `${window.location.origin}/#`;

export const getGlobalURL = path =>
  `${baseAppURL}${path.startsWith("/") ? "" : "/"}${path}`;

export const providerAppURL = isProd
  ? `https://provider-virtual-care.innovaccer.com/`
  : `https://uspreprod.z22.web.core.windows.net/`;

export const providerAppBuildMac = isProd
  ? `https://innote-releases.s3.amazonaws.com/Covid-19/InNote-2.0.0.dmg`
  : `https://innote-releases.s3.amazonaws.com/Covid-19/InNote-1.3.1.dmg`;

export const providerAppBuildWindows = isProd
  ? `https://innote-releases.s3.amazonaws.com/Covid-19/InNote-2.0.0.exe`
  : `https://innote-releases.s3.amazonaws.com/Covid-19/InNote-1.3.1.exe`;

export const LogoConfig = {
  title: "Logo link",
  src: `${process.env.PUBLIC_URL}/img/logo.png`,
  alt: "brand logo",
  link: "",
  type: "logo"
};

export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const logInStrategyMap = {
  0: "email_pwd",
  1: "email"
};

const titles = ["Dr.", "Mr.", "Mrs.", "Ms."];
export const titleOptions = [
  ...titles.map(title => ({
    value: title,
    label: title
  })),
  { value: "", label: "Other" }
];

export const orgTypes = {
  small: "INDIVIDUAL",
  large: "NETWORK"
};

export const searchEnabledFilterThreshold = 5;

export const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const phoneNumberPlaceholder = "(___) ___-____";

export const startofTime = new Date("01/02/1970").valueOf();
