import React, { Component } from "react";
import Modal from "@datashop/modal";
import axios from "helper/axios";
import { exportJson2Excel } from "helper/fileDownload";
import {
  PlaceholderParagraph,
  Button,
  Text,
  Icon
} from "@innovaccer/design-system";

class SendBulkAssessment extends Component {
  fileInputRef = React.createRef();

  state = {
    reviewState: false,
    fileName: "",
    fileSize: 0,
    fileSelected: false,
    isFileUploading: false,
    fileUploadPercent: 0,
    fileUploadSuccess: false,
    fileUploadError: false,
    totalRecords: 0,
    uploadId: null,
    sendingOutreach: false,
    outreachMessage: "",
    outreachMessageLoading: false,
    errorList: []
  };

  downloadSample = () => {
    const a = document.createElement("a");
    a.href = `${process.env.PUBLIC_URL}/bulkUploadAssessmentsSample.xlsx`;
    a.click();
  };

  unselectFile = () => {
    this.setState(prevProps => ({
      fileSelected: !prevProps.fileSelected,
      isFileUploading: false,
      fileUploadSuccess: false,
      fileUploadError: false
    }));
  };

  onFileSelect = () => {
    this.fileInputRef.current.value = null;
    this.fileInputRef.current.click();
  };

  retryFileSelect = () => {
    this.unselectFile();
    const timer = setTimeout(() => {
      this.onFileSelect();
      clearTimeout(timer);
    }, 500);
  };

  downloadErrorReport = () => {
    exportJson2Excel([{ data: this.state.errorList }], "errorReport");
  };

  calculateFileSize = size => {
    const kb = size / 1024;
    if (kb > 1024) {
      return `${(kb / 1024).toFixed(1)} MB`;
    }
    return `${kb.toFixed(1)} KB`;
  };

  getOutreachMessageConfig = () => {
    this.setState({ outreachMessageLoading: true });
    axios
      .get(
        `/organizations/assessment-configurations/5e7b840c98c5bf3ad547f6a2/outReach/smsMssg?format_type=sample_text`
      )
      .then(({ data: { config } }) => {
        this.setState({
          outreachMessageLoading: false,
          outreachMessage: config.value
        });
      })
      .catch(() => {
        this.setState({ outreachMessageLoading: false });
      });
  };

  uploadFile = () => {
    this.setState({
      fileUploadPercent: 0,
      isFileUploading: true,
      fileName: this.fileInputRef.current.files[0].name,
      fileSize: this.calculateFileSize(this.fileInputRef.current.files[0].size)
    });
    let data = new FormData();
    data.append("file", this.fileInputRef.current.files[0]);
    axios
      .post(`/outreach/bulk/5e7b840c98c5bf3ad547f6a2/upload`, data, {
        onUploadProgress: progressEvent =>
          this.setState({
            fileUploadPercent: parseInt(
              (progressEvent.loaded * 100) / progressEvent.total
            )
          })
      })
      .then(({ data: { uploadId, errorList, recipientCount } }) => {
        const fileUploadError = errorList.length > 0 || !uploadId;
        this.setState({
          errorList,
          isFileUploading: false,
          fileUploadSuccess: !fileUploadError,
          fileUploadError,
          totalRecords: recipientCount,
          uploadId
        });
      })
      .catch(() => {
        this.setState({ isFileUploading: false, fileUploadError: true });
      });
  };

  sendOutreach = () => {
    this.setState({ sendingOutreach: true });
    axios
      .post(`/outreach/bulk/${this.state.uploadId}/send`)
      .then(() => {
        this.props.showToast({
          title: "Outreach successfully sent",
          message: `${this.state.totalRecords} outreach messages have been successfully sent`
        });
        this.props.onClose({ totalRecords: this.state.totalRecords });
      })
      .catch(() => {
        this.props.showToast({
          message: "Something wend wrong, please try again",
          appearance: "alert"
        });
      })
      .finally(() => {
        this.setState({ sendingOutreach: false });
      });
  };

  onFileDrop = e => {
    e.stopPropagation();
    e.preventDefault();
    this.fileInputRef.current.files = e.dataTransfer.files;
    this.uploadFile();
    this.setState({ fileDragedOver: false });
  };

  onDragEnter = e => {
    console.log("drag enter");
    e.stopPropagation();
    e.preventDefault();
    this.setState({ fileDragedOver: true });
  };

  onDragLeave = e => {
    console.log("drag leave");
    e.stopPropagation();
    e.preventDefault();
    this.setState({ fileDragedOver: false });
  };

  render() {
    const { onClose } = this.props;

    const {
      reviewState,
      fileName,
      isFileUploading,
      fileSize,
      fileUploadSuccess,
      fileUploadError,
      totalRecords,
      errorList,
      sendingOutreach,
      outreachMessage,
      outreachMessageLoading,
      fileUploadPercent,
      fileDragedOver
    } = this.state;

    const additionalDnDProps = {
      onDragEnter: this.onDragEnter,
      onDragOver: this.onDragEnter,
      //
      onDragLeave: this.onDragLeave,
      onDragEnd: this.onDragLeave,
      //
      onDrop: this.onFileDrop
    };

    const getFileWrapperBody = () => {
      if (isFileUploading || fileUploadSuccess) {
        return (
          <>
            <div className='upload-main-container'>
              <Icon name='insert_drive_file' size={50} appearance='subtle' />
              <Text weight='strong'>{fileName}</Text>
              <Text appearance='subtle'>{fileSize}</Text>
              <div className='upload-status-bar'>
                <div
                  className='upload-status-fill'
                  style={{ width: `${fileUploadPercent}%` }}
                />
              </div>
              <Text style={{ width: "100%" }}>{fileUploadPercent}%</Text>
            </div>
            <a
              className='download-sample'
              href='javascript:void(0)'
              onClick={this.retryFileSelect}>
              Upload a different file
            </a>
          </>
        );
      }

      if (fileUploadError) {
        if (errorList.length) {
          return (
            <>
              <div className='upload-main-container'>
                <Icon appearance='alert' name='error' size={40} />
                <Text
                  weight='strong'
                  style={{
                    textAlign: "center",
                    maxWidth: "80%"
                  }}>
                  {errorList.length} errors found in {totalRecords} records
                </Text>
                <Text
                  weight='medium'
                  className='error-msg mt-4'
                  appearance='subtle'>
                  We found errors in your file. Please correct the errors and
                  reupload the file.
                </Text>

                <Button
                  className='mt-8'
                  onClick={this.downloadErrorReport}
                  dimension='tiny'>
                  <Icon name='cloud_download' />
                  &nbsp; Download error report
                </Button>
              </div>
              <a
                className='download-sample'
                href='javascript:void(0)'
                onClick={this.retryFileSelect}>
                Upload a different file
              </a>
            </>
          );
        }

        return (
          <>
            <div className='upload-main-container'>
              <Icon appearance='alert' name='error' size={40} />
              <Text
                weight='medium'
                className='error-msg mt-4'
                appearance='destructive'>
                Upload failed. Retry, or if the issue persists, upload a
                different file.
              </Text>
              <Button
                className='mt-8'
                onClick={this.uploadFile}
                dimension='tiny'>
                Retry
              </Button>
            </div>
            <a
              className='download-sample'
              href='javascript:void(0)'
              onClick={this.retryFileSelect}>
              Upload a different file
            </a>
          </>
        );
      }

      return (
        <>
          <div
            className='upload-main-container'
            {...additionalDnDProps}
            fileDragedOver={fileDragedOver}>
            <Icon size={40} name='backup' appearance='disabled' />
            <Text weight='strong'>Drag and drop file here</Text>
            <Text appearance='subtle'>
              or{" "}
              <a
                href='javascript:void(0)'
                style={{ color: "rgb(0, 112, 221)" }}
                onClick={this.onFileSelect}>
                browse
              </a>{" "}
              from device
            </Text>
            <Text appearance='subtle' className='upload-container-text'>
              Upload file in .csv, .xls or .xlsx format with the recipient names
              as described in sample file
            </Text>
          </div>
          <a className='download-sample' onClick={this.downloadSample}>
            <Icon name='get_app'></Icon> Download sample file
          </a>
        </>
      );
    };

    const getUploadState = () => {
      return (
        <>
          <Modal.Header heading='Send new outreach'></Modal.Header>
          <Modal.Body>
            <Text weight='strong' className='mb-6'>
              Upload the recipient's file
            </Text>
            {getFileWrapperBody()}
            <input
              style={{ display: "none" }}
              type='file'
              ref={this.fileInputRef}
              accept='.xls,.xlsx,.csv'
              onChange={e => {
                const { target } = e;
                if (target.value.length > 0) {
                  this.uploadFile();
                } else {
                  this.unselectFile();
                }
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              appearance='primary'
              disabled={!fileUploadSuccess}
              onClick={() =>
                this.setState(
                  { reviewState: true },
                  this.getOutreachMessageConfig
                )
              }>
              Review
              <Icon
                name='arrow_right_alt'
                appearance='white'
                className='ml-4'></Icon>
            </Button>
          </Modal.Footer>
        </>
      );
    };

    const getReviewState = () => {
      return (
        <>
          <Modal.Header
            heading={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Icon
                  className='mr-5 review-back-icon'
                  size={24}
                  onClick={() => this.setState({ reviewState: false })}
                  name='keyboard_backspace'></Icon>
                Review
              </div>
            }></Modal.Header>
          <Modal.Body>
            <Text weight='strong' className='mb-6'>
              SENDING COVID-19 ASSESSMENT TO
            </Text>
            <h1>
              {totalRecords}
              <i style={{ fontSize: 16, marginLeft: 8, fontWeight: "normal" }}>
                recipients
              </i>
            </h1>
            <div className='mt-7 mb-8'>
              <Text weight='strong' className='mb-6'>
                MESSAGE PREVIEW
              </Text>
              <div className='message-preview'>
                {outreachMessageLoading ? (
                  <>
                    <PlaceholderParagraph length='medium' />
                    <PlaceholderParagraph length='small' />
                  </>
                ) : (
                  outreachMessage
                )}
              </div>
              <div className='review-helper-text'>
                <Icon name='error_outline' appearance='disabled' /> To edit the
                message, go to Assessments Settings under Admin console
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={sendingOutreach}
              expanded
              appearance='success'
              style={{ margin: 0 }}
              onClick={this.sendOutreach}>
              Send outreach
            </Button>
          </Modal.Footer>
        </>
      );
    };

    return (
      <Modal
        style={{ boxShadow: "none", height: "480px" }}
        containerStyle={{
          background: "white"
        }}
        showCloseButton
        onClose={onClose}
        backdrop={false}>
        {!reviewState ? getUploadState() : getReviewState()}
      </Modal>
    );
  }

  componentDidMount() {}
}

export default SendBulkAssessment;
