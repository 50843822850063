import { PureComponent } from "react";

class If extends PureComponent {
  render() {
    const { children, condition, fallback } = this.props;

    if (!condition) {
      return fallback || null;
    }

    return children;
  }
}

export default If;
