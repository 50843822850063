import React, { useState, useCallback, useEffect } from "react";
import { RoutesWrapper, SubRoutesWrapper, CardsWrapper } from "../commons";
import { PageHeader, Button, Text, Heading } from "@innovaccer/design-system";
import {
  NetworkCard,
  Name,
  Logo,
  SubDomain,
  Instructions,
  ErrorState,
  DetailsRow,
  CopyText,
  WelcomeMessage,
  Address,
  Phone
} from "./atoms";
import { getNetworkInfo, updateNetworkInfo } from "services/orgService";
import PageLoader from "components/commons/PageLoader";
import AlertService, { defaultDissmissDelay } from "services/alertService";
import ConfirmService from "services/confirmService";
import { getAdminUrl, getProviderUrl, getFDUrl } from "utils";

const showAlert = () => {
  AlertService.showAlert({
    appearance: "alert",
    message: "Failed to update details",
    autoClearAfter: defaultDissmissDelay
  });
};

const DetailsCard = ({ details, updateDetails }) => {
  const {
    name: defaultName,
    logoUrl: defaultUrl,
    phoneNo,
    address: defaultAddress
  } = details;
  const [editable, makeEditable] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [name, setName] = useState(defaultName);
  const [phone, setPhone] = useState(phoneNo);
  const [address, setAddress] = useState(defaultAddress);
  const [logo, setLogo] = useState(null);

  const isPhoneInvalid = phone && (phone || "").length < 10;

  useEffect(() => {
    setName(defaultName);
    setPhone(phoneNo);
    setAddress(defaultAddress);
    setLogo(null);
  }, [editable]);

  const saveDetails = () => {
    setUpdating(true);
    updateDetails({ logo, name, phoneNo: phone, address })
      .then(() => {
        setUpdating(false);
        makeEditable(false);
      })
      .catch(() => {
        setUpdating(false);
        showAlert();
      });
  };

  return (
    <NetworkCard
      disabled={updating}
      title='Details'
      subtitle='Essential information of your practice.'
      footer={
        editable ? (
          <>
            <Button
              key='cancel'
              className='mr-4'
              onClick={() => makeEditable(false)}>
              Cancel
            </Button>
            <Button key='save' onClick={saveDetails} appearance='primary'>
              Save Changes
            </Button>
          </>
        ) : (
          <Button
            key='edit'
            onClick={() => makeEditable(true)}
            appearance='primary'>
            Edit
          </Button>
        )
      }>
      <Name
        placeholder='Practice name'
        editable={editable}
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Phone
        editable={editable}
        value={phone}
        error={isPhoneInvalid}
        onBlur={() => isPhoneInvalid && setPhone(phoneNo)}
        onChange={(__, p) => setPhone(p)}
      />
      <Address
        placeholder='402 E Holt Blvd, CA 91761 United States '
        editable={editable}
        value={address}
        onChange={e => setAddress(e.target.value)}
      />
      <Logo
        editable={editable}
        defaultUrl={defaultUrl}
        file={logo}
        onChange={file => setLogo(file)}
      />
    </NetworkCard>
  );
};

const WebLinksCard = ({ details, updateDetails }) => {
  const { subDomain: defaultsubDomain } = details;
  const [editable, makeEditable] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [subDomain, setSubDomain] = useState(defaultsubDomain);

  useEffect(() => {
    setSubDomain(defaultsubDomain);
  }, [editable]);

  const saveDetails = () => {
    setUpdating(true);
    ConfirmService.confirm({
      dimension: "small",
      heading: "Set subdomain?",
      description: `You’re about to set the Subdomain for your practice to ${subDomain}.innovacccer.com. Please note, later you won't be able to make changes to this subdomain.`,
      confirmationText: "Set subdomain"
    })
      .then(modal => {
        modal.setLoading(true);
        updateDetails({ subDomain })
          .then(() => {
            setUpdating(false);
            makeEditable(false);
          })
          .catch(() => {
            setUpdating(false);
            showAlert();
          })
          .finally(() => {
            modal.close();
            modal.setLoading(false);
          });
      })
      .catch(modal => {
        modal.close();
      });
  };

  return (
    <NetworkCard
      disabled={updating}
      title='Practice web links'
      subtitle='Weblinks which will be used by your teammates and patients.'
      footer={
        subDomain ? null : editable ? (
          <>
            <Button
              key='cancel'
              className='mr-4'
              onClick={() => makeEditable(false)}>
              Cancel
            </Button>
            <Button
              disabled={!subDomain}
              key='save'
              onClick={saveDetails}
              appearance='primary'>
              Save Changes
            </Button>
          </>
        ) : (
          <Button
            key='edit'
            onClick={() => makeEditable(true)}
            appearance='primary'>
            Set subdomain
          </Button>
        )
      }>
      {!subDomain && (
        <SubDomain
          editable={editable}
          placeholder='subdomain'
          value={subDomain}
          onChange={e => setSubDomain(e.target.value)}></SubDomain>
      )}
      {subDomain && (
        <>
          <DetailsRow
            label='Admin'
            subLabel='Web address for admins and practice managers.'>
            <CopyText text={getAdminUrl(subDomain)} />
          </DetailsRow>
          <DetailsRow
            label='Provider'
            subLabel='Web address for providers in your team.'>
            <CopyText text={getProviderUrl(subDomain)} />
          </DetailsRow>
          <div className='w-100 mt-4'>
            <Heading size='s'>Front door</Heading>
            <Text appearance='subtle'>
              Weblink which will be used by your patients.
            </Text>
          </div>
          <DetailsRow
            label='Front door web address'
            subLabel={`This will be your clinic's web address which can be embedded to your website. It can also be used directly by your patients to schedule appointments.`}>
            <CopyText text={getFDUrl(subDomain)} />
          </DetailsRow>
        </>
      )}
    </NetworkCard>
  );
};

const PresenceCard = ({ details, updateDetails }) => {
  const {
    welcomeMessage: defaultWelcomeMessage,
    examRoomLogoUrl,
    instructionVideoUrl,
    instructionVideo
  } = details;
  const [editable, makeEditable] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState(defaultWelcomeMessage);
  const [instImage, setInstImage] = useState(null);
  const [instVideo, setInstVideo] = useState(instructionVideoUrl);
  const [isVideo, setIsVideo] = useState(instructionVideo);

  useEffect(() => {
    setWelcomeMessage(defaultWelcomeMessage);
    setInstImage(null);
    setInstVideo(instructionVideoUrl);
    setIsVideo(instructionVideo);
  }, [editable]);

  const saveDetails = () => {
    setUpdating(true);
    updateDetails({
      welcomeMessage,
      instructionVideoUrl: instVideo,
      examRoomLogoUrl: instImage,
      instructionVideo: isVideo
    })
      .then(() => {
        setUpdating(false);
        makeEditable(false);
      })
      .catch(() => {
        setUpdating(false);
        showAlert();
      });
  };

  return (
    <NetworkCard
      disabled={updating}
      title='Waiting room presence'
      subtitle='Create a personalized experience for your clinic’s waiting room.'
      footer={
        editable ? (
          <>
            <Button
              key='cancel'
              className='mr-4'
              onClick={() => makeEditable(false)}>
              Cancel
            </Button>
            <Button key='save' onClick={saveDetails} appearance='primary'>
              Save Changes
            </Button>
          </>
        ) : (
          <Button
            key='edit'
            onClick={() => makeEditable(true)}
            appearance='primary'>
            Edit
          </Button>
        )
      }>
      <WelcomeMessage
        editable={editable}
        value={welcomeMessage}
        onChange={e => setWelcomeMessage(e.target.value)}
      />
      <Instructions
        editable={editable}
        isVideo={isVideo}
        file={instImage}
        defaultImageUrl={examRoomLogoUrl}
        defaultVideoUrl={instVideo}
        onChange={(content, video) => {
          if (video) {
            setInstVideo(content);
            setIsVideo(content && video);
          } else {
            setIsVideo(content ? false : !!instVideo);
            setInstImage(content);
          }
        }}
      />
    </NetworkCard>
  );
};

const IndividualPractice = ({}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [details, setDetails] = useState({});

  const fetchDetails = useCallback(() => {
    setLoading(true);
    setError(false);
    getNetworkInfo()
      .then(({ data }) => {
        setDetails(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  const updateDetails = useCallback(payload => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (![null, undefined].includes(value)) {
        formData.append(key, value);
      }
    });

    return updateNetworkInfo(formData, {
      headers: { "Content-Type": "multipart/form-data" }
    }).then(({ data }) => {
      setDetails(data);
    });
  }, []);

  useEffect(() => {
    fetchDetails();
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorState onRetry={fetchDetails} />;
  }

  return (
    <RoutesWrapper>
      <PageHeader title='Practice' />
      <SubRoutesWrapper overflow='auto'>
        <CardsWrapper>
          <DetailsCard details={details} updateDetails={updateDetails} />
          <WebLinksCard details={details} updateDetails={updateDetails} />
          <PresenceCard details={details} updateDetails={updateDetails} />
        </CardsWrapper>
      </SubRoutesWrapper>
    </RoutesWrapper>
  );
};

export default IndividualPractice;
