import React, { Component, useState } from "react";
import styled from "styled-components";
import { StyledText } from "components/commons/atoms";
import { openUrl } from "helper/fileDownload";
import { queryParamsToConfig } from "helper/queryParams";
import FlexBox from "components/commons/FlexBox";
import axios, { getErrorMessage } from "helper/axios";
import { isEmpty, noop } from "lodash";
import AlertService, { defaultDissmissDelay } from "services/alertService";
import PageLoader from "components/commons/PageLoader";
import Placeholder from "components/commons/Placeholder";
import {
  SettingsCard,
  RoutesWrapper,
  SubRoutesWrapper,
  CardsWrapper,
} from "../commons";
import {
  PageHeader,
  Input,
  Button,
  Caption,
  Switch,
  Icon,
  Text,
  Heading,
} from "@innovaccer/design-system";
import ConfirmService from "services/confirmService";
import { getAdminUrl } from "../../../utils";

const CardWrapper = styled(SettingsCard)`
  padding: 16px;
`;

const Seperator = styled.div`
  height: 0px;
  margin: 16px 0px;
  border-bottom: var(--border);
`;

const CustomCoPayViewMode = ({
  customCoPayAmount,
  customCoPay,
  onEdit,
  paymentEnabled,
}) => {
  return (
    <>
      <FlexBox className='mt-4'>
        <Text>
          {customCoPay
            ? "Accepting custom copayment."
            : "Currently not accepting custom copayment."}
        </Text>
      </FlexBox>
      <FlexBox className='mt-6'>
        <Text className='w-50' weight='strong'>
          Custom copay amount
        </Text>
        <Text className='w-50' appearance='subtle'>
          {customCoPayAmount || customCoPayAmount === 0
            ? "$" + customCoPayAmount
            : "Amount not set"}
        </Text>
      </FlexBox>
      <FlexBox className='mt-7' justifyContent='flex-end'>
        <Button
          disabled={!paymentEnabled}
          appearance='primary'
          onClick={onEdit}>
          Edit
        </Button>
      </FlexBox>
    </>
  );
};

const CustomCoPayEditMode = ({
  customCoPayAmount,
  customCoPay,
  onSave,
  onDismiss,
}) => {
  const [amount, setAmount] = useState(customCoPayAmount || "");
  const [enabled, toggleSwitch] = useState(customCoPay);

  const convertToNum = (a, trim) =>
    trim ? parseFloat(Number(a).toFixed(2)) : parseFloat(Number(a));

  return (
    <>
      <FlexBox className='mt-6' justifyContent='space-between'>
        <FlexBox direction='column' style={{ width: "80%" }}>
          <Text weight='strong'>Accept custom copayment</Text>
          <Text small appearance='subtle'>
            Incase of failure in copayment calculation or a visit with non
            insured patient; Virtual Care Network will ask the patient to pay an
            amount set by you.
          </Text>
        </FlexBox>
        <Switch
          size='tiny'
          checked={enabled}
          onChange={() => {
            setAmount(customCoPayAmount);
            toggleSwitch(!enabled);
          }}
          appearance='success'></Switch>
      </FlexBox>
      <Seperator />
      <FlexBox style={{ lineHeight: "32px" }}>
        <Text className='w-50' weight='strong'>
          Custom copay amount
        </Text>
        {enabled ? (
          <>
            $&nbsp;
            <div>
              <Input
                error={convertToNum(amount) < 0.5}
                type='number'
                value={amount}
                onChange={e => {
                  setAmount(e.target.value);
                }}
              />
              {convertToNum(amount) < 0.5 && (
                <Caption error>Copay amount should be at least $0.5</Caption>
              )}
            </div>
          </>
        ) : (
          <Text className='w-50' appearance='subtle'>
            {customCoPayAmount || customCoPayAmount === 0
              ? "$" + customCoPayAmount
              : "Amount not set"}
          </Text>
        )}
      </FlexBox>
      <FlexBox className='mt-7' justifyContent='flex-end'>
        <Button className='mr-4' onClick={onDismiss}>
          Cancel
        </Button>
        <Button
          disabled={convertToNum(amount) < 0.5}
          appearance='primary'
          onClick={() =>
            onSave({
              customCoPay: enabled,
              customCoPayAmount: convertToNum(amount, true),
            })
          }>
          Save changes
        </Button>
      </FlexBox>
    </>
  );
};

class Copayment extends Component {
  queryParams = queryParamsToConfig(this.props.location.search || "") || {};

  state = {
    config: {},
    settingsEditMode: false,
    loading: true,
    error: false,
    updatingConfig: false,
  };

  updateConfig = (patch = {}, cb = noop) => {
    this.setState({
      updatingConfig: true,
    });
    return axios
      .patch("/organizations/payment-config", patch)
      .then(() => {
        this.setState(({ config = {} }) => ({
          config: { ...config, ...patch },
          updatingConfig: false,
        }));
        cb();
      })
      .catch(err => {
        this.setState({
          updatingConfig: false,
        });
        AlertService.showAlert({
          appearance: "alert",
          title: "Unable to update preference",
          message: getErrorMessage(err),
          autoClearAfter: defaultDissmissDelay,
        });
      });
  };

  disconnectAccount = () => {
    ConfirmService.confirm({
      appearance: "alert",
      heading: "Disconnect from Stripe?",
      description:
        "You’re about to disconnect your account from Stripe, after this you won’t be able to accept copayments via Virtual Care Network.",
      confirmationText: "Disconnect account",
      reverse: true,
    })
      .then(modal => {
        modal.close();
        this.updateConfig({ accountLinked: false });
      })
      .catch(modal => {
        modal.close();
      });
  };

  connectWithGateway = () => {
    this.setState({
      updatingConfig: true,
    });
    return axios
      .post("/organizations/payment-account/link")
      .then(({ data }) => {
        const { clientId, scope } = data || {};
        this.setState({
          updatingConfig: false,
        });
        openUrl(
          `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=${scope}&redirect_uri=${getAdminUrl()}%23/settings/copayment`
        );
      })
      .catch(err => {
        this.setState({
          updatingConfig: false,
        });
        AlertService.showAlert({
          appearance: "alert",
          title: "Unable to Connect with Stripe",
          message: getErrorMessage(err),
          autoClearAfter: defaultDissmissDelay,
        });
      });
  };

  fetchOrgPaymentConfig = () => {
    this.setState({
      loading: true,
      error: false,
    });
    return axios
      .get("/organizations/payment-config")
      .then(({ data = {} }) => {
        this.setState({
          loading: false,
          config: data,
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: getErrorMessage(err),
        });
      })
      .finally(() => {
        this.cleanupUrlParams();
      });
  };

  cleanupUrlParams = () => {
    this.props.history.replace(this.props.location.pathname);
  };

  linkAccount = code => {
    this.setState({
      loading: true,
    });
    return axios
      .post("/organizations/payment-config", { authorization_code: code })
      .then(({ data }) => {
        this.setState({
          config: data,
          loading: false,
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
        });
        AlertService.showAlert({
          appearance: "alert",
          message: "Failed to link Account, Please try in sometime",
          autoClearAfter: defaultDissmissDelay,
        });
      })
      .finally(() => {
        this.cleanupUrlParams();
      });
  };

  render() {
    const {
      config = {},
      settingsEditMode,
      loading,
      error,
      updatingConfig,
    } = this.state;

    if (loading) {
      return <PageLoader />;
    }

    if (error) {
      return (
        <Placeholder
          error
          iconStyle={{ color: "var(--alert)" }}
          title='Failed to fetch payment details'
          subTitle='It may be because of technical failure or network reasons. Please try again'
          onRetry={this.fetchOrgPaymentConfig}
        />
      );
    }

    const {
      accountLinked,
      paymentEnabled,
      customCoPay,
      customCoPayAmount,
    } = config;

    const disconnectedState = (
      <>
        <CardWrapper>
          <Heading size='s'>Accept copayment</Heading>
          <Text small appearance='subtle'>
            Connect with Stripe and accept copayment from your patients
            transferred directly to your account.
          </Text>

          <Button
            className='mt-8'
            appearance='primary'
            onClick={this.connectWithGateway}>
            {/* <img
                style={{ height: 14, marginRight: 6 }}
                src={`${process.env.PUBLIC_URL}/img/stripe-logo.png`}
              /> */}
            Connect with Stripe
          </Button>
        </CardWrapper>
      </>
    );

    const connectedState = (
      <>
        <CardWrapper>
          <FlexBox justifyContent='space-between'>
            <FlexBox direction='column' style={{ width: "80%" }}>
              <Heading size='s'>
                {paymentEnabled
                  ? "Accepting copayment"
                  : "Not accepting copayment"}
              </Heading>
              <Text appearance='subtle'>
                You’re now {paymentEnabled ? "" : "not "}accepting copayments
                from your patients via Virtual Care Network.
              </Text>
            </FlexBox>
            <Switch
              size='tiny'
              checked={paymentEnabled}
              onChange={() =>
                this.updateConfig({ paymentEnabled: !paymentEnabled })
              }
              appearance='success'></Switch>
          </FlexBox>
          <FlexBox className='my-6' alignItems='center'>
            Connected with Stripe
            <Icon className='ml-4 color-success' name='check_circle' />
          </FlexBox>
          <FlexBox>
            <StyledText
              size='12'
              style={{
                cursor: "pointer",
                color: "var(--alert)",
              }}
              onClick={this.disconnectAccount}>
              Disconnect
            </StyledText>
          </FlexBox>
        </CardWrapper>
        {/* =============== Copayment options ================ */}
        <CardWrapper>
          <div>
            <Heading size='s'>Copayment options</Heading>
            <Text appearance='subtle'>Manage copay calculation settings.</Text>
          </div>
          {paymentEnabled && settingsEditMode ? (
            <CustomCoPayEditMode
              customCoPay={customCoPay}
              customCoPayAmount={customCoPayAmount}
              onSave={config =>
                this.updateConfig(config, () => {
                  this.setState({
                    settingsEditMode: false,
                  });
                })
              }
              onDismiss={() =>
                this.setState({
                  settingsEditMode: false,
                })
              }
            />
          ) : (
            <CustomCoPayViewMode
              paymentEnabled={paymentEnabled}
              customCoPay={customCoPay}
              customCoPayAmount={customCoPayAmount}
              onEdit={() => this.setState({ settingsEditMode: true })}
            />
          )}
        </CardWrapper>
      </>
    );

    return (
      <RoutesWrapper>
        <PageHeader title='Copayment' />
        {/* cards */}
        <SubRoutesWrapper>
          <CardsWrapper disabled={updatingConfig}>
            {accountLinked ? connectedState : disconnectedState}
          </CardsWrapper>
        </SubRoutesWrapper>
      </RoutesWrapper>
    );
  }

  componentDidMount() {
    if (!isEmpty(this.queryParams)) {
      const { error, error_description, code } = this.queryParams;
      if (error || !code) {
        if (error !== "access_denied") {
          AlertService.showAlert({
            appearance: "alert",
            message: error_description || "Failed to link account",
            autoClearAfter: defaultDissmissDelay,
          });
        }
        this.fetchOrgPaymentConfig();
      } else {
        this.linkAccount(code);
      }
    } else {
      this.fetchOrgPaymentConfig();
    }
  }
}

export default Copayment;
