import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Text,
  Card,
  PageHeader,
  Dialog,
  Icon,
  Breadcrumbs,
  PlaceholderParagraph,
} from "@innovaccer/design-system";
import axios from "../../../../helper/axios";
import "../style.css";

const breadcrumbData = [
  {
    label: "Assessment",
    link: "/settings/assessments",
  },
];

const baseUrl = `/organizations/assessment-configurations/`;
const defaulGetErrorMsg = "Config not found";
const defaulPostErrorMsg = "Failed to save";

const AssessmentSettings = () => {
  const [outreachMsg, setOutreachMsg] = useState("");
  const [outreachMsgLoading, setOutreachMsgLoading] = useState(false);
  const [outreachMsgError, setOutreachMsgError] = useState(false);
  const [lowRiskEduMsg, setLowRiskEduMsg] = useState("");
  const [mediumRiskEduMsg, setMediumRiskEduMsg] = useState("");
  const [highRiskEduMsg, setHighRiskEduMsg] = useState("");
  const [lowRiskEduMsgLoading, setLowRiskEduMsgLoading] = useState(false);
  const [mediumRiskEduMsgLoading, setMediumRiskEduMsgLoading] = useState(false);
  const [highRiskEduMsgLoading, setHighRiskEduMsgLoading] = useState(false);
  const [lowRiskEduMsgError, setLowRiskEduMsgError] = useState(false);
  const [mediumRiskEduMsgError, setMediumRiskEduMsgError] = useState(false);
  const [highRiskEduMsgError, setHighRiskEduMsgError] = useState(false);

  const history = useHistory();
  const match = useRouteMatch();

  const { assessmentId, name } = match.params;

  const options = {
    breadcrumbs: (
      <Breadcrumbs list={breadcrumbData} onClick={link => history.push(link)} />
    ),
  };

  const getOutreachMsg = () => {
    setOutreachMsgLoading(true);
    axios
      .get(`${baseUrl}${assessmentId}/outReach/smsMssg`)
      .then(
        ({
          data: {
            config: { value },
          },
        }) => {
          setOutreachMsg(value || "");
          setOutreachMsgLoading(false);
        }
      )
      .catch(errorData => {
        setOutreachMsgLoading(false);
        setOutreachMsgError(defaulGetErrorMsg);
      });
  };

  const getLowRiskEducationMsg = () => {
    setLowRiskEduMsgLoading(true);
    axios
      .get(`${baseUrl}${assessmentId}/educationalMsg/lowRisk`)
      .then(
        ({
          data: {
            config: { value },
          },
        }) => {
          setLowRiskEduMsgLoading(false);
          setLowRiskEduMsg(value || "");
        }
      )
      .catch(error => {
        setLowRiskEduMsgLoading(false);
        setLowRiskEduMsgError(defaulGetErrorMsg);
      });
  };

  const getMediumRiskEducationMsg = () => {
    setMediumRiskEduMsgLoading(true);
    axios
      .get(`${baseUrl}${assessmentId}/educationalMsg/mediumRisk`)
      .then(
        ({
          data: {
            config: { value },
          },
        }) => {
          setMediumRiskEduMsgLoading(false);
          setMediumRiskEduMsg(value || "");
        }
      )
      .catch(error => {
        setMediumRiskEduMsgLoading(false);
        setMediumRiskEduMsgError(defaulGetErrorMsg);
      });
  };

  const getHighRiskEducationMsg = () => {
    setHighRiskEduMsgLoading(true);
    axios
      .get(`${baseUrl}${assessmentId}/educationalMsg/highRisk`)
      .then(
        ({
          data: {
            config: { value },
          },
        }) => {
          setHighRiskEduMsgLoading(false);
          setHighRiskEduMsg(value || "");
        }
      )
      .catch(error => {
        setHighRiskEduMsgLoading(false);
        setHighRiskEduMsgError(defaulGetErrorMsg);
      });
  };

  const onOutreachMessageClick = () => {
    history.push(`${match.url}/outreachmessage`);
  };

  const onRiskMessageClick = riskType => {
    history.push(`${match.url}/customizedoutreach/${riskType}`);
  };

  const getLoaders = () => {
    return (
      <div className='outreach-message'>
        <PlaceholderParagraph length='large' />
        <PlaceholderParagraph length='large' />
        <PlaceholderParagraph length='large' />
        <PlaceholderParagraph length='large' />
      </div>
    );
  };

  useEffect(() => {
    getOutreachMsg();
    getLowRiskEducationMsg();
    getMediumRiskEducationMsg();
    getHighRiskEducationMsg();
  }, []);

  return (
    <div className='w-100 assessment-detail'>
      <Dialog {...options} />
      <PageHeader title={name} {...options} />
      <div className='d-flex justify-content-center'>
        <Card className='assessment p-6 mb-6 mt-6' shadow='light'>
          <div className='pb-3'>
            <Text appearance='default' weight='strong' className='font-size-16'>
              Outreach message
            </Text>
          </div>
          <div>
            <Text appearance='subtle'>Customize outreach message</Text>
          </div>
          <div className='d-flex justify-content-between pt-8'>
            <Text appearance='default' weight='strong'>
              Default message
            </Text>
            {outreachMsgLoading ? (
              getLoaders()
            ) : (
              <div
                className='d-flex outreach-message'
                onClick={onOutreachMessageClick}>
                <div
                  className='pr-10'
                  dangerouslySetInnerHTML={{ __html: outreachMsg }}></div>
                <span>
                  <Icon
                    appearance='basic'
                    name='navigate_next'
                    size='regular'
                  />
                </span>
              </div>
            )}
          </div>
        </Card>
      </div>
      <div className='d-flex justify-content-center'>
        <Card className='assessment p-6 mb-6 mt-3' shadow='light'>
          <div className='pb-3'>
            <Text appearance='default' weight='strong' className='font-size-16'>
              Customize outreach
            </Text>
          </div>
          <div>
            <Text appearance='subtle'>Manage assessment outcome</Text>
          </div>
          <div className='d-flex justify-content-between pt-8'>
            <Text appearance='default' weight='strong'>
              Low Risk
            </Text>
            {lowRiskEduMsgLoading ? (
              getLoaders()
            ) : (
              <div
                className='d-flex outreach-message'
                onClick={() => onRiskMessageClick("lowRisk")}>
                <div
                  className='pr-10'
                  dangerouslySetInnerHTML={{ __html: lowRiskEduMsg }}></div>
                <span>
                  <Icon
                    appearance='basic'
                    name='navigate_next'
                    size='regular'
                  />
                </span>
              </div>
            )}
          </div>
          <div className='d-flex justify-content-between pt-8'>
            <Text appearance='default' weight='strong'>
              Medium Risk
            </Text>
            {mediumRiskEduMsgLoading ? (
              getLoaders()
            ) : (
              <div
                className='d-flex outreach-message'
                onClick={() => onRiskMessageClick("mediumRisk")}>
                <div
                  className='pr-10'
                  dangerouslySetInnerHTML={{ __html: mediumRiskEduMsg }}></div>
                <span>
                  <Icon
                    appearance='basic'
                    name='navigate_next'
                    size='regular'
                  />
                </span>
              </div>
            )}
          </div>
          <div className='d-flex justify-content-between pt-8'>
            <Text appearance='default' weight='strong'>
              High Risk
            </Text>
            {highRiskEduMsgLoading ? (
              getLoaders()
            ) : (
              <div
                className='d-flex outreach-message'
                onClick={() => onRiskMessageClick("highRisk")}>
                <div
                  className='pr-10'
                  dangerouslySetInnerHTML={{ __html: highRiskEduMsg }}></div>
                <span>
                  <Icon
                    appearance='basic'
                    name='navigate_next'
                    size='regular'
                  />
                </span>
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AssessmentSettings;
