import React, { PureComponent } from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  LabelList,
  Label,
} from "recharts";

import Text from "@datashop/text";

import axios from "helper/axios";
import {
  WidgetCard,
  WidgetLoader,
  WidgetError,
  LegendLabel,
  Header,
  transformFilters,
  Legend,
} from ".";
import { withRouter } from "react-router-dom";
import { openUrl } from "helper/fileDownload";
import { getGlobalURL } from "config";

const providerLimit = 5;

export const getData = ({ filters }) => {
  return axios.get("/insights/graph/providers-virtual-visit", {
    params: transformFilters({ ...filters, limit: providerLimit }),
  });
};

export const getXLSXData = ({ filters }) => {
  return getData({ filters }).then(
    ({
      data: {
        virtualVisitStats: { providerStats = [] },
      },
    }) => {
      const schema = [
        { displayText: "Provider Name", key: "provider" },
        {
          displayText: "Number of calls scheduled",
          key: "requested",
          emptyValue: 0,
        },
        {
          displayText: "Number of calls completed",
          key: "completed",
          emptyValue: 0,
        },
      ];
      return {
        data: [
          ...providerStats,
          {
            provider: "Total",
            requested: providerStats.reduce(
              (totalCount, { requested }) => totalCount + requested,
              0
            ),
            completed: providerStats.reduce(
              (totalCount, { completed }) => totalCount + completed,
              0
            ),
          },
        ],
        schema,
        name: "Virtual Visits",
      };
    }
  );
};

class VirtualVisits extends PureComponent {
  static getData = getData;

  state = { providersLoading: false };

  showRespectiveData = (payload, status) => {
    const { filters } = this.props;
    const { statusMapping } = this.state;
    const { startDate, endDate, callType } = filters || {};
    const link = `${getGlobalURL("/virtualVisits")}?filters=${JSON.stringify({
      visitStatus: statusMapping[status],
      providers: [payload.provider_id],
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(callType && { callType: [callType] }),
    })}`;
    openUrl(link, { target: "_blank" });
  };

  getProvidersData = (filters = {}) => {
    this.setState(
      {
        providersLoading: true,
        providersError: false,
      },
      () => {
        getData({ filters })
          .then(({ data }) => {
            const { virtualVisitStats, statusMapping } = data;
            const { providerStats } = virtualVisitStats;
            this.setState({
              providersLoading: false,
              providerStats,
              statusMapping,
            });
          })
          .catch(error => {
            this.setState({
              providersError: true,
              providersLoading: false,
            });
          });
      }
    );
  };

  render() {
    const { providerStats, providersLoading, providersError } = this.state;

    return (
      <WidgetCard>
        {providersLoading ? (
          <WidgetLoader />
        ) : providersError ? (
          <WidgetError
            onRetry={() => this.getProvidersData(this.props.filters)}
          />
        ) : (
          <>
            <div className='d-flex justify-content-between w-100 mb-6'>
              <Header
                style={{
                  width: "78%",
                  fontSize: "20px",
                }}>
                {`Visits`}
              </Header>
              <div className='d-flex'>
                <Legend color='#0070E5' title='Scheduled' />
                <Legend color='#71C077' title='Completed' />
              </div>
            </div>
            <ResponsiveContainer>
              <BarChart
                data={providerStats}
                barGap={0}
                margin={{ top: 20, right: 30, left: 20, bottom: 30 }}>
                <CartesianGrid
                  horizontal={true}
                  vertical={false}
                  stroke='#F4F4F4'
                />
                <XAxis
                  minTickGap={0}
                  interval={0}
                  domain={["auto", "auto"]}
                  tickLine={false}
                  dataKey='provider'>
                  <Label
                    value={`Providers (Top ${providerLimit})`}
                    offset={16}
                    position='bottom'
                    fontWeight={700}
                  />
                </XAxis>
                <YAxis
                  label={{
                    value: "No. of patients",
                    angle: -90,
                    position: "insideLeft",
                    fontWeight: 700,
                  }}
                  tickLine={false}
                  axisLine={false}
                  allowDecimals={false}
                />
                <Bar
                  style={{ cursor: "pointer" }}
                  onClick={({ payload }) =>
                    this.showRespectiveData(payload, "requested")
                  }
                  radius={3}
                  dataKey='requested'
                  fill='#0070E5'
                  barSize={24}>
                  <LabelList dataKey='requested' position='top' />
                </Bar>
                <Bar
                  style={{ cursor: "pointer" }}
                  onClick={({ payload }) =>
                    this.showRespectiveData(payload, "completed")
                  }
                  radius={3}
                  dataKey='completed'
                  fill='#71C077'
                  barSize={24}>
                  <LabelList dataKey='completed' position='top' />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
      </WidgetCard>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.filters != prevProps.filters) {
      this.getProvidersData(this.props.filters);
    }
  }

  componentDidMount() {
    this.getProvidersData(this.props.filters);
  }
}

export default withRouter(VirtualVisits);
