import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import OutreachPerformance from "./outreachPerformance";
import axios from "helper/axios";
import { get } from "lodash";
import OutreachRecipients from "./outreachRecipients";
import { statusMap } from "../list/outreachHelper";
import If from "components/commons/If";
import PageError from "components/commons/Placeholder";
import OutreachHeader from "./outreachDetailHeader";

const tabs = [
  {
    label: "Performance",
    key: "performance",
    route: "performance"
  },
  {
    label: "Recipients",
    key: "recipients",
    route: "recipients"
  }
];

export const OutreachError = onRetry => (
  <PageError
    error
    iconStyle={{ color: "rgb(217, 55, 55)" }}
    title='Something went wrong'
    subTitle='It may be because of technical failure or network reasons. Please try again'
    onRetry={onRetry}
  />
);

class OutreachDetails extends Component {
  state = {
    performance: {
      loading: false
    },
    loading: false
  };

  componentDidCatch() {
    this.setState({ error: true });
  }

  fetchPerformanceData = () => {
    const outreachId = get(this.props, "match.params.outreachId", "");

    this.setState({
      performance: {
        loading: true
      },
      loading: true
    });
    axios
      .get(`/outreach/${outreachId}/performance`)
      .then(({ data }) => {
        const { sendEmail, sendSms, status, name } = data;
        this.setState({
          performance: {
            loading: false,
            data
          },
          data: {
            name,
            status,
            sendEmail,
            sendSms
          },
          loading: false,
          error: false
        });
      })
      .catch(() => {
        this.setState({
          performance: {
            loading: false,
            error: true
          },
          loading: false,
          error: true
        });
      });
  };

  render() {
    const {
      history,
      location: { pathname },
      match: { path, url }
    } = this.props;

    const activeTab = tabs.findIndex(tab => pathname.includes(tab.route));

    const { performance, data, loading, error } = this.state;
    const { name, sendSms, sendEmail, status } = data || {};

    if (error) {
      return <OutreachError onRetry={this.fetchPerformanceData} />;
    }

    return (
      <div className='w-100' style={{ background: "#f4f4f4" }}>
        <OutreachHeader
          name={name}
          sendSms={sendSms}
          sendEmail={sendEmail}
          status={status}
          statusMap={statusMap}
        />
        <>
          <If
            condition={status != "FAILED"}
            fallback={
              <PageError
                error
                iconStyle={{ color: "rgb(217, 55, 55)" }}
                title='Oops! Campaign failed'
                subTitle='It may be because of technical failure or network reasons. Please try again to run the campaign.'
              />
            }>
            <Switch>
              <Route
                path={`${path}/performance`}
                component={() => (
                  <OutreachPerformance
                    {...performance}
                    onRetry={this.fetchPerformanceData}
                  />
                )}
              />
              <Route
                exact
                path={`${path}/recipients`}
                component={() => (
                  <OutreachRecipients
                    outreachId={get(this.props, "match.params.outreachId", "")}
                    sendEmail={sendEmail}
                    sendSms={sendSms}
                  />
                )}
              />
              <Route
                component={() => (
                  <Redirect to={`${url}/performance`}></Redirect>
                )}
              />
            </Switch>
          </If>
        </>
      </div>
    );
  }

  componentDidMount() {
    this.fetchPerformanceData();
  }
}

export default OutreachDetails;
