/* eslint-disable react/prop-types */
import React from "react";
import { PageHeader, Button } from "@innovaccer/design-system";
import { Template } from "./template";

const PatientPageHeader = props => {
  const { config = [] } = props;
  return (
    <PageHeader
      title='Patients'
      actions={
        <div className='d-flex justify-content-end'>
          {config.map((conf, index) => (
            <div key={index}>
              {!conf.hidden && Template[conf.template](props, conf)}
            </div>
          ))}
        </div>
      }
    />
  );
};

export default PatientPageHeader;
