import { Button } from "@innovaccer/design-system";
import React from "react";
import { useHistory } from "react-router";
import "./style.css";

function DetailComponent({ imagePath, error, message }) {
  const history = useHistory();
  return (
    <div className='container'>
      <img src={imagePath} />
      <div className='content-message'>
        {error && (
          <span className='font-weight'>
            Uh Oh!
            <br />
          </span>
        )}
        {error && (
          <span className='font-weight'>
            {message || "Something went wrong"}
          </span>
        )}
        <span className='font-weight'>
          {!error && (message || "Integration is in process")}
        </span>
        <br />
        <p className='mt-5 content'>
          {!error ? (
            "Please check after 5 to 10 mins to view the data within InOffice"
          ) : (
            <>
              {"Sorry, the integration failed"} <br />
              {"Please contact innovaccer team"}
            </>
          )}
        </p>
      </div>
      {!error && (
        <Button
          className='btn-goto'
          appearance='primary'
          size='regular'
          onClick={() => history.push("login")}>
          Go to inoffice
        </Button>
      )}
    </div>
  );
}

export default DetailComponent;
