import React, { PureComponent } from "react";
import Card from "@datashop/card";
import { StyledText } from "./atoms";
import Button from "@datashop/button";
import Icon from "@datashop/icon";
import styled from "styled-components";
import Input from "@datashop/input";
import moment from "moment";

const CardWrapper = styled(Card)`
  max-width: 100%;
  width: 480px;
  padding: 32px;
  background-color: ${props => props.theme.datashop.palette.white.main};
`;

const Header = styled.div`
  margin-bottom: 32px;
`;

const OTPWrapper = styled.div`
  margin: 8px 0 0 0;
  display: flex;
`;

const OTPInput = styled(Input)`
  height: 50px;
  width: 60px;
  padding: 0;
  text-align: center;
  margin-right: 8px;

  :focus {
    outline: none;
    box-shadow: none;
  }
`;

const Code = styled.div``;

const Footer = styled.div`
  margin-top: 32px;
`;

const OTPLength = 4;

class OTPForm extends PureComponent {
  static defaultProps = {
    onChange: () => "",
    onComplete: () => "",
    onResend: () => "",
  };

  state = { otp: Array.from(Array(OTPLength), () => ""), resendTimer: 60 };

  onChange = (value, index) => {
    const { otp } = this.state;
    const newOtp = [...otp];
    newOtp[index] = (value || "").replace(otp[index], "");
    this.setState(
      {
        otp: newOtp,
      },
      () => {
        const otp = this.state.otp.join("");
        this.props.onChange(otp);
        if (otp.length === OTPLength) {
          this.props.onComplete(otp);
        }
        if (!value) {
          return;
        }
        if (index + 1 < OTPLength) {
          document.querySelector(`#OTP-input-${index + 1}`).focus();
        } else {
          document.querySelector(`#OTP-input-${index}`).blur();
        }
      }
    );
  };

  onResend = () => {
    this.setState(
      {
        resendTimer: 60,
      },
      this.props.onResend
    );
  };

  render() {
    const { email, onResend, disabled, error, ...rest } = this.props;
    const { otp, resendTimer } = this.state;

    return (
      <CardWrapper {...rest}>
        <Header>
          <StyledText size={20}>Enter verification code</StyledText>
          <StyledText fontWeight='light' light>
            We have sent a 4-digit verification code to your email.
            {email ? " " + email : ""}
          </StyledText>
        </Header>
        <Code>
          <StyledText fontWeight='bold'>Verification Code</StyledText>
          <OTPWrapper>
            {Array.from(Array(OTPLength), (__, index) => (
              <OTPInput
                disabled={disabled}
                key={index}
                id={`OTP-input-${index}`}
                name={index}
                placeholder='_'
                type='number'
                onChange={e => this.onChange(e.target.value, index)}
                value={otp[index]}
              />
            ))}
          </OTPWrapper>
        </Code>
        <Footer>
          <StyledText inline style={{ margin: "0 12px 8px 0" }}>
            Haven’t received the email?
          </StyledText>
          {!!resendTimer && (
            <StyledText fontWeight='light' light inline>
              {moment().startOf("day").seconds(resendTimer).format("mm:ss")}
            </StyledText>
          )}
          <Button disabled={!!resendTimer || disabled} onClick={this.onResend}>
            <Icon name='refresh' />
            &nbsp; Resend code
          </Button>
        </Footer>
      </CardWrapper>
    );
  }

  componentDidMount() {
    this.resendTimer = setInterval(() => {
      this.setState(({ resendTimer }) => ({
        resendTimer: resendTimer ? resendTimer - 1 : 0,
      }));
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.resendTimer);
  }
}

export default OTPForm;
