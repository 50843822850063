import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  PageHeader,
  Navigation,
  Button,
  Popover,
} from "@innovaccer/design-system";
import PageLoader from "components/commons/PageLoader";
import Placeholder from "components/commons/Placeholder";
import GridLayout from "./gridLayout";
import { allWidgetsXLSX } from "./widgets";
import Filters, { getLastNDays } from "./filters";
import styled from "styled-components";
import { LogoConfig } from "config";
import { Heading } from "@innovaccer/design-system";
import { formatDate } from "utils";
import { exportJson2Excel } from "helper/fileDownload";
import { setConfig, getConfig } from "services/orgService";
import AlertService, { defaultDissmissDelay } from "services/alertService";
import { Mixpanel } from "helper/mixpanelHelper";
import axios from "helper/axios";
import authService from "services/authService";

let pendingRequests = 0;
let requestInterceptor;
let responseInterceptor;

const setInterceptors = () => {
  requestInterceptor = axios.interceptors.request.use(
    function (config) {
      pendingRequests++;
      // show loader
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  responseInterceptor = axios.interceptors.response.use(
    function (response) {
      pendingRequests--;
      return response;
    },
    function (error) {
      pendingRequests--;
      return Promise.reject(error);
    }
  );
};

const removeInterceptors = () => {
  axios.interceptors.request.eject(requestInterceptor);
  axios.interceptors.response.eject(responseInterceptor);
};

export const defaultTabs = [
  {
    name: "virtualVisits",
    label: "Virtual visits",
    hidden: false,
    layout: [
      { i: "virtual-visits", x: 0, y: 0, w: 13, h: 10 },
      { i: "visits-distribution", x: 13, y: 0, w: 7, h: 10 },
      { i: "call-duration", x: 0, y: 10, w: 11, h: 12 },
      { i: "providers-virtual-visits", x: 0, y: 10, w: 20, h: 12 },
    ],
  },
  {
    name: "assessments",
    label: "Assessments",
    hidden: false,
    layout: [
      { i: "assessments", x: 0, y: 0, w: 13, h: 10 },
      { i: "risk-donut", x: 13, y: 0, w: 7, h: 10 },
      { i: "day-wise-distribution", x: 0, y: 10, w: 20, h: 10 },
      { i: "assessments-zip-map", x: 0, y: 20, w: 20, h: 15 },
    ],
  },
];

const getTabFilters = name => {
  const filters = localStorage.getItem(`dashboard/${name}`);
  return filters ? JSON.parse(filters) : undefined;
};

const setTabFilters = (name, filters) => {
  localStorage.setItem(`dashboard/${name}`, JSON.stringify(filters));
};

const defaultFilters = {
  ...getLastNDays(30),
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media print {
    zoom: 0.8;
    .layout {
      display: none;
    }
    .header {
      display: none;
    }
  }
`;

const PrintHeader = styled.div`
  display: none;
  @media print {
    display: flex;
  }
  position: relative;
  height: 48px;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  img {
    height: 24px;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
  }
`;

export const getDefaultFiltersForTabs = (tabs, filters = defaultFilters) =>
  tabs.reduce((result, { name }) => {
    result[name] = getTabFilters(name) || { ...filters };
    return result;
  }, {});

class Dashboard extends Component {
  state = {
    tabs: [],
    latestTabs: [],
    filters: {},
    reorganizing: false,
    disableActions: false,
    exportingPDF: false,
    loading: true,
    error: false,
  };

  componentDidCatch() {
    this.setState({
      error: true,
    });
  }

  getActiveTab = () => {
    const { location, match } = this.props;
    return this.state.tabs.find(
      ({ name }) =>
        location.pathname.replace(match.url, "").replace("/", "") === name
    );
  };

  handleTabChange = ({ name }) => {
    const { history, match } = this.props;
    history.push(`${match.url}/${name}`);
  };

  updateConfig = (tab, patch = {}) => {
    this.setState(({ latestTabs }) => {
      const newTabs = [...latestTabs];
      newTabs[tab] = { ...newTabs[tab], ...patch };
      return { latestTabs: newTabs };
    });
  };

  updateFilters = (tabName, patch = {}) => {
    this.setState(
      ({ filters }) => {
        const newfilters = { ...filters };
        newfilters[tabName] = { ...newfilters[tabName], ...patch };
        setTabFilters(tabName, { ...newfilters[tabName], ...patch });
        return { filters: newfilters };
      },
      () => {
        Mixpanel.track("Filters Used", {
          category: "TA-Dashboard",
          tab: tabName,
          filters: (this.state.filters || {})[tabName],
        });
      }
    );
  };

  saveConfiguration = () => {
    const { latestTabs } = this.state;
    this.setState({
      disableActions: true,
    });
    setConfig("/insights-tab/layout", latestTabs)
      .then(() => {
        AlertService.showAlert({
          appearance: "success",
          title: "Preferences saved!",
          autoClearAfter: defaultDissmissDelay,
        });
        this.setState({
          reorganizing: false,
          tabs: latestTabs,
          disableActions: false,
        });
      })
      .catch(() => {
        AlertService.showAlert({
          appearance: "alert",
          title: "Failed to save layout. Please retry",
          autoClearAfter: defaultDissmissDelay,
        });
        this.setState({
          disableActions: false,
        });
      });
    Mixpanel.track("Reorganised", { category: "TA-Dashboard" });
  };

  exportPDF = () => {
    AlertService.showAlert({
      title: "Genrating PDF...",
      autoClearAfter: 3000,
    });
    try {
      setInterceptors();
      this.setState(
        {
          exportingPDF: true,
        },
        () => {
          const timeoutId = setTimeout(() => {
            const intervalId = setInterval(() => {
              if (pendingRequests < 1) {
                const renderTimer = setTimeout(() => {
                  window.print();
                  removeInterceptors();
                  this.setState({
                    exportingPDF: false,
                  });
                  clearTimeout(renderTimer);
                }, 2000);
                clearInterval(intervalId);
              }
            }, 500);
            clearTimeout(timeoutId);
          }, 1000);
        }
      );
    } catch (err) {
      AlertService.showAlert({
        appearance: "alert",
        title: "Failed to generate PDF",
        message: "Something went wrong, please try again after sometime.",
        autoClearAfter: 2000,
      });
      this.setState({
        exportingPDF: false,
      });
      removeInterceptors();
    }
  };
  exportXLSX = () => {
    const { filters } = this.state;
    const { layout, name, label } = this.getActiveTab() || {};
    const tabFilters = filters[name];
    if (!layout) {
      return;
    }
    AlertService.showAlert({
      title: "Preparing Download...",
      autoClearAfter: defaultDissmissDelay,
    });
    Promise.all(
      layout.map(({ i }) =>
        allWidgetsXLSX[i]({ filters: tabFilters }).catch(() =>
          console.log(`failed to export data for ${i}`)
        )
      )
    )
      .then(data => {
        exportJson2Excel(
          data,
          `${label} Report_${formatDate(tabFilters.startDate)}_to_${formatDate(
            tabFilters.endDate
          )}`
        );
      })
      .catch(() => {
        AlertService.showAlert({
          appearance: "alert",
          title: "Failed to export data...",
          autoClearAfter: defaultDissmissDelay,
        });
      });
  };

  render() {
    const isAdmin = authService.isAdmin();
    const { match } = this.props;
    const {
      tabs,
      filters,
      loading,
      error,
      reorganizing,
      disableActions,
      exportingPDF,
    } = this.state;

    if (loading) {
      return <PageLoader />;
    }

    if (error) {
      return (
        <Placeholder
          error
          iconStyle={{ color: "var(--alert)" }}
          title='Something went wrong'
          subTitle='It may be because of technical failure or network reasons. Please try again'
          onRetry={this.fetchLayout}
        />
      );
    }

    return (
      <Wrapper>
        <PageHeader
          className='header'
          title='Dashboard'
          navigation={
            <Navigation
              menus={tabs.map(({ name, label }) => ({
                name,
                label,
                disabled: !!reorganizing,
              }))}
              active={this.getActiveTab()}
              onClick={this.handleTabChange}
            />
          }
          actions={
            <div className='d-flex justify-content-end'>
              {reorganizing ? (
                <>
                  <Button
                    icon='done'
                    appearance='success'
                    className='mr-3'
                    disabled={disableActions}
                    onClick={this.saveConfiguration}>
                    Save
                  </Button>
                  <Button
                    icon='close'
                    disabled={disableActions}
                    onClick={() =>
                      this.setState({ reorganizing: false, latestTabs: tabs })
                    }>
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  {isAdmin && (
                    <Button
                      icon='dashboard'
                      onClick={() => this.setState({ reorganizing: true })}>
                      Reorganize
                    </Button>
                  )}
                </>
              )}
              <Button
                disabled={exportingPDF}
                className='ml-4'
                icon='get_app'
                onClick={this.exportPDF}>
                Export to PDF
              </Button>
              <Popover
                position='bottom-end'
                triggerClass='flex-grow-0'
                trigger={
                  <Button className='ml-4' icon='arrow_drop_down'></Button>
                }>
                <div style={{ backgroundColor: "white", width: 120 }}>
                  <div
                    className='hoverable p-4 cursor-pointer'
                    onClick={this.exportXLSX}>
                    XLSX
                  </div>
                </div>
              </Popover>
            </div>
          }></PageHeader>
        <Switch>
          {tabs.map(({ name, label, layout }, tabIndex) => {
            const tabFilters = filters[name];

            return (
              <Route key={name} path={`${match.path}/${name}`}>
                <div className='p-6'>
                  <Filters
                    tab={{ name, label }}
                    filters={tabFilters}
                    onChange={filters => this.updateFilters(name, filters)}
                  />
                  <PrintHeader>
                    <img src={LogoConfig.src} />
                    <Heading size='m'>
                      {label} report for {formatDate(tabFilters.startDate)} -{" "}
                      {formatDate(tabFilters.endDate)}
                    </Heading>
                  </PrintHeader>
                  <GridLayout
                    exportingPDF={exportingPDF}
                    layout={layout}
                    filters={tabFilters}
                    updateLayout={layout =>
                      this.updateConfig(tabIndex, { layout })
                    }
                    reorganizing={reorganizing}
                  />
                </div>
              </Route>
            );
          })}
          <Route
            component={() => <Redirect to={`${match.path}/${tabs[0].name}`} />}
          />
        </Switch>
      </Wrapper>
    );
  }

  fetchLayout = () => {
    this.setState({
      loading: true,
      error: false,
    });
    getConfig("/insights-tab/layout")
      .then(
        ({
          data: {
            config: { value },
          },
        }) => {
          const tabs =
            (value &&
              value.filter(
                v => v.hidden === undefined || v.hidden === false
              )) ||
            defaultTabs;
          this.setState({
            loading: false,
            tabs,
            latestTabs: tabs,
            filters: getDefaultFiltersForTabs(tabs),
          });
        }
      )
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  };

  componentDidMount() {
    Mixpanel.track("Dashboard Accessed", { category: "TA-Dashboard" });
    this.fetchLayout();
  }

  componentWillUnmount() {
    removeInterceptors();
  }
}

export default Dashboard;
