import React, { PureComponent } from "react";
import { Table } from "@innovaccer/design-system";
import { getCurrentUsers } from "services/userService";
import {
  commonSchemaOptions,
  cellRenderers,
  adminOptions,
  defaultPagination,
} from "./templates";
import { formatPhone } from "utils";
import Placeholder from "components/commons/Placeholder";

const EmptyPlaceholder = () => {
  return (
    <Placeholder
      title={`No one's inactive`}
      subTitle={`We don't see any inactive users because you have not deactivated anyone yet.`}
    />
  );
};

class InactiveUsers extends PureComponent {
  state = {
    key: 0,
    optionsData: { practices: [], roles: [] },
    loading: false,
    error: false,
  };

  columns = [
    {
      cellType: "AVATAR_WITH_META_LIST",
      displayName: `Name and Email`,
      name: "name",
      translate: a => {
        return {
          title: `${a.patientFirstName} ${a.patientLastName}`,
          firstName: a.patientFirstName,
          lastName: a.patientLastName,
          metaList: [a.email, formatPhone(a.phoneNumber)],
        };
      },
      width: "38%",
    },

    {
      displayName: "Role",
      name: "role",
      width: "18%",
      cellRenderer: opts => {
        const { data } = opts;
        return cellRenderers.role({
          ...opts,
          props: {
            disabled: true,
            options: [
              {
                value: data.accessRole,
                label: data.accessRole,
                selected: true,
              },
            ],
          },
        });
      },
    },
    {
      displayName: "NPI",
      name: "npi",
      width: "15%",
    },
    {
      displayName: "Practice",
      name: "practices",
      width: "18%",
      cellRenderer: opts => {
        const { data } = opts;
        return cellRenderers.practice({
          ...opts,
          props: {
            disabled: true,
            options: (data.practices || []).map(name => ({
              value: name,
              label: name,
              selected: true,
            })),
          },
        });
      },
    },
    {
      displayName: "Admin",
      name: "isAdmin",
      width: "10%",
      cellRenderer: opts => {
        const { data } = opts;
        return cellRenderers.admin({
          ...opts,
          props: {
            disabled: true,
            options: [
              {
                ...adminOptions.find(({ value }) => value === !!data.isAdmin),
                selected: true,
              },
            ],
          },
        });
      },
    },
  ];

  schema = this.columns.map(column => ({
    ...commonSchemaOptions,
    ...column,
  }));

  loaderSchema = this.columns.map(({ cellRenderer, ...rest }) => rest);

  getAPIParams = ({ filterList, page, pageSize, searchTerm, sortingList }) => {
    return {
      status: "inactive",
      from_page: page,
      per_page_limit: pageSize,
    };
  };

  getUsers = filters => {
    return getCurrentUsers(this.getAPIParams(filters)).then(
      ({ data: { data, totalPatients, metadata } }) => {
        this.setState({
          optionsData: metadata,
        });
        return {
          count: totalPatients,
          data,
          schema: this.schema,
        };
      }
    );
  };

  render() {
    return (
      <Table
        errorTemplate={EmptyPlaceholder}
        key={this.state.key}
        async
        withHeader
        headerOptions={{ dynamicColumn: false }}
        showMenu={false}
        fetchData={this.getUsers}
        loaderSchema={this.loaderSchema}
        withPagination
        pageSize={defaultPagination.size}
      />
    );
  }
}

export default InactiveUsers;
