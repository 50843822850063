import styled from "styled-components";

const FlexBox = styled.div`
  display: flex;
  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  ${props =>
    props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${props => props.direction && `flex-direction: ${props.direction};`}
`;

export default FlexBox;
