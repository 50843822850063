import React from "react";
import { PlaceholderParagraph } from "@innovaccer/design-system";
import moment from "moment";

export const Capitalize = (String.prototype.capitalize = function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
});

export const GetAge = DOB => {
  if (!DOB || DOB === "") {
    return "";
  }
  var current = moment();
  var dob = moment(DOB);
  var age_in_years = current.diff(dob, "years");
  var age_in_months = current.diff(dob, "months");
  var age_in_days = current.diff(dob, "days");
  if (age_in_years > 0) {
    return age_in_years + " yrs";
  } else if (age_in_months > 0) {
    return age_in_months + " months";
  }
  if (age_in_days > 0) {
    return age_in_days + " days";
  }
};

export const TextWithMetaList = props => {
  let { onClickHandler, text, metaText, isLoading } = props;
  return (
    <>
      {isLoading ? (
        <PlaceholderParagraph length='medium' />
      ) : (
        <div
          onClick={onClickHandler ? onClickHandler : () => {}}
          className='name-container'>
          <div>
            <div className='name'>{text}</div>
            <div className='name-meta-list d-flex'>{metaText}</div>
          </div>
        </div>
      )}
    </>
  );
};
