import React, { useState, useCallback, useEffect } from "react";
import { RoutesWrapper, SubRoutesWrapper, CardsWrapper } from "../commons";
import {
  PageHeader,
  Button,
  Text,
  Breadcrumbs,
} from "@innovaccer/design-system";
import {
  NetworkCard,
  Name,
  Instructions,
  DetailsRow,
  WelcomeMessage,
  Address,
  Phone,
  CheckBoxRow,
  ErrorState,
  CopyText,
} from "../network/atoms";
import {
  getNetworkInfo,
  getPracticeDetails,
  updatePracticeDetails,
} from "services/orgService";
import AlertService, { defaultDissmissDelay } from "services/alertService";
import PageLoader from "components/commons/PageLoader";
import { getPracticeFDUrl } from "utils";
import AuthService from "../../../services/authService";

const showAlert = () => {
  AlertService.showAlert({
    appearance: "alert",
    message: "Failed to update details",
    autoClearAfter: defaultDissmissDelay,
  });
};

const DetailsCard = ({ details, updateDetails }) => {
  const { name: defaultName, phoneNo, address: defaultAddress } = details;
  const [editable, makeEditable] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [name, setName] = useState(defaultName);
  const [phone, setPhone] = useState(phoneNo);
  const [address, setAddress] = useState(defaultAddress);

  const isPhoneInvalid = phone && (phone || "").length < 10;

  useEffect(() => {
    setName(defaultName);
    setPhone(phoneNo);
    setAddress(defaultAddress);
  }, [editable]);

  const saveDetails = () => {
    setUpdating(true);
    updateDetails({ name, phoneNo: phone, address })
      .then(() => {
        setUpdating(false);
        makeEditable(false);
      })
      .catch(() => {
        setUpdating(false);
        showAlert();
      });
  };

  return (
    <NetworkCard
      disabled={updating}
      title='Details'
      subtitle='Manage essential information related to your practice.'
      footer={
        editable ? (
          <>
            <Button
              key='cancel'
              className='mr-4'
              onClick={() => makeEditable(false)}>
              Cancel
            </Button>
            <Button key='save' onClick={saveDetails} appearance='primary'>
              Save Changes
            </Button>
          </>
        ) : (
          <Button
            key='edit'
            onClick={() => makeEditable(true)}
            appearance='primary'>
            Edit
          </Button>
        )
      }>
      <Name
        placeholder='Practice name'
        editable={editable}
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Phone
        editable={editable}
        error={isPhoneInvalid}
        value={phone}
        onBlur={() => isPhoneInvalid && setPhone(phoneNo)}
        onChange={(e, phone) => setPhone(phone)}
      />
      <Address
        placeholder='402 E Holt Blvd, CA 91761 United States '
        editable={editable}
        value={address}
        onChange={e => setAddress(e.target.value)}
      />
    </NetworkCard>
  );
};

const WebLinksCard = ({ networkDetails, details }) => {
  const { subDomain } = networkDetails;
  const { practiceKey } = details;

  return (
    <NetworkCard
      title='Practice web links'
      subtitle='Weblinks which will be used by your teammates and patients.'>
      <DetailsRow
        label='Front door web address'
        subLabel='This is your clinic’s web address which can be embedded to your website or can be used directly to let your patients schedule appointments.'>
        {subDomain ? (
          <CopyText text={getPracticeFDUrl(subDomain, practiceKey)} />
        ) : (
          <Text weight='medium' appearance='destructive'>
            Set subdomain first to enable practice level front door web address
          </Text>
        )}
      </DetailsRow>
    </NetworkCard>
  );
};

const PresenceCard = ({ networkDetails, details, updateDetails }) => {
  const {
    instructionVideoUrl: networkInstructionVideoUrl,
    examRoomLogoUrl: networkExamRoomLogoUrl,
    welcomeMessage: networkWelcomeMessage,
    instructionVideo: networkInstructionVideo,
  } = networkDetails;
  const {
    instructionVideo,
    welcomeMessage: defaultWelcomeMessage,
    overrideNetworkPresence,
    instructionVideoUrl,
    examRoomLogoUrl,
  } = details;

  const [editable, makeEditable] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [override, setOverride] = useState(!!overrideNetworkPresence);

  const [welcomeMessage, setWelcomeMessage] = useState(defaultWelcomeMessage);

  const [instImage, setInstImage] = useState(null);

  const [instVideo, setInstVideo] = useState(instructionVideoUrl);

  const [isVideo, setIsVideo] = useState(instructionVideo);

  useEffect(() => {
    setInstImage(null);
    setWelcomeMessage(defaultWelcomeMessage);
    setInstVideo(instructionVideoUrl);
    setIsVideo(instructionVideo);
    setOverride(!!overrideNetworkPresence);
  }, [editable]);

  const saveDetails = () => {
    setUpdating(true);
    updateDetails({
      welcomeMessage,
      overrideNetworkPresence: override,
      instructionVideoUrl: instVideo,
      examRoomLogoUrl: instImage,
      instructionVideo: isVideo,
    })
      .then(() => {
        setUpdating(false);
        makeEditable(false);
      })
      .catch(() => {
        setUpdating(false);
        showAlert();
      });
  };

  return (
    <NetworkCard
      disabled={updating}
      title='Waiting room presence'
      subtitle='Create a personalized experience for your clinic’s waiting room.'
      footer={
        editable ? (
          <>
            <Button
              key='cancel'
              className='mr-4'
              onClick={() => makeEditable(false)}>
              Cancel
            </Button>
            <Button key='save' onClick={saveDetails} appearance='primary'>
              Save Changes
            </Button>
          </>
        ) : (
          <Button
            key='edit'
            onClick={() => makeEditable(true)}
            appearance='primary'>
            Edit
          </Button>
        )
      }>
      <CheckBoxRow
        labelProps={{
          label: "Override network presence settings",
          subLabel:
            "This will allow you to put a separate logo, message and instructions for this practice.",
        }}
        disabled={!editable}
        checked={override}
        onChange={(___, selected) => setOverride(selected)}
      />
      <WelcomeMessage
        editable={editable && override}
        value={
          override
            ? welcomeMessage || networkWelcomeMessage
            : networkWelcomeMessage
        }
        onChange={e => setWelcomeMessage(e.target.value)}
      />
      <Instructions
        editable={editable && override}
        isVideo={override ? !!isVideo : !!networkInstructionVideo}
        defaultImageUrl={
          override
            ? examRoomLogoUrl || networkExamRoomLogoUrl
            : networkExamRoomLogoUrl
        }
        file={instImage}
        onChange={(content, video) => {
          if (video) {
            setInstVideo(content);
            setIsVideo(content && video);
          } else {
            setInstImage(content);
            setIsVideo(content ? false : !!instVideo);
          }
        }}
        defaultVideoUrl={
          override
            ? instVideo || networkInstructionVideoUrl
            : networkInstructionVideoUrl
        }></Instructions>
    </NetworkCard>
  );
};

const PracticeDetails = ({ history, match }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [networkDetails, setNetworkDetails] = useState({});
  const [details, setDetails] = useState({});

  const { practiceId } = match.params;
  const currentUser = AuthService.getUser();

  const fetchDetails = useCallback(() => {
    setLoading(true);
    setError(false);
    Promise.all([getNetworkInfo(), getPracticeDetails(practiceId)])
      .then(([{ data: networkDetails }, { data: practiceDetails }]) => {
        setNetworkDetails(networkDetails);
        setDetails(practiceDetails);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  const updateDetails = useCallback(payload => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (![null, undefined].includes(value)) {
        formData.append(key, value);
      }
    });
    // const newDetails = { ...details, ...payload };

    return updatePracticeDetails(practiceId, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then(({ data }) => {
      setDetails(data);
    });
  }, []);

  useEffect(() => {
    fetchDetails();
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorState onRetry={fetchDetails} />;
  }

  return (
    <RoutesWrapper>
      <PageHeader
        breadcrumbs={
          <Breadcrumbs
            list={[
              {
                label: "Practices",
                link: "/settings/practices",
              },
            ]}
            onClick={link => history.push(link)}
          />
        }
        title={details.name || "Practice"}
      />
      <SubRoutesWrapper overflow='auto'>
        <CardsWrapper>
          <DetailsCard
            networkDetails={networkDetails}
            details={details}
            updateDetails={updateDetails}
          />
          {currentUser.organization_category !== "INDIVIDUAL" && (
            <WebLinksCard
              networkDetails={networkDetails}
              details={details}
              updateDetails={updateDetails}
            />
          )}
          {currentUser.organization_category !== "INDIVIDUAL" && (
            <PresenceCard
              networkDetails={networkDetails}
              details={details}
              updateDetails={updateDetails}
            />
          )}
        </CardsWrapper>
      </SubRoutesWrapper>
    </RoutesWrapper>
  );
};

export default PracticeDetails;
