import React from "react";
import Icon from "@datashop/icon";
import theme from "@datashop/theme";
import Text from "@datashop/text";
import Button from "@datashop/button";
import styled from "styled-components";
import If from "./If";

const Wrapper = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-self: center;
  align-items: center;
  justify-content: center;
`;

export default ({
  error,
  onRetry,
  title,
  subTitle,
  icon,
  iconStyle = {},
  children
}) => {
  return (
    <Wrapper>
      <If
        condition={!!error}
        fallback={
          <img
            style={{ height: 160, marginBottom: 32 }}
            src={`${process.env.PUBLIC_URL}/img/emptyPlaceholder.png`}
          />
        }>
        <Icon
          name={icon || "warning"}
          style={{
            fontSize: 88,
            color: theme.datashop.palette.stone.dark,
            marginBottom: 32,
            ...iconStyle
          }}
        />
      </If>
      {title && (
        <Text
          fontWeight='bold'
          style={{ fontSize: 20, marginBottom: 12, maxWidth: 320 }}>
          {title}
        </Text>
      )}
      {subTitle && (
        <Text
          style={{
            marginBottom: 18,
            color: theme.datashop.palette.neutral.lighter,
            maxWidth: 320
          }}>
          {subTitle}
        </Text>
      )}
      {onRetry && (
        <Button appearance='secondary' onClick={onRetry}>
          <Icon name='refresh' />
          &nbsp; Retry
        </Button>
      )}
      {children}
    </Wrapper>
  );
};
