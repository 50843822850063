import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalDescription,
  Button,
} from "@innovaccer/design-system";

class ConfirmService extends Component {
  static list = [];

  static UpdateList() {
    window.dispatchEvent(new Event("confirmService.onChange"));
  }

  static setLoading(id, loading) {
    const index = ConfirmService.list.findIndex(item => item.id === id);
    if (!ConfirmService.list[index]) {
      return;
    }
    ConfirmService.list[index].loading = loading;
    ConfirmService.UpdateList();
  }

  static close(id) {
    const indexToClose = ConfirmService.list.findIndex(item => item.id === id);
    ConfirmService.list.splice(indexToClose, 1);

    ConfirmService.UpdateList();

    return indexToClose > -1;
  }

  static confirm(config = {}) {
    const options = {
      id: Date.now(),
      heading: "Confirmation",
      dismissText: "Cancel",
      confirmationText: "Confirm",
      appearance: "primary",
      ...config,
    };
    options.close = () => ConfirmService.close(options.id);
    options.setLoading = loading =>
      ConfirmService.setLoading(options.id, loading);
    const promise = new Promise((res, rej) => {
      options.confirm = () => res(options);
      options.cancel = () => rej(options);
    });
    options.promise = promise;

    ConfirmService.list.push(options);

    ConfirmService.UpdateList();

    return promise;
  }

  render() {
    return (
      <>
        {ConfirmService.list.map(
          ({
            dimension,
            icon,
            heading,
            subHeading,
            body,
            description,
            dismissText,
            confirmationText,
            appearance,
            reverse,
            confirm,
            cancel,
            loading,
          }) => {
            const actions = [
              <Button disabled={loading} appearance='basic' onClick={cancel}>
                {dismissText}
              </Button>,
              <Button
                className='ml-4'
                disabled={loading}
                appearance={appearance}
                onClick={confirm}>
                {confirmationText}
              </Button>,
            ];

            return (
              <Modal dimension={dimension} open={true} closeOnEscape={true}>
                <ModalHeader
                  onClose={cancel}
                  icon={icon}
                  heading={heading}
                  subHeading={subHeading}
                />
                <ModalBody>
                  <ModalDescription
                    description={description}
                    removePadding={true}
                  />
                  {body}
                </ModalBody>
                <ModalFooter>
                  {reverse ? actions.reverse() : actions}
                </ModalFooter>
              </Modal>
            );
          }
        )}
      </>
    );
  }

  listUpdated = () => {
    this.forceUpdate();
  };

  componentDidMount() {
    window.addEventListener("confirmService.onChange", this.listUpdated);
  }

  componentWillUnmount() {
    window.removeEventListener("confirmService.onChange", this.listUpdated);
  }
}

export default ConfirmService;
