import React, { Component, Fragment } from "react";
import {
  StyledOutreachReveal,
  ColumnWrapper,
  RowWrapper,
  Label,
  MediumWrapper,
  TextWithBg
} from "../../Patients/styles";

import Text from "@datashop/text";
import Radio from "@datashop/radio";
import Dropdown from "@datashop/dropdown";
import Input from "@datashop/input";
import Icon from "@datashop/icon";
import Checkbox from "@datashop/checkbox";
import Popup from "@datashop/popup";
import Button from "@datashop/button";
import Toast from "@datashop/toast";

import OutreachReview from "./SendOutreachReview";
import Message from "../ContentEditable";
import axios from "../../../helper/axios";
import theme from "@datashop/theme";
import { Mixpanel } from "../../../helper/mixpanelHelper";

const radioBtns = [
  {
    label: "Assessment",
    value: "assessment",
    id: "assessment",
    mediumKey: "send_email",
    messageKey: "email_message"
  },
  {
    label: "Message",
    value: "message",
    id: "message",
    mediumKey: "send_sms",
    messageKey: "sms_message"
  }
];
const assessmentTypes = [{ label: "COVID-19", value: "COVID-19" }];
const outReachMediumTypes = [
  { title: "Text Message", icon: "message", key: "send_sms" },
  { title: "E-mail", icon: "drafts", value: "mail", key: "send_email" }
];
const initConfig = {
  name: "",
  category: "",
  send_sms: false,
  send_email: false
};

const outreachMixpanelCategory = {
  patients: "TA-Patients",
  assessments: "TA-Assessments"
};

class OutreachReveal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outreachType: 0,
      selectedAssessment: [],
      assessmentPlaceholder: "Select",
      fetchingMessageTemplate: false,
      personalizeOpen: false,
      reviewMode: false,
      config: initConfig
    };
    this.contentEditableRef = React.createRef();
    this.sms_message = "";
    this.assessmentIntro = "";
    this.messageIntro = "";
    this.assessmentMsgToSave = "";
    this.normalMsgToSave = "";
  }

  canReview = () => {
    const { config, outreachType } = this.state;
    let canReview = true;
    canReview = canReview && config.name ? true : false;
    canReview =
      canReview && (config.send_email || config.send_sms) ? true : false;
    if (outreachType === 0) {
      canReview = canReview && this.assessmentIntro ? true : false;
      canReview = canReview && config.category ? true : false;
    } else {
      canReview = canReview && this.messageIntro ? true : false;
    }
    return canReview;
  };

  handleOutreachTypeChange = index => {
    const { config = {}, previousConfig = {}, outreachType } = this.state;
    const { name, send_email, send_sms } = config;
    let configToSave = {
      name,
      send_email,
      send_sms
    };
    this.setState({
      outreachType: index,
      config: {
        ...this.state.config,
        name: previousConfig.name ? previousConfig.name : "",
        send_email: previousConfig.send_email
          ? previousConfig.send_email
          : false,
        send_sms: previousConfig.send_sms ? previousConfig.send_sms : false
      },
      outreachType: index,
      previousConfig: configToSave
    });
  };
  handleAssessmentSelect = selected => {
    let newConfig = { ...this.state.config };
    newConfig.category = selected[0];
    this.setState({
      selectedAssessment: selected,
      assessmentPlaceholder: selected.label,
      config: newConfig
    });
  };
  toggleReview = () => {
    this.setState({
      reviewMode: !this.state.reviewMode
    });
  };

  sendToOutreach = () => {
    const { config, outreachType } = this.state;
    const { selectedRows = [], parent } = this.props;
    let recipients = selectedRows.map(row => {
      return {
        firstName: row.patientFirstName,
        lastName: row.patientLastName,
        phoneNo: row.contactNumber,
        email: row.email
      };
    });
    let payload = { ...config };
    payload.type = outreachType === 0 ? "ASSESSMENT" : "MESSAGE";
    if (outreachType === 0) {
      payload.sms_message = this.assessmentMsgToSave;
      payload.email_message = this.assessmentMsgToSave;
    } else {
      payload.sms_message = this.normalMsgToSave;
      payload.email_message = this.normalMsgToSave;
    }
    // payload.sms_message = this.state.messageToSend;
    // payload.email_message = this.state.messageToSend;
    payload.assessment_id =
      outreachType === 0 ? "5e7b840c98c5bf3ad547f6a2" : null;
    payload.recipients = recipients;
    this.setState({
      sending: true
    });
    axios
      .post(`/outreach/send_outreach`, payload)
      .then(data => {
        let mixPanelPayload = {
          category: outreachMixpanelCategory[parent]
        };
        if (payload.sms_message) mixPanelPayload.text = payload.sms_message;
        if (payload.email_message)
          mixPanelPayload.email = payload.email_message;
        Mixpanel.track("Send Outreach", mixPanelPayload);
        this.resetSendToOutreach("success");
      })
      .catch(error => {
        this.setState({
          sending: false,
          sendingError: true
        });
      });
  };

  resetSendToOutreach = type => {
    this.assessmentIntro = "";
    this.messageIntro = "";
    this.setState(
      {
        outreachType: 0,
        selectedAssessment: [],
        assessmentPlaceholder: "Select",
        sending: false,
        sendingError: false,
        config: initConfig,
        previousConfig: {},
        reviewMode: false
      },
      () => {
        if (type === "success") {
          this.props.onSendSucess();
        } else {
          this.props.onClose();
        }
      }
    );
  };

  renderOutreachMedium = () => {
    const { outreachType, config } = this.state;
    return (
      <RowWrapper>
        {outReachMediumTypes.map((medium, index) => {
          return (
            <MediumWrapper>
              <RowWrapper style={{ flex: 1 }}>
                <Icon
                  style={{
                    height: "20px",
                    width: "20px",
                    lineHeight: "20px",
                    color: config[medium.key] ? "#2F2F2F" : "#A6A6A6"
                  }}
                  name={medium.icon}
                />
                <Text style={{ color: "#2F2F2F" }}>{medium.title}</Text>
              </RowWrapper>
              <Checkbox
                style={{
                  height: "16px",
                  width: "16px",
                  flex: 1
                }}
                name={medium.name}
                checked={config[medium.key]}
                value={medium.value}
                id={index}
                onChange={() => {
                  let newConfig = { ...config };
                  newConfig[medium.key] = !newConfig[medium.key];
                  this.setState({
                    config: newConfig
                  });
                }}
              />
            </MediumWrapper>
          );
        })}
      </RowWrapper>
    );
  };

  fetchMessageTemplate = () => {
    const { outreachType } = this.state;
    this.setState({ fetchingMessageTemplate: true });

    // todo: fix hardcoded assessment id;
    axios
      .get(
        `/organizations/assessment-configurations/5e7b840c98c5bf3ad547f6a2/outReach/smsMssg?format_type=sample_text`
      )
      .then(({ data: { config } }) => {
        Mixpanel.track("Import Template Used", {
          category: outreachMixpanelCategory[this.props.parent]
        });
        if (outreachType === 0) {
          this.assessmentIntro = config.value;
          this.assessmentMsgToSave = config.value;
        } else {
          this.messageIntro = config.value;
          this.normalMsgToSave = config.value;
        }
        this.setState({
          fetchingMessageTemplate: false,
          messageToSend: config.value
        });
      })
      .catch(() => {
        this.setState({ fetchingMessageTemplate: false });
      });
  };

  handlePersonalise = placeholder => {
    const { outreachType } = this.state;
    let html = outreachType === 0 ? this.assessmentIntro : this.messageIntro;
    let message =
      outreachType === 0 ? this.assessmentMsgToSave : this.normalMsgToSave;
    html = `${html} <${placeholder}>`;
    message = `${message} <${placeholder}>`;
    this.updateMessage(html, message);
  };

  renderPersonalizeOptions = () => {
    const { personalizeOpen } = this.state;
    const btn = (
      <RowWrapper style={{ cursor: "pointer" }}>
        <Text
          style={{ fontSize: "12px", color: "#868686", marginRight: "4px" }}>
          PERSONALISE USING
        </Text>
        <Icon
          name={personalizeOpen ? "expand_less" : "expand_more"}
          style={{ width: "16px", height: "16px", color: "#A6A6A6" }}
        />
      </RowWrapper>
    );
    return (
      <Popup
        trigger={btn}
        open={personalizeOpen}
        onToggle={open => this.setState({ personalizeOpen: open })}
        position='BottomLeft'>
        <RowWrapper>
          <TextWithBg
            onClick={() => {
              this.handlePersonalise("firstName");
            }}
            style={{ marginRight: "8px", fontSize: "14px", cursor: "pointer" }}>
            Fisrt Name
          </TextWithBg>
          <TextWithBg
            onClick={() => {
              this.handlePersonalise("lastName");
            }}
            style={{ fontSize: "14px", cursor: "pointer" }}>
            Last Name
          </TextWithBg>
        </RowWrapper>
      </Popup>
    );
  };

  updateMessage = (el, value) => {
    const { outreachType } = this.state;
    if (outreachType === 0) {
      this.assessmentIntro = el;
      this.assessmentMsgToSave = value;
    } else {
      this.messageIntro = el;
      this.normalMsgToSave = value;
    }
    this.setState({
      refresh: true
    });
  };

  render() {
    const { open } = this.props;
    const {
      outreachType,
      selectedAssessment,
      assessmentPlaceholder,
      fetchingMessageTemplate,
      reviewMode,
      config = {},
      sending,
      sendingError
    } = this.state;
    return (
      <StyledOutreachReveal
        open={open}
        closeButton
        containerStyle={{
          boxSizing: "border-box",
          overflow: "unset",
          height: "100%"
        }}
        onClose={() => this.resetSendToOutreach()}>
        <ColumnWrapper style={{ height: "100%" }}>
          <Text
            style={{
              fontSize: "16px",
              color: "#151414",
              marginBottom: "25px"
            }}>
            {this.props.selectedRows &&
              `Send outreach to ${this.props.selectedRows.length} ${
                this.props.selectedRows.length > 1 ? "patients" : "patient"
              }`}
          </Text>
          {!reviewMode ? (
            <Fragment>
              <ColumnWrapper style={{ marginBottom: "26px" }}>
                <Label style={{ marginBottom: "8px" }}>Type</Label>
                <RowWrapper>
                  {radioBtns.map((radio, index) => {
                    return (
                      <Radio
                        style={{
                          marginRight:
                            index < radioBtns.length - 1 ? "26px" : "0px"
                        }}
                        key={radio.value}
                        value={radio.value}
                        label={radio.label}
                        id={radio.id}
                        label={radio.label}
                        name='Assessment'
                        checked={index === outreachType}
                        onChange={() => {
                          this.handleOutreachTypeChange(index);
                        }}
                      />
                    );
                  })}
                </RowWrapper>
              </ColumnWrapper>
              {outreachType === 0 && (
                <ColumnWrapper style={{ marginBottom: "24px" }}>
                  <Label style={{ marginBottom: "4px" }}>Assessment</Label>
                  <Dropdown
                    style={{ width: "384px", height: "32px" }}
                    options={assessmentTypes}
                    search={false}
                    placeholder={assessmentPlaceholder}
                    onChange={this.handleAssessmentSelect}
                    selected={selectedAssessment}
                  />
                </ColumnWrapper>
              )}
              <ColumnWrapper style={{ marginBottom: "24px" }}>
                <Label style={{ marginBottom: "4px" }}>Name</Label>
                <Input
                  value={config.name}
                  style={{ fontFamily: "inherit", width: "384px" }}
                  placeholder='Give name to this outreach, e.g. Wellness Visit'
                  onChange={e => {
                    let newConfig = { ...config };
                    newConfig.name = e.target.value;
                    this.setState({
                      config: newConfig
                    });
                  }}
                />
              </ColumnWrapper>
              <ColumnWrapper style={{ marginBottom: "24px" }}>
                <Label style={{ marginBottom: "4px" }}>Medium</Label>
                {this.renderOutreachMedium()}
              </ColumnWrapper>
              <ColumnWrapper>
                <RowWrapper style={{ justifyContent: "space-between" }}>
                  <Text style={{ fontWeight: 600, lineHeight: "16px" }}>
                    Introductory Message
                  </Text>
                  <Button
                    dimension='tiny'
                    disabled={outreachType === 1 || fetchingMessageTemplate}
                    onClick={this.fetchMessageTemplate}>
                    Import template
                  </Button>
                </RowWrapper>
                <Message
                  updateMessage={this.updateMessage}
                  disabled={fetchingMessageTemplate}
                  message={
                    outreachType === 0
                      ? this.assessmentIntro
                      : this.messageIntro
                  }
                />
                <RowWrapper style={{ justifyContent: "space-between" }}>
                  {this.renderPersonalizeOptions()}
                  <RowWrapper
                    style={{ color: theme.datashop.palette.neutral.lighter }}>
                    <Icon name='info' style={{ fontSize: "14px !important" }} />
                    &nbsp;
                    <Text style={{ fontSize: 12 }}>
                      Do not share PHI via text/email outreach
                    </Text>
                  </RowWrapper>
                </RowWrapper>
              </ColumnWrapper>
              <RowWrapper
                style={{
                  justifyContent: "flex-end",
                  botton: 0,
                  marginTop: "auto"
                }}>
                <Button
                  style={{ marginRight: "8px" }}
                  onClick={() => {
                    this.resetSendToOutreach();
                  }}>
                  Close
                </Button>
                <Button
                  disabled={!this.canReview()}
                  appearance='primary'
                  onClick={() => this.setState({ reviewMode: true })}>
                  Review
                </Button>
              </RowWrapper>
            </Fragment>
          ) : (
            <OutreachReview
              toggleReview={this.toggleReview}
              config={config}
              sendToOutreach={this.sendToOutreach}
              sending={sending}
              resetSendToOutreach={this.resetSendToOutreach}
              html={
                outreachType === 0 ? this.assessmentIntro : this.messageIntro
              }
              outreachType={outreachType}
            />
          )}
          {sendingError && (
            <Toast
              appearance='alert'
              title='Failed to submit'
              onDismissClick={() => this.setState({ sendingError: false })}
            />
          )}
        </ColumnWrapper>
      </StyledOutreachReveal>
    );
  }
}

export default OutreachReveal;
