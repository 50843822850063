/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Dropdown,
  Subheading,
  Button,
  Popover,
  PlaceholderParagraph,
  Label,
} from "@innovaccer/design-system";
// eslint-disable-next-line import/no-extraneous-dependencies
import { noop } from "lodash";
import RangePickerComponent, {
  getLabel,
} from "../../components/commons/RangePicker";
import { searchEnabledFilterThreshold, startofTime } from "../../config";
import "./style.css";

const commonTriggerOptions = {
  customLabel: selected => `${selected} selected`,
};

const getSelected = (options = [], selected = []) => {
  return options.filter(o => selected.includes(o.value));
};

const getUpdated = (action, options = [], selected = [], changed) => {
  if (action === "select-all") {
    return options.map(o => o.value);
  }
  if (action === "deselect-all") {
    return [];
  }
  if (action === "deselect-option") {
    const selectedArray = selected.slice();
    const index = selectedArray.findIndex(item => item === changed.value);
    selectedArray.splice(index, 1);
    return selectedArray;
  }

  return Array.from(new Set([...selected, changed.value]));
};

const Filters = ({
  isLoadingAssessments,
  isLoadingProviders,
  isLoadingAttributedProviders,
  filters,
  filterOptions,
  onChange = noop,
  config = [],
}) => {
  const {
    assessmentDate = {},
    visitDate = {},
    selectedProviders = [],
    selectedAssessments = [],
    selectedAttributedProviders = [],
    selectedMembershipStatus = [],
  } = filters;
  const {
    assessments = [],
    visitProviders = [],
    attributedProviders = [],
    membershipStatus = [],
  } = filterOptions;
  const [assessmentDatePickerOpen, toggleAssessmentDatePicker] = useState(
    false
  );
  const [visitDatePickerOpen, toggleVisitDatePicker] = useState(false);

  const filterTemplate = {
    assessment: (
      <Dropdown
        className='mb-5'
        closeOnSelect
        withCheckbox
        triggerOptions={commonTriggerOptions}
        withSearch={assessments.length > searchEnabledFilterThreshold}
        options={assessments}
        selected={getSelected(assessments, selectedAssessments)}
        onUpdate={(action, option) => {
          onChange({
            selectedAssessments: getUpdated(
              action,
              assessments,
              selectedAssessments,
              option
            ),
          });
        }}
      />
    ),
    assessmentDate: (
      <Popover
        position='bottom-start'
        open={assessmentDatePickerOpen}
        triggerClass='flex-grow-0 mb-5 w-100'
        onToggle={toggleAssessmentDatePicker}
        trigger={
          <Button
            className={`d-flex justify-content-between pl-5 pr-4${
              assessmentDate.startDate || assessmentDate.endDate
                ? ""
                : " color-text-light"
            }`}
            expanded
            icon='expand_more'
            iconAlign='right'>
            <span className='DropdownButton-wrapper'>
              {/* <span className='DropdownButton-label'>Assessment date</span> */}
              <span className='DropdownButton-text'>{`${
                assessmentDate.startDate || assessmentDate.endDate
                  ? getLabel(assessmentDate.startDate, assessmentDate.endDate)
                  : "Select"
              }`}</span>
            </span>
          </Button>
        }>
        <RangePickerComponent
          startDate={assessmentDate.startDate || startofTime}
          endDate={assessmentDate.endDate}
          showCustomPeriods
          outputFormat='MM-DD-YYYY'
          onApplyDate={(startDate, endDate) => {
            toggleAssessmentDatePicker(false);
            onChange({
              assessmentDate: {
                startDate,
                endDate,
              },
            });
          }}
        />
      </Popover>
    ),
    visitProvider: (
      <Dropdown
        className='mb-5'
        closeOnSelect
        withCheckbox
        triggerOptions={commonTriggerOptions}
        withSearch={visitProviders.length > searchEnabledFilterThreshold}
        options={visitProviders}
        selected={getSelected(visitProviders, selectedProviders)}
        onUpdate={(action, option) => {
          onChange({
            selectedProviders: getUpdated(
              action,
              visitProviders,
              selectedProviders,
              option
            ),
          });
        }}
      />
    ),
    visitDate: (
      <Popover
        position='bottom-start'
        open={visitDatePickerOpen}
        triggerClass='flex-grow-0 mb-5 w-100'
        onToggle={toggleVisitDatePicker}
        trigger={
          <Button
            className={`d-flex justify-content-between pl-5 pr-4${
              visitDate.startDate || visitDate.endDate
                ? ""
                : " color-text-light"
            }`}
            expanded
            icon='expand_more'
            iconAlign='right'>
            <span className='DropdownButton-wrapper'>
              {/* <span className='DropdownButton-label'>Visit date</span> */}
              <span className='DropdownButton-text'>{`${
                visitDate.startDate || visitDate.endDate
                  ? getLabel(visitDate.startDate, visitDate.endDate)
                  : "Select"
              }`}</span>
            </span>
          </Button>
        }>
        <RangePickerComponent
          startDate={visitDate.startDate || startofTime}
          endDate={visitDate.endDate}
          showCustomPeriods
          outputFormat='MM-DD-YYYY'
          onApplyDate={(startDate, endDate) => {
            toggleVisitDatePicker(false);
            onChange({
              visitDate: {
                startDate,
                endDate,
              },
            });
          }}
        />
      </Popover>
    ),
    attributedProvider: (
      <Dropdown
        className='mb-5'
        closeOnSelect
        withCheckbox
        triggerOptions={commonTriggerOptions}
        withSearch={attributedProviders.length > searchEnabledFilterThreshold}
        options={attributedProviders}
        selected={getSelected(attributedProviders, selectedAttributedProviders)}
        onUpdate={(action, option) => {
          onChange({
            selectedAttributedProviders: getUpdated(
              action,
              attributedProviders,
              selectedAttributedProviders,
              option
            ),
          });
        }}
      />
    ),
    membershipStatus: (
      <Dropdown
        className='mb-5'
        closeOnSelect
        withCheckbox
        triggerOptions={commonTriggerOptions}
        withSearch={membershipStatus.length > searchEnabledFilterThreshold}
        options={membershipStatus}
        selected={getSelected(membershipStatus, selectedMembershipStatus)}
        onUpdate={(action, option) => {
          onChange({
            selectedMembershipStatus: getUpdated(
              action,
              membershipStatus,
              selectedMembershipStatus,
              option
            ),
          });
        }}
      />
    ),
  };

  return (
    <>
      <Subheading className='mb-5 font-size-13'>FILTERS</Subheading>
      {!isLoadingProviders &&
      !isLoadingAssessments &&
      !isLoadingAttributedProviders ? (
        <>
          {config.map((conf, index) => (
            <div key={index}>
              {!conf.hidden && (
                <>
                  <Label withInput className='filter-label'>
                    {conf.label}
                  </Label>
                  {filterTemplate[conf.template]}
                </>
              )}
            </div>
          ))}
        </>
      ) : (
        Array(4)
          .fill("")
          // eslint-disable-next-line react/no-array-index-key
          .map((__, i) => <PlaceholderParagraph key={i} length='large' />)
      )}
    </>
  );
};

export default Filters;
