import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import Onboarding from "./Onboarding";
import Signup from "./Signup";
import DataSyncLandingPage from "./PracticeDataSyncing/DataSyncLandingPage";

class PublicRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route path='/login/:email?' component={Login} />
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/onboarding' component={Onboarding} />
        <Route path='/signup' component={Signup} />
        <Route path='/practice-data-sync' component={DataSyncLandingPage} />
        <Route component={() => <Redirect to={`/login`} />} />
      </Switch>
    );
  }
}

export default PublicRoutes;
