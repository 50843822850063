import axios from "helper/axios";

const getCurrentUsers = params => axios.get("/innote-survey/users", { params });

const getInvitedUsers = params =>
  axios.get("/innote-survey/user/invite", { params });

const updateUser = (id, payload = {}) =>
  axios.patch(`/innote-survey/user/${id}`, payload);

const sendUserInvites = (invites = []) =>
  axios.post("/innote-survey/user/invite", invites);

const resendUserInvite = id => axios.patch(`/innote-survey/user/invite/${id}`);

const removeUserInvite = id => axios.delete(`/innote-survey/user/invite/${id}`);

const deactivateUser = id => updateUser(id, { deactivate: true });

export {
  getCurrentUsers,
  getInvitedUsers,
  updateUser,
  sendUserInvites,
  resendUserInvite,
  removeUserInvite,
  deactivateUser
};
