import axios from "helper/axios";
import { Mixpanel } from "helper/mixpanelHelper";
import { version } from "../../package.json";
import { pick } from "lodash";

class authService {
  isAuthenticated = () => {
    return !!localStorage.getItem("token");
  };

  isAdmin = () => !!(this.getUser() || {}).admin;

  getUser = () => JSON.parse(localStorage.getItem("activeUser"));

  getUserName = () => (this.getUser() || {}).name;

  getUserRole = () => (this.getUser() || {}).accessRole;

  getUserKey = key => (this.getUser() || {})[key];

  setUserKey = (key, value) =>
    localStorage.setItem(
      "activeUser",
      JSON.stringify({ ...this.getUser(), [key]: value })
    );

  logIn = details => {
    if (details) {
      const { token, ...data } = details;
      localStorage.setItem("token", token);
      localStorage.setItem("activeUser", JSON.stringify(data));
      axios.defaults.headers = {
        Authorization: token
      };
      try {
        Mixpanel.identify(data.userName);
        Mixpanel.people.set({
          $name: data.name,
          $email: data.userName,
          ...pick(data, [
            "userId",
            "accessRole",
            "organization",
            "organization_category",
            "loginType"
          ]),
          Type: data.accessRole,
          AppName: "COVID-19 Dashboard",
          AppVersion: version
        });
      } catch (e) {
        console.log("unable set mixpanel user", e);
      }
      const event = new Event("login-user");
      window.dispatchEvent(event);
    } else {
      console.warn("No user details passed for login");
    }
  };

  dispatchLogoutEvent = () => {
    localStorage.removeItem("token");
    const event = new Event("logout-user");
    window.dispatchEvent(event);
    Mixpanel.reset();
  };

  logOut = () => {
    return axios
      .delete("/innote-survey/user/auth/_invalidate")
      .then(this.dispatchLogoutEvent);
  };
}

export default new authService();
