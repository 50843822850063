import React, { Component, Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import StyledCard from "@datashop/card";
import Select from "@datashop/select";
import { Popover } from "@innovaccer/design-system";
import RangePicker, { getLabel } from "../commons/RangePicker";
import Placeholder from "@datashop/placeholder";
import Dropdown from "@datashop/dropdown";
import axios from "helper/axios";
import { get } from "lodash";

const FilterWrapper = styled.div`
  min-width: 240px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex: 0 0;
`;

const FilterCard = styled(StyledCard)`
  margin-bottom: 8px;
  cursor: pointer;
  padding: 4px 0;
  display: flex;
  background-color: #fff;
  flex-direction: column;

  input {
    border: 0 !important;
  }
`;

const FilterHeader = styled.div`
  color: #868686;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 12px;
  margin-bottom: 4px;
  font-weight: 700;
`;

const StyledSelect = styled(Select)`
  background-color: #ffffff;
  font-size: 14px;
  div {
    background-color: #ffffff;
    border: 0;
  }
`;

const DateWrapper = styled.div`
  padding: 4px 12px;
  font-size: 14px;
  margin-top: 6px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const riskStatus = [
  { label: "All", value: "all" },
  { label: "High Risk", value: "HIGH" },
  { label: "Medium Risk", value: "MEDIUM" },
  { label: "Low Risk", value: "LOW" }
];

const MarathonHeathLoginFilter = ({
  assessmentId,
  selected,
  onChange,
  ...rest
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedOptions = selected || options.map(({ value }) => value);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/insights/assessment/${assessmentId}/marathon-login-suffixes`)
      .then(({ data }) => {
        setLoading(false);
        setOptions(data.marathonLoginSuffixes);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Placeholder.Paragraph
        style={{ margin: "8px 0px 8px 10px" }}
        length='medium'
      />
    );
  }

  return (
    <Dropdown
      customPlaceholderRenderer={() =>
        selectedOptions.length == options.length
          ? "All"
          : `${selectedOptions.length} Selected`
      }
      multi={true}
      enableSelectAll={true}
      options={options}
      selected={selectedOptions}
      onChange={selected => {
        selected.length === options.length ? onChange() : onChange(selected);
      }}
      key='assessment-filter-marathon-health-id'
      id='assessment-filter-marathon-health-id'
      placeholder='Select Login suffix'
      {...rest}
    />
  );
};

const StyledMarathonHeathLoginFilter = styled(MarathonHeathLoginFilter)`
  * {
    background: white !important;
    border: none !important;
  }
  input {
    box-shadow: none !important;
  }
`;

class Filter extends Component {
  state = {
    risk: "All",
    telemedicineStatus: "All",
    showDatePicker: false,
    pickerLabel:
      getLabel(
        get(this.props, "filters.assessmentDate.startDate"),
        get(this.props, "filters.assessmentDate.endDate")
      ) || "Select Date"
  };

  onChangeFilter = (option, type) => {
    let selectedVal = option.value;

    if (option.value === "all") {
      selectedVal = "";
    }
    this.setState({
      [type]: option.label
    });
    this.props.onChangeFilter(type, selectedVal);
  };

  onApplyDate = (startDate, endDate, pickerLabel) => {
    this.setState({
      showDatePicker: false,
      pickerLabel
    });
    this.props.onChangeFilter("assessmentDate", { startDate, endDate });
  };

  onClearDates = event => {
    event.stopPropagation();
    this.setState({
      startDate: "",
      endDate: "",
      pickerLabel: "Select Date"
    });
    this.props.onChangeFilter("assessmentDate", { startDate: "", endDate: "" });
  };

  render() {
    const {
      schema = [],
      isLoading,
      assessmentId,
      onChangeFilter,
      filters = {}
    } = this.props;

    const { startDate, endDate } = filters.assessmentDate || {};
    const { pickerLabel } = this.state;

    if (isLoading) {
      return (
        <FilterWrapper>
          {Array.from(Array(4), () => (
            <Placeholder>
              <Placeholder.Paragraph
                length='large'
                style={{ paddingBottom: "4px" }}
              />
            </Placeholder>
          ))}
        </FilterWrapper>
      );
    }

    const columnFiltersMap = {
      assessmentTakenOn: (
        <FilterCard>
          <Popover
            closeOnBackdropClick
            trigger={
              <div style={{ width: "100%" }}>
                <FilterHeader>Assessment Taken</FilterHeader>
                <DateWrapper>
                  {pickerLabel}
                  {startDate || endDate ? (
                    <i className='material-icons' onClick={this.onClearDates}>
                      close
                    </i>
                  ) : (
                    <i className='material-icons'> keyboard_arrow_down</i>
                  )}
                </DateWrapper>
              </div>
            }
            onToggle={showDatePicker => {
              this.setState({ showDatePicker });
            }}
            open={this.state.showDatePicker}>
            <RangePicker
              startDate={startDate}
              endDate={endDate}
              onApplyDate={this.onApplyDate}
            />
          </Popover>
        </FilterCard>
      ),
      riskCategory: (
        <FilterCard>
          <FilterHeader>Risk</FilterHeader>
          <StyledSelect
            id='risk-filter'
            placeholder={this.state.risk_category || "All"}
            options={riskStatus}
            onChange={opt => {
              this.onChangeFilter(opt, "risk_category");
            }}
          />
        </FilterCard>
      ),
      healthLogin: (
        <FilterCard>
          <FilterHeader>Marathon Health Login Id</FilterHeader>
          <StyledMarathonHeathLoginFilter
            onChange={values => onChangeFilter("healthLogin", values)}
            selected={filters.healthLogin}
            assessmentId={assessmentId}
          />
        </FilterCard>
      )
    };

    return (
      <FilterWrapper>
        {schema.map(column => columnFiltersMap[column.key])}
      </FilterWrapper>
    );
  }
}

export default Filter;
