import React, { PureComponent, Fragment } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
  Label,
  Line,
  LineChart
} from "recharts";

import Text from "@datashop/text";

import axios from "helper/axios";
import {
  WidgetCard,
  WidgetLoader,
  WidgetError,
  LegendLabel,
  Header,
  transformFilters,
  Legend
} from ".";

export const getData = ({ filters }) => {
  return axios.get("/insights/assessment-graph-data/", {
    params: transformFilters(filters)
  });
};

export const getXLSXData = ({ filters }) => {
  return getData({ filters }).then(
    ({
      data: {
        data: {
          combinedGraphData: {
            combinedSummary = [],
            totalCompleted,
            totalScreened
          }
        }
      }
    }) => {
      const schema = [
        { displayText: "Date", key: "name" },
        {
          displayText: "Patients Assessed",
          key: "completedValue",
          emptyValue: 0
        },
        {
          displayText: "Patients Screened",
          key: "screenedValue",
          emptyValue: 0
        }
      ];
      return {
        data: [
          ...combinedSummary,
          {
            name: "Total",
            completedValue: totalCompleted,
            screenedValue: totalScreened
          }
        ],
        schema,
        name: "Day wise distribution"
      };
    }
  );
};

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;
    const labelWords = payload.value.split(" ");
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          // style={{ fontSize: "var(--font-size-s)" }}
          x={0}
          y={10}
          textAnchor='middle'>
          {labelWords[0]}
        </text>
        <text
          style={{ fontSize: "var(--font-size-s)" }}
          x={0}
          y={25}
          textAnchor='middle'>
          {labelWords[1]}
        </text>
      </g>
    );
  }
}
class DayWiseDistribution extends PureComponent {
  state = {};

  getAssessmentData = (filters = {}) => {
    this.setState(
      {
        assessmentDataLoading: true,
        assessmentDataError: false
      },
      () => {
        getData({ filters })
          .then(({ data }) => {
            let { combinedGraphData } = data.data;
            let { combinedSummary } = combinedGraphData;
            this.setState({
              assessmentDataLoading: false,
              assessmentDataError: null,
              combinedSummary
            });
          })
          .catch(error => {
            this.setState({
              assessmentDataLoading: false,
              assessmentDataError: error
            });
          });
      }
    );
  };
  render() {
    const {
      assessmentDataLoading,
      assessmentDataError,
      combinedSummary
    } = this.state;

    return (
      <WidgetCard
        isCardTitle={true}
        isLoading={assessmentDataLoading}
        isError={assessmentDataError}>
        {assessmentDataLoading ? (
          <WidgetLoader />
        ) : assessmentDataError ? (
          <WidgetError
            onRetry={() => this.getAssessmentData(this.props.filters)}
          />
        ) : (
          <Fragment>
            <div
              style={{
                display: "flex",
                width: "100%",
                marginBottom: "16px",
                justifyContent: "space-between"
              }}>
              <Header style={{ width: "50%", fontSize: "15px" }}>
                {`Day-wise distribution`}
              </Header>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between"
                }}>
                <Legend color='var(--primary)' title='Patients assessed' />
                <Legend color='var(--secondary)' title='Patients screened' />
              </div>
            </div>
            <ResponsiveContainer>
              <LineChart
                width={"100%"}
                height={"95%"}
                data={combinedSummary}
                margin={{ top: 20, right: 30, left: 20, bottom: 30 }}>
                <CartesianGrid
                  vertical={false}
                  horizontal={true}
                  stroke='#F4F4F4'
                />
                <XAxis
                  padding={{ left: 30 }}
                  interval={
                    combinedSummary && combinedSummary.length > 30
                      ? combinedSummary.length / 6 > 30
                        ? 13
                        : 6
                      : 0
                  }
                  dataKey='name'
                  minTickGap={0}
                  tick={<CustomizedAxisTick />}>
                  <Label
                    value='Date'
                    offset={16}
                    position='bottom'
                    fontWeight={700}
                  />
                </XAxis>
                <YAxis
                  allowDecimals={false}
                  label={{
                    value: "No. of patients",
                    angle: -90,
                    position: "center",
                    fontWeight: 700,
                    dx: -20
                  }}
                  // domain={[0, dataMax => Math.ceil(dataMax * 1.1)]}
                  tickLine={false}
                  axisLine={false}
                />
                <Line
                  type='linear'
                  dataKey='completedValue'
                  stroke='var(--primary)'
                  strokeWidth={2}>
                  <LabelList
                    dataKey='completedValue'
                    position='top'
                    offsetBottom={5}
                  />
                </Line>
                <Line
                  type='linear'
                  dataKey='screenedValue'
                  stroke='var(--secondary)'
                  strokeWidth={2}>
                  <LabelList
                    dataKey='screenedValue'
                    position='top'
                    offsetBottom={5}
                  />
                </Line>
              </LineChart>
            </ResponsiveContainer>
          </Fragment>
        )}
      </WidgetCard>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.filters != prevProps.filters) {
      this.getAssessmentData(this.props.filters);
    }
  }

  componentDidMount() {
    this.getAssessmentData(this.props.filters);
  }
}

export default DayWiseDistribution;
