export const queryParamsToConfig = q => {
  let result = {};
  if (q) {
    let pairs = q.slice(1).split("&");
    pairs.forEach(pair => {
      pair = pair.split("=");
      if (!result[pair[0]]) {
        result[pair[0]] = decodeURI(pair[1]);
      } else if (Array.isArray(result[pair[0]])) {
        result[pair[0]].push(decodeURI(pair[1]));
      } else {
        result[pair[0]] = [result[pair[0]]];
        result[pair[0]].push(decodeURI(pair[1]));
      }
    });
  }
  return result;
};

export const stringifyQueryParams = params => {
  return Object.keys(params)
    .reduce((all, key) => {
      if (params[key] !== null && params[key] !== "") {
        if (Array.isArray(params[key])) {
          params[key].forEach(item => {
            all.push(`${key}=${encodeURI(item)}`);
          });
        } else {
          all.push(`${key}=${encodeURI(params[key])}`);
        }
      }
      return all;
    }, [])
    .join("&");
};
