import React from "react";
import "../style.css";
import { Card, Text } from "@innovaccer/design-system";
import { replaceReservedTags } from "components/commons/ContentEditable";
import PageLoader from "components/commons/PageLoader";

const OutreachPerformance = ({ data = {}, loading, error, onRetry }) => {
  const {
    recipientCount,
    completed_assessment,
    completed_assessment_percent,
    delivered_sms,
    delivered_sms_percent,
    delivered_email,
    delivered_email_percent,
    emailMessage,
    smsMessage
  } = data;

  if (loading) {
    return (
      <div className='panel-wrapper'>
        <PageLoader />
      </div>
    );
  }
  return (
    <div className='panel-wrapper'>
      <div className='performace-main-container'>
        <Card className='w-100 message-card' shadow='light'>
          <Text className='message-header-text'>Message</Text>
          <div className='message'>
            <div
              className='message-inner'
              dangerouslySetInnerHTML={{
                __html: replaceReservedTags(smsMessage || emailMessage, false)
              }}></div>
          </div>
        </Card>
        <Card className='w-100 stats-card' shadow='light'>
          <div className='stats-row p-6'>
            <Text className='message-header-text mb-6'>Total recipients</Text>
            <div className='recipient-count'>{recipientCount}</div>
          </div>
          {delivered_sms !== undefined && (
            <div className='stats-row'>
              <div className='border-left pl-6 mb-6 mt-6'>
                <div className='message-header-text mb-6'>SMS Delivered</div>
                <Text appearance='default' className='recipient-count'>
                  {delivered_sms}
                </Text>
                <Text weight='medium' appearance='subtle' className='pl-5'>
                  {delivered_sms_percent}
                </Text>
              </div>
            </div>
          )}
          {delivered_email !== undefined && (
            <div className='stats-row'>
              <div className='border-left pl-6 mb-6 mt-6'>
                <Text className='message-header-text mb-6'>
                  Email Delivered
                </Text>
                <Text appearance='default' className='recipient-count'>
                  {delivered_email}
                </Text>
                <Text weight='medium' appearance='subtle' className='pl-5'>
                  {delivered_email_percent}
                </Text>
              </div>
            </div>
          )}
          {completed_assessment !== undefined && (
            <div className='stats-row'>
              <div className='border-left pl-6 mb-6 mt-6'>
                <Text className='message-header-text mb-6'>
                  Assessment completed
                </Text>
                <Text appearance='default' className='recipient-count'>
                  {completed_assessment}
                </Text>
                <Text weight='medium' appearance='subtle' className='pl-5'>
                  {completed_assessment_percent}
                </Text>
              </div>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default OutreachPerformance;
