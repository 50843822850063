import React, { Component } from "react";
import styled from "styled-components";
import {
  PageHeader,
  Button,
  Grid,
  Pagination,
  Subheading,
  PlaceholderParagraph,
  StatusHint,
  Text,
} from "@innovaccer/design-system";
import Header from "./header";
import axios from "helper/axios";
import Filters from "./filters";
import { debounce, get, startCase } from "lodash";
import AlertService, { defaultDissmissDelay } from "services/alertService";
import { exportJson2Excel } from "helper/fileDownload";
import { paramsSerializer } from "utils";
import { getProviderList } from "services/orgService";
import { queryParamsToConfig } from "helper/queryParams";
import Placeholder from "components/commons/Placeholder";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
  width: 100%;
`;

const BodyWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const Gridwrapper = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 8px 8px 16px 16px;
`;

const FiltersWrapper = styled.div`
  height: 100%;
  width: 20%;
  display: flex;
  flex-direction: column;
  padding: 8px 16px 16px 8px;

  > * {
    margin-bottom: 16px;
  }
`;

const defaultFilters = {};
const defaultPagination = {
  page: 1,
  size: 20,
};

const ErrorTemplate = ({ error, onRetry }) => (
  <Placeholder
    {...(error
      ? {
          error: true,
          iconStyle: { color: "var(--alert)" },
          title: "Something went wrong",
          subTitle:
            "It may be because of technical failure or network reasons. Please try again",
          onRetry,
        }
      : {
          title: "We're off record!",
          subTitle:
            "Because there is no record available on virtual visits at the moment.",
        })}
  />
);

const getVisitStatusAppearance = status => {
  const checkStatus = (status || "").replace(/\W/g, "").toUpperCase();
  if (["SCHEDULED"].includes(checkStatus)) {
    return "warning";
  }
  if (["COMPLETED"].includes(checkStatus)) {
    return "success";
  }
  if (["CANCELLED", "INQUEUE", "READYFORDOCTOR"].includes(checkStatus)) {
    return "alert";
  }
};

const getPaymentStatusAppearance = status => {
  const mapping = {
    SUCCESS: "success",
    PENDING: "warning",
    FAILED: "alert",
  };

  return mapping[status] || "";
};

const StatusWithMeta = ({ children, meta, appearance }) => {
  return (
    <div>
      <StatusHint appearance={appearance}>{children}</StatusHint>
      <Text className='ml-6' appearance='subtle' small>
        {meta}
      </Text>
    </div>
  );
};

const columns = {
  patientName: {
    displayName: "Patients",
    name: "patientName",
    cellType: "WITH_META_LIST",
    resizable: false,
    translate: ({ patientName, age, gender }) => {
      return {
        title: patientName,
        metaList: [age, gender].filter(val => !!val),
      };
    },
  },
  insurance: {
    displayName: "Insurance",
    name: "insurance",
    cellType: "WITH_META_LIST",
    resizable: false,
    minWidth: 240,
    translate: ({ insuranceProvider, insuranceMemberId, insuranceGroupId }) => {
      return {
        title: insuranceProvider,
        metaList: [insuranceMemberId, insuranceGroupId].filter(val => !!val),
      };
    },
  },
  displayPhoneNo: {
    displayName: "Phone",
    name: "displayPhoneNo",
    resizable: false,
    width: 150,
  },
  visitDate: {
    displayName: "Date",
    name: "visitDate",
    resizable: false,
    width: 130,
    sorting: true,
  },
  provider: {
    displayName: "Provider",
    name: "provider",
    resizable: false,
    tooltip: true,
  },
  callType: {
    displayName: "Visit mode",
    name: "callType",
    cellType: "WITH_META_LIST",
    resizable: false,
    width: 160,
    cellRenderer: ({ data }) => {
      return (
        <StatusWithMeta
          meta={data.visitStatus}
          appearance={getVisitStatusAppearance(data.visitStatus)}>
          {data.callType}
        </StatusWithMeta>
      );
    },
  },
  payment: {
    displayName: "Payment",
    name: "paymentStatus",
    cellType: "WITH_META_LIST",
    resizable: false,
    width: 160,
    cellRenderer: ({ data }) => {
      const { amount, paymentStatus } = data;
      return (
        <StatusWithMeta
          meta={
            paymentStatus && amount
              ? startCase((paymentStatus || "").toLowerCase())
              : ""
          }
          appearance={getPaymentStatusAppearance(paymentStatus)}>
          {amount
            ? amount
            : paymentStatus
            ? startCase((paymentStatus || "").toLowerCase())
            : ""}
        </StatusWithMeta>
      );
    },
  },
};

const loaderSchema = Object.values(columns).map(
  ({ cellRenderer, ...rest }) => rest
);

const getSchema = rawSchema => {
  const schema = Object.values(columns).map(column => ({
    sorting: false,
    resizable: true,
    ...column,
  }));
  // rawSchema // todo: use this when api starts to send right tableSchema
  //   .filter(({ visible }) => !!visible)
  //   .map(({ displayText, key }) => ({
  //     sorting: false,
  //     resizable: true,
  //     name: key,
  //     displayName: displayText,
  //     ...(columns[key] || {})
  //   }));

  return schema;
};
class VirtualVisits extends Component {
  queryParams = queryParamsToConfig(
    (this.props.location && this.props.location.search) || ""
  );

  state = {
    key: 0,
    data: [],
    schema: [],
    totalCount: 0,
    filterOptions: {},
    filters: {
      ...defaultFilters,
      ...get(this.props, "location.state.filters", {}),
      ...JSON.parse(this.queryParams.filters || `{}`),
    },
    sort: [{ name: "visitDate", type: "desc" }],
    search: "",
    pagination: { ...defaultPagination },
    firstRender: true,
    loadingProviders: false,
    loading: false,
    error: false,
  };

  getAPIParams = () => {
    const columnSortKeyMap = {
      visitDate: "visit_date_sorting",
    };

    const {
      pagination = defaultPagination,
      sort,
      filters,
      search = "",
    } = this.state;
    return {
      from_page: pagination.page,
      per_page_limit: pagination.size,
      virtual_visit_date_start: filters.startDate,
      virtual_visit_date_end: filters.endDate,
      virtual_visit_providers: filters.providers,
      age_group: filters.ageGroup,
      call_type: filters.callType,
      payment_status: filters.paymentStatus,
      visit_status: filters.visitStatus,
      ...(search && { search }),
      ...sort.reduce((res, obj) => {
        res[columnSortKeyMap[obj.name] || obj.name] = obj.type;
        return res;
      }, {}),
    };
  };
  getAPIPromise = (params = {}) =>
    axios.get(`/insights/virtual-visit`, { params, paramsSerializer });

  lastFetched = 0;
  fetchRecords = () => {
    const apiTime = Date.now();
    this.lastFetched = apiTime;
    this.setState({ loading: true, error: false, key: this.state.key + 1 });
    this.getAPIPromise(this.getAPIParams())
      .then(
        ({
          data: {
            visitData,
            visitCount,
            metadata: { tableSchema, ...filterOptions },
          },
        }) => {
          if (this.lastFetched > apiTime) {
            return;
          }
          const newState = {
            firstRender: false,
            loading: false,
            data: visitData,
            totalCount: visitCount,
          };
          if (this.state.firstRender) {
            newState.filterOptions = {
              ...this.state.filterOptions,
              ...filterOptions,
            };
            getSchema(tableSchema);
            newState.schema = getSchema(tableSchema);
          }
          this.setState(newState);
        }
      )
      .catch(() => {
        this.setState({
          error: true,
          loading: false,
        });
      });
  };

  debouncedFetchRecords = debounce(this.fetchRecords, 300);

  exportData = () => {
    AlertService.showAlert({
      title: "Preparing download ...",
      autoClearAfter: defaultDissmissDelay,
    });
    this.getAPIPromise({
      ...this.getAPIParams(),
      task: "download",
    })
      .then(
        ({
          data: {
            visitData,
            metadata: { tableSchema },
          },
        }) => {
          exportJson2Excel(
            [
              {
                schema: tableSchema.filter(
                  ({ visible, download }) => visible || download
                ),
                data: visitData,
              },
            ],
            `Virtual Visit Report`
          );
        }
      )
      .catch(() => {
        AlertService.showAlert({
          appearance: "alert",
          title: "Failed to export",
          autoClearAfter: defaultDissmissDelay,
        });
      });
  };

  onSearchChange = search => {
    this.setState(
      {
        search,
        pagination: { ...defaultPagination },
      },
      this.debouncedFetchRecords
    );
  };

  onFilterChange = (patch = {}, replace) => {
    this.setState(
      {
        filters: replace ? { ...patch } : { ...this.state.filters, ...patch },
        pagination: { ...defaultPagination },
      },
      this.fetchRecords
    );
  };

  onSortChange = sort => {
    this.setState(
      {
        sort,
        pagination: { ...defaultPagination },
      },
      this.fetchRecords
    );
  };

  onPageChange = page => {
    this.setState(
      {
        pagination: { ...this.state.pagination, page },
      },
      this.fetchRecords
    );
  };

  render() {
    const {
      key,
      data,
      schema,
      totalCount,
      sort,
      search,
      filterOptions,
      filters = defaultFilters,
      pagination = defaultPagination,
      firstRender,
      loadingProviders,
      loading,
      error,
    } = this.state;

    const totalpages =
      totalCount > pagination.size
        ? Math.ceil(totalCount / pagination.size)
        : 1;

    return (
      <Wrapper>
        <PageHeader
          title='Virtual Visits'
          actions={
            <div className='d-flex justify-content-end'>
              <Button
                onClick={this.exportData}
                disabled={loading}
                icon='get_app'>
                Download records
              </Button>
            </div>
          }
        />
        <BodyWrapper>
          <Gridwrapper>
            <Header
              searchPlaceholder='Search'
              withPagination={true}
              updateSchema={() => ""}
              loading={loading}
              error={error}
              searchTerm={search}
              totalRecords={totalCount}
              schema={schema}
              data={data}
              updateSearchTerm={this.onSearchChange}
              filters={filters}
              filterOptions={filterOptions}
              updateFilters={this.onFilterChange}
            />
            <div className='Table-grid'>
              <Grid
                key={key}
                errorTemplate={props => (
                  <ErrorTemplate
                    {...props}
                    error={error}
                    onRetry={this.fetchRecords}
                  />
                )}
                error={error || data.length < 1}
                sortingList={sort}
                updateSortingList={this.onSortChange}
                showMenu={false}
                separator={true}
                pageSize={pagination.size}
                loading={loading}
                schema={schema}
                data={data}
                loaderSchema={loaderSchema}
              />
            </div>
            <div className='Table-pagination'>
              <Pagination
                type='jump'
                page={pagination.page}
                totalPages={totalpages}
                onPageChange={this.onPageChange}
              />
            </div>
          </Gridwrapper>
          <FiltersWrapper>
            <Subheading className='mb-4 font-size-13'>FILTERS</Subheading>
            {(loading && firstRender) || loadingProviders ? (
              Array(5)
                .fill("")
                .map((__, i) => <PlaceholderParagraph key={i} length='large' />)
            ) : (
              <Filters
                loading={loading && firstRender}
                options={filterOptions}
                filters={filters}
                onChange={this.onFilterChange}
              />
            )}
          </FiltersWrapper>
        </BodyWrapper>
      </Wrapper>
    );
  }

  fetchProviders = () => {
    this.setState({ loadingProviders: true });
    getProviderList({ params: { tab: "virtual_visit" } })
      .then(({ data: { providers } }) => {
        this.setState(({ filterOptions }) => ({
          filterOptions: { ...filterOptions, providers },
          loadingProviders: false,
        }));
      })
      .catch(() => {
        this.setState({
          loadingProviders: false,
        });
      });
  };

  componentDidMount() {
    this.fetchRecords();
    this.fetchProviders();
  }
}

export default VirtualVisits;
