import React, { useState } from "react";
import moment from "moment";
import { Text, Chip, Popover, Button } from "@innovaccer/design-system";
import { startofTime } from "config";
import RangePickerComponent from "components/commons/RangePicker";
import { formatDate } from "utils";
import styled from "styled-components";

export const APIDateFormat = "MM-DD-YYYY";

const today = formatDate(moment(), APIDateFormat);
const startTime = formatDate(startofTime, APIDateFormat);

export const getLastNDays = days => {
  return {
    startDate: formatDate(
      moment(today, "MM-DD-YYYY").subtract(days - 1, "days"),
      APIDateFormat
    ),
    endDate: today
  };
};

const dateFilters = [
  {
    label: "Last 30 days",
    ...getLastNDays(30)
  },
  {
    label: "Last 15 days",
    ...getLastNDays(15)
  },
  {
    label: "Last 7 days",
    ...getLastNDays(7)
  }
  // {
  //   label: "Alltime",
  //   startDate: startTime,
  //   endDate: today
  // }
];

const datesAreEqual = (a, b, type) => {
  return (
    a == b ||
    (!a && !b) ||
    (!a && b == (type == "start" ? startTime : today)) ||
    (!b && a == (type == "start" ? startTime : today))
  );
};

const callTypes = [
  { value: undefined, label: "All" },
  { value: "AUDIO", label: "Voice" },
  { value: "VIDEO", label: "Video" }
];

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  @media print {
    display: none;
  }
  > * {
    flex-shrink: 1;
  }
`;

const customFilters = {
  virtualVisits: (filters, onChange) => {
    const { callType } = filters;
    return (
      <>
        <Text weight='strong'>Call type</Text>
        {callTypes.map(({ label, value }, chipIndex) => (
          <Chip
            key={chipIndex}
            onClick={() =>
              onChange({
                callType: value
              })
            }
            selected={value == callType}
            icon={value == callType ? "done" : ""}
            className='ml-4 cursor-pointer'
            type='selection'
            label={label}
          />
        ))}
      </>
    );
  }
};

const Filters = ({ filters = {}, onChange, tab = {} }) => {
  const { startDate, endDate } = filters;

  const [rangePickerOpen, toggleRangePicker] = useState(false);

  const activeDateFilter = dateFilters.findIndex(
    filter =>
      datesAreEqual(filter.startDate, startDate, "start") &&
      datesAreEqual(filter.endDate, endDate, "end")
  );

  return (
    <Wrapper>
      <div className='d-flex w-50 align-items-center'>
        <Text weight='strong'>Time</Text>
        {dateFilters.map(({ label, startDate, endDate }, chipIndex) => (
          <Chip
            key={chipIndex}
            onClick={() =>
              onChange({
                startDate,
                endDate
              })
            }
            selected={activeDateFilter == chipIndex}
            icon={activeDateFilter == chipIndex ? "done" : ""}
            className='ml-4 cursor-pointer'
            type='selection'
            label={label}
          />
        ))}
        <Popover
          position='bottom-start'
          open={rangePickerOpen}
          triggerClass='flex-grow-0 ml-6'
          onToggle={toggleRangePicker}
          trigger={
            <Button size='tiny' icon='expand_more' iconAlign='right'>
              {`Custom${
                activeDateFilter < 0
                  ? ": " +
                    [formatDate(startDate), formatDate(endDate)].join(" - ")
                  : ""
              }`}
            </Button>
          }>
          <RangePickerComponent
            startDate={startDate || startTime}
            endDate={endDate || today}
            showCustomPeriods={false}
            outputFormat='MM-DD-YYYY'
            onApplyDate={(startDate, endDate) => {
              toggleRangePicker(false);
              onChange({
                startDate,
                endDate
              });
            }}
          />
        </Popover>
      </div>
      <div className='d-flex w-50 align-items-center'>
        {customFilters[tab.name]
          ? customFilters[tab.name](filters, onChange)
          : null}
      </div>
    </Wrapper>
  );
};

export default React.memo(Filters);
