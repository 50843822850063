import React from "react";
import {
  Dropdown,
  Subheading,
  PlaceholderParagraph,
  Label,
} from "@innovaccer/design-system";
import { searchEnabledFilterThreshold } from "config";
import { noop } from "lodash";
import "../style.css";

const commonTriggerOptions = {
  customLabel: selected => `${selected} selected`,
};

const getSelected = (options = [], selected = []) =>
  options.filter(o => selected.includes(o.value));

const getUpdated = (action, options = [], selected = [], changed) => {
  if (action == "select-all") {
    return options.map(o => o.value);
  }
  if (action == "deselect-all") {
    return [];
  }
  if (action == "deselect-option") {
    const selectedArray = selected.slice();
    const index = selectedArray.findIndex(item => item == changed.value);
    selectedArray.splice(index, 1);
    return selectedArray;
  }

  return Array.from(new Set([...selected, changed.value]));
};

const Filters = ({
  isLoading,
  loadingSender,
  filters = {},
  options = {},
  onChange = noop,
}) => {
  const { type = [], status = [], sender = [] } = options;
  return (
    <>
      <Subheading className='mb-5 font-size-13'>FILTERS</Subheading>
      {isLoading && loadingSender ? (
        Array(3)
          .fill("")
          .map((__, i) => <PlaceholderParagraph key={i} length='large' />)
      ) : (
        <>
          <Label withInput className='filter-label'>
            Type
          </Label>
          <Dropdown
            className='mb-5'
            closeOnSelect
            withCheckbox={true}
            triggerOptions={commonTriggerOptions}
            withSearch={type.length > searchEnabledFilterThreshold}
            options={type}
            selected={getSelected(type, filters.type)}
            onUpdate={(action, option) => {
              onChange({
                type: getUpdated(action, type, filters.type, option),
              });
            }}
          />
          <Label withInput className='filter-label'>
            Status
          </Label>
          <Dropdown
            className='mb-5'
            closeOnSelect
            withCheckbox={true}
            triggerOptions={commonTriggerOptions}
            withSearch={status.length > searchEnabledFilterThreshold}
            options={status}
            selected={getSelected(status, filters.status)}
            onUpdate={(action, option) => {
              onChange({
                status: getUpdated(action, status, filters.status, option),
              });
            }}
          />
          <Label withInput className='filter-label'>
            Sender
          </Label>
          <Dropdown
            className='mb-5'
            closeOnSelect
            withCheckbox={true}
            triggerOptions={commonTriggerOptions}
            withSearch={sender.length > searchEnabledFilterThreshold}
            options={sender}
            selected={getSelected(sender, filters.sender)}
            onUpdate={(action, option) => {
              onChange({
                sender: getUpdated(action, sender, filters.sender, option),
              });
            }}
          />
        </>
      )}
    </>
  );
};

export default Filters;
