import React from "react";
import EllipsisText from "routes/components/ellipsisText";
import {
  PlaceholderParagraph,
  Dropdown,
  Icon,
  Button,
  Popover,
  Paragraph
} from "@innovaccer/design-system";
import Name from "routes/components/name";
import styled from "@datashop/theme/cjs/styled";

export const defaultPagination = {
  page: 1,
  size: 20
};

export const adminOptions = [
  {
    label: "Yes",
    value: true
  },
  {
    label: "No",
    value: false
  }
];

export const CellPlaceholder = ({}) => (
  <>
    {}
    <PlaceholderParagraph length='medium' size='comfortable' />
  </>
);

const OptionsDropdown = styled(Dropdown)`
  width: auto !important;
`;

export const cellRenderers = {
  default: ({ data, loading, schema }) => {
    if (loading) {
      return <CellPlaceholder />;
    }
    const text = data[schema.name];
    return (
      <EllipsisText>
        {typeof text === "object" ? JSON.stringify(text) : text}
      </EllipsisText>
    );
  },
  email: ({ data, loading, schema, props = {} }) => {
    if (loading) {
      return <CellPlaceholder />;
    }
    const text = data[schema.name];
    return (
      <>
        {props.withIcon && <Icon size={24} className='mr-4' name='schedule' />}
        <EllipsisText>
          {typeof text === "object" ? JSON.stringify(text) : text}
        </EllipsisText>
      </>
    );
  },
  name: ({ loading, props }) => {
    if (loading) {
      return <CellPlaceholder />;
    }
    return <Name {...props} />;
  },
  role: ({ loading, props }) => {
    if (loading) {
      return <CellPlaceholder />;
    }
    return <Dropdown {...props} />;
  },
  practice: ({ loading, props }) => {
    if (loading) {
      return <CellPlaceholder />;
    }

    const trigger = (
      <Dropdown
        truncateOption={false}
        withCheckbox
        showApplyButton
        {...props}
      />
    );

    const selectedPractices = (props.options || [])
      .filter(({ selected }) => !!selected)
      .map(({ label }) => label);

    if (props.disabled && selectedPractices.length) {
      return (
        <Popover position='bottom-start' hoverable on='hover' trigger={trigger}>
          <div style={{ maxHeight: 240, overflow: "auto" }} className='p-6'>
            <ul type='disc'>
              {selectedPractices.map(name => (
                <li>
                  <Paragraph className='py-3'>{name}</Paragraph>
                </li>
              ))}
            </ul>
          </div>
        </Popover>
      );
    }
    return trigger;
  },
  admin: ({ loading, props }) => {
    if (loading) {
      return <CellPlaceholder />;
    }
    return <Dropdown {...props} />;
  },
  options: ({ loading, props = {} }) => {
    if (loading) {
      return null;
    }
    return (
      <div className='d-flex justify-content-end w-100'>
        <OptionsDropdown
          menu
          width={160}
          options={props.options.map(({ label }, i) => ({
            label,
            value: i
          }))}
          align='left'
          onChange={index => {
            props.options[index].onClick();
          }}
          customTrigger={() => (
            <Button
              icon='more_horiz'
              iconAlign='left'
              appearance='transparent'
              size='regular'></Button>
          )}
        />
      </div>
    );
  }
};

export const commonSchemaOptions = {
  sorting: false,
  resizable: true
  // cellRenderer: cellRenderers.default
};
