import React from "react";
import styled from "styled-components";
import { Card } from "@innovaccer/design-system";
import Placeholder from "components/commons/Placeholder";

export const RoutesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

export const SubRoutesWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 16px;
  overflow: hidden;
  ${props => props.overflow && `overflow: ${props.overflow};`}
`;

export const CardsWrapper = styled.div`
  ${props => props.disabled && `pointer-events: none; opacity: 0.6;`}
  width: 100%;
  max-width: 720px;

  > * {
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const SettingsCard = styled(Card).attrs({ shadow: "light" })`
  ${props => props.disabled && `pointer-events: none; opacity: 0.6;`}
  background: var(--white);
`;

export const TableCard = styled(SettingsCard)`
  height: 100%;
  width: 100%;

  .Grid-cellContent {
    > .PopperWrapper-trigger {
      width: 100%;
    }
  }
`;

export const TableErrorPlaceholder = props => {
  return (
    <Placeholder
      error
      iconStyle={{ color: "var(--alert)" }}
      title='Failed to fetch payment details'
      subTitle='It may be because of technical failure or network reasons. Please try again'
      // onRetry={this.fetchOrgPaymentConfig}
    />
  );
};
