/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Column,
  Label,
  Input,
  InputMask,
  Dropdown,
  Text,
  Icon,
  Spinner,
} from "@innovaccer/design-system";
import {
  titleOptions,
  phoneNumberMask,
  phoneNumberPlaceholder,
} from "../../../config";
import { adminOptions } from "./templates";
import { updateUser } from "../../../services/userService";
import AlertService, {
  defaultDissmissDelay,
} from "../../../services/alertService";
import { getErrorMessage } from "../../../helper/axios";
import { formatPhone } from "../../../utils";
import "./style.css";

const errors = {
  patientFirstName: val => !val,
  patientLastName: val => !val,
  accessRole: val => !val,
  practices: val => !val || !val.length,
  isAdmin: val => typeof val === "undefined",
  npi: val => val && val.replace(/\D/g, "").length !== 10,
  phoneNumber: val => val && val.replace(/\D/g, "").length !== 10,
};

const UserDetails = ({
  data,
  roleOptions = [],
  practicesOptions = [],
  open,
  onClickHandler,
  renderTable,
  config,
}) => {
  const [userDetails, setUserDetails] = useState(null);
  const [formError, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
    onClickHandler(!open);
  };
  const options = {
    open,
  };

  const modalHeaderOptions = {
    onClose,
    heading: "User details",
  };

  const setFormError = event => {
    if (event && event.target) {
      const {
        target: { value, name },
      } = event;
      setFormError({ ...formError, [name]: errors[name](value) });
    } else {
      setError(
        Object.keys(errors).reduce((error, key) => {
          error[key] = errors[key](userDetails[key]);
          return error;
        }, {})
      );
    }
  };

  const validate = () => {
    const {
      patientFirstName,
      patientLastName,
      accessRole,
      npi,
      practices,
      isAdmin,
      phoneNumber,
    } = userDetails;
    return (
      errors.patientFirstName(patientFirstName) ||
      errors.patientLastName(patientLastName) ||
      errors.accessRole(accessRole) ||
      errors.npi(npi) ||
      errors.practices(practices) ||
      errors.isAdmin(isAdmin) ||
      errors.phoneNumber(phoneNumber)
    );
  };

  const onSaveChanges = () => {
    if (!validate()) {
      setIsLoading(true);
      const {
        patientFirstName,
        patientLastName,
        accessRole,
        npi,
        practices,
        isAdmin,
        _id,
        phoneNumber,
      } = userDetails;
      const payload = {
        patientFirstName: patientFirstName,
        patientLastName: patientLastName,
        accessRole: accessRole,
        npi: npi,
        practices: practices,
        admin: isAdmin,
        phoneNumber: phoneNumber,
      };
      updateUser(_id, {
        ...payload,
      })
        .then(res => {
          if (res.data === "") {
            setIsLoading(false);
            renderTable();
            onClose();
            AlertService.showAlert({
              appearance: "success",
              message: "User updated successfully.",
              autoClearAfter: defaultDissmissDelay,
            });
          }
        })
        .catch(err => {
          setIsLoading(false);
          AlertService.showAlert({
            appearance: "alert",
            message: getErrorMessage(err, "Failed to update user"),
            autoClearAfter: defaultDissmissDelay,
          });
        });
    } else {
      setFormError();
    }
  };

  useEffect(() => {
    if (data) {
      const {
        patientFirstName,
        patientLastName,
        email,
        phoneNumber,
        accessRole,
        npi,
        practices,
        isAdmin,
        title,
        _id,
      } = data;
      setUserDetails({
        patientFirstName,
        patientLastName,
        email,
        phoneNumber,
        accessRole,
        npi,
        practices,
        isAdmin,
        title,
        _id,
      });
    }
  }, []);

  const {
    patientFirstName,
    patientLastName,
    email,
    phoneNumber,
    accessRole,
    npi,
    practices,
    isAdmin,
    title = null,
    rowKey,
  } = userDetails || {};
  const {
    adminEditable,
    npiEditable,
    patientFirstNameEditable,
    patientLastNameEditable,
    practicesEditable,
    phoneNumberEditable,
  } = config || {};
  return (
    <>
      {userDetails && config && (
        <Modal dimension='small' {...options} className='user-details'>
          <ModalHeader {...modalHeaderOptions} />
          <div className='divider' />
          <ModalBody className='py-6'>
            <Row>
              <Column size='2' className='pr-4'>
                <Label withInput className='label-bold'>
                  Title
                </Label>
                <Dropdown
                  disabled
                  options={titleOptions.map(option => ({
                    ...option,
                    selected: option.value === title,
                  }))}
                />
              </Column>
              <Column size='5' className='pr-4 user-details-col'>
                <Label withInput className='label-bold'>
                  First name
                </Label>
                <Input
                  name='patientFirstName'
                  value={patientFirstName}
                  placeholder='John'
                  error={formError.patientFirstName}
                  onBlur={setFormError}
                  onChange={e =>
                    setUserDetails({
                      ...userDetails,
                      patientFirstName: e.target.value,
                    })
                  }
                  disabled={!patientFirstNameEditable}
                />
                {formError.patientFirstName && (
                  <Text appearance='destructive'>
                    <Icon name='error' appearance='destructive' />
                    &nbsp; First name is required
                  </Text>
                )}
              </Column>
              <Column size='5' className='user-details-col'>
                <Label withInput className='label-bold '>
                  Last name
                </Label>
                <Input
                  name='patientLastName'
                  placeholder='John'
                  value={patientLastName}
                  error={formError.patientLastName}
                  onBlur={setFormError}
                  onChange={e =>
                    setUserDetails({
                      ...userDetails,
                      patientLastName: e.target.value,
                    })
                  }
                  disabled={!patientLastNameEditable}
                />
                {formError.patientLastName && (
                  <Text appearance='destructive'>
                    <Icon name='error' appearance='destructive' />
                    &nbsp; Last name is required
                  </Text>
                )}
              </Column>
            </Row>
            <Row className='pt-6'>
              <Column size='6' className='pr-4 user-details-col'>
                <Label withInput className='label-bold'>
                  Email
                </Label>
                <Input
                  value={email}
                  disabled
                  type='email'
                  name='email'
                  placeholder='john@doe.com'
                />
              </Column>
              <Column size='6' className='user-details-col'>
                <Label withInput className='label-bold '>
                  Phone number
                </Label>
                <Input
                  name='phoneNumber'
                  pattern='\d{10}'
                  type='text'
                  value={
                    phoneNumber && phoneNumber.length > 0
                      ? formatPhone(phoneNumber)
                      : phoneNumber
                  }
                  placeholder={phoneNumberPlaceholder}
                  error={formError.phoneNumber}
                  onBlur={setFormError}
                  onChange={e => {
                    setUserDetails({
                      ...userDetails,
                      phoneNumber: e.target.value.replace(/\D/g, ""),
                    });
                  }}
                  disabled={!phoneNumberEditable}
                />
                {formError.phoneNumber && (
                  <Text appearance='destructive'>
                    <Icon name='error' appearance='destructive' />
                    &nbsp; Phone Number must be 10 digits long
                  </Text>
                )}
              </Column>
            </Row>
            <Row>
              <Column size='4' className='pr-4 user-details-col'>
                <Label withInput className='label-bold'>
                  Role
                </Label>
                {roleOptions && roleOptions.length > 0 && (
                  <Dropdown
                    onChange={role => {
                      if (role === "PRACTICE_MANAGER") {
                        setUserDetails({
                          ...userDetails,
                          accessRole: role,
                          npi: "",
                          rowKey: new Date(),
                          practices: practicesOptions.map(({ value }) => value),
                        });
                      } else {
                        setUserDetails({
                          ...userDetails,
                          accessRole: role,
                          rowKey: new Date(),
                          npi: "",
                        });
                      }
                    }}
                    options={roleOptions.map(option => ({
                      ...option,
                      selected: option.value === accessRole,
                    }))}
                  />
                )}
              </Column>
              <Column size='8' className='user-details-col'>
                <Label withInput className='label-bold '>
                  NPI
                </Label>
                <Input
                  value={npi}
                  name='npi'
                  type='number'
                  placeholder='Eg. 1346336807'
                  disabled={accessRole !== "PROVIDER"}
                  error={formError.npi}
                  onBlur={setFormError}
                  onChange={e =>
                    setUserDetails({ ...userDetails, npi: e.target.value })
                  }
                  disabled={!npiEditable}
                />
                {formError.npi && (
                  <Text appearance='destructive'>
                    <Icon name='error' appearance='destructive' />
                    &nbsp; Please enter a 10 digits NPI
                  </Text>
                )}
              </Column>
            </Row>
            <Row className='pt-6' key={rowKey}>
              <Column size='4' className='pr-4 user-details-col'>
                <Label withInput className='label-bold'>
                  Practice
                </Label>
                {practicesOptions && practicesOptions.length > 0 && (
                  <Dropdown
                    name='practices'
                    disabled={
                      !practicesEditable || accessRole === "PRACTICE_MANAGER"
                    }
                    onChange={pract =>
                      setUserDetails({ ...userDetails, practices: pract })
                    }
                    withCheckbox
                    showApplyButton
                    options={practicesOptions.map(option => ({
                      ...option,
                      selected: practices && practices.includes(option.value),
                    }))}
                  />
                )}
                {formError.practices && (
                  <Text appearance='destructive'>
                    <Icon name='error' appearance='destructive' />
                    &nbsp; Please select practices
                  </Text>
                )}
              </Column>
              <Column size='4' className='user-details-col'>
                <Label withInput className='label-bold '>
                  Admin
                </Label>
                <Dropdown
                  disabled={!adminEditable}
                  onChange={admin =>
                    setUserDetails({ ...userDetails, isAdmin: admin })
                  }
                  options={adminOptions.map(option => ({
                    ...option,
                    selected: option.value === isAdmin,
                  }))}
                />
              </Column>
            </Row>
          </ModalBody>
          <div className='divider' />
          <ModalFooter>
            <Button appearance='basic' onClick={onClose}>
              Cancel
            </Button>
            <Button
              appearance='primary'
              onClick={onSaveChanges}
              className='ml-4'>
              {isLoading ? (
                <Spinner appearance='white' size='small' />
              ) : (
                "Save changes"
              )}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default UserDetails;
