import React, { PureComponent } from "react";
import {
  WidgetCard,
  WidgetLoader,
  WidgetError,
  Header,
  transformFilters,
} from ".";
import axios from "helper/axios";
import { Table, Tooltip, Link, Icon } from "@innovaccer/design-system";
import { cloneDeep } from "lodash-es";
import Placeholder from "components/commons/Placeholder";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { getGlobalURL } from "config";
import { openUrl } from "helper/fileDownload";

const getData = ({ filters }) => {
  return axios.get("/insights/graph/providers-virtual-visit", {
    params: transformFilters(filters),
  });
};

export const getXLSXData = ({ filters }) => {
  return getData({ filters }).then(
    ({
      data: {
        virtualVisitStats: { providerStats = [] },
      },
    }) => {
      const schema = [
        { displayText: "Provider Name", key: "provider" },
        {
          displayText: "Number of all calls scheduled",
          key: "requested",
          emptyValue: 0,
        },
        {
          displayText: "Number of calls completed",
          key: "completed",
          emptyValue: 0,
        },
        {
          displayText: "Number of calls incomplete",
          key: "incompleted",
          emptyValue: 0,
        },
        {
          displayText: "% completed",
          key: "completed_percentage",
        },
      ];
      return {
        data: [
          ...providerStats,
          {
            provider: "Total",
            requested: providerStats.reduce(
              (totalCount, { requested }) => totalCount + requested,
              0
            ),
            completed: providerStats.reduce(
              (totalCount, { completed }) => totalCount + completed,
              0
            ),
            incompleted: providerStats.reduce(
              (totalCount, { incompleted }) => totalCount + incompleted,
              0
            ),
          },
        ],
        schema,
        name: "Providers Virtual Visits",
      };
    }
  );
};

const StyledText = styled(Link)`
  color: var(--text) !important;
  cursor: pointer;
  width: 100%;
  justify-content: flex-start;
  display: flex;
  &:hover .icon-span {
    display: inline;
  }
`;

const IconSpan = styled.span`
  display: none;
`;

const getColumns = ({ showVisitData }) => [
  {
    displayName: "Provider",
    name: "provider",
    width: "20%",
    cellRenderer: ({ data }) => {
      return (
        <Tooltip position='top' tooltip={`view all visits by ${data.provider}`}>
          <StyledText
            onClick={() =>
              showVisitData({
                providerId: data.provider_id,
              })
            }>
            {data.provider}
            <IconSpan className='icon-span'>
              <Icon appearance='basic' name='navigate_next' size='regular' />
            </IconSpan>
          </StyledText>
        </Tooltip>
      );
    },
  },
  {
    displayName: "All visits",
    name: "allVisits",
    width: "10%",
    align: "right",
    cellRenderer: ({ data }) => {
      return (
        <Tooltip position='top' tooltip={`view all visits by ${data.provider}`}>
          <StyledText
            onClick={() =>
              showVisitData({
                status: "requested",
                providerId: data.provider_id,
              })
            }>
            {data.requested}
            <IconSpan className='icon-span'>
              <Icon appearance='basic' name='navigate_next' size='regular' />
            </IconSpan>
          </StyledText>
        </Tooltip>
      );
    },
  },
  {
    displayName: "Completed",
    name: "completed",
    width: "10%",
    align: "right",
    cellRenderer: ({ data }) => {
      return (
        <Tooltip
          position='top'
          tooltip={`view completed visits by ${data.provider}`}>
          <StyledText
            onClick={() =>
              showVisitData({
                status: "completed",
                providerId: data.provider_id,
              })
            }>
            {data.completed}
            <IconSpan className='icon-span'>
              <Icon appearance='basic' name='navigate_next' size='regular' />
            </IconSpan>
          </StyledText>
        </Tooltip>
      );
    },
  },
  {
    displayName: "Cancelled",
    name: "cancelled",
    width: "10%",
    align: "right",
    cellRenderer: ({ data }) => {
      return (
        <Tooltip
          position='top'
          tooltip={`view cancelled visits by ${data.provider}`}>
          <StyledText
            onClick={() =>
              showVisitData({
                status: "cancelled",
                providerId: data.provider_id,
              })
            }>
            {data.cancelled}
            <IconSpan className='icon-span'>
              <Icon appearance='basic' name='navigate_next' size='regular' />
            </IconSpan>
          </StyledText>
        </Tooltip>
      );
    },
  },
  {
    displayName: "In-queue",
    name: "inqueue",
    width: "10%",
    align: "right",
    cellRenderer: ({ data }) => {
      return (
        <Tooltip
          position='top'
          tooltip={`view In-queue visits by ${data.provider}`}>
          <StyledText
            onClick={() =>
              showVisitData({
                status: "inqueue",
                providerId: data.provider_id,
              })
            }>
            {data.inqueue}
            <IconSpan className='icon-span'>
              <Icon appearance='basic' name='navigate_next' size='regular' />
            </IconSpan>
          </StyledText>
        </Tooltip>
      );
    },
  },
  {
    displayName: "Ready for doctor",
    name: "ready_for_doctor",
    width: "10%",
    align: "right",
    cellRenderer: ({ data }) => {
      return (
        <Tooltip
          position='top'
          tooltip={`view Ready for doctor visits by ${data.provider}`}>
          <StyledText
            onClick={() =>
              showVisitData({
                status: "ready_for_doctor",
                providerId: data.provider_id,
              })
            }>
            {data.ready_for_doctor}
            <IconSpan className='icon-span'>
              <Icon appearance='basic' name='navigate_next' size='regular' />
            </IconSpan>
          </StyledText>
        </Tooltip>
      );
    },
  },
  {
    displayName: "Incomplete",
    name: "incompleted",
    width: "10%",
    align: "right",
    cellRenderer: ({ data }) => {
      return (
        <Tooltip
          position='top'
          tooltip={`view incomplete visits by ${data.provider}`}>
          <StyledText
            onClick={() =>
              showVisitData({
                status: "incompleted",
                providerId: data.provider_id,
              })
            }>
            {data.incompleted}
            <IconSpan className='icon-span'>
              <Icon appearance='basic' name='navigate_next' size='regular' />
            </IconSpan>
          </StyledText>
        </Tooltip>
      );
    },
  },
];

class ProvidersVirtualVisits extends PureComponent {
  static getData = getData;

  state = {
    loading: false,
    error: false,
    data: [],
    statusMapping: {},
  };

  showVisitData = ({ status, providerId }) => {
    const { history, filters } = this.props;
    const { statusMapping } = this.state;
    const { startDate, endDate, callType } = filters || {};
    const link = `${getGlobalURL("/virtualVisits")}?filters=${JSON.stringify({
      ...(status && { visitStatus: statusMapping[status] }),
      providers: [providerId],
      ...(startDate && { startDate }),
      ...(endDate && { endDate }), // perf: | todo: filter names should be consistent across platform
      ...(callType && { callType: [callType] }),
    })}`;
    openUrl(link, { target: "_blank" });
  };

  columns = getColumns({
    showVisitData: this.showVisitData,
    statusMapping: this.state.statusMapping,
  });

  schema = this.columns.map(column => ({
    ...column,
    sorting: false,
    separator: true,
  }));

  loaderSchema = this.schema.map(({ cellRenderer, ...column }) => column);

  fetchTableData = filters => {
    this.setState({
      loading: true,
      error: false,
    });
    getData({ filters })
      .then(
        ({
          data: {
            virtualVisitStats: { providerStats },
            statusMapping,
          },
        }) => {
          this.setState({
            data: providerStats,
            statusMapping,
            loading: false,
          });
        }
      )
      .catch(() => {
        this.setState({
          error: true,
          loading: false,
        });
      });
  };

  fetchData = ({ searchTerm }) => {
    const { data } = this.state;
    // perf: pass without cloning when https://github.com/innovaccer/design-system/issues/265 is fixed
    const filteredData = searchTerm
      ? cloneDeep(data).filter(({ provider = "" }) =>
          provider.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : cloneDeep(data);

    return Promise.resolve({
      data: filteredData,
      schema: this.schema,
      count: filteredData.length,
    });
  };

  render() {
    const { history, isPDF } = this.props;
    const { loading, data, error, statusMapping } = this.state;

    const getBody = () => {
      if (loading) {
        return <WidgetLoader />;
      }

      if (error) {
        return (
          <WidgetError
            onRetry={() => this.fetchTableData(this.props.filters)}
          />
        );
      }

      return (
        <>
          <Header
            style={{
              width: "100%",
              fontSize: "20px",
              marginBottom: 16,
            }}>
            Visits distribution (by providers)
          </Header>
          <Table
            errorTemplate={() => (
              <Placeholder
                title={`We're off record!`}
                subTitle={`Because there is no record available at the moment.`}
              />
            )}
            async
            withHeader
            loaderSchema={this.loaderSchema}
            headerOptions={{
              withSearch: !isPDF,
              dynamicColumn: false,
              searchPlaceholder: "Search by provider",
            }}
            showMenu={false}
            fetchData={this.fetchData}
          />
        </>
      );
    };

    return <WidgetCard>{getBody()}</WidgetCard>;
  }

  componentDidUpdate(prevProps) {
    if (this.props.filters != prevProps.filters) {
      this.fetchTableData(this.props.filters);
    }
  }

  componentDidMount() {
    this.fetchTableData(this.props.filters);
  }
}

export default withRouter(ProvidersVirtualVisits);
