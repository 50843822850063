import React from "react";
import { Text } from "@innovaccer/design-system";
import styled from "styled-components";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import Users from "./users";
import Practices from "./practices";
import Copayment from "./copayment";
import ChatConfiguration from "./chat";
import Assessment from "./assessment";
import { Mixpanel } from "helper/mixpanelHelper";
import NetworkSettings from "./network";
import VirtualFronDoor from "./virtual_fron_door";
import AuthService from "../../services/authService";
import axios from "helper/axios";
import PageLoader from "../components/pageLoader";
import ErrorTemplate from "../components/errorTemplate";

const routeMapping = {
  networkSettings: NetworkSettings,
  practices: Practices,
  users: Users,
  copayment: Copayment,
  assessment: Assessment,
  chatConfiguration: ChatConfiguration,
  virtualFronDoor: VirtualFronDoor,
};

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 40px);
  overflow: hidden;
`;

const RoutesWrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

const NavigationWrapper = styled.div`
  width: 18%;
  max-width: 200px;
`;

const MenuCategory = styled.div`
  :not(:last-child) {
    border-bottom: var(--border);
  }
`;

const Error = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Navigation = ({ menus, match, routes }) => {
  const currentUser = AuthService.getUser();
  return (
    <NavigationWrapper className='mr-5'>
      {menus.map(({ category, subMenu }) => {
        return (
          <MenuCategory className='py-5'>
            <div className='mb-4 Navigation-menu Navigation-menu--subMenu Navigation-menu--vertical pl-5'>
              <Text weight='strong'>{category}</Text>
            </div>
            <div className='Navigation-subMenu'>
              {subMenu.map(({ label, name }) => {
                if (
                  name === "network" &&
                  currentUser.organization_category === "INDIVIDUAL"
                ) {
                  return;
                }
                return (
                  <NavLink
                    activeClassName='Navigation-menu--active'
                    className='Navigation-menu Navigation-menu--subMenu Navigation-menu--vertical pl-5'
                    onClick={() => {
                      Mixpanel.track(`${label} clicked`, {
                        category: "InOffice-Settings",
                      });
                    }}
                    to={`${match.url}/${routes[name].link}`}>
                    {label}
                  </NavLink>
                );
              })}
            </div>
          </MenuCategory>
        );
      })}
    </NavigationWrapper>
  );
};

class Settings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      navigation: [],
      routes: {},
    };
  }

  getNavigationConfig = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        axios
          .get("organizations/configurations/settings/navigation")
          .then(({ data: { config } }) => {
            this.setState({
              config: config.value,
              navigation: [...config.value.navigation],
              routes: config.value.routes,
              isLoading: false,
            });
          })
          .catch(error => {
            this.setState({
              isLoading: false,
              error,
            });
          });
      }
    );
  };

  componentDidMount() {
    this.getNavigationConfig();
  }

  render() {
    const { match } = this.props;
    const { isLoading, navigation, routes } = this.state;
    const currentUser = AuthService.getUser();
    if (isLoading) {
      return <PageLoader />;
    }
    if (navigation.length === 0 && !isLoading) {
      return (
        <Error>
          <ErrorTemplate error={true} />
        </Error>
      );
    }
    return (
      <Wrapper>
        <Navigation
          match={match}
          menus={navigation.map(({ subMenu, ...rest }) => ({
            ...rest,
            subMenu: subMenu.filter(
              ({ name, hidden }) => routes[name] && !hidden
            ),
          }))}
          routes={routes}
        />
        <RoutesWrapper>
          <Switch>
            {Object.entries(routes).map(
              ([key, { link, component, hidden }]) => {
                if (
                  link === "network" &&
                  currentUser.organization_category === "INDIVIDUAL"
                ) {
                  return;
                }
                if (hidden) {
                  return;
                }
                return (
                  <Route
                    key={key}
                    path={`${match.path}/${link}`}
                    component={routeMapping[component]}
                  />
                );
              }
            )}
            <Route
              key='default'
              path='/'
              component={() => <Redirect to={`${match.path}/users`} />}
            />
          </Switch>
        </RoutesWrapper>
      </Wrapper>
    );
  }
}

export default Settings;
