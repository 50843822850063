import styled from "styled-components";
import Card from "@datashop/card";
import { isEmpty } from "lodash";
import authService from "services/authService";
import { orgTypes } from "config";

export const CommonCard = styled(Card).attrs({ shadow: "light" })`
  background-color: ${props => props.theme.datashop.palette.white.main};
  width: 100%;
`;

export const welcomeTasks = {
  all: [
    // {
    //   key: "video",
    //   title: "Learn InNote",
    //   description: {
    //     incomplete:
    //       "Take a quick video tour for an introduction to InNote’s telemedicine capabilities and how seamless it has become now to be in contact with your patients.",
    //     complete:
    //       "Take a quick video tour for an introduction to InNote’s telemedicine capabilities and how seamless it has become now to be in contact with your patients."
    //   }
    // },
    {
      key: "practice",
      title: "Add Practice",
      description: {
        incomplete:
          "Let your users and patients see your practice in all its glory, complete your practice profile now.",
        complete:
          "You can always go to ‘Practice’ under ’Settings’ tab to add more practices.",
      },
      visible: () =>
        authService.getUserKey("organization_category") === orgTypes.large &&
        authService.isAdmin(),
    },
    {
      key: "invite",
      title: "Invite your teammates",
      description: {
        incomplete:
          "A medical practice is incomplete without Providers, MAs etc. Let’s onboard them and add to your team.",
        complete:
          "You can always go to ‘User role and permissions’ under ’Settings’ tab to edit or add more of your teammates.",
      },
      visible: () => authService.isAdmin(),
    },
    {
      key: "outreach",
      title: "Send your first campaign",
      description: {
        incomplete:
          "From annual appointment reminders to survey campaigns, our best-in-class outreach module makes it click easy to contact your patients.",
        complete:
          "Go to the 'Assessments' tab from the top navigation to see the assessment responses.",
      },
      visible: () => true,
    },
  ],
  get tasks() {
    return this.all.filter(({ visible }) =>
      visible instanceof Function ? visible() : visible
    );
  },
  hasIncompleteTasks: function (statuses) {
    if (isEmpty(statuses)) {
      return this.tasks.length;
    }

    return this.tasks.filter(task =>
      this.isTaskStatusInCommplete(statuses[task.key])
    ).length;
  },
  isTaskStatusInCommplete: function (status) {
    return !["SKIPPED", "DONE"].includes(status);
  },
};
