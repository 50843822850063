import React, { useState, useEffect } from "react";
import {
  Text,
  Card,
  Switch,
  PageHeader,
  Spinner,
  Dialog,
} from "@innovaccer/design-system";
import axios from "../../../helper/axios";
import AlertService, {
  defaultDissmissDelay,
} from "../../../services/alertService";
import "./style.css";

const VirtualFronDoor = () => {
  const [open, setOpen] = useState(false);
  const [enableVirtualFronDoor, setEnableVirtualFronDoor] = useState(false);
  const [enableChatAssistant, setEnableChatAssistant] = useState(false);
  const [enableInstantVisit, setEnableInstantVisit] = useState(false);
  const [enableRequestVisit, setEnableRequestVisit] = useState(false);

  const onVirtualFronDoorSwitchToggle = (event, selected) => {
    if (!selected) {
      onClose();
    } else {
      SetVirtualFronDoorConfig("enableVirtualFronDoor", selected);
    }
  };

  const onRequestVisitSwitchToggle = (event, selected) => {
    SetVirtualFronDoorConfig("enableRequestVisit", selected);
  };

  const onInstantVisitSwitchToggle = (event, selected) => {
    SetVirtualFronDoorConfig("enableInstantVisit", selected);
  };

  const onChatAssistantSwitchToggle = (event, selected) => {
    SetVirtualFronDoorConfig("enableChatAssistant", selected);
  };
  const SetVirtualFronDoorConfig = (commingFrom, selected) => {
    if (commingFrom) {
      const value = {
        vfdEnabled:
          commingFrom === "enableVirtualFronDoor"
            ? selected
            : enableVirtualFronDoor,
        chatAssistant:
          commingFrom === "enableChatAssistant"
            ? selected
            : enableChatAssistant,
        instantVisit:
          commingFrom === "enableInstantVisit" ? selected : enableInstantVisit,
        requestVisit:
          commingFrom === "enableRequestVisit" ? selected : enableRequestVisit,
      };
      axios
        .patch("/organizations/configurations/vfd/settings", { value })
        .then(res => {
          if (res && res.data && res.data.value) {
            const {
              vfdEnabled,
              chatAssistant,
              instantVisit,
              requestVisit,
            } = res.data.value;
            setEnableVirtualFronDoor(vfdEnabled);
            setEnableChatAssistant(chatAssistant);
            setEnableInstantVisit(instantVisit);
            setEnableRequestVisit(requestVisit);
            AlertService.showAlert({
              appearance: "success",
              title: "Config saved successfully",
              autoClearAfter: defaultDissmissDelay,
            });
          }
        })
        .catch(() => {
          AlertService.showAlert({
            appearance: "alert",
            title: "Something went wrong",
            autoClearAfter: defaultDissmissDelay,
          });
        });
    }
  };
  const onClose = () => {
    setOpen(!open);
  };

  const onConfirm = () => {
    SetVirtualFronDoorConfig("enableVirtualFronDoor", false);
    onClose();
  };

  const options = {
    open,
    onClose,
    dimension: "small",
    heading: "Disable Front door",
    description:
      "This will disable the virtual fron door for your organization and your patients will not be able to access it as well.",
    primaryButtonLabel: "Confirm",
    primaryButtonCallback: onConfirm,
    secondaryButtonLabel: "Cancel",
    secondaryButtonCallback: onClose,
  };

  const GetVirtualFrontDoorConfig = () => {
    axios
      .get("/organizations/configurations/vfd/settings")
      .then(res => {
        const { config } = res && res.data;
        if (config && config.value) {
          const {
            vfdEnabled,
            chatAssistant,
            instantVisit,
            requestVisit,
          } = config.value;
          setEnableVirtualFronDoor(vfdEnabled);
          setEnableChatAssistant(chatAssistant);
          setEnableInstantVisit(instantVisit);
          setEnableRequestVisit(requestVisit);
        }
      })
      .catch(() => {
        AlertService.showAlert({
          appearance: "alert",
          title: "Something went wrong",
          autoClearAfter: defaultDissmissDelay,
        });
      });
  };

  useEffect(() => {
    GetVirtualFrontDoorConfig();
  }, []);

  return (
    <div className='w-100'>
      <Dialog {...options} />
      <PageHeader title='Virtual front door' />
      <div className='d-flex justify-content-center'>
        <Card className='vfd global-vfd p-6 mb-6 mt-6' shadow='light'>
          <div className='d-flex justify-content-between'>
            <Text appearance='default' weight='strong' className='font-size-16'>
              Virtual front door
            </Text>
            <Switch
              appearance='success'
              size='tiny'
              checked={enableVirtualFronDoor}
              onChange={onVirtualFronDoorSwitchToggle}
            />
          </div>
          <Text appearance='subtle'>
            Use it to enable or disable your virtual front door
          </Text>
        </Card>
      </div>
      <div className='d-flex justify-content-center'>
        <Card className='role-based-vfd vfd p-6' shadow='light'>
          <div className='mb-6'>
            <Text appearance='default' weight='strong' className='font-size-16'>
              Features
            </Text>
          </div>

          <div className='d-flex justify-content-between border-bottom'>
            <Text appearance='default' weight='strong'>
              Chat assistant
            </Text>
            <Switch
              appearance='success'
              disabled={!enableVirtualFronDoor}
              size='tiny'
              checked={enableChatAssistant}
              onChange={onChatAssistantSwitchToggle}
            />
          </div>
          <div className='pb-6'>
            <Text appearance='subtle'>
              Allow your patients to chat with your staff memmbers to
              assistance.
            </Text>
          </div>

          <div className='d-flex justify-content-between border-bottom'>
            <Text appearance='default' weight='strong'>
              Instant visit
            </Text>
            <Switch
              appearance='success'
              size='tiny'
              disabled={!enableVirtualFronDoor}
              checked={enableInstantVisit}
              onChange={onInstantVisitSwitchToggle}
            />
          </div>
          <div className='pb-6'>
            <Text appearance='subtle'>
              Allow your patients to book instant visits with your providers.
            </Text>
          </div>
          <div className='d-flex justify-content-between'>
            <Text appearance='default' weight='strong'>
              Request visit
            </Text>
            <Switch
              appearance='success'
              size='tiny'
              disabled={!enableVirtualFronDoor}
              checked={enableRequestVisit}
              onChange={onRequestVisitSwitchToggle}
            />
          </div>
          <div className='pb-6'>
            <Text appearance='subtle'>
              Allow your patients to request future visits with your providers.
            </Text>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default VirtualFronDoor;
