import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Text from "@datashop/text";
import MaskedInput from "react-text-mask";
import Input from "@datashop/input";

export const StyledText = styled(Text)`
  ${props =>
    props.light && `color: ${props.theme.datashop.palette.neutral.lightest};`}
  ${props => props.size && `font-size: ${props.size}px;`}
  ${props => props.transform && `text-transform: ${props.transform};`}
`;

const phoneNumberFormat = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const PhoneNumberInput = ({ onChange, onBlur, value, ...rest }) => {
  return (
    <MaskedInput
      mask={phoneNumberFormat}
      placeholder='(555) 123-4567'
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      render={(ref, renderProps) => {
        const { onChange, placeholder, value, onBlur, ...other } = renderProps;
        return (
          <Input
            innerRef={ref}
            inputFormat='phoneNumber'
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            value={value}
            {...rest}
            {...other}
          />
        );
      }}
    />
  );
};

const StyledPwdInput = styled(Input)`
  & ~ i {
    font-size: 16px;
    ${props =>
      props.enableVisbilityToggle &&
      `color: ${props.theme.datashop.palette.neutral.lightest};`}
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
`;

export const PasswordInput = ({
  id,
  enableVisbilityToggle,
  onClearClick,
  clearButton,
  type,
  ...rest
}) => {
  const [visible, setVisibility] = useState(false);
  const inputId = id || "password-input-field";

  useEffect(() => {
    const inputIcon = document.querySelector(`#${inputId} + i`);
    if (enableVisbilityToggle && inputIcon) {
      inputIcon.innerHTML = visible ? "visibility_off" : "visibility";
    }
  }, [visible]);

  return (
    <StyledPwdInput
      id={inputId}
      enableVisbilityToggle={enableVisbilityToggle}
      type={visible ? "text" : type || "password"}
      clearButton={enableVisbilityToggle || clearButton}
      onClearClick={
        enableVisbilityToggle ? () => setVisibility(!visible) : onClearClick
      }
      {...rest}
    />
  );
};
