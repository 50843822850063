import React, { Component } from "react";
import styled from "styled-components";
import FlexBox from "../commons/FlexBox";
import axios from "helper/axios";
import Spinner from "@datashop/spinner";
import Badge from "@datashop/badge";
import Text from "@datashop/text";
import { debounce } from "lodash";
import Input from "@datashop/input";
import Card from "@datashop/card";
import Placeholder from "@datashop/placeholder";
import EmptyMessage from "components/commons/Placeholder";
import Button from "@datashop/button";
import theme from "@datashop/theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
`;

const Header = styled.div`
  display: flex;
  background-color: white;
  align-items: center;
  width: 100%;
  padding: 12px;
  border-bottom: 1px solid ${props => props.theme.datashop.palette.stone.dark};
`;
const BodyWrapper = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex: 1;
`;

const AssessmentCard = styled(Card).attrs({
  shadow: "light"
})`
  width: 100%;
  background-color: white;
`;

const AssessmentListItem = styled.div`
  padding: 16px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.datashop.palette.stone.main};
`;

class AssessmentListing extends Component {
  state = { search: "", loading: false, data: [], totalCount: 0 };

  handleSearchChange = search => {
    this.setState(
      {
        search
      },
      this.fetchAssessmentList
    );
  };

  debounceSearchChange = debounce(this.handleSearchChange, 300);

  fetchAssessmentList = () => {
    this.setState({ loading: true });
    axios
      .get(
        `/insights/assessment/types${
          this.state.search ? "?searchText=" + this.state.search : ""
        }`
      )
      .then(({ data: { data, totalCount } }) => {
        this.setState({
          loading: false,
          data,
          totalCount
        });
      })
      .catch(() => {
        this.setState({
          loading: false
        });
      });
  };

  redirectToAssessment = id => {
    this.props.history.push(`${this.props.match.url}/${id}`);
  };

  render() {
    const { data, loading, totalCount, search } = this.state;

    const renderList = () => {
      if (loading) {
        return (
          <AssessmentCard>
            <AssessmentListItem style={{ flexDirection: "column" }}>
              <Placeholder.Paragraph length='large'></Placeholder.Paragraph>
              <Placeholder.Paragraph
                style={{ marginBottom: 0 }}
                length='small'></Placeholder.Paragraph>
            </AssessmentListItem>
            <AssessmentListItem style={{ flexDirection: "column" }}>
              <Placeholder.Paragraph length='large'></Placeholder.Paragraph>
              <Placeholder.Paragraph
                style={{ marginBottom: 0 }}
                length='small'></Placeholder.Paragraph>
            </AssessmentListItem>
          </AssessmentCard>
        );
      }

      if (!data.length) {
        return (
          <EmptyMessage
            title='Assessments'
            subTitle='No assessments records found. Assessment dashboard shows you the result of assessments conducted by your clinic.'>
            <Button
              appearance='primary'
              onClick={() => this.props.history.push("/outreach")}>
              Send assessment
            </Button>
          </EmptyMessage>
        );
      }

      return (
        <>
          <Input
            icon='search'
            placeholder='Search'
            style={{ width: 200, marginBottom: 16 }}
            onChange={e => {
              this.debounceSearchChange(e.target.value);
            }}
          />
          <AssessmentCard>
            {data.map(assessment => {
              return (
                <AssessmentListItem
                  onClick={() => this.redirectToAssessment(assessment._id)}>
                  <FlexBox direction='column'>
                    <Text fontWeight='bold'>{assessment.name}</Text>
                    <Text
                      fontSize={"small"}
                      style={{
                        color: theme.datashop.palette.neutral.lighter,
                        marginTop: 2
                      }}>
                      ASSESSMENT
                    </Text>
                  </FlexBox>
                  <FlexBox
                    direction='column'
                    style={{
                      color: theme.datashop.palette.neutral.lighter,
                      textAlign: "right"
                    }}>
                    <Text>{assessment.responses}</Text>
                    <Text>responses</Text>
                  </FlexBox>
                </AssessmentListItem>
              );
            })}
          </AssessmentCard>
        </>
      );
    };

    return (
      <Wrapper>
        <Header>
          <Badge style={{ marginRight: 6 }}>
            {typeof totalCount === "number" ? totalCount : "..."}
          </Badge>
          <Text fontWeight='bold'>Assessments</Text>
        </Header>
        <BodyWrapper>
          <FlexBox style={{ width: "50%", height: "100%" }} direction='column'>
            {renderList()}
          </FlexBox>
        </BodyWrapper>
      </Wrapper>
    );
  }

  componentDidMount() {
    this.fetchAssessmentList();
  }
}

export default AssessmentListing;
