import styled from "styled-components";
import Reveal from "@datashop/reveal";
import Text from "@datashop/text";

export const HeaderWrapper = styled.div`
  background-color: white;
  display: flex;
  border-bottom: 2px solid #ededed;
  background: #fff;
  width: 100%;
`;

export const StyledOutreachReveal = styled(Reveal)`
  height: 100%;
  width: 640px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  padding: 16px;
  /* Safari 4.0 - 8.0 */
  @-webkit-keyframes sidebar-animation {
    0% {
      margin-right: -100vw;
      opacity: 0;
    }
    100% {
      margin-right: 0vw;
      opacity: 1;
    }
  }
  /* Standard syntax */
  @keyframes sidebar-animation {
    0% {
      margin-right: -100vw;
      opacity: 0;
    }
    100% {
      width: 95vw !important;
      margin-right: 0vw;
      opacity: 1;
    }
  }
  animation: sidebar-animation 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  animation-iteration-count: 1;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Label = styled(Text)`
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 600;
`;

export const MediumWrapper = styled.div`
  display: flex;
  width: 186px;
  padding: 10px 0px 10px 16px;
  margin-right: 12px;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
`;

export const TextWithBg = styled(Text)`
  line-height: 16px;
  padding: 4px 12px 4px 12px;
  background: #e5e5e5;
  border-radius: 3px;
`;

export const MessageContentWrapper = styled.div`
  width: 440px;
  height: auto;
  background: #f4f4f4;
  padding: 16px 24px;
  color: #2f2f2f;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  word-wrap: break-word;
`;

export const MessageBox = styled.div.attrs({ contentEditable: true })`
  height: 94px;
  width: 100%;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  background-color: #ffffff;
  overflow-y: scroll;
  padding: 12px 8px 0px 12px;
  margin: 8px 0 8px 0;
  font-family: inherit;

  &:empty:not(:focus):before {
    content: attr(Your text goes here);
  }
`;

export const Dot = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 4px;
  background-color: #2f2f2f;
  margin: 7px 5px 7px 7px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;
