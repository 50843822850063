import React, { Component } from "react";
import styled from "styled-components";
import { StyledText } from "components/commons/atoms";
import authService from "services/authService";
import Button from "@datashop/button";
import Icon from "@datashop/icon";
import axios from "helper/axios";
import theme from "@datashop/theme";
import { withRouter } from "react-router-dom";
import { CommonCard, welcomeTasks } from "./commons";
import { Mixpanel } from "helper/mixpanelHelper";

const TasksWrapper = styled(CommonCard)`
  overflow: auto;
  flex: 1;
  padding: 24px;
`;

const TaskNumberWrapper = styled.div`
  width: 48px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const TaskNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: ${props =>
    props.theme.datashop.palette[props.complete ? "green" : "blue"].lightest};
  color: ${props =>
    props.theme.datashop.palette[props.complete ? "green" : "blue"].main};
`;

const TaskContent = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid ${props => props.theme.datashop.palette.stone.light};
  > * {
    margin-bottom: 16px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const TasksRow = styled.div`
  display: flex;
  background: ${props => props.theme.datashop.palette.white.main};
  margin-top: 24px;
  :first-child {
    margin-top: 0px;
  }
  :last-child {
    ${TaskContent} {
      padding-bottom: 0;
      border: none;
    }
  }
`;

const TaskActions = styled.div`
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 20px;
  }
`;

class WelcomeTasks extends Component {
  state = {
    error: false,
    loading: false,
    welcomeStatus: authService.getUserKey("welcomeStatus") || {}
  };

  actions = {
    video: (
      <Button
        onClick={() => {
          this.updateTask("video", "DONE");
        }}>
        <Icon name='play_arrow' />
        &nbsp;Play video
      </Button>
    ),
    invite: (
      <Button
        onClick={() => {
          Mixpanel.track("Users Invited", {
            category: "InOffice-Welcome Screen"
          });
          this.props.history.push("/settings/users/invited");
        }}>
        <Icon name='add' />
        &nbsp;Invite teammates
      </Button>
    ),
    practice: (
      <Button
        onClick={() => {
          Mixpanel.track("Practice added", {
            category: "InOffice-Welcome Screen"
          });
          this.props.history.push("/settings/practices");
        }}>
        <Icon name='local_hospital' />
        &nbsp;Add practice
      </Button>
    ),
    outreach: (
      <Button
        onClick={() => {
          Mixpanel.track("Patient Outreach accessed", {
            category: "InOffice-Welcome Screen"
          });
          this.props.history.push("/outreach");
        }}>
        <Icon name='assignment' />
        &nbsp;Send assessments
      </Button>
    )
  };

  fetchStatus = () => {
    this.setState({
      loading: true
    });
    axios
      .get("/innote-survey/user/welcome-status")
      .then(({ data: { welcomeStatus } }) => {
        this.setState(
          {
            welcomeStatus,
            loading: false
          },
          this.updateStatusToLS
        );
      })
      .catch(() => {
        this.setState({
          error: true,
          loading: false
        });
      });
  };

  updateStatusToLS = () => {
    authService.setUserKey("welcomeStatus", this.state.welcomeStatus);
    if (!welcomeTasks.hasIncompleteTasks(this.state.welcomeStatus)) {
      this.props.onComplete(this.state.welcomeStatus);
    }
  };

  updateTask = (key, status) => {
    const originalStatus = { ...this.state.welcomeStatus };
    this.setState({
      welcomeStatus: { ...originalStatus, [key]: status }
    });
    axios
      .patch("/innote-survey/user/welcome-status", {
        activity: key,
        status
      })
      .then(() => {
        this.setState(
          {
            loading: false
          },
          this.updateStatusToLS
        );
      })
      .catch(() => {
        this.setState(
          {
            welcomeStatus: originalStatus,
            error: true,
            loading: false
          },
          this.updateStatusToLS
        );
      });
  };

  tasks = welcomeTasks.tasks;

  render() {
    const { welcomeStatus } = this.state;

    return (
      <TasksWrapper data-test='landing-screen-welcome-tasks'>
        {this.tasks
          .sort((taska, taskb) => {
            const aStatus = welcomeTasks.isTaskStatusInCommplete(
              welcomeStatus[taska.key]
            );
            const bStatus = welcomeTasks.isTaskStatusInCommplete(
              welcomeStatus[taskb.key]
            );
            return aStatus === bStatus ? 0 : aStatus ? -1 : 1;
          })
          .slice(0, 2)
          .map((task, index) => {
            const taskIncomplete = welcomeTasks.isTaskStatusInCommplete(
              welcomeStatus[task.key]
            );

            return (
              <TasksRow key={task.key}>
                <TaskNumberWrapper>
                  <TaskNumber complete={!taskIncomplete}>
                    {taskIncomplete ? index + 1 : <Icon name='done' />}
                  </TaskNumber>
                </TaskNumberWrapper>
                <TaskContent>
                  <StyledText fontSize='large' fontWeight='bold'>
                    {task.title}
                  </StyledText>
                  <StyledText
                    style={{ color: theme.datashop.palette.neutral.light }}
                    fontWeight='light'>
                    {
                      task.description[
                        taskIncomplete ? "incomplete" : "complete"
                      ]
                    }
                  </StyledText>
                  {taskIncomplete && (
                    <TaskActions>
                      {this.actions[task.key]}
                      <StyledText
                        style={{ cursor: "pointer" }}
                        light
                        inline
                        onClick={() => this.updateTask(task.key, "SKIPPED")}>
                        Skip
                      </StyledText>
                    </TaskActions>
                  )}
                </TaskContent>
              </TasksRow>
            );
          })}
      </TasksWrapper>
    );
  }

  componentDidMount() {
    this.fetchStatus();
  }
}

export default withRouter(WelcomeTasks);
