import React, { Component } from "react";
import { render } from "react-dom";
import Reveal from "@datashop/reveal";
import styled, { ThemeProvider } from "styled-components";
import datashopTheme from "@datashop/theme";
import Button from "@datashop/button";
import Text from "@datashop/text";
import Tabs from "@datashop/tabs";
import Icon from "@datashop/icon";
import Badge from "@datashop/badge";
import axios from "helper/axios";
import Spinner from "@datashop/spinner";
import { formatDate } from "utils";
import moment from "moment";
import PatientDetailsExport from "./ExportTemplate";
import { getExportableTemplate } from "./renderToString";
import Popup from "@datashop/popup";
import Card from "@datashop/card";
import Input from "@datashop/input";
import Toast from "@datashop/toast";
import { Mixpanel } from "../../../helper/mixpanelHelper";
import { emailRegex } from "config";

const StyledReveal = styled(Reveal)`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 45%;
  padding: 12px 16px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  ${props =>
    props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${props => props.direction && `flex-direction: ${props.direction};`}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 32px;
  margin-bottom: 32px;
`;

const LeftDetails = styled.div``;

const PersonalInfoWrapper = styled.div`
  margin-bottom: 32px;
`;

export const StyledText = styled(Text).attrs({ inline: true })`
  ${props =>
    props.light && `color: ${props.theme.datashop.palette.neutral.lighter};`}
  ${props => props.size && `font-size: ${props.size}px;`}
  ${props => props.transform && `text-transform: ${props.transform};`}
`;

const DetailsTable = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${props => props.theme.datashop.palette.stone.dark};
  border-radius: 4px;
  margin-top: 16px;
  > * {
    border-right: 1px solid ${props => props.theme.datashop.palette.stone.dark};
    &:last-child {
      border-right: none;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border: 1px solid ${props => props.theme.datashop.palette.stone.dark};
  border-radius: 4px;
  border-spacing: 0;
`;

export const THead = styled.thead`
  font-weight: ${props => props.theme.datashop.weights.bold};
  background-color: ${props => props.theme.datashop.palette.stone.light};
`;
export const TBody = styled.tbody``;
export const Tr = styled.tr`
  border-bottom: 1px solid ${props => props.theme.datashop.palette.stone.main};
  &:last-child {
    border-bottom: none;
  }
`;
export const Td = styled.td`
  padding: 8px;
`;

const PopupWrapper = styled(Card)`
  width: 400px;
  background-color: white;
  padding: 12px;
`;

const getFormat = s => {
  const m = s / 60;
  if (m < 1) return `ss[sec]`;
  if (m < 60) return `mm[min] ss[sec]`;
  return `hh[hr] mm[min] ss[sec]`;
};

export const secsToMins = s =>
  moment()
    .startOf("day")
    .seconds(s)
    .format(getFormat(s));

export const getAssessmentHistoryTable = (data = []) => {
  if (!data || !data.length) {
    return (
      <FlexWrapper justifyContent='center' light style={{ padding: 8 }}>
        <i>No Data</i>
      </FlexWrapper>
    );
  }
  return (
    <Table>
      <THead>
        <Tr>
          <Td></Td>
          <Td>Assessment</Td>
          <Td>Assessment taken</Td>
          <Td>Outcome</Td>
        </Tr>
      </THead>
      <TBody>
        {!data.length && (
          <StyledText light style={{ padding: 8 }}>
            (No Data)
          </StyledText>
        )}
        {data.map((row, i) => (
          <Tr>
            <Td>{i + 1}.</Td>
            <Td>{row.assessmentName}</Td>
            <Td>{formatDate(row.assessmentDate, "MM/DD/YYYY, hh:mm A")}</Td>
            <Td>{row.outcome}</Td>
          </Tr>
        ))}
      </TBody>
    </Table>
  );
};

export const getTelemedicineHistoryTable = (data = []) => {
  if (!data || !data.length) {
    return (
      <FlexWrapper justifyContent='center' light style={{ padding: 8 }}>
        <i>No Data</i>
      </FlexWrapper>
    );
  }
  return (
    <Table>
      <THead>
        <Tr>
          <Td></Td>
          <Td>Provider</Td>
          <Td>Timestamp</Td>
          <Td>Duration</Td>
        </Tr>
      </THead>
      <TBody>
        {data.map(({ providerName = {}, timestamp, callDuration }, i) => (
          <Tr>
            <Td>{i + 1}.</Td>
            <Td>
              {providerName.firstName} {providerName.lastName}
            </Td>
            <Td>{formatDate(timestamp, "MM/DD/YYYY, hh:mm A")}</Td>
            <Td>{secsToMins(callDuration)}</Td>
          </Tr>
        ))}
      </TBody>
    </Table>
  );
};

const patientDetailCategory = {
  patients: "TA-Patients",
  assessments: "TA-Assessments"
};

class PatientDetails extends Component {
  state = {
    selectedTab: 0,
    loading: false,
    error: false,
    details: null,
    showSharePopup: false,
    shareEmail: "",
    toast: false,
    sharingProfile: false,
    shareEmailValid: true
  };

  showToast = props => {
    this.setState({
      toast: { ...{ title: "", appearance: "success" }, ...props }
    });
    const timer = setTimeout(() => {
      this.hideToast();
      clearTimeout(timer);
    }, 5000);
  };

  hideToast = () => {
    this.setState({
      toast: false
    });
  };

  fetchPatientDetails = () => {
    const {
      patientFirstName,
      patientLastName,
      contactNumber
    } = this.props.patientDetails;
    this.setState({ loading: true });
    axios
      .get(
        `/insights/patient/profile?first_name=${patientFirstName}&last_name=${patientLastName}&phone_no=${contactNumber}`
      )
      .then(({ data }) => {
        this.setState({
          loading: false,
          error: false,
          details: data
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true
        });
      });
  };

  shareProfile = ({ patientInfo, assessmentDetails, telemedicineDetails }) => {
    this.setState({
      sharingProfile: true
    });
    const template = getExportableTemplate(
      <ThemeProvider theme={datashopTheme}>
        <PatientDetailsExport
          patientInfo={patientInfo}
          assessmentDetails={assessmentDetails}
          telemedicineDetails={telemedicineDetails}></PatientDetailsExport>
      </ThemeProvider>
    );

    axios
      .post("insights/patient/profile/pdf", {
        html_data: template,
        email_id: this.state.shareEmail,
        patient_info: patientInfo
      })
      .then(({ data }) => {
        this.showToast({
          message: `Profile shared with ${this.state.shareEmail}`
        });
        this.toggleSharePopup();
        Mixpanel.track("Profile Shared", {
          category: patientDetailCategory[this.props.parent]
        });
      })
      .catch(err => {
        this.showToast({
          title: `Couldn't share profile`,
          message: "Please confirm, the email belongs to the same organization",
          appearance: "alert"
        });
      })
      .finally(() => {
        this.setState({
          sharingProfile: false
        });
      });
  };

  onShareEmailChange = e => {
    const email = e.target.value;
    this.setState({
      shareEmail: email,
      shareEmailValid: emailRegex.test(email)
    });
  };

  toggleSharePopup = () => {
    this.setState(({ showSharePopup }) => ({
      showSharePopup: !showSharePopup,
      shareEmail: ""
    }));
  };

  render() {
    const {
      selectedTab,
      loading,
      error,
      details,
      showSharePopup,
      sharingProfile,
      shareEmail,
      toast,
      shareEmailValid
    } = this.state;
    const { onClose, parent } = this.props;

    const getChildren = () => {
      if (loading) {
        return (
          <FlexWrapper
            style={{ height: "100%" }}
            justifyContent='center'
            alignItems='center'>
            <Spinner size={32}></Spinner>
          </FlexWrapper>
        );
      }

      if (error || !details) {
        return (
          <FlexWrapper
            style={{ height: "100%" }}
            justifyContent='center'
            direction='column'
            alignItems='center'>
            <StyledText style={{ marginBottom: 8 }} light size='24'>
              Unable to fetch Patient details.
            </StyledText>
            <Button>
              <Icon name='rotate_right' onClick={this.fetchPatientDetails} />
              &nbsp;Retry
            </Button>
          </FlexWrapper>
        );
      }

      const patientInfo = details.patientInfo || {};
      const assessmentDetails = details.assessmentDetails || {
        total: 0,
        assessmentHistory: []
      };
      const telemedicineDetails = details.telemedicineDetails || {
        total: 0,
        telemedicineHistory: []
      };

      return (
        <>
          <Header>
            <LeftDetails>
              <Text fontSize='large' fontWeight='bold'>
                Patient Profile
              </Text>
            </LeftDetails>
            <Popup
              verticalOffset={10}
              trigger={
                <Button
                // onClick={() =>
                //   this.shareProfile({
                //     patientInfo,
                //     assessmentDetails,
                //     telemedicineDetails
                //   })
                >
                  <Icon name='mail' />
                  &nbsp;Share Profile
                </Button>
              }
              open={showSharePopup}
              on='click'
              onToggle={this.toggleSharePopup}
              position='BottomLeft'>
              <PopupWrapper shadow='medium'>
                <StyledText
                  style={{ marginBottom: 16, display: "block" }}
                  size={18}
                  fontWeight='bold'>
                  Share patient profile via email
                </StyledText>

                <StyledText>Recipient's mail</StyledText>
                <Input
                  placeholder='john.doe@xyz.com'
                  type='email'
                  onChange={this.onShareEmailChange}
                  error={shareEmail && !shareEmailValid}
                  style={{ fontFamily: "inherit", margin: "4px 0" }}
                />
                <StyledText light size={12}>
                  Recipient should be part of your organization
                </StyledText>

                <FlexWrapper
                  justifyContent='flex-end'
                  style={{ marginTop: 16 }}>
                  <Button
                    appearance='success'
                    onClick={() =>
                      this.shareProfile({
                        patientInfo,
                        assessmentDetails,
                        telemedicineDetails
                      })
                    }
                    disabled={
                      sharingProfile || !shareEmail || !shareEmailValid
                    }>
                    Share profile
                  </Button>
                </FlexWrapper>
              </PopupWrapper>
            </Popup>
          </Header>
          <PersonalInfoWrapper>
            <StyledText light>Name</StyledText>
            <FlexWrapper alignItems='baseline'>
              <StyledText transform='capitalize' size={20}>
                {patientInfo.lastName}, {patientInfo.firstName}
              </StyledText>
              &nbsp;
              <StyledText>{patientInfo.gender}</StyledText>
            </FlexWrapper>
            <DetailsTable>
              <FlexWrapper
                direction='column'
                style={{ padding: 12, flexGrow: 1 }}>
                <StyledText light>DOB</StyledText>
                <StyledText>
                  {patientInfo.dob ? formatDate(patientInfo.dob) : "N/A"}
                </StyledText>
              </FlexWrapper>
              <FlexWrapper
                direction='column'
                style={{ padding: 12, flexGrow: 1 }}>
                <StyledText light>Insurance</StyledText>
                <StyledText>
                  {patientInfo.insurance ? patientInfo.insurance : "N/A"}
                </StyledText>
              </FlexWrapper>
              <FlexWrapper
                direction='column'
                style={{ padding: 12, flexGrow: 1 }}>
                <StyledText light>Contact</StyledText>
                <StyledText>
                  {patientInfo.phoneNo ? patientInfo.phoneNo : "N/A"}
                </StyledText>
              </FlexWrapper>
              <FlexWrapper
                direction='column'
                style={{ padding: 12, flexGrow: 1 }}>
                <StyledText light>Email</StyledText>
                <StyledText>
                  {patientInfo.email ? patientInfo.email : "N/A"}
                </StyledText>
              </FlexWrapper>
            </DetailsTable>
          </PersonalInfoWrapper>
          <Tabs
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden"
            }}
            paneWrapperStyle={{
              paddingTop: 12,
              height: "100%",
              overflow: "hidden"
            }}
            selected={selectedTab}
            onTabChange={index => {
              this.setState({ selectedTab: index });
              if (index === 1) {
                Mixpanel.track("Telemed History Accessed", {
                  category: patientDetailCategory[parent]
                });
              }
            }}>
            <Tabs.Tab
              style={{ overflow: "auto", height: "100%" }}
              label={
                <FlexWrapper style={{ cursor: "pointer" }} alignItems='center'>
                  <Badge>{assessmentDetails.total}</Badge>&nbsp;Assessment
                  history
                </FlexWrapper>
              }>
              {getAssessmentHistoryTable(assessmentDetails.assessmentHistory)}
            </Tabs.Tab>
            <Tabs.Tab
              style={{ overflow: "auto", height: "100%" }}
              label={
                <FlexWrapper
                  style={{ cursor: "pointer", overflow: "auto" }}
                  alignItems='center'>
                  <Badge>{telemedicineDetails.total}</Badge>&nbsp;Telemedicine
                  history
                </FlexWrapper>
              }>
              {getTelemedicineHistoryTable(
                telemedicineDetails.telemedicineHistory
              )}
            </Tabs.Tab>
          </Tabs>
        </>
      );
    };

    return (
      <StyledReveal backdrop closeButton={true} open={true} onClose={onClose}>
        {getChildren()}
        {toast && (
          <Toast
            title={toast.title}
            message={toast.message}
            appearance={toast.appearance}
            onDismissClick={this.hideToast}></Toast>
        )}
      </StyledReveal>
    );
  }

  componentDidMount() {
    this.fetchPatientDetails();
  }
}

export default PatientDetails;
