import { render } from "react-dom";

export const getExportableTemplate = template => {
  const iframe = document.createElement("iframe");
  iframe.id = "dynamic-iframe-id";

  document.body.appendChild(iframe);

  render(template, iframe.contentDocument.body);

  const styleTags = document.querySelectorAll("style");
  styleTags.forEach(tag => {
    iframe.contentDocument.head.appendChild(tag.cloneNode(true));
  });
  iframe.contentDocument.head.insertAdjacentHTML(
    "beforeend",
    '<meta charset="utf-8">'
  );

  const html = `<!doctype html><html>${iframe.contentDocument.documentElement.innerHTML}</html>`;

  iframe.remove();
  return html;
};
