import React, { PureComponent } from "react";
import { Responsive as ResponsiveGrid, WidthProvider } from "react-grid-layout";

import { AllWidgets } from "./widgets";
import styled from "styled-components";

const ResponsiveGridLayout = WidthProvider(ResponsiveGrid);

const PDFLayout = styled.div`
  position: fixed;
  left: -200vw;
  width: 9.9in;
  text {
    font-size: 10px;
  }
  @media print {
    position: unset;
    left: 0px;
  }

  > div {
    page-break-inside: avoid !important;
    display: flex;
    min-height: 380px;
    width: 100%;
    margin-bottom: 16px;
  }
`;

class GridLayout extends PureComponent {
  render() {
    const {
      layout,
      filters,
      updateLayout,
      reorganizing,
      exportingPDF
    } = this.props;

    const visibleWidgets = layout
      // .filter(({ disabled }) => !disabled)
      .map(widget => ({ ...widget, static: !reorganizing }));

    return (
      <>
        {exportingPDF && (
          <PDFLayout>
            {visibleWidgets.map(({ i }) => {
              const Widget = AllWidgets[i];
              return (
                <div key={i}>
                  <Widget key={i} filters={filters} isPDF={true} />
                </div>
              );
            })}
          </PDFLayout>
        )}
        <ResponsiveGridLayout
          className='layout'
          onLayoutChange={newLayout => {
            if (reorganizing) {
              updateLayout(
                newLayout.map(({ i, h, w, x, y }) => ({ i, h, w, x, y }))
              );
            }
          }}
          layouts={{ lg: visibleWidgets }}
          cols={{ lg: 20 }}
          breakpoints={{ lg: 900 }}
          rowHeight={30}>
          {visibleWidgets.map(({ i }) => {
            const Widget = AllWidgets[i];
            return (
              <div className='d-flex' key={i}>
                <Widget key={i} filters={filters} />
              </div>
            );
          })}
        </ResponsiveGridLayout>
      </>
    );
  }
}

export default GridLayout;
