import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Text from "@datashop/text";
import Input from "@datashop/input";
import Message from "@datashop/message";
import { Label, Text as DSText } from "@innovaccer/design-system";
import Button from "@datashop/button";
import Icon from "@datashop/icon";
import authService from "services/authService";
import axios, { getErrorMessage } from "helper/axios";
import { emailRegex, logInStrategyMap } from "config";
import OTPForm from "components/commons/OTPForm";
import { AuthRouteWrapper, SmallErrorText } from "./commons";
import Loader from "@datashop/loader";
import { handleKeyPress } from "helper/form";
import { PasswordInput, StyledText } from "components/commons/atoms";
import { Mixpanel } from "helper/mixpanelHelper";
import { getOrgSubdomain, getAdminUrl } from "utils";
import { queryParamsToConfig } from "helper/queryParams";
import { openUrl } from "helper/fileDownload";

const domains = [
  "https://preprod-admin-virtual-care.innovaccer.com",
  "https://admin-virtual-care.innovaccer.com",
  "http://localhost:3000",
];

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.datashop.palette.white.main};
`;

const LoginImage = styled.img`
  @media (max-width: 800px) {
    display: none;
  }
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.datashop.palette.blue.main};
`;

const FormWrapper = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;

const TopHeader = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 100%;
  text-align: right;
`;

const FormHeading = styled.div`
  max-width: 100%;
`;

const Form = styled.div`
  width: 400px;
  margin-top: 48px;
  max-width: 100%;
`;

const Heading = styled(Text)`
  margin-bottom: 6px;
  text-align: center;
  font-size: 28px;
`;
const SubHeading = styled(Text)`
  text-align: center;
  font-size: 16px;
  color: ${props => props.theme.datashop.palette.neutral.lightest};
`;

const FormField = styled.div`
  margin-top: 32px;
  :first-child {
    margin-top: 0px;
  }
`;

const StyledInput = styled(Input)`
  height: 40px;
  margin: 4px 0;
`;

const StylePwdInput = styled(PasswordInput)`
  height: 40px;
  margin: 4px 0;
`;

const SeparatorText = styled.div`
  display: flex;
  color: ${props => props.theme.datashop.palette.neutral.lightest};
  align-items: center;
  font-weight: ${props => props.theme.datashop.weights.bold};
`;

const errors = {
  email: val => !val || !emailRegex.test(val),
  password: val => !val,
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.subDomain = getOrgSubdomain();
    this.queryParams = {
      ...queryParamsToConfig(
        this.props.location ? this.props.location.search : ""
      ),
      email:
        authService.getUserKey("userName") || this.props.match.params.email,
    };

    this.state = {
      viewState: errors.email(this.queryParams.email) ? 0 : 1,
      email: this.queryParams.email || "",
      password: "",
      showOtp: false,
      loading: false,
      error: false,
      formError: {},
    };
  }

  componentDidMount() {
    const email = authService.getUserKey("userName");
    if (email) {
      this.setState({ email }, () => {
        this.validateEmailAddress();
      });
    }
  }

  onChange = event => {
    const {
      target: { value, name },
    } = event;
    this.setState({ [name]: value });
  };

  setFormError = event => {
    if (event) {
      const {
        target: { value, name },
      } = event;
      this.setState({
        formError: { ...this.state.formError, [name]: errors[name](value) },
      });
    } else {
      this.setState({
        formError: Object.keys(errors).reduce((error, key) => {
          error[key] = errors[key](this.state[key]);
          return error;
        }, {}),
      });
    }
  };

  sendOTP = () => {
    const { email } = this.state;

    this.setState({
      sendingOTP: true,
      loading: true,
      error: false,
    });
    axios
      .post("/innote-survey/user/auth", {
        email: email,
        loginType: logInStrategyMap[1],
      })
      .then(({ data }) => {
        this.setState({
          sendingOTP: false,
          loading: false,
          showOtp: true,
        });
      })
      .catch(error => {
        this.setState({
          sendingOTP: false,
          loading: false,
          error: getErrorMessage(error),
        });
      });
  };

  login = () => {
    const { email, password, showOtp } = this.state;
    this.setFormError();
    if (errors.password(password) || errors.email(email)) {
      return;
    }
    this.setState({
      loading: true,
      error: false,
    });
    axios
      .post("/innote-survey/user/auth/_validate", {
        password,
        email,
        loginType: logInStrategyMap[showOtp ? 1 : 0],
      })
      .then(({ data }) => {
        Mixpanel.track("Login ", {
          category: "InOffice-Login",
        });
        authService.logIn(data);
        this.setState({
          loading: false,
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: getErrorMessage(error),
        });
      });
  };

  validateEmailAddress = () => {
    const { email } = this.state;
    if (errors.email(email)) {
      return;
    }
    this.setState({
      loading: true,
      error: false,
    });
    axios
      .get(`/innote-survey/user/email/exists`, {
        params: { email, existing_email: true },
      })
      .then(({ data: { sub_domain, email } }) => {
        if (!sub_domain || 0 === sub_domain.length) {
          if (domains.includes(window.location.origin)) {
            this.setState({
              viewState: 1,
              loading: false,
            });
          } else {
            this.setState({
              error: "Sorry we don’t recognize that user.",
              loading: false,
            });
            return;
          }
        } else {
          if (sub_domain !== this.subDomain) {
            const redirectUrl = `${getAdminUrl(sub_domain)}#/login/${email}`;
            if (domains.includes(window.location.origin)) {
              openUrl(redirectUrl);
            } else if (window.location.href === redirectUrl) {
              this.setState({
                viewState: 1,
                loading: false,
              });
            } else {
              this.setState({
                error: "Sorry we don’t recognize that user.",
                loading: false,
              });
              return;
            }
          } else {
            this.setState({
              viewState: 1,
              loading: false,
            });
          }
        }
      })
      .catch(res => {
        this.setState({
          loading: false,
          error: getErrorMessage(res),
        });
      });
  };

  render() {
    const {
      viewState,
      email,
      sendingOTP,
      showOtp,
      loading,
      error,
      formError = {},
    } = this.state;

    if (showOtp) {
      return (
        <AuthRouteWrapper>
          <OTPForm
            error={error}
            disabled={loading}
            onResend={this.sendOTP}
            email={email}
            onComplete={otp =>
              this.setState(
                {
                  password: otp,
                },
                this.login
              )
            }
          />
        </AuthRouteWrapper>
      );
    }

    const getFormState = () => {
      if (viewState == 0) {
        return (
          <Form
            onKeyDown={e => handleKeyPress(e, [13], this.validateEmailAddress)}>
            {error && <Message appearance='alert'>{error}</Message>}
            <FormField>
              <Label>Email address</Label>
              <StyledInput
                name='email'
                type='email'
                defaultValue={email}
                onChange={this.onChange}
                onBlur={this.setFormError}
                error={formError.email}
                placeholder='name@email.com'
              />
              {formError.email && (
                <SmallErrorText>
                  <Icon name='error' />
                  &nbsp; Sorry we don’t recognize that email.
                </SmallErrorText>
              )}
            </FormField>
            <FormField>
              <Button
                expanded
                dimension='large'
                appearance='primary'
                onClick={!loading && this.validateEmailAddress}>
                {loading ? <Loader /> : `Continue`}
              </Button>
            </FormField>
          </Form>
        );
      }

      if (viewState == 1) {
        return (
          <Form onKeyDown={e => handleKeyPress(e, [13], this.login)}>
            {error && <Message appearance='alert'>{error}</Message>}
            <FormField>
              <div className='d-flex justify-content-between'>
                <StyledText fontSize='large'>{email}</StyledText>
                <StyledText
                  onClick={() => this.setState({ viewState: 0 })}
                  className='color-primary cursor-pointer'
                  fontSize='large'>
                  Not you?
                </StyledText>
              </div>
            </FormField>
            <FormField>
              <Label>Password</Label>
              <StylePwdInput
                disabled={sendingOTP}
                id='login-form-password-input-field'
                enableVisbilityToggle={true}
                name='password'
                type='password'
                onChange={this.onChange}
                onBlur={this.setFormError}
                error={formError.password}
                placeholder='***********'
              />
              {formError.password && (
                <SmallErrorText>
                  <Icon name='error' />
                  &nbsp; Please fill in this field
                </SmallErrorText>
              )}
              <StyledLink to='/forgot-password'>Forgot password?</StyledLink>
            </FormField>
            <FormField>
              <Button
                disabled={sendingOTP}
                expanded
                dimension='large'
                appearance='primary'
                onClick={!loading && this.login}>
                {loading ? <Loader /> : `Sign in`}
              </Button>
            </FormField>
            <FormField>
              <SeparatorText>
                <hr style={{ width: "100%" }} />
                <span style={{ margin: "0 16px" }}>OR</span>
                <hr style={{ width: "100%" }} />
              </SeparatorText>
            </FormField>
            <FormField style={{ display: "flex", justifyContent: "center" }}>
              <Button
                disabled={sendingOTP}
                dimension='large'
                onClick={this.sendOTP}>
                <Icon style={{ fontSize: 18 }} name='textsms' />
                &nbsp; Sign in with verification code
              </Button>
            </FormField>
          </Form>
        );
      }
    };

    return (
      <Wrapper>
        <LoginImage src={`${process.env.PUBLIC_URL}/img/login.png`} />
        <FormWrapper>
          {!this.subDomain && (
            <TopHeader>
              New to virtual care network?&nbsp;
              <StyledLink to={`/signup`}>Sign up</StyledLink> today
            </TopHeader>
          )}
          <FormHeading>
            <Heading>Sign in to your organization</Heading>
            <SubHeading>
              {viewState == 0
                ? "Enter your email address to continue"
                : "Enter your password to continue"}
            </SubHeading>
          </FormHeading>
          {getFormState()}
        </FormWrapper>
      </Wrapper>
    );
  }
}

export default Login;
