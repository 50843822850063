import React, { Component } from "react";
import styled from "styled-components";
import StyledCard from "@datashop/card";
import Toast from "@datashop/toast";

import Filter from "./Filter";
import Button from "@datashop/button";
import Text from "@datashop/text";
import Input from "@datashop/input";
import Modal from "@datashop/modal";

import Table from "./Table";
import Pagination from "../commons/pagination";
import OutreachReveal from "../commons/outreachReveal";
import { HeaderWrapper, ColumnWrapper, RowWrapper } from "./styles.js";

import moment from "moment";
import { debounce, noop } from "lodash";
// import {data as downloadData} from './DownloadableData';
import { exportJson2Excel } from "helper/fileDownload";
import axios from "helper/axios";
import { stringifyQueryParams } from "helper/queryParams";
import PatientDetails from "../commons/patientDetails";
import { Mixpanel } from "../../helper/mixpanelHelper";
import { isEmpty, get } from "lodash";
import { formatDate } from "../../utils";

// todo: bind with permissions in future
const enableOutreach = () => {
  const { leftMenus = [], rightMenus = [] } = JSON.parse(
    localStorage.getItem("navConfig") || `{}`
  );
  return [...leftMenus, ...rightMenus].some(menu => menu.key === "outreach");
};

const ViewWrapper = styled.div`
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  .material-icons {
    font-size: 16px;
  }
`;

const DashboardWrapper = styled(StyledCard)`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: 12px;
  margin-left: 16px;
  height: 100%;
  overflow: hidden;
`;

const DashboardHeader = styled.div`
  display: flex;
  height: 40px;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 8px;
  }
`;

const TotalCount = styled.span`
  color: #151414;
  font-size: 14px;
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const DownloadIcon = styled.i`
  font-size: 16px;
  margin-right: 8px;
`;

const SearchInput = styled(Input)`
  width: 320px;
  font-size: 13px;
  font-family: inherit;
  padding-right: 2px;
`;

const MainHeader = styled.div`
  padding: 12px 0 12px 16px;
  border-bottom: 1px solid #d5d5d5;
  display: flex;
  justify-content: space-between;
`;

const FlexWrapper = styled.div`
  display: flex;
`;

const MainWrapper = styled.div`
  max-width: calc(100% - 240px);
  display: flex;
  flex-direction: column;
  flex: 4;
  height: 100%;
  overflow: hidden;
`;

const HeaderText = styled.div`
  color: #868686;
  font-size: 12px;
`;

const ItemName = styled.div`
  color: #2f2f2f;
  font-size: 20px;
`;

const defaultPagination = {
  page: 1,
  size: 20
};

class AssessmentDetails extends Component {
  constructor(props) {
    super(props);

    const defaultFilters = {
      assessmentDate: {
        startDate: moment()
          .subtract(29, "days")
          .format("MM/DD/YYYY"),
        endDate: moment().format("MM/DD/YYYY")
      }
    };

    const customFilters = get(this.props, "location.state.filters", {});

    this.state = {
      firstLoad: true,
      isLoading: false,
      data: [],
      filters: {
        ...defaultFilters,
        ...customFilters
      },
      pagination: {
        ...defaultPagination
      },
      isDownloadLoading: false,
      selectedRows: [],
      showOutreachReavel: false,
      patientDetails: null,
      clearSelectedRows: false
    };
  }

  showDetailsModal = details => {
    this.setState({
      patientDetails: details
    });
    Mixpanel.track("Patient Click", { category: "TA-Assessments" });
  };

  closeDetailsModal = () => {
    this.setState({
      patientDetails: null
    });
  };

  componentDidMount() {
    this.getAssessments();
    this.fetchAssessmentDetails();
  }

  fetchAssessmentDetails = () => {
    const assessmentId = this.props.match.params.assessmentId;
    axios
      .get(`/innote-survey/assessments/${assessmentId}/_fetch`)
      .then(({ data }) => {
        const { name } = data.data || {};
        this.setState({
          assessmentName: name
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          assessmentName: ""
        });
      });
  };

  prepareUrl = () => {
    const {
      pagination: { page, size },
      filters: {
        risk_category = "",
        assessmentDate = {},
        searchKeyword = "",
        healthLogin
      }
    } = this.state;
    const options = {
      from_page: page,
      per_page_limit: size,
      risk_category,
      search_text: searchKeyword,
      ...(!isEmpty(healthLogin) && { marathon_login_suffix: healthLogin })
    };
    if (assessmentDate) {
      if (assessmentDate.startDate) {
        options.assessment_date_start = moment(assessmentDate.startDate).format(
          "MM-DD-YYYY"
        );
      }
      if (assessmentDate.endDate) {
        options.assessment_date_end = moment(assessmentDate.endDate).format(
          "MM-DD-YYYY"
        );
      }
    }
    const params = stringifyQueryParams(options);
    const assessmentId = this.props.match.params.assessmentId;

    return `insights/assessment/${assessmentId}/responses?${params}`;
  };

  onPageChange = (__, page) => {
    this.setState({ pagination: { ...this.state.pagination, page } }, () => {
      this.trackEvent("filter");
      this.getAssessments();
    });
  };

  applyFilters = (type, value) => {
    const filters = {
      ...this.state.filters,
      [type]: value
    };
    this.setState(
      {
        filters,
        pagination: { ...defaultPagination } // reseting pagination on filter change
      },
      () => {
        const mixPanelEvent = type === "searchKeyword" ? "search" : "filter";
        this.trackEvent(mixPanelEvent);
        this.getAssessments();
      }
    );
  };

  debounceApplyFilters = debounce(this.applyFilters, 300);

  trackEvent = (mixPanelEvent = "filter") => {
    const eventName = {
      search: "Search Accessed",
      filter: "Filters Used"
    };
    Mixpanel.track(eventName[mixPanelEvent], {
      category: "TA-Assessments",
      ...this.state.filters,
      page: this.state.pagination
    });
  };

  lastFetched = 0;
  getAssessments = () => {
    const apiTime = Date.now();
    this.lastFetched = apiTime;
    const url = this.prepareUrl();
    this.setState(
      {
        isLoading: true
      },
      () => {
        axios
          .get(url)
          .then(({ data }) => {
            if (this.lastFetched > apiTime) {
              return;
            }
            const { tableSchema = [] } = data.metadata || {};
            this.setState({
              data: data.patientListData,
              totalPatients: data.totalPatients,
              tableSchema: tableSchema.filter(
                column => column.visible !== false
              ),
              isLoading: false,
              firstLoad: false
            });
          })
          .catch(error => {
            if (this.lastFetched > apiTime) {
              return;
            }
            this.setState({
              isLoading: false,
              error
            });
          });
      }
    );
  };

  renderSuccessModal = () => {
    let { selectedRows } = this.state;
    return (
      <Modal
        onClose={() =>
          this.setState(
            {
              showSuccessModal: false
            },
            () => this.onSelectRows([])
          )
        }
        showCloseButton={false}
        closeOnEscape={this.state.closeOnEscape}>
        <Modal.Body>
          <ColumnWrapper style={{ alignItems: "center" }}>
            <RowWrapper style={{ justifyContent: "center" }}>
              <span
                style={{
                  color: "#2EA843",
                  margin: "38px 0 24px 0",
                  fontSize: "40px",
                  display: "inline-block"
                }}
                class='material-icons'>
                check_circle
              </span>
            </RowWrapper>
            <RowWrapper style={{ justifyContent: "center" }}>
              <Text
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "48px"
                }}>
                {`Outreach sent to ${selectedRows.length} ${
                  selectedRows.length > 1 ? "patients" : "patient"
                }`}
              </Text>
            </RowWrapper>
          </ColumnWrapper>
        </Modal.Body>
      </Modal>
    );
  };
  downLoad = () => {
    const { assessmentDate = {} } = this.state.filters;
    this.setState({ isDownloadLoading: true, showPreparingToDownload: true });
    const url = this.prepareUrl();
    axios
      .get(`${url}&task=download`)
      .then(({ data: { patientListData } }) => {
        exportJson2Excel(
          [{ data: patientListData, schema: this.state.tableSchema }],
          `Assessment Report ${assessmentDate.startDate} ${assessmentDate.endDate}`
        );
      })
      .finally(() => {
        Mixpanel.track("Download", { category: "TA-Assessments" });
        this.setState({
          isDownloadLoading: false,
          showPreparingToDownload: false
        });
      });
  };

  onSelectRows = selectedRows => {
    this.setState(
      {
        selectedRows,
        clearSelectedRows: selectedRows.length < 1
      },
      selectedRows.length < 1
        ? () => {
            this.setState({ clearSelectedRows: false });
          }
        : noop
    );
  };

  onClickSendOutreach = () => {
    this.setState({
      showOutreachReavel: !this.state.showOutreachReavel
    });
  };

  onChangeSearch = search => {
    this.debounceApplyFilters("searchKeyword", search);
  };

  toggleOutreachReveal = () => {
    this.setState({
      showOutreachReavel: !this.state.showOutreachReavel
    });
  };

  render() {
    const {
      totalPatients = 0,
      pagination,
      data,
      isLoading,
      isDownloadLoading,
      showOutreachReavel,
      showSuccessModal,
      patientDetails,
      selectedRows,
      tableSchema,
      filters,
      firstLoad,
      clearSelectedRows
    } = this.state;
    const { searchKeyword } = filters || {};
    const assessmentId = this.props.match.params.assessmentId;

    const searchableFeildNames = (tableSchema || [])
      .reduce((names, column) => {
        if (column.search) {
          names.push(column.displayText);
        }
        return names;
      }, [])
      .join(", ");
    return (
      <ViewWrapper>
        <OutreachReveal
          open={showOutreachReavel}
          backdrop
          closeOnEscape
          closeButton
          onClose={() => this.setState({ showOutreachReavel: false })}
          selectedRows={this.state.selectedRows}
          onSendSucess={() => {
            this.setState({
              showOutreachReavel: false,
              showSuccessModal: true
            });
          }}
          parent='assessments'
        />

        <HeaderWrapper>
          <Text
            style={{
              maxWidth: "calc(100%  - 240px)",
              flex: 4,
              padding: "8px 0 8px 16px",
              borderRight: "1px solid #E7E5E5",
              display: "flex",
              flexFlow: "column",
              justifyContent: "center"
            }}>
            <HeaderText>Assessments / </HeaderText>
            <ItemName>{this.state.assessmentName || "..."}</ItemName>
          </Text>
          <Text
            style={{
              width: "240px",
              fontSize: "16px",
              color: "#2F2F2F",
              flex: "0 0",
              padding: "16px",
              display: "flex",
              alignItems: "center"
            }}>
            Filters
          </Text>
        </HeaderWrapper>
        <Wrapper>
          {this.state.showPreparingToDownload && (
            <Toast
              title='Preparing Download...'
              onDismissClick={() =>
                this.setState({ showPreparingToDownload: false })
              }
            />
          )}
          <MainWrapper>
            <MainHeader>
              <SearchInput
                id='assessment-search-filter'
                placeholder={`Search patients by ${searchableFeildNames}...`}
                icon='search'
                defaultValue={searchKeyword || ""}
                onChange={e => this.onChangeSearch(e.target.value)}
              />
              <FlexWrapper>
                {enableOutreach() && (
                  <StyledButton
                    disabled={this.state.selectedRows.length < 1}
                    onClick={this.onClickSendOutreach}>
                    Send outreach
                  </StyledButton>
                )}
                <StyledButton
                  disabled={
                    isLoading ||
                    (data && data.length === 0) ||
                    isDownloadLoading
                  }
                  onClick={this.downLoad}>
                  <DownloadIcon className='material-icons'>
                    get_app
                  </DownloadIcon>
                  Download records
                </StyledButton>
              </FlexWrapper>
            </MainHeader>
            <DashboardWrapper style={{ height: `calc(100vh - 164px)` }}>
              <DashboardHeader>
                <Header>
                  {selectedRows.length < 1 && (
                    <>
                      <TotalCount>
                        {`${totalPatients} ${
                          totalPatients > 1 ? "records" : "record"
                        }`}
                      </TotalCount>
                    </>
                  )}
                  {selectedRows.length > 0 && (
                    <>
                      <TotalCount>
                        {`${selectedRows.length} patient(s) selected`}
                      </TotalCount>
                      <Button
                        dimension='tiny'
                        onClick={() => this.onSelectRows([])}>
                        Deselect all ({selectedRows.length})
                      </Button>
                    </>
                  )}
                </Header>
              </DashboardHeader>
              <Table
                data={data}
                isLoading={isLoading}
                schema={tableSchema}
                selectedRows={selectedRows}
                clearSelectedRows={clearSelectedRows}
                onSelectRows={this.onSelectRows}
                showPatientDetails={this.showDetailsModal}
              />
            </DashboardWrapper>

            <Pagination
              currentPage={pagination.page}
              pageSize={pagination.size}
              totalCount={totalPatients}
              onChangePage={this.onPageChange}
              disabled={isLoading || !data || !data.length}
            />
          </MainWrapper>
          <Filter
            assessmentId={assessmentId}
            onChangeFilter={this.applyFilters}
            filters={filters}
            isLoading={firstLoad && isLoading}
            schema={tableSchema}
          />
          {showSuccessModal && this.renderSuccessModal()}
        </Wrapper>
        {patientDetails && (
          <PatientDetails
            patientDetails={patientDetails}
            onClose={this.closeDetailsModal}
            parent='assessments'
          />
        )}
      </ViewWrapper>
    );
  }
}

export default AssessmentDetails;
