import React from "react";
import {
  Row,
  Column,
  Pagination,
  Card,
  StatusHint,
  Text,
  Grid
} from "@innovaccer/design-system";
import { startCase } from "lodash";
import axios from "helper/axios";
import { stringifyQueryParams } from "helper/queryParams";
import Placeholder from "components/commons/Placeholder";
import Header from "../list/header";

var loading = false;
// Error template
const ErrorTemplate = ({ error, onRetry }) => (
  <Placeholder
    {...(error
      ? {
          error: true,
          iconStyle: { color: "var(--alert)" },
          title: "Something went wrong",
          subTitle:
            "It may be because of technical failure or network reasons. Please try again",
          onRetry
        }
      : {
          title: "No records available"
        })}
  />
);
// Get total no of pages
const getTotalPages = (totalRecords, pageSize) =>
  Math.ceil(totalRecords / pageSize);

//  Get status color
const getStatusAppearance = status => {
  const mapping = {
    delivered: "success",
    Sent: "success"
  };

  return status == "" ? "info" : mapping[status];
};

const StatusWithMeta = ({ children, meta, appearance }) => {
  return (
    <div>
      <StatusHint appearance={appearance}>{children}</StatusHint>
      <Text className='ml-6' appearance='subtle' small>
        {meta}
      </Text>
    </div>
  );
};

// Table column definition

const columns = {
  outreachName: {
    displayName: "Patients",
    name: "Name",
    width: "30%",
    cellRenderer: ({ data }) => {
      const { firstName, lastName } = data;
      return (
        <div className='patient-name '>
          {firstName &&
            `${startCase((firstName || "").toLowerCase())}, ${startCase(
              (lastName || "").toLowerCase()
            )}`}
        </div>
      );
    }
  },
  phoneNo: {
    displayName: "Phone",
    name: "phoneNo",
    width: "25%",
    cellRenderer: ({ data }) => {
      const { currentSmsStatus, phoneNo } = data;
      return (
        <>
          {!loading && (
            <StatusWithMeta
              meta={phoneNo ? phoneNo : "Not available"}
              appearance={getStatusAppearance(currentSmsStatus)}>
              {currentSmsStatus
                ? startCase((currentSmsStatus || "").toLowerCase())
                : "Skipped"}
            </StatusWithMeta>
          )}
        </>
      );
    }
  },
  email: {
    displayName: "Email",
    name: "email",
    width: "25%",
    cellRenderer: ({ data }) => {
      const { currentEmailStatus, email } = data;
      return (
        <>
          {!loading && (
            <StatusWithMeta
              meta={email ? email : "Not available"}
              appearance={getStatusAppearance(currentEmailStatus)}>
              {currentEmailStatus
                ? startCase((currentEmailStatus || "").toLowerCase())
                : "Skipped"}
            </StatusWithMeta>
          )}
        </>
      );
    }
  }
};

// Table loader schema definition

const loaderSchema = Object.values(columns).map(
  ({ cellRenderer, ...rest }) => rest
);

// Function for get table schema
const getSchema = rawSchema => {
  const schema = Object.values(columns).map(column => ({
    sorting: false,
    resizable: false,
    ...column
  }));

  return schema;
};

// Default pagination
const defaultPagination = {
  page: 1,
  size: 20
};

class Recipients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      data: [],
      schema: [],
      sort: [{ name: "sentOn", type: "desc" }],
      isLoading: true,
      totalCount: 0,
      search: "",
      pagination: {
        ...defaultPagination
      }
    };
  }

  lastFetched = 0;
  fetchRecipientsData = () => {
    const apiTime = Date.now();
    this.lastFetched = apiTime;
    const outreachId = this.props.outreachId;
    loading = true;
    const {
      pagination: { page, size },
      search
    } = this.state;

    const params = stringifyQueryParams({
      from_page: page,
      per_page_limit: size,
      search
    });

    this.setState({
      isLoading: true
    });
    axios
      .get(`/outreach/${outreachId}/recipients?${params}`)
      .then(({ data: { outreachRecipients } }) => {
        if (this.lastFetched > apiTime) {
          return;
        }
        loading = false;
        this.setState({
          isLoading: false,
          data: outreachRecipients.filteredData,
          totalCount: outreachRecipients.totalCount || 0,
          schema: getSchema()
        });
      })
      .catch(() => {
        if (this.lastFetched > apiTime) {
          return;
        }
        loading = false;
        this.setState({
          isLoading: false,
          error: true
        });
      });
  };

  onPageChange = page => {
    this.setState(
      { pagination: { ...this.state.pagination, page } },
      this.fetchRecipientsData
    );
  };

  onSearchChange = search => {
    this.setState(
      {
        search,
        pagination: {
          // resetting pagination
          ...defaultPagination
        }
      },
      this.fetchRecipientsData
    );
  };

  componentDidMount() {
    this.fetchRecipientsData();
  }

  render() {
    const {
      search,
      data,
      key,
      sort,
      schema,
      isLoading,
      error,
      pagination,
      totalCount
    } = this.state;
    return (
      <Row className='remove-flex-grow p-6'>
        <Column
          sizeXS={"12"}
          sizeXL={"12"}
          sizeS={"12"}
          sizeM={"12"}
          sizeL={"12"}>
          <div className='Table-container'>
            <div className='table-height-with-header'>
              <Card className='Table'>
                <div className='Table-header'>
                  <Header
                    searchPlaceholder='Search'
                    withPagination={true}
                    updateSchema={() => ""}
                    loading={isLoading}
                    error={error}
                    searchTerm={search}
                    totalRecords={totalCount}
                    schema={schema}
                    data={data}
                    updateSearchTerm={this.onSearchChange}
                  />
                </div>
                <div className='Table-grid'>
                  <Grid
                    key={key}
                    errorTemplate={props => (
                      <ErrorTemplate
                        {...props}
                        error={error}
                        onRetry={this.fetchRecipientsData}
                      />
                    )}
                    type='data'
                    size='comfortable'
                    error={error || data.length < 1}
                    sortingList={sort}
                    showMenu={false}
                    separator={true}
                    pageSize={pagination.size}
                    loading={isLoading}
                    schema={schema}
                    data={data}
                    loaderSchema={loaderSchema}
                  />
                </div>
                <div className='Table-pagination'>
                  <Pagination
                    page={pagination.page}
                    totalPages={getTotalPages(totalCount, pagination.size)}
                    type='jump'
                    onPageChange={this.onPageChange}
                  />
                </div>
              </Card>
            </div>
          </div>
        </Column>
      </Row>
    );
  }
}

export default Recipients;
