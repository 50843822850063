import React, { Component } from "react";
import styled from "styled-components";
import { StyledText } from "components/commons/atoms";
import authService from "services/authService";
import { CommonCard } from "./commons";
import FlexBox from "components/commons/FlexBox";
import Icon from "@datashop/icon";
import { withRouter } from "react-router-dom";
import { Mixpanel } from "helper/mixpanelHelper";

const ShortcutsWrapper = styled(CommonCard)`
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Shortcut = styled.div`
  display: flex;
  padding: 0px 24px;
  cursor: pointer;
  :not(:last-child) {
    ${TextWrapper} {
      border-bottom: 1px solid
        ${props => props.theme.datashop.palette.stone.light};
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 16px 0px;
  margin-right: 16px;
  > img {
    width: 30px;
  }
`;

class PlatformShortcuts extends Component {
  tasks = [
    {
      img: `${process.env.PUBLIC_URL}/img/testPassed.png`,
      title: "Bulk assessments",
      description: "Send assessments to your patients",
      onClick: () => {
        Mixpanel.track("Bulk assessment used", {
          category: "InOffice-Welcome Screen"
        });
        this.props.history.push("/outreach");
      },
      visible: true
    },
    {
      img: `${process.env.PUBLIC_URL}/img/doughnutChart.png`,
      title: "View reports",
      description: "View insights and analyse data related to televisits",
      onClick: () => {
        Mixpanel.track("Reports viewed", {
          category: "InOffice-Welcome Screen"
        });
        this.props.history.push("/analytics");
      },
      visible: true
    },
    {
      img: `${process.env.PUBLIC_URL}/img/team.png`,
      title: "Invite teammates",
      description: "Onboard your teammates and assign roles quickly",
      onClick: () => {
        Mixpanel.track("Users Invited", {
          category: "InOffice-Welcome Screen"
        });
        this.props.history.push("/settings/users/invited");
      },
      visible: authService.isAdmin()
    }
  ];

  render() {
    return (
      <ShortcutsWrapper data-test='landing-screen-platform-shortcuts'>
        {this.tasks
          .filter(({ visible }) => visible)
          .map(({ img, title, description, onClick }, index) => (
            <Shortcut key={index} onClick={onClick}>
              <ImageWrapper>
                <img src={img} />
              </ImageWrapper>
              <TextWrapper>
                <FlexBox direction='column'>
                  <StyledText fontWeight='bold'>{title}</StyledText>
                  <StyledText light>{description}</StyledText>
                </FlexBox>
                <Icon name='arrow_forward_ios' />
              </TextWrapper>
            </Shortcut>
          ))}
      </ShortcutsWrapper>
    );
  }
}

export default withRouter(PlatformShortcuts);
