import React, { Component } from "react";
import _get from "lodash/get";

import styled from "styled-components";
import Icon from "@datashop/icon";

const MainWrapper = styled.div`
  display: flex;
  padding: 16px 0 8px 0;
  justify-content: center;
  align-items: center;
  width: 100%;

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const NavigationItem = styled.div`
  height: 40px;
  width: 80px;
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  margin: 0 24px;
  border-radius: 2px;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
`;

const CurrentPage = styled.input`
  background: transparent;
  width: 40px;
  border: 1px solid ${props => props.theme.datashop.palette.stone.main};
  height: 40px;
  text-align: center;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 0;
`;

const StepPage = styled.i`
  font-size: 20px;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
`;

/**
 * @memberof module:components
 */
class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: props.currentPage
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ currentPage: nextProps.currentPage });
  }

  /**
   * Handles change in page input
   * @function
   * @param {event} event
   */
  onChangePageInput = event => {
    const { pageSize = 10 } = this.props;
    const newPage = parseInt(+event.currentTarget.value, 10);
    const totalPages = this.getTotalPages();

    if (event.currentTarget.value === "") {
      this.setState({
        currentPage: event.currentTarget.value
      });
    } else if (newPage > 0 && newPage <= totalPages) {
      // this.props.onChangePage((newPage - 1) * pageSize, newPage);
      this.setState({
        currentPage: newPage
      });
    } else {
      return;
    }
  };

  onPageInputFocusOut = () => {
    const { pageSize = 10 } = this.props;
    const newPage =
      (this.state.currentPage && parseInt(this.state.currentPage)) || 1;
    this.props.onChangePage((newPage - 1) * pageSize, newPage);
  };

  /**
   * Handles page change
   * @function
   * @param {event} event
   */
  handlePageChange = event => {
    const type = _get(event, "currentTarget.dataset.type");

    const changeDetails = this.getOffsetPage(type);
    if (changeDetails.isNotValid) {
      return;
    }
    this.setState({
      currentPage: changeDetails.currentPage
    });
    this.props.onChangePage(changeDetails.offset, changeDetails.currentPage);
  };

  /**
   * Get total number of pages
   * @function
   * @returns {number} - total number of pages
   */
  getTotalPages = () => {
    const { pageSize = 10, totalCount } = this.props;
    return Math.ceil(totalCount / pageSize);
  };

  /**
   * Get offset of page
   * @function
   * @param {string} type - type of button clicked
   * @returns {object} - object containing offset, currentPage and isNotValid
   */
  getOffsetPage = type => {
    const { currentPage } = this.state;
    const { pageSize = 10 } = this.props;
    const totalPages = this.getTotalPages();

    switch (type) {
      case "first":
        return {
          offset: 0,
          currentPage: 1,
          isNotValid: currentPage === 1
        };

      case "last": {
        return {
          offset: (totalPages - 1) * pageSize,
          currentPage: totalPages,
          isNotValid: currentPage === totalPages
        };
      }

      case "previous": {
        if (!currentPage) {
          return {
            offset: 0,
            currentPage: 1,
            isNotValid: false
          };
        }
        return {
          offset: (currentPage - 2) * pageSize,
          currentPage: currentPage - 1,
          isNotValid: currentPage === 1
        };
      }

      case "next":
        if (!currentPage) {
          return {
            offset: 0,
            currentPage: 1,
            isNotValid: false
          };
        }
        return {
          offset: currentPage * pageSize,
          currentPage: currentPage + 1,
          isNotValid: currentPage === totalPages
        };

      default:
        return 0;
    }
  };

  /**
   * Render the pagination component
   */
  render() {
    const totalPages = this.getTotalPages();
    const { disabled } = this.props;
    return (
      <MainWrapper data-testid='pagination'>
        <StepPage
          data-testid='pagination__first-page-button'
          className='material-icons'
          disabled={this.state.currentPage === 1 || disabled}
          data-type='first'
          onClick={this.handlePageChange}>
          first_page
        </StepPage>
        <NavigationItem
          data-testid='pagination__previous-page-button'
          id='previousBtn'
          disabled={this.state.currentPage === 1 || disabled}
          data-type='previous'
          onClick={this.handlePageChange}>
          <Icon name='chevron_left' />
        </NavigationItem>
        <CurrentPage
          data-testid='pagination__page-input'
          type='number'
          min='1'
          max={totalPages}
          data-type='go'
          value={this.state.currentPage}
          onBlur={this.onPageInputFocusOut}
          onChange={this.onChangePageInput}
          disabled={disabled}
        />{" "}
        <span>
          {" "}
          of {totalPages} {totalPages > 1 ? `pages` : `page`}
        </span>
        <NavigationItem
          data-testid='pagination__next-page-button'
          id='nextBtn'
          disabled={this.state.currentPage === totalPages || disabled}
          data-type='next'
          onClick={this.handlePageChange}>
          <Icon name='chevron_right' />
        </NavigationItem>
        <StepPage
          data-testid='pagination__last-page-button'
          className='material-icons'
          disabled={this.state.currentPage === totalPages || disabled}
          data-type='last'
          onClick={this.handlePageChange}>
          last_page
        </StepPage>
      </MainWrapper>
    );
  }
}

export default Pagination;
