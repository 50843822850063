import React from "react";
import {
  PlaceholderParagraph,
  MetaList,
  Button,
} from "@innovaccer/design-system";
import { GetAge, TextWithMetaList, Capitalize } from "./patientHelper";
import EllipsisText from "../components/ellipsisText";

export const Template = {
  default: (props, loading) => {
    if (loading) {
      return <PlaceholderParagraph length='medium' />;
    }
    return <EllipsisText>{props.data[props.schema.name]}</EllipsisText>;
  },

  lastAssesmentDate: (props, loading) => {
    const { lastAssessment, assessmentTakenDate } = props.data;
    return (
      <TextWithMetaList
        text={lastAssessment}
        metaText={assessmentTakenDate}
        isLoading={loading}
      />
    );
  },

  lastVirtualVisitDate: (props, loading) => {
    const { telemedicineProvider, telemedicineDate } = props.data;
    return (
      <TextWithMetaList
        text={telemedicineProvider}
        metaText={telemedicineDate}
        isLoading={loading}
      />
    );
  },

  patientName: (props, showDetailsModal, loading) => {
    const { patientFirstName, patientLastName, gender, dob } = props.data;
    if (loading) {
      return <PlaceholderParagraph length='medium' />;
    }
    return (
      <div
        onClick={() => showDetailsModal(props.data)}
        className='name-container'>
        <div>
          <div
            className='name'
            style={{ color: "rgb(0, 112, 221)" }}>{`${Capitalize(
            patientFirstName
          )}${patientLastName ? `, ` : ""} ${Capitalize(
            patientLastName
          )}`}</div>
          <div className='name-meta-list d-flex'>
            {`${GetAge(dob)}`}
            {gender && <MetaList list={[{ label: gender }]} seperator />}
          </div>
        </div>
      </div>
    );
  },
  sendOutreach: (props, config) => {
    const { selectedRows, onSendOutreachClick } = props;
    return (
      <Button
        appearance='basic'
        size='regular'
        className='mr-4'
        onClick={onSendOutreachClick}
        disabled={selectedRows.length < 1}>
        {config.displayName}
      </Button>
    );
  },
  downloadRecords: (props, config) => {
    const { isLoading, isDownloadLoading, data, onDownloadClick } = props;
    return (
      <Button
        disabled={isLoading || (data && data.length === 0) || isDownloadLoading}
        onClick={onDownloadClick}
        appearance='basic'
        icon='get_app'
        iconAlign='left'
        size='regular'>
        {config.displayName}
      </Button>
    );
  },
};
