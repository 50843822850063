import React, { Component } from "react";
import styled from "styled-components";
import {
  AuthRouteWrapper,
  passwordChecks,
  SmallErrorText,
  PasswordCheckList,
  CardSection,
  CommonCard,
} from "./commons";
import { StyledText, PasswordInput } from "components/commons/atoms";
import { Label } from "@innovaccer/design-system";
import Input from "@datashop/input";
import { emailRegex, logInStrategyMap } from "config";
import Button from "@datashop/button";
import axios, { getErrorMessage } from "helper/axios";
import authService from "services/authService";
import OTPForm from "components/commons/OTPForm";
import Icon from "@datashop/icon";
import Message from "@datashop/message";
import Loader from "@datashop/loader";
import AlertService, { defaultDissmissDelay } from "services/alertService";
import { handleKeyPress } from "helper/form";

const CardWrapper = styled(CommonCard)`
  width: 480px;
`;

const StyledInput = styled(Input)`
  height: 40px;
  margin: 4px 0;
`;

const StyledPwdInput = styled(PasswordInput)`
  height: 40px;
  margin: 4px 0;
`;

const errors = {
  email: val => !val || !emailRegex.test(val),
  password: val =>
    (passwordChecks.find(check => !check.test(val)) || {}).errorMsg,
};

class ForgotPassword extends Component {
  state = {
    email: "",
    otp: "",
    password: "",
    authData: null,
    loading: false,
    error: false,
    viewState: 0,
    formError: {},
  };

  onChange = event => {
    const {
      target: { value, name },
    } = event;
    this.setState({ [name]: value });
  };

  setFormError = event => {
    if (event) {
      const {
        target: { value, name },
      } = event;
      this.setState({
        formError: { ...this.state.formError, [name]: errors[name](value) },
      });
    } else {
      this.setState({
        formError: Object.keys(errors).reduce((error, key) => {
          error[key] = errors[key](this.state[key]);
          return error;
        }, {}),
      });
    }
  };

  validatePassword = () => {
    const { password, authData } = this.state;
    if (errors.password(password)) {
      this.setFormError();
      return;
    }
    this.setState({
      loading: true,
      error: false,
      formError: {},
    });
    axios
      .patch(
        "/innote-survey/user/save-password",
        {
          password,
        },
        {
          headers: {
            Authorization: authData.token,
          },
        }
      )
      .then(() => {
        authService.logIn(authData);
        AlertService.showAlert({
          title: "Password changed successfully",
          autoClearAfter: defaultDissmissDelay,
        });
        this.setState({
          loading: false,
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: getErrorMessage(error),
        });
      });
  };

  validateOtp = () => {
    const { email, otp } = this.state;
    if (!otp) {
      return;
    }
    this.setState({
      loading: true,
      error: false,
    });
    axios
      .post("/innote-survey/user/auth/_validate", {
        password: otp,
        email,
        loginType: logInStrategyMap[1],
      })
      .then(({ data }) => {
        this.setState({
          loading: false,
          authData: data,
          viewState: 2,
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
        });
        AlertService.showAlert({
          title: getErrorMessage(error),
          autoClearAfter: defaultDissmissDelay,
          appearance: "alert",
        });
      });
  };

  validateEmail = () => {
    const { email } = this.state;
    if (errors.email(email)) {
      this.setFormError();
      return;
    }
    this.setState({
      loading: true,
      error: false,
      formError: {},
    });
    axios
      .post("/innote-survey/user/auth", {
        email: email,
        loginType: logInStrategyMap[1],
      })
      .then(({ data }) => {
        this.setState({
          loading: false,
          viewState: 1,
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: getErrorMessage(error),
        });
      });
  };

  render() {
    const {
      email,
      password,
      viewState,
      loading,
      error,
      formError = {},
    } = this.state;

    const getViewState = () => {
      if (!viewState) {
        return (
          <CardWrapper
            onKeyDown={e => handleKeyPress(e, [13], this.validateEmail)}>
            <CardSection>
              <StyledText size={20}>Reset your password</StyledText>
            </CardSection>
            {error && (
              <CardSection>
                <Message appearance='alert'>{error}</Message>
              </CardSection>
            )}
            <CardSection>
              <Label>Email address</Label>
              <StyledInput
                name='email'
                type='email'
                onChange={this.onChange}
                onBlur={this.setFormError}
                error={formError.email}
                placeholder='name@email.com'
              />
              {formError.email && (
                <SmallErrorText>
                  <Icon name='error' />
                  &nbsp; Sorry, but this is not valid email!
                </SmallErrorText>
              )}
            </CardSection>
            <Button
              expanded
              dimension='large'
              appearance='primary'
              onClick={!loading && this.validateEmail}>
              {loading ? <Loader /> : "Submit"}
            </Button>
          </CardWrapper>
        );
      }

      if (viewState == 1) {
        return (
          <OTPForm
            disabled={loading}
            onResend={this.validateEmail}
            email={email}
            onComplete={otp => this.setState({ otp }, this.validateOtp)}
          />
        );
      }

      if (viewState == 2) {
        return (
          <CardWrapper
            onKeyDown={e => handleKeyPress(e, [13], this.validatePassword)}>
            <CardSection>
              <StyledText size={20}>Change password</StyledText>
              <StyledText fontWeight='light' light>
                In order to protect your account, new password should include
              </StyledText>
            </CardSection>
            {error && (
              <CardSection>
                <Message appearance='alert'>{error}</Message>
              </CardSection>
            )}
            <CardSection>
              <PasswordCheckList password={password} />
            </CardSection>
            <CardSection>
              <Label>New password</Label>
              <StyledPwdInput
                id='forgot-password-input-field'
                enableVisbilityToggle={true}
                name='password'
                type='password'
                onChange={e => {
                  this.onChange(e);
                  this.setFormError(e);
                }}
                onBlur={this.setFormError}
                error={formError.password}
                placeholder=''
              />
              {formError.password && (
                <SmallErrorText>
                  <Icon name='error' />
                  &nbsp;
                  {formError.password}
                </SmallErrorText>
              )}
            </CardSection>
            <Button
              expanded
              dimension='large'
              appearance='primary'
              onClick={!loading && this.validatePassword}>
              {loading ? <Loader /> : "Confirm new password"}
            </Button>
          </CardWrapper>
        );
      }
    };

    return (
      <AuthRouteWrapper
        actionList={[
          {
            title: (
              <Button onClick={() => this.props.history.push(`/login`)}>
                Sign in
              </Button>
            ),
            key: "signup",
          },
        ]}>
        {getViewState()}
      </AuthRouteWrapper>
    );
  }
}

export default ForgotPassword;
