import React from 'react';
import styled, { keyframes } from 'styled-components';

const Ellipsis1 = keyframes`
 0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const Ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`;

const Ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

// Ellipsis child
const EllipsisChild = styled.div``;

// Ellipsis parent
const EllipsisParent = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 15px;
  margin-top: 8px;

  div {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #0070dd;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  div:nth-child(1) {
    left: 6px;
    animation: ${Ellipsis1} 0.6s infinite;
  }
  div:nth-child(2) {
    left: 6px;
    animation: ${Ellipsis2} 0.6s infinite;
  }
  div:nth-child(3) {
    left: 26px;
    animation: ${Ellipsis2} 0.6s infinite;
  }
  div:nth-child(4) {
    left: 45px;
    animation: ${Ellipsis3} 0.6s infinite;
  }
`;

const Ellipsis = () => (
  <EllipsisParent>
    <EllipsisChild />
    <EllipsisChild />
    <EllipsisChild />
    <EllipsisChild />
  </EllipsisParent>
);

export default Ellipsis;
