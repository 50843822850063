import React, { Component } from "react";
import authService from "services/authService";
import styled from "styled-components";
import { StyledText } from "components/commons/atoms";
import FlexBox from "components/commons/FlexBox";
import Badge from "@datashop/badge";
import Button from "@datashop/button";
import WelcomeTasks from "./WelcomeTasks";
import axios from "helper/axios";
import {
  providerAppURL,
  providerAppBuildMac,
  providerAppBuildWindows,
} from "config";
import Loader from "@datashop/loader";
import { detectOS } from "helper/platform";
import { openUrl } from "helper/fileDownload";
import { CommonCard, welcomeTasks } from "./commons";
import PlatformShortcuts from "./PlatformShortcuts";
import VirtualVisitsCard from "./VirtualVIsitsCard";
import AssessmentsCompletedCard from "./AssessmentsCompletedCard";
import theme from "@datashop/theme";
import { Mixpanel } from "helper/mixpanelHelper";
import { getProviderUrl } from "utils";

const Wrapper = styled.div`
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

const HeadingWrapper = styled.div`
  text-align: center;
  padding: 32px;
`;

const BodyWrapper = styled.div`
  overflow: hidden;
  padding: 0px 24px 24px;
  display: flex;
  /* height: 100%; */
  width: 100%;
  justify-content: center;
  > * {
    :not(:last-child) {
      margin-right: 24px;
    }
  }
  @media (max-width: 800px) {
    flex-wrap: wrap;
    overflow: auto;
    > * {
      :not(:last-child) {
        margin-right: 0px;
        margin-bottom: 24px;
      }
    }
  }
`;

const VirtualVisitCard = styled(CommonCard)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 480px;
  background-color: ${props => props.theme.datashop.palette.white.main};
  overflow: hidden;
`;

const VirtualVisitsOptions = styled.div`
  padding: 32px 32px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > * {
    margin-bottom: 8px;
  }
`;

const VirtualVisitLaunchImage = styled.img`
  width: 100%;
  height: auto;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 568px;

  > * {
    :not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

class Home extends Component {
  state = {
    launchingInNote: false,
    inNoteOpen: false,
    hasIncompleteTasks: !!welcomeTasks.hasIncompleteTasks(
      authService.getUserKey("welcomeStatus")
    ),
  };

  launchInNote = () => {
    Mixpanel.track("Launch InNote clicked", {
      category: "InOffice-Welcome Screen",
    });
    if (this.state.launchingInNote) {
      return;
    }
    this.setState({
      launchingInNote: true,
    });
    axios
      .get("/innote-survey/user/auth/get-one-time-token")
      .then(({ data: { token } }) => {
        let loggedInUser = authService.getUser(),
          { sub_domain } = loggedInUser,
          url = null;
        if (!sub_domain || 0 === sub_domain.length) {
          url = `${getProviderUrl(null)}#/?OTT=${token}`;
        } else {
          url = `${getProviderUrl(sub_domain)}#/?OTT=${token}`;
        }
        const inNote = window.open(
          `${url}`,
          "InNote",
          `directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=no,
          width=400,height=${window.innerHeight}`
        );
        this.setState({
          launchingInNote: false,
          inNoteOpen: true,
        });
      })
      .catch(() => {
        this.setState({
          launchingInNote: false,
        });
      });
  };

  downloadInNote = () => {
    const OS = detectOS() || "";
    Mixpanel.track("InNote downloaded", {
      category: "InOffice-Welcome Screen",
      platform: OS,
    });
    if (OS.toLocaleLowerCase() === "windows") {
      openUrl(providerAppBuildWindows, { donwload: "InNote" });
    } else if (OS.toLocaleLowerCase() === "mac") {
      openUrl(providerAppBuildMac, { donwload: "InNote" });
    }
  };

  render() {
    const { launchingInNote, hasIncompleteTasks } = this.state;

    const userName =
      authService.getUserKey("firstName") || authService.getUserName();

    return (
      <Wrapper data-test='landing-screen'>
        <HeadingWrapper>
          <StyledText data-test='landing-screen-welcome-message' size={28}>
            Welcome, {userName} 🙌
          </StyledText>
        </HeadingWrapper>
        <BodyWrapper>
          <VirtualVisitCard data-test='landing-screen-launch-virtual-visit'>
            <VirtualVisitsOptions>
              <Badge
                style={{ fontWeight: theme.datashop.weights.bold }}
                subtle
                appearance='neel'>
                NEW FEATURE
              </Badge>
              <StyledText size={30}>Start taking virtual visits now</StyledText>
              <StyledText
                light
                style={{ padding: "0px 32px", marginBottom: 32 }}>
                Provide care to your patients in no time with our simple and
                secure televisit app.
              </StyledText>
              <FlexBox justify='center'>
                <Button
                  appearance='primary'
                  onClick={this.launchInNote}
                  disabled={launchingInNote}
                  style={{ marginRight: 8 }}>
                  {launchingInNote ? (
                    <>
                      <Loader />
                      &nbsp;Launching
                    </>
                  ) : (
                    "Launch now"
                  )}
                </Button>
                <Button appearance='transparent' onClick={this.downloadInNote}>
                  Download for desktop
                </Button>
              </FlexBox>
            </VirtualVisitsOptions>
            <VirtualVisitLaunchImage
              src={`${process.env.PUBLIC_URL}/img/virtualVisit.png`}
            />
          </VirtualVisitCard>
          <ActionsWrapper>
            {hasIncompleteTasks && (
              <WelcomeTasks
                onComplete={() => this.setState({ hasIncompleteTasks: false })}
              />
            )}
            <PlatformShortcuts />
            {!hasIncompleteTasks && (
              <>
                <VirtualVisitsCard />
                <AssessmentsCompletedCard />
              </>
            )}
          </ActionsWrapper>
        </BodyWrapper>
      </Wrapper>
    );
  }
}

export default Home;
