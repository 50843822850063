/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Icon, Text, Badge, Button } from "@innovaccer/design-system";
import { replaceReservedTags } from "../ContentEditable";
import "./style.css";

class OutreachReview extends Component {
  renderHeader() {
    const { toggleReview, config, outreachType, sending } = this.props;

    return (
      <div className='d-flex flex-row justify-content-between mb-6'>
        <div className='d-flex flex-row'>
          <Icon
            appearance='success'
            name='check_circle'
            className='review-check-icon review-line-height mr-4'
          />
          <div className='d-flex flex-column'>
            <Text
              appearance='default'
              weight='strong'
              className='mb-3 review-config-name review-line-height'>
              {config && config.name}
            </Text>
            <div className='d-flex flex-row'>
              {outreachType === 1 ? (
                <Text className='mr-3'>
                  {[
                    ...(config.send_sms ? ["Text"] : []),
                    ...(config.send_email ? ["E-mail"] : []),
                  ].join(" + ")}
                </Text>
              ) : (
                <div className='d-flex flex-row justify-content-between'>
                  <Text className='review-line-height'>{`${config.category.label} Assessment`}</Text>
                  <div className='review-dot' />
                  <Text className='mr-3'>
                    {[
                      ...(config.send_sms ? ["Text"] : []),
                      ...(config.send_email ? ["E-mail"] : []),
                    ].join(" + ")}
                  </Text>
                </div>
              )}
              <Badge className='review-lock-badge'>
                <Icon
                  appearance='success'
                  className='pr-2 review-lock-icon'
                  name='lock'
                />
                <span style={{ color: "#227934", fontWeight: "bold" }}>
                  SECURED
                </span>
              </Badge>
            </div>
          </div>
        </div>
        <Button disabled={sending} onClick={() => toggleReview()}>
          Edit
        </Button>
      </div>
    );
  }

  renderBody() {
    const { html } = this.props;
    return (
      <div
        className='review-message-content-wrapper'
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: replaceReservedTags(html),
        }}
      />
    );
  }

  render() {
    const { sending, resetSendToOutreach, sendToOutreach } = this.props;
    return (
      <div className='d-flex flex-column align-content-between flex-grow-1 h-100 pr-4 review-overflow-y'>
        <div className='d-flex flex-column review-flex-grow-3'>
          {this.renderHeader()}
          {this.renderBody()}
        </div>
        <div className='d-flex flex-row justify-content-end align-items-end flex-grow-1 mt-6 '>
          <Button
            disabled={sending}
            onClick={() => resetSendToOutreach()}
            className='mr-5'>
            Close
          </Button>
          <Button
            disabled={sending}
            appearance='success'
            onClick={sendToOutreach}>
            Send Outreach
          </Button>
        </div>
      </div>
    );
  }
}

export default OutreachReview;
