import React from "react";
import Spinner from "@datashop/spinner";

export default props => {
  return (
    <div
      {...props}
      style={{
        display: "flex",
        height: "100vh",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <Spinner size={48}></Spinner>
    </div>
  );
};
