import React from "react";
import Network from "./network";
import authService from "services/authService";
import IndividualPractice from "./individualPractice";
import { orgTypes } from "config";

export default props => {
  return authService.getUserKey("organization_category") === orgTypes.large ? (
    <Network {...props} />
  ) : (
    <IndividualPractice {...props} />
  );
};
