import React from "react";
import { Dropdown, Button, Avatar } from "@innovaccer/design-system";

export const typeMap = {
  ASSESSMENT: {
    label: "Assessment",
    value: "ASSESSMENT"
  },
  MESSAGE: {
    label: "Message",
    value: "MESSAGE"
  }
};

export const statusMap = {
  COMPLETED: {
    label: "Sent",
    value: "COMPLETED",
    appearance: "success"
  },
  FAILED: {
    label: "Failed",
    value: "FAILED",
    appearance: "alert"
  },
  IN_PROGRESS: {
    label: "In Progress",
    value: "IN_PROGRESS",
    appearance: "success"
  }
};

export const optionsRenderer = ({ loading, props = {} }) => {
  let name = props.data && props.data.split(" ");
  if (loading) {
    return null;
  }
  return (
    <div className='d-flex justify-content-end w-100'>
      <Avatar
        firstName={name[0] || null}
        lastName={name[1] || null}
        size='regular'
        tooltipPosition='bottom'
        withTooltip
      />
      <Dropdown
        menu
        width={160}
        options={props.options.map(({ label }, i) => ({
          label,
          value: i
        }))}
        align='left'
        onChange={index => {
          props.options[index].onClick();
        }}
        customTrigger={() => (
          <Button
            icon='more_horiz'
            iconAlign='left'
            appearance='transparent'
            size='regular'></Button>
        )}
      />
    </div>
  );
};
