import React, { PureComponent, Fragment } from "react";
import get from "lodash/get";
import {
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  ComposedChart,
  LabelList,
  Label,
  Line,
  Tooltip
} from "recharts";

import axios from "helper/axios";
import {
  WidgetCard,
  WidgetLoader,
  WidgetError,
  Header,
  transformFilters
} from ".";

const CustomToolTip = ({ active, payload = [], label }) => {
  if (active) {
    return (
      <div
        style={{
          boxShadow: "var(--shadow-s)",
          padding: "4px",
          borderRadius: "4px",
          background: "var(--white)"
        }}>
        <p style={{ fontWeight: 500 }}>
          {payload && get(payload[0], "payload.percentage")}
        </p>
      </div>
    );
  }

  return null;
};

export const getData = ({ filters }) => {
  return axios.get("/insights/assessment-graph-data/", {
    params: transformFilters(filters)
  });
};

export const getXLSXData = ({ filters }) => {
  return getData({ filters }).then(
    ({
      data: {
        data: { assessmentGraphData = [] }
      }
    }) => {
      const schema = [
        { displayText: "Category", key: "name" },
        { displayText: "Number of Patients", key: "value" },
        { displayText: "Percentage", key: "percentage" }
      ];
      return {
        data: assessmentGraphData,
        schema,
        name: "Assessments"
      };
    }
  );
};

class Assessments extends PureComponent {
  state = {};

  getAssessmentData = (filters = {}) => {
    this.setState(
      {
        assessmentDataLoading: true,
        assessmentDataError: false
      },
      () => {
        getData({ filters })
          .then(({ data }) => {
            let { assessmentGraphData, combinedGraphData } = data.data;
            this.setState({
              assessmentGraphData,
              assessmentDataLoading: false,
              assessmentDataError: null
            });
          })
          .catch(error => {
            this.setState({
              assessmentDataLoading: false,
              assessmentDataError: error
            });
          });
      }
    );
  };
  render() {
    const {
      assessmentGraphData,
      assessmentDataLoading,
      assessmentDataError
    } = this.state;

    return (
      <WidgetCard
        isLoading={assessmentDataLoading}
        isError={assessmentDataError}
        isCardTitle={true}>
        {assessmentDataLoading ? (
          <WidgetLoader />
        ) : assessmentDataError ? (
          <WidgetError
            onRetry={() => this.getAssessmentData(this.props.filters)}
          />
        ) : (
          <Fragment>
            <div
              style={{
                display: "flex",
                width: "100%",
                marginBottom: "16px"
              }}>
              <Header style={{ paddingBottom: "8px", fontSize: "20px" }}>
                Assessment
              </Header>
            </div>
            <ResponsiveContainer style={{ marginLeft: "4px" }}>
              <ComposedChart
                width={"100%"}
                height={"100%"}
                data={assessmentGraphData}
                margin={{ top: 20, right: 5, bottom: 5, left: 5 }}>
                <XAxis dataKey='name'>
                  <Label />
                </XAxis>
                <YAxis
                  hide={true}
                  dataKey='value'
                  axisLine={false}
                  tickLine={false}
                  // domain={[0, dataMax => dataMax * 1.1]}
                />
                <CartesianGrid stroke='#F4F4F4' />
                <Area
                  type='monotone'
                  dataKey='value'
                  fill='var(--primary-lighter)'
                />
                <Line
                  type='monotone'
                  stroke='var(--primary-lighter)'
                  dataKey='value'
                  dot={false}></Line>
                <Tooltip content={<CustomToolTip />} />
                <Bar
                  radius={3}
                  dataKey='value'
                  fill='var(--primary)'
                  barSize={80}>
                  <LabelList offset={8} dataKey='value' position='top' />
                </Bar>
              </ComposedChart>
            </ResponsiveContainer>
          </Fragment>
        )}
      </WidgetCard>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.filters != prevProps.filters) {
      this.getAssessmentData(this.props.filters);
    }
  }

  componentDidMount() {
    this.getAssessmentData(this.props.filters);
  }
}

export default Assessments;
