import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import AssessmentListing from "./List";
import AssessmentDetails from "./Details";
import { Mixpanel } from "../../helper/mixpanelHelper";

class Assessments extends Component {
  componentDidMount() {
    Mixpanel.track("Assessments Accessed", { category: "TA-Assessments" });
  }
  render() {
    const {
      match: { path }
    } = this.props;

    return (
      <Switch>
        <Route path={`${path}/:assessmentId`} component={AssessmentDetails} />
        <Route exact path={`${path}/`} component={AssessmentListing} />
      </Switch>
    );
  }
}

export default Assessments;
