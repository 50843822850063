import React from "react";
import { Avatar, Text } from "@innovaccer/design-system";
import styled from "styled-components";

const StyledText = styled(Text)`
  text-transform: "capitalise";
`;

const Name = ({ title, firstName, lastName, withAvatar }) => (
  <>
    {withAvatar && <Avatar firstName={firstName} lastName={lastName} />}&nbsp;
    <StyledText>
      {[title, firstName, lastName]
        .filter(val => !!String(val).trim())
        .join(" ")}
    </StyledText>
  </>
);

export default Name;
