import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Text,
  Card,
  Switch,
  PageHeader,
  Spinner,
  Dialog,
  Icon,
} from "@innovaccer/design-system";
import axios from "../../../helper/axios";
import "./style.css";

const AssessmentList = () => {
  const [assessmentListLoading, setAssessmentListLoading] = useState(false);
  const [assessmentListError, setAssessmentListError] = useState(false);
  const [assessmentList, setAssessmentList] = useState([]);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();

  const handleAssessmentClick = ({ _id, name }) => {
    history.push(`${match.url}/${_id}/${name}`);
  };

  const getAssessmentTypes = () => {
    setAssessmentListLoading(true);
    axios
      .get("insights/assessment/types")
      .then(({ data }) => {
        let assessmentList = data.data;
        assessmentList = assessmentList.map(item => {
          item.selected = true;
          return item;
        });
        setAssessmentList([...assessmentList]);
        setAssessmentListLoading(false);
      })
      .catch(error => {
        setAssessmentListLoading(false);
        setAssessmentListError(true);
      });
  };

  useEffect(() => {
    getAssessmentTypes();
  }, []);

  const onDisable = () => {
    setOpen(false);
  };

  const onClose = () => {
    setOpen(!open);
  };

  const onSwitchToggle = (event, selected) => {
    // Not implimented yet
    // if (!selected) {
    //   onClose();
    // } else {
    //   //Save state
    // }
  };

  const options = {
    open,
    onClose,
    dimension: "small",
    heading: "Disable assessment?",
    description:
      "Remember. Once you disable this assessment it will not appear in your assessment list. Your previously saved data for this assessment won't get affected.",
    primaryButtonLabel: "Disable",
    primaryButtonCallback: onDisable,
    secondaryButtonLabel: "Cancel",
    secondaryButtonCallback: onClose,
  };

  if (assessmentListLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center w-100 h-100'>
        <Spinner appearance='primary' size='medium' />
      </div>
    );
  }

  return (
    <div className='w-100'>
      <Dialog {...options} />
      <PageHeader title='Assessments' />
      <div className='d-flex justify-content-center'>
        {assessmentList && assessmentList.length > 0 && (
          <Card className='assessment mt-6' shadow='light'>
            {assessmentList.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className='d-flex justify-content-between p-6 assessment-cursor'
                    style={{
                      borderBottom:
                        index < assessmentList.length - 1
                          ? "1px solid #E7E4E4"
                          : "none",
                    }}>
                    <div
                      className='d-flex'
                      onClick={() => handleAssessmentClick(item)}>
                      <Text
                        appearance='default'
                        weight='strong'
                        className='font-size-16'>
                        {item.name}
                      </Text>
                      <span className='hide-span'>
                        <Icon
                          appearance='basic'
                          name='navigate_next'
                          size='regular'
                        />
                      </span>
                    </div>

                    <Switch
                      appearance='primary'
                      size='regular'
                      checked={item.selected}
                      onChange={onSwitchToggle}
                    />
                  </div>
                  {/* <Text appearance='subtle'>
                    Use it to enable or disable patient-provider chat
                  </Text> */}
                </div>
              );
            })}
          </Card>
        )}
      </div>
    </div>
  );
};

export default AssessmentList;
