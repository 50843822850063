import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StyledText } from "components/commons/atoms";
import Button from "@datashop/button";
import Icon from "@datashop/icon";
import axios from "helper/axios";
import Placeholder from "@datashop/placeholder";
import moment from "moment";
import { formatDate } from "utils";
import { CommonCard } from "./commons";
import { useHistory } from "react-router-dom";

const StatsCard = styled(CommonCard)`
  padding: 16px;
`;

const AssessmentsCompletedCard = ({ days = 7 }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [count, setCount] = useState({
    totalCompletedAssessments: 0,
    totalUniquePatients: 0
  });

  useEffect(() => {
    axios
      .get(`/insights/assessment/responses/count?days=${days}`)
      .then(({ data: { totalCompletedAssessments, totalUniquePatients } }) => {
        setLoading(false);
        setError(false);
        setCount({ totalCompletedAssessments, totalUniquePatients });
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  return (
    <StatsCard data-test='landing-screen-assessment-count'>
      <StyledText style={{ marginBottom: 12 }} size={28}>
        {loading ? (
          <Placeholder.Paragraph style={{ height: 35, width: 40 }} />
        ) : (
          count.totalCompletedAssessments
        )}
      </StyledText>
      <StyledText
        style={{ marginBottom: 2 }}
        fontWeight='bold'
        fontSize='large'>
        {loading ? (
          <Placeholder.Paragraph length='medium' />
        ) : (
          `assessments by ${count.totalUniquePatients} patients`
        )}
      </StyledText>
      <StyledText style={{ marginBottom: 16 }} light fontSize='small'>
        in last {days} Days
      </StyledText>
      <Button
        onClick={() => {
          history.push({
            pathname: "/patients",
            state: {
              filters: {
                assessmentDate: {
                  startDate: formatDate(moment().subtract(days - 1, "days")),
                  endDate: formatDate(moment())
                }
              }
            }
          });
        }}>
        See patients&nbsp;
        <Icon name='arrow_right_alt' />
      </Button>
    </StatsCard>
  );
};

export default AssessmentsCompletedCard;
