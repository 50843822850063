import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  PageHeader,
  Breadcrumbs,
  Badge,
  Navigation
} from "@innovaccer/design-system";
import "../style.css";

const OutreachDeatilHeader = ({
  name,
  sendSms,
  sendEmail,
  status,
  statusMap
}) => {
  const location = useLocation();
  const history = useHistory();
  const [active, setActive] = useState({
    name: "performance"
  });
  const onClickHandler = menu => {
    setActive(menu);
    let path = location.pathname.split("/", 3).join("/");
    history.replace(`${path}/${menu.name}`);
  };

  useEffect(() => {
    let path = location.pathname.split("/", 4);
    if (path[3] === "recipients") {
      setActive({
        name: "recipients"
      });
    }
  }, []);

  const navigationData = [
    {
      name: "performance",
      label: "Performance"
    },
    {
      name: "recipients",
      label: "Recipients"
    }
  ];

  const breadcrumbData = [
    {
      label: "Outreach",
      link: "/outreach"
    }
  ];

  return (
    <div className='w-100' style={{ background: "#f4f4f4" }}>
      <PageHeader
        title={name}
        breadcrumbs={
          <Breadcrumbs
            list={breadcrumbData}
            onClick={link => history.goBack()}
          />
        }
        navigation={
          <Navigation
            menus={navigationData}
            onClick={onClickHandler}
            active={active}
          />
        }
        badge={
          <Badge
            subtle
            style={{ textTransform: "uppercase" }}
            appearance={status && statusMap[status].appearance}>
            {status && statusMap[status].label}
          </Badge>
        }
        status={
          <Badge appearance='secondary'>
            {[
              ...(sendSms ? ["TEXT"] : []),
              ...(sendEmail ? ["EMAIL"] : [])
            ].join(` + `)}
          </Badge>
        }
      />
    </div>
  );
};

export default OutreachDeatilHeader;
