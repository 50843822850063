import React from "react";
import {
  Input,
  Dropdown,
  Button,
  Checkbox,
  PlaceholderParagraph,
  Placeholder,
  Label,
  Chip
} from "@innovaccer/design-system";

const getOptionsWithLabel = (options = [], selected = []) =>
  options.filter(o => selected.includes(o.value));

const AppliedFilters = ({ filters, filterOptions, onChange }) => {
  const { type = [], status = [], sender = [] } = filterOptions;

  const selectedTypes = filters.type
    ? getOptionsWithLabel(type, filters.type)
    : [];
  const selectedStatus = filters.status
    ? getOptionsWithLabel(status, filters.status)
    : [];
  const selectedSender = filters.sender
    ? getOptionsWithLabel(sender, filters.sender)
    : [];

  return (
    <>
      {!!selectedSender.length && (
        <Chip
          key={`sender`}
          className='mr-4 mb-6'
          clearButton
          onClose={() => {
            const { sender, ...rest } = filters;
            onChange(rest, true);
          }}
          type='selection'
          selected
          label={`${selectedSender[0].label}${
            selectedSender.length > 1
              ? ` and ${selectedSender.slice(1).length} other`
              : ""
          }`}
        />
      )}
      {selectedStatus.map((st, i) => (
        <Chip
          key={`status-${i}`}
          className='mr-4 mb-6'
          clearButton
          onClose={() => {
            const { status } = filters;
            onChange({ status: status.filter(s => s != st.value) }, true);
          }}
          type='selection'
          selected
          label={`${st.label}`}
        />
      ))}
      {selectedTypes.map((ty, i) => (
        <Chip
          key={`type-${i}`}
          className='mr-4 mb-6'
          clearButton
          onClose={() => {
            const { type } = filters;
            onChange({ type: type.filter(g => g != ty.value) }, true);
          }}
          type='selection'
          selected
          label={ty.label}
        />
      ))}
    </>
  );
};

const Header = props => {
  const {
    loading,
    error,
    data,
    schema,
    showHead,
    withPagination,
    withCheckbox,
    updateSchema,
    totalRecords = 0,
    onSelectAll,
    searchPlaceholder,
    selectAll,
    searchTerm,
    updateSearchTerm,
    allowSelectAll,
    filters = {},
    filterOptions = {},
    updateFilters
  } = props;

  const [selectAllRecords, setSelectAllRecords] = React.useState(false);
  const [flag, setFlag] = React.useState(true);

  React.useEffect(() => {
    setFlag(!flag);
  }, [schema]);

  React.useEffect(() => {
    if (selectAll && selectAll.checked) {
      if (onSelectAll) onSelectAll(true, selectAllRecords);
    }
  }, [selectAllRecords]);

  React.useEffect(() => {
    if (selectAll && !selectAll.checked) setSelectAllRecords(false);
  }, [selectAll]);

  const onSearchChange = e => {
    const value = e.target.value;
    if (updateSearchTerm) {
      updateSearchTerm(value);
    }
  };

  const selectedCount =
    data && data.length > 0 && data.filter(d => d._selected).length;
  const label =
    withCheckbox && selectedCount
      ? selectAllRecords
        ? `Selected all ${totalRecords} items`
        : `Selected ${selectedCount} items on this page`
      : `Showing ${!error ? totalRecords : 0} items`;

  return (
    <div className='Header'>
      <div className='Header-content Header-content--top'>
        <div className='Header-search'>
          <Input
            name='GridHeader-search'
            icon='search'
            placeholder={searchPlaceholder}
            onChange={onSearchChange}
            value={searchTerm}
            onClear={() => updateSearchTerm && updateSearchTerm("")}
            disabled={loading && !(schema && schema.length)}
            autocomplete='off'
          />
        </div>
        <div className='Header-dropdown'></div>
        <div className='Header-actions'></div>
      </div>
      <div className='d-flex w-100 flex-wrap'>
        <AppliedFilters
          filters={filters || {}}
          filterOptions={filterOptions || {}}
          onChange={updateFilters}
        />
      </div>
      <div className='Header-content Header-content--bottom'>
        <div className='Header-label'>
          {showHead && !loading && (
            <Checkbox
              {...selectAll}
              onChange={event => {
                if (onSelectAll) onSelectAll(event.target.checked);
              }}
            />
          )}
          {loading ? (
            <Placeholder withImage={!showHead && withCheckbox}>
              <PlaceholderParagraph length={"small"} size={"s"} />
            </Placeholder>
          ) : (
            <>
              <Label>{label}</Label>
              {withPagination && allowSelectAll && selectAll.checked && (
                <div className='ml-4'>
                  {!selectAllRecords ? (
                    <Button
                      size='tiny'
                      onClick={() => setSelectAllRecords(true)}>
                      {`Select all ${totalRecords} items`}
                    </Button>
                  ) : (
                    <Button
                      size='tiny'
                      onClick={() => setSelectAllRecords(false)}>
                      Clear Selection
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
