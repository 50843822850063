/* eslint-disable react/prop-types */
import React from "react";
import { Icon, MetaList } from "@innovaccer/design-system";
import styled from "styled-components";
import { formatPhone } from "../../utils";
import "../settings/users/style.css";

const CustomIcon = styled.div`
  background: var(--secondary-light);
  margin-right: var(--spacing-l);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
`;

const NameEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const NameWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100% !important;
  color: var(--text);
  margin: 0;
  line-height: var(--font-height);
`;

const EmailWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-subtle);
  font-size: var(--font-size-s);
  line-height: var(--font-height-normal);
  margin: 0;
  word-break: break-all;
`;

const TextWithIcon = ({ data, appearance, iconName, iconSize }) => (
  <div style={{ display: "flex" }}>
    <CustomIcon>
      <Icon appearance={appearance} name={iconName} size={iconSize} />
    </CustomIcon>
    {data && (
      <NameEmailWrapper>
        <NameWrapper>{`${data.firstName} ${data.lastName}`}</NameWrapper>
        <EmailWrapper className='user-name-meta-list'>
          {data.email}
          <MetaList
            list={[{ label: formatPhone(data.phoneNumber) }]}
            seperator
          />
        </EmailWrapper>
      </NameEmailWrapper>
    )}
  </div>
);

export default TextWithIcon;
