import React from "react";
import CustomPlaceholder from "components/commons/Placeholder";

const ErrorTemplate = ({ error, onRetry }) => (
  <CustomPlaceholder
    {...(error
      ? {
          error: true,
          iconStyle: { color: "var(--alert)" },
          title: "Something went wrong",
          subTitle:
            "It may be because of technical failure or network reasons. Please try again",
          onRetry,
        }
      : {
          title: "No records available",
        })}
  />
);

export default ErrorTemplate;
