import React, { Component } from "react";
import {
  Row,
  Column,
  PageHeader,
  Button,
  Pagination,
  Card,
  StatusHint,
  Text,
  Grid,
  Toast,
  Avatar,
  Icon,
  Placeholder,
  PlaceholderParagraph,
} from "@innovaccer/design-system";
import { Mixpanel } from "helper/mixpanelHelper";
import Header from "./header";
import { startCase } from "lodash";
import { formatDate } from "utils";
import axios from "helper/axios";
import { statusMap, typeMap } from "./outreachHelper";
import Filters from "./filter";
import CustomPlaceholder from "components/commons/Placeholder";
import SendBulkAssessment from "./SendBulkAssessment";
import "../style.css";

// Error template
const ErrorTemplate = ({ error, onRetry }) => (
  <CustomPlaceholder
    {...(error
      ? {
          error: true,
          iconStyle: { color: "var(--alert)" },
          title: "Something went wrong",
          subTitle:
            "It may be because of technical failure or network reasons. Please try again",
          onRetry,
        }
      : {
          title: `We’re off record!`,
          subTitle:
            "Because there is no record available on outreach at the moment.",
        })}
  />
);
// Get total no of pages
const getTotalPages = (totalRecords, pageSize) =>
  Math.ceil(totalRecords / pageSize);

//  Get status color
const getStatusAppearance = status => {
  const mapping = {
    COMPLETED: "success",
    FAILED: "alert",
  };

  return mapping[status] || "";
};

const StatusWithMeta = ({ children, meta, appearance }) => {
  return (
    <div>
      <StatusHint appearance={appearance}>{children}</StatusHint>
      <Text className='ml-6' appearance='subtle' small>
        {meta}
      </Text>
    </div>
  );
};

// Redirect to outreach details
let scope = null;
const RedirectToDetail = outreachId => {
  const {
    history,
    match: { url },
  } = scope.props;

  history.push(`${url}/${outreachId}`);
};
// Table column definition

const columns = {
  outreachName: {
    displayName: "Name",
    name: "Name",
    width: "40%",
    cellRenderer: ({ data }) => {
      const { outreachName, recipients, outreachId } = data;
      return (
        <>
          {outreachName ? (
            <div
              onClick={() => RedirectToDetail(outreachId)}
              className='name-container'>
              <div className='name-icon'>
                <Icon name='mic' size={20} appearance='subtle'></Icon>
              </div>
              <div>
                <div className='name'>{outreachName}</div>
                <div className='name-meta-list'>
                  {recipients && `${recipients} recepients`}
                </div>
              </div>
            </div>
          ) : (
            <Placeholder withImage={true} round={true} imageSize='medium'>
              <PlaceholderParagraph length='large' />
              <PlaceholderParagraph length='large' />
            </Placeholder>
          )}
        </>
      );
    },
  },
  status: {
    displayName: "Status",
    name: "status",
    cellType: "WITH_META_LIST",
    width: "25%",
    cellRenderer: ({ data }) => {
      const { successPercent, status } = data;
      return (
        <>
          {!status ? (
            <Placeholder withImage={true} round={true}>
              <PlaceholderParagraph />
              <PlaceholderParagraph />
            </Placeholder>
          ) : (
            <StatusWithMeta
              meta={
                successPercent ? `${parseInt(successPercent)}% open rate` : ""
              }
              appearance={getStatusAppearance(status)}>
              {status
                ? startCase(
                    (status === "COMPLETED"
                      ? "Sent"
                      : status || ""
                    ).toLowerCase()
                  )
                : ""}
            </StatusWithMeta>
          )}
        </>
      );
    },
  },
  sentOn: {
    displayName: "Date",
    name: "sentOn",
    width: "25%",
    cellRenderer: ({ data }) => {
      const { sentOn } = data;
      return (
        <>
          {!sentOn ? (
            <PlaceholderParagraph />
          ) : (
            <div>{sentOn && formatDate(sentOn, "MMM DD, YYYY")}</div>
          )}
        </>
      );
    },
  },
  sender: {
    displayName: "",
    name: "sender",
    width: "10%",
    cellRenderer: opts => {
      let name =
        opts.data && opts.data.senderName && opts.data.senderName.split(" ");
      return (
        <>
          {!name ? (
            <Placeholder withImage={true} round={true} imageSize='medium' />
          ) : (
            <div className='d-flex justify-content-end w-100'>
              <Avatar
                firstName={(name && name[0]) || null}
                lastName={(name && name[1]) || null}
                size='regular'
                tooltipPosition='bottom'
                withTooltip
              />
            </div>
          )}
        </>
      );
    },
  },
};

// Table loader schema definition

const loaderSchema = Object.values(columns).map(
  ({ cellRenderer, ...rest }) => rest
);

// Function for get table schema
const getSchema = rawSchema => {
  const schema = Object.values(columns).map(column => ({
    sorting: false,
    resizable: false,
    ...column,
  }));

  return schema;
};

const defaultFilters = {};
// Default pagination
const defaultPagination = {
  page: 1,
  size: 20,
};

// Filter options
const allStatusOptions = Object.values(statusMap);
const allTypeOptions = Object.values(typeMap);

// Outreach class definition

class Outreach extends Component {
  constructor(props) {
    super(props);
    // Class states
    this.state = {
      key: 0,
      data: [],
      schema: [],
      showUploadModal: false,
      totalCount: 0,
      sort: [{ name: "sentOn", type: "desc" }],
      search: "",
      filterOptions: {
        type: allTypeOptions,
        status: allStatusOptions,
        sender: [],
      },
      filters: {
        ...defaultFilters,
      },
      pagination: { ...defaultPagination },
      firstRender: true,
      loadingSender: false,
      isLoading: false,
      error: false,
      updating: false,
      toast: false,
    };
  }

  // Prepare url
  prepareUrl = () => {
    const {
      pagination: { page, size },
      search,
      filters: { type, status, sender },
      senderOptions,
    } = this.state;
    let url = "outreach/listing";
    if (page) {
      url = `${url}?from_page=${page}`;
    }
    if (size) {
      url = `${url}&per_page_limit=${size}`;
    }
    // filters
    if (search) {
      url = `${url}&search=${search}`;
    }
    if (type && type.length && type.length != allTypeOptions.length) {
      url = `${url}&type=${type}`;
    }
    if (status && status.length && status.length != allStatusOptions.length) {
      url = `${url}&status=${status}`;
    }
    if (sender && sender.length && sender.length != senderOptions.length) {
      url = `${url}&sender=${sender}`;
    }

    return url;
  };

  // Get outreach list
  getList = () => {
    const url = this.prepareUrl();
    this.setState(
      {
        isLoading: true,
        loadingSender: true,
      },
      () => {
        axios
          .get(url)
          .then(({ data }) => {
            const schema = this.state.schema.length
              ? this.state.schema
              : getSchema();
            this.setState({
              data: data.data,
              totalCount: data.totalCount,
              isLoading: false,
              loadingSender: false,
              firstRender: false,
              schema,
              ...(this.state.updating
                ? {}
                : {
                    updating: true,
                    filters: {
                      ...this.state.filters,
                    },
                    senderOptions: (data.senderMetadata || []).map(
                      ({ senderName, senderId }) => ({
                        label: senderName,
                        value: senderId,
                      })
                    ),
                    filterOptions: {
                      ...this.state.filterOptions,
                      sender: (data.senderMetadata || []).map(
                        ({ senderName, senderId }) => ({
                          label: senderName,
                          value: senderId,
                        })
                      ),
                    },
                  }),
            });
          })
          .catch(error => {
            console.log(error);
            this.setState({
              isLoading: false,
              error,
            });
          });
      }
    );
  };

  // Handle Filter Change
  onFilterChange = (patch = {}, replace) => {
    this.setState(
      {
        filters: replace ? { ...patch } : { ...this.state.filters, ...patch },
        pagination: { ...defaultPagination },
      },
      () => {
        this.getList();
      }
    );
  };

  // Handle Search
  onSearchChange = search => {
    this.setState(
      {
        search,
        pagination: { ...defaultPagination },
      },
      () => {
        this.getList();
      }
    );
  };

  // Handle Sorting
  onSortChange = sort => {
    this.setState(
      {
        sort,
        pagination: { ...defaultPagination },
      },
      () => {
        this.getList();
      }
    );
  };

  // Handle Page change
  onPageChange = page => {
    this.setState({ pagination: { ...this.state.pagination, page } }, () => {
      this.getList();
    });
  };

  // show toast
  showToast = props => {
    this.setState({ toast: { appearance: "success", ...props } });
    const timer = setTimeout(() => {
      this.hideToast();
      clearTimeout(timer);
    }, 7000);
  };

  // hide toast
  hideToast = () => {
    this.setState({ toast: false });
  };

  // Bulk outreach toggle function
  toggleBulkUploadModal = () => {
    this.setState({ showUploadModal: !this.state.showUploadModal });
    Mixpanel.track("New Outreach Initiated", { category: "TA-Outreach" });
  };

  componentDidMount() {
    this.getList();
  }

  // Start of JSX
  render() {
    const {
      totalCount,
      isLoading,
      sort,
      pagination,
      key,
      data,
      schema,
      error,
      search,
      filters,
      filterOptions,
      loadingSender,
      showUploadModal,
      toast,
      firstRender,
    } = this.state;
    const totalPages = getTotalPages(totalCount, pagination.size);
    scope = this;
    return (
      <>
        {showUploadModal && (
          <SendBulkAssessment
            showToast={this.showToast}
            onClose={() => this.setState({ showUploadModal: false })}
          />
        )}
        <Row className='remove-flex-grow'>
          <Column>
            <PageHeader
              title='Outreach'
              actions={
                <div className='d-flex justify-content-end'>
                  <Button
                    onClick={this.toggleBulkUploadModal}
                    appearance='primary'
                    size='regular'>
                    New outreach
                  </Button>
                </div>
              }
            />
          </Column>
        </Row>
        <Row className='p-5'>
          <Column
            sizeXS={"9"}
            sizeXL={"9"}
            sizeS={"9"}
            sizeM={"9"}
            sizeL={"9"}
            className='table-col-width'>
            <div className='Table-container'>
              <div className='table-height-with-header'>
                <Card className='Table'>
                  <div className='Table-header'>
                    <Header
                      searchPlaceholder='Search'
                      withPagination={true}
                      updateSchema={() => ""}
                      loading={isLoading}
                      error={error}
                      searchTerm={search}
                      totalRecords={totalCount}
                      schema={schema}
                      data={data}
                      updateSearchTerm={this.onSearchChange}
                      filters={filters}
                      filterOptions={filterOptions}
                      updateFilters={this.onFilterChange}
                    />
                  </div>
                  <div className='Table-grid'>
                    <Grid
                      key={key}
                      errorTemplate={props => (
                        <ErrorTemplate
                          {...props}
                          error={error}
                          onRetry={this.getList}
                        />
                      )}
                      type='data'
                      size='comfortable'
                      error={error || data.length < 1}
                      sortingList={sort}
                      updateSortingList={this.onSortChange}
                      showMenu={false}
                      pageSize={pagination.size}
                      loading={isLoading}
                      schema={schema}
                      data={data}
                      loaderSchema={loaderSchema}
                    />
                  </div>
                  <div className='Table-pagination'>
                    <Pagination
                      page={this.state.page}
                      totalPages={getTotalPages(totalCount, pagination.size)}
                      type='jump'
                      onPageChange={this.onPageChange.bind(this)}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </Column>
          <Column
            sizeXS={"3"}
            sizeXL={"3"}
            sizeS={"3"}
            sizeM={"3"}
            sizeL={"3"}
            className={"pl-6 pr-3 filter-col-width"}>
            <Filters
              isLoading={isLoading && firstRender}
              loadingSender={loadingSender}
              filters={filters}
              options={filterOptions}
              onChange={this.onFilterChange}
            />
          </Column>
        </Row>
        {toast && (
          <Toast
            title={toast.title || ""}
            message={toast.message}
            appearance={toast.appearance}
            onClose={this.hideToast}
          />
        )}
      </>
    );
  }
}

export default Outreach;
