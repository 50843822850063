import { Spinner } from "@innovaccer/design-system";
import React, { useEffect, useState } from "react";
import axios, { getErrorMessage } from "../../../helper/axios";
import DetailComponent from "./DetailComponent";
import "./style.css";

const DataSyncLandingPage = props => {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const params = new URLSearchParams(props.location.search);
  const authToken = params.get("code");

  const syncData = () => {
    setIsLoading(true);
    const postRequestBody = {
      authorization_code: authToken,
    };
    axios
      .post("/attribution/hint-practice-link/", postRequestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(res => {
        if (res.status === 200) {
          setError(false);
          setMessage(res.data.message);
        }
        // else if(res.status===400){
        //   setError(true)
        //   setMessage(res.data.error.message)
        // }
        else {
          setError(true);
        }
        setIsLoading(false);
      })
      .catch(err => {
        setError(true);
        setMessage(getErrorMessage(err));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (authToken?.length >= 0) {
      syncData();
    } else {
      setError(true);
    }
  }, []);

  const imagePath = error
    ? `${process.env.PUBLIC_URL}/img/landing-page-error.png`
    : `${process.env.PUBLIC_URL}/img/landing-page.svg`;
  return isLoading ? (
    <div className='d-flex align-items-center justify-content-center spinner-container'>
      <Spinner appearance='primary' size='medium' />
    </div>
  ) : error ? (
    <DetailComponent imagePath={imagePath} message={message} error={error} />
  ) : (
    <DetailComponent imagePath={imagePath} message={message} error={error} />
  );
};

export default DataSyncLandingPage;
