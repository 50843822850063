import React, { PureComponent } from "react";
import {
  Table,
  MetaList,
  Avatar,
  Placeholder,
  PlaceholderParagraph,
} from "@innovaccer/design-system";
import {
  getCurrentUsers,
  updateUser,
  deactivateUser,
} from "services/userService";
import ConfirmService from "services/confirmService";
import AlertService from "services/alertService";
import { getErrorMessage } from "helper/axios";
import { noop } from "lodash";
import { formatPhone } from "utils";
import CustomPlaceholder from "components/commons/Placeholder";
import {
  commonSchemaOptions,
  cellRenderers,
  adminOptions,
  defaultPagination,
} from "./templates";
import UserDetailsModal from "./userDetails";
import ErrorTemplate from "../../components/errorTemplate";
import PageLoader from "../../components/pageLoader";

const EmptyPlaceholder = () => (
  <CustomPlaceholder
    title='No active users'
    subTitle='We found no active users.'
  />
);

class CurrentUsers extends PureComponent {
  state = {
    key: 0,
    optionsData: { practices: [], roles: [] },
    loading: false,
    error: false,
    openUserDetailsModel: false,
    selectedRow: null,
  };

  updateRole = (id, payload, cb = noop) => {
    ConfirmService.confirm({
      heading: "Update Role ?",
      description:
        "You're about to update the role of this user and it's going to impact their permissions.",
      confirmationText: "Change role",
    })
      .then(modal => {
        modal.setLoading(true);
        updateUser(id, {
          ...payload,
        })
          .then(() => {
            modal.close();
            cb();
          })
          .catch(err => {
            modal.close();
            cb();
            modal.setLoading(false);
            AlertService.showAlert({
              appearance: "alert",
              message: getErrorMessage(err, "Failed to update role"),
            });
          });
      })
      .catch(modal => {
        modal.close();
      });
  };

  updatePractices = (id, practices, cb = noop) => {
    ConfirmService.confirm({
      heading: "Update Practice ?",
      description:
        "You're about to update the Practice of this user.In case, a Practice was deselected, the user will be unable to view it's data.",
      confirmationText: "Change Practice",
    })
      .then(modal => {
        modal.setLoading(true);
        updateUser(id, {
          practices,
        })
          .then(() => {
            modal.close();
            cb();
          })
          .catch(err => {
            modal.setLoading(false);
            AlertService.showAlert({
              appearance: "alert",
              message: getErrorMessage(err, "Failed to update practices"),
            });
          });
      })
      .catch(modal => {
        modal.close();
      });
  };

  updateAdminPermission = (id, admin, cb = noop) => {
    ConfirmService.confirm({
      heading: "Update Admin ?",
      description:
        "You're about to update the Access of this user. It will impact this user's permissions.",
      confirmationText: "Change Role",
    })
      .then(modal => {
        modal.setLoading(true);
        updateUser(id, {
          admin,
        })
          .then(() => {
            modal.close();
            cb();
          })
          .catch(err => {
            modal.setLoading(false);
            AlertService.showAlert({
              appearance: "alert",
              message: getErrorMessage(err, "Failed to update admin access"),
            });
          });
      })
      .catch(modal => {
        modal.close();
      });
  };

  deactivateAccount = (
    { _id, patientFirstName, patientLastName },
    cb = noop
  ) => {
    ConfirmService.confirm({
      appearance: "alert",
      heading: `Deactivate ${patientFirstName} ${patientLastName}'s account ?`,
      description:
        "You're about to deactivate a user account which will remove their access to the platform and prohibit them to login or perform any activity.",
      reverse: true,
      confirmationText: "Deactivate account",
    })
      .then(modal => {
        modal.setLoading(true);
        deactivateUser(_id)
          .then(() => {
            modal.close();
            AlertService.showAlert({
              title: "User deactivated",
            });
            cb();
          })
          .catch(err => {
            modal.setLoading(false);
            AlertService.showAlert({
              appearance: "alert",
              message: getErrorMessage(err, "Failed to update role"),
            });
          });
      })
      .catch(modal => {
        modal.close();
      });
  };

  rerenderTable = () => this.setState({ key: this.state.key + 1 });

  openUserDetailsModel = row => {
    const { openUserDetailsModel } = this.state;
    this.setState({
      openUserDetailsModel: !openUserDetailsModel,
      selectedRow: row,
    });
  };

  columns = [
    {
      cellType: "AVATAR_WITH_META_LIST",
      displayName: `Name and Email`,
      name: "name",
      cellRenderer: props => {
        const {
          patientFirstName,
          patientLastName,
          phoneNumber,
          email,
        } = props.data;
        return (
          <>
            {email ? (
              <div className='d-flex'>
                <Avatar
                  firstName={patientFirstName}
                  lastName={patientLastName}
                  size='regular'
                  className='mr-5'
                />
                <div
                  className='currrent-user-cursor'
                  onClick={() => this.openUserDetailsModel(props.data)}>
                  <div className='user-name'>{`${patientFirstName}, ${patientLastName}`}</div>
                  <div className='d-flex user-name-meta-list'>
                    {email}
                    <MetaList
                      list={[{ label: formatPhone(phoneNumber) }]}
                      seperator
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Placeholder withImage={true} round={true} imageSize='medium'>
                <PlaceholderParagraph length='large' />
                <PlaceholderParagraph length='large' />
              </Placeholder>
            )}
          </>
        );
      },
      width: "33%",
    },
    {
      displayName: "Role",
      name: "role",
      width: "17%",
      cellRenderer: opts => {
        const { data, rowIndex } = opts;
        const { roles, practices } = this.state.optionsData;
        return cellRenderers.role({
          ...opts,
          props: {
            onChange: role =>
              role == "PRACTICE_MANAGER"
                ? this.updateRole(
                    data._id,
                    {
                      accessRole: role,
                      practices: practices.map(({ value }) => value),
                    },
                    this.rerenderTable
                  )
                : this.updateRole(
                    data._id,
                    { accessRole: role },
                    this.rerenderTable
                  ),
            options: (roles || []).map(role =>
              role.value === data.accessRole
                ? { ...role, selected: true }
                : role
            ),
            key: `${data._id}-${rowIndex}`,
          },
        });
      },
    },
    {
      displayName: "NPI",
      name: "npi",
      width: "15%",
    },
    {
      displayName: "Practice",
      name: "practices",
      width: "17%",
      cellRenderer: opts => {
        const { data, rowIndex } = opts;
        const { practices } = this.state.optionsData;
        const config =
          this.props.config || this.props.location.state.config || {};
        return cellRenderers.practice({
          ...opts,
          props: {
            onChange: practices =>
              this.updatePractices(data._id, practices, this.rerenderTable),
            options: practices.map(p =>
              (data.practices || []).includes(p.value)
                ? { ...p, selected: true }
                : p
            ),
            key: `${data._id}-${rowIndex}`,
            disabled:
              !config.practicesEditable ||
              data.accessRole == "PRACTICE_MANAGER",
          },
        });
      },
    },
    {
      displayName: "Admin",
      name: "isAdmin",
      width: "5%",
      cellRenderer: opts => {
        const { data } = opts;
        const config =
          this.props.config || this.props.location.state.config || {};
        return cellRenderers.admin({
          ...opts,
          props: {
            onChange: admin =>
              this.updateAdminPermission(data._id, admin, this.rerenderTable),
            options: adminOptions.map(opt =>
              opt.value == data.isAdmin ? { ...opt, selected: true } : opt
            ),
            disabled: !config.adminEditable,
          },
        });
      },
    },
    {
      displayName: "",
      name: "options",
      width: "5%",
      align: "right",
      cellRenderer: opts =>
        cellRenderers.options({
          ...opts,
          props: {
            options: [
              {
                label: "Deactivate Account",
                onClick: () =>
                  this.deactivateAccount(opts.data, this.rerenderTable),
              },
            ],
          },
        }),
    },
  ];

  schema = this.columns.map(column => ({
    ...commonSchemaOptions,
    ...column,
  }));

  loaderSchema = this.columns.map(({ cellRenderer, ...rest }) => rest);

  getAPIParams = ({ filterList, page, pageSize, searchTerm, sortingList }) => ({
    status: "active",
    from_page: page,
    per_page_limit: pageSize,
    search_text: searchTerm,
  });

  getUsers = filters =>
    getCurrentUsers(this.getAPIParams(filters)).then(
      ({ data: { data, totalPatients, metadata } }) => {
        this.setState({
          optionsData: metadata,
        });
        return {
          count: totalPatients,
          data,
          schema: this.schema,
        };
      }
    );

  render() {
    const { key, openUserDetailsModel, selectedRow, optionsData } = this.state;
    const config = this.props.config;
    const isConfigLoading = this.props.isConfigLoading;
    return (
      <>
        {selectedRow && (
          <UserDetailsModal
            data={selectedRow}
            roleOptions={optionsData.roles}
            practicesOptions={optionsData.practices}
            open={openUserDetailsModel}
            onClickHandler={this.openUserDetailsModel}
            renderTable={this.rerenderTable}
            config={config}
          />
        )}
        {config &&
        Object.keys(config).length !== 0 &&
        config.constructor === Object ? (
          <Table
            errorTemplate={EmptyPlaceholder}
            key={key}
            async
            withHeader
            showMenu={false}
            headerOptions={{ withSearch: true, dynamicColumn: false }}
            fetchData={this.getUsers}
            loaderSchema={this.loaderSchema}
            withPagination
            pageSize={defaultPagination.size}
          />
        ) : isConfigLoading ? (
          <PageLoader />
        ) : !config || Object.keys(config).length === 0 ? (
          <ErrorTemplate error={true} />
        ) : null}
      </>
    );
  }
}

export default CurrentUsers;
