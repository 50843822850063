import React, { PureComponent, Fragment } from "react";
import { Cell, ResponsiveContainer, PieChart, Pie } from "recharts";
import Text from "@datashop/text";

import axios from "helper/axios";
import {
  DivWrapper,
  WidgetCard,
  WidgetLoader,
  WidgetError,
  LegendLabel,
  Header,
  transformFilters,
  Legend
} from ".";

export const getData = ({ filters }) => {
  return axios.get("/insights/graph/risk-distribution", {
    params: transformFilters(filters)
  });
};

export const getXLSXData = ({ filters }) => {
  return getData({ filters }).then(
    ({
      data: {
        data: { riskDistributionData = [] }
      }
    }) => {
      const schema = [
        { displayText: "Risk Level", key: "name" },
        { displayText: "Percentage", key: "labelText" }
      ];
      return {
        data: [
          ...riskDistributionData,
          {
            name: "Total",
            labelText: `100% (${riskDistributionData.reduce(
              (total, { value }) => total + value,
              0
            )})`
          }
        ],
        schema,
        name: "Risk Distribution"
      };
    }
  );
};

const colorMap = {
  HIGH: "var(--alert)",
  MEDIUM: "var(--warning)",
  LOW: "var(--success)"
};

class RiskDonut extends PureComponent {
  static getData = getData;

  state = { riskData: [], riskDataLoading: false, riskDataError: false };

  getRiskData = (filters = {}) => {
    this.setState(
      {
        riskDataLoading: true,
        riskDataError: false
      },
      () => {
        getData({ filters })
          .then(data => {
            let result = data.data && data.data.data;
            let riskData = result.riskDistributionData;
            let uniquePatientCount = result.uniquePatientCount;
            this.setState({
              riskDataLoading: false,
              riskData,
              uniquePatientCount
            });
          })
          .catch(err => {
            this.setState({
              riskDataLoading: false,
              riskDataError: true
            });
          });
      }
    );
  };

  render() {
    const {
      riskDataLoading,
      riskDataError,
      riskData,
      uniquePatientCount
    } = this.state;

    return (
      <WidgetCard>
        {riskDataLoading ? (
          <WidgetLoader />
        ) : riskDataError ? (
          <WidgetError onRetry={() => this.getRiskData(this.props.filters)} />
        ) : (
          <Fragment>
            <div
              style={{
                display: "flex",
                width: "100%",
                marginBottom: "2px"
              }}>
              <Header style={{ paddingBottom: "8px", fontSize: "20px" }}>
                Risk Distribution
              </Header>
            </div>
            <DivWrapper style={{ width: "100%", height: "100%" }}>
              <ResponsiveContainer>
                <PieChart margin={{ top: 20, bottom: 20 }}>
                  <text
                    style={{ fontSize: "32px" }}
                    x={"50%"}
                    y={"45%"}
                    textAnchor='middle'
                    dominantBaseline='middle'>
                    {uniquePatientCount}
                  </text>
                  <text
                    style={{ fontSize: "12px" }}
                    fill='var(--text)'
                    x={"50%"}
                    y={"54%"}
                    textAnchor='middle'
                    dominantBaseline='middle'>
                    {uniquePatientCount === 1
                      ? `Unique Patient`
                      : `Unique Patients`}
                  </text>
                  <text
                    style={{ fontSize: "12px" }}
                    fill='var(--text)'
                    x={"50%"}
                    y={"59%"}
                    textAnchor='middle'
                    dominantBaseline='middle'>
                    Assessed
                  </text>
                  <Pie
                    data={riskData}
                    innerRadius={80}
                    outerRadius={120}
                    dataKey='value'
                    fill='#868686'
                    label={({
                      cx,
                      cy,
                      midAngle,
                      innerRadius,
                      outerRadius,
                      value,
                      index
                    }) => {
                      const RADIAN = Math.PI / 180;
                      const radius =
                        22 + innerRadius + (outerRadius - innerRadius);
                      const x = cx + radius * Math.cos(-midAngle * RADIAN);
                      const y = cy + radius * Math.sin(-midAngle * RADIAN);

                      return (
                        <text
                          style={{ fontSize: "10px" }}
                          x={x}
                          y={y}
                          fill='#868686'
                          textAnchor={x > cx ? "start" : "end"}
                          dominantBaseline='central'>
                          {this.state.riskData[index].labelText}
                        </text>
                      );
                    }}>
                    {riskData &&
                      riskData.length > 0 &&
                      riskData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={colorMap[entry.name]}
                        />
                      ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </DivWrapper>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Legend color={colorMap.HIGH} title='High' />
              <Legend color={colorMap.MEDIUM} title='Medium' />
              <Legend color={colorMap.LOW} title='Low' />
            </div>
          </Fragment>
        )}
      </WidgetCard>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.filters != prevProps.filters) {
      this.getRiskData(this.props.filters);
    }
  }

  componentDidMount() {
    this.getRiskData(this.props.filters);
  }
}

export default RiskDonut;
