function JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel = true) {
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  const { data = [], schema } = JSONData;
  var arrData = typeof JSONData != "object" ? JSON.parse(data) || [] : data;
  //This condition will generate the Label/Header
  const columnNames = schema
    ? schema.map(column => column.displayText)
    : Object.keys(arrData[0] || {});
  const columnKeys = schema ? schema.map(column => column.key) : columnNames;

  var CSV = "";
  if (ShowLabel) {
    var row = columnNames.join(",");

    CSV += row + "\r\n";
  }

  //1st loop is to extract each row
  for (var i = 0; i < arrData.length; i++) {
    var row = "";

    //2nd loop will extract each column and convert it in string comma-seprated
    for (var index of columnKeys) {
      row += '"' + arrData[i][index] + '",';
    }

    row.slice(0, row.length - 1);

    //add a line break after each row
    CSV += row + "\r\n";
  }

  if (CSV == "") {
    alert("Invalid data");
    return;
  }

  //Generate a file name
  var fileName = "MyReport_";
  //this will remove the blank-spaces from the title and replace it with an underscore
  fileName += ReportTitle.replace(/ /g, "_");

  //Initialize file format you want csv or xls
  var uri = "data:text/csv;charset=utf-8," + escape(CSV);

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  //this trick will generate a temp <a /> tag
  var link = document.createElement("a");
  link.href = uri;

  //set the visibility hidden so it will not effect on your web-layout
  link.style = "visibility:hidden";
  link.download = fileName + ".csv";

  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const saveAs = (blob, name) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, name);
  }
  const blobUrl = URL.createObjectURL(blob);
  openUrl(blobUrl, { download: name });
};

export const openUrl = (url, props = {}) => {
  const link = document.createElement("a");
  link.href = url;
  Object.assign(link, props);
  link.click();
  link.remove();
};

export const exportJson2Excel = (function() {
  const datenum = date => {
    return (+date - +new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
  };

  const sheetFromDataArray = data => {
    const ws = {};
    const range = {
      s: {
        c: 10000000,
        r: 10000000
      },
      e: {
        c: 0,
        r: 0
      }
    };
    for (let R = 0; R !== data.length; ++R) {
      for (let C = 0; C !== data[R].length; ++C) {
        if (range.s.r > R) range.s.r = R;
        if (range.s.c > C) range.s.c = C;
        if (range.e.r < R) range.e.r = R;
        if (range.e.c < C) range.e.c = C;
        const cell = {
          v: data[R][C],
          t: "",
          z: ""
        };
        if (cell.v == null) continue;
        const cellRef = window.XLSX.utils.encode_cell({
          c: C,
          r: R
        });
        if (typeof cell.v === "number") cell.t = "n";
        else if (typeof cell.v === "boolean") cell.t = "b";
        else if (cell.v instanceof Date) {
          cell.t = "n";
          cell.z = window.XLSX.SSF.get_table()[14];
          cell.v = datenum(cell.v);
        } else cell.t = "s";
        ws[cellRef] = cell;
      }
    }
    if (range.s.c < 10000000) {
      ws["!ref"] = window.XLSX.utils.encode_range(range);
    }
    return ws;
  };

  const s2ab = s => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const formatData = (data = [], header = [], emptyValues = []) =>
    data.map(row => header.map((key, index) => row[key] || emptyValues[index]));

  return (tableDataList, filename = "Innovaccer", bookType = "xlsx") => {
    const wb = new window.XLSX.utils.book_new();

    tableDataList.forEach(
      ({ data = [], schema, name, autoWidth = true }, index) => {
        const columnKeys = schema
          ? schema.map(column => column.key)
          : Object.keys(data[0] || {});
        const columnNames = schema
          ? schema.map(column => column.displayText)
          : columnKeys;
        const emptyValues = schema
          ? schema.map(column =>
              Object.prototype.hasOwnProperty.call(column, "emptyValue")
                ? column.emptyValue
                : ""
            )
          : Array(columnKeys.length).fill("");

        const sheetData = [
          columnNames,
          ...formatData(data, columnKeys, emptyValues)
        ];
        const sheetName = name || `Sheet ${index}`;

        const ws = sheetFromDataArray(sheetData);

        if (autoWidth) {
          // 设置worksheet每列的最大宽度
          const colWidth = sheetData.map(row =>
            row.map(val => {
              // 先判断是否为 null/undefined
              if (val == null) {
                return {
                  wch: 10
                };
                // 再判断是否为中文
              } else if (val.toString().charCodeAt(0) > 255) {
                return {
                  wch: val.toString().length * 2
                };
              } else {
                return {
                  wch: val.toString().length
                };
              }
            })
          );
          // 以第一行为初始值
          const result = colWidth[0];
          for (let i = 1; i < colWidth.length; i++) {
            for (let j = 0; j < colWidth[i].length; j++) {
              if (result[j].wch < colWidth[i][j].wch) {
                result[j].wch = colWidth[i][j].wch;
              }
            }
          }
          ws["!cols"] = result;
        }

        wb.SheetNames.push(sheetName);
        wb.Sheets[sheetName] = ws;
      }
    );

    const wbout = window.XLSX.write(wb, {
      bookType: bookType,
      bookSST: false,
      type: "binary"
    });

    saveAs(
      new Blob([s2ab(wbout)], {
        type: "application/octet-stream"
      }),
      `${filename}.${bookType}`
    );
  };
})();
