import React, { useState, useEffect } from "react";
import { Dropdown, Popover, Button, Label } from "@innovaccer/design-system";
import { noop } from "lodash";
import { searchEnabledFilterThreshold } from "config";
import RangePickerComponent, { getLabel } from "components/commons/RangePicker";
import { startofTime } from "config";
import "./style.css";

const getSelected = (options = [], selected = []) =>
  options.filter(o => selected.includes(o.value));

const getUpdated = (action, options = [], selected = [], changed) => {
  if (action == "select-all") {
    return options.map(o => o.value);
  }
  if (action == "deselect-all") {
    return [];
  }
  if (action == "deselect-option") {
    const selectedArray = selected.slice();
    const index = selectedArray.findIndex(item => item == changed.value);
    selectedArray.splice(index, 1);
    return selectedArray;
  }

  return Array.from(new Set([...selected, changed.value]));
};

const commonTriggerOptions = {
  customLabel: selected => `${selected} selected`,
};

const Filters = ({ filters = {}, options = {}, onChange = noop }) => {
  const { startDate, endDate } = filters;
  const {
    providers = [],
    ageGroup = [],
    callType = [],
    paymentStatus = [],
    visitStatus = [],
  } = options;

  const [datePickerOpen, toggleDatePicker] = useState(false);

  const dateApplied = startDate || endDate;

  return (
    <>
      <Label withInput className='filter-label'>
        Date
      </Label>
      <Popover
        position='bottom-start'
        open={datePickerOpen}
        triggerClass='flex-grow-0'
        onToggle={toggleDatePicker}
        trigger={
          <Button
            className={`d-flex justify-content-between pl-5 pr-4${
              dateApplied ? "" : " color-text-light"
            }`}
            expanded
            icon='expand_more'
            iconAlign='right'>
            <span className='DropdownButton-wrapper'>
              {/* <span className='DropdownButton-label'>Date</span> */}
              <span className='DropdownButton-text'>{`${
                dateApplied ? getLabel(startDate, endDate) : "Select"
              }`}</span>
            </span>
          </Button>
        }>
        <RangePickerComponent
          startDate={startDate || startofTime}
          endDate={endDate}
          showCustomPeriods={true}
          outputFormat='MM-DD-YYYY'
          onApplyDate={(startDate, endDate) => {
            toggleDatePicker(false);
            onChange({
              startDate,
              endDate,
            });
          }}
        />
      </Popover>
      <Label withInput className='filter-label'>
        Provider
      </Label>
      <Dropdown
        triggerOptions={commonTriggerOptions}
        withCheckbox={true}
        withSearch={providers.length > searchEnabledFilterThreshold}
        options={providers}
        selected={getSelected(providers, filters.providers)}
        onUpdate={(action, option) => {
          onChange({
            providers: getUpdated(action, providers, filters.providers, option),
          });
        }}
      />
      <Label withInput className='filter-label'>
        Age group
      </Label>
      <Dropdown
        triggerOptions={commonTriggerOptions}
        withCheckbox={true}
        withSearch={ageGroup.length > searchEnabledFilterThreshold}
        options={ageGroup}
        selected={getSelected(ageGroup, filters.ageGroup)}
        onUpdate={(action, option) => {
          onChange({
            ageGroup: getUpdated(action, ageGroup, filters.ageGroup, option),
          });
        }}
      />
      <Label withInput className='filter-label'>
        Payment status
      </Label>
      <Dropdown
        triggerOptions={commonTriggerOptions}
        withCheckbox={true}
        withSearch={paymentStatus.length > searchEnabledFilterThreshold}
        options={paymentStatus}
        selected={getSelected(paymentStatus, filters.paymentStatus)}
        onUpdate={(action, option) => {
          onChange({
            paymentStatus: getUpdated(
              action,
              paymentStatus,
              filters.paymentStatus,
              option
            ),
          });
        }}
      />
      <Label withInput className='filter-label'>
        Visit Status
      </Label>
      <Dropdown
        triggerOptions={commonTriggerOptions}
        withCheckbox={true}
        withSearch={visitStatus.length > searchEnabledFilterThreshold}
        options={visitStatus}
        selected={getSelected(visitStatus, filters.visitStatus)}
        onUpdate={(action, option) => {
          onChange({
            visitStatus: getUpdated(
              action,
              visitStatus,
              filters.visitStatus,
              option
            ),
          });
        }}
      />
      <Label withInput className='filter-label'>
        Visit mode
      </Label>
      <Dropdown
        triggerOptions={commonTriggerOptions}
        withCheckbox={true}
        withSearch={callType.length > searchEnabledFilterThreshold}
        options={callType}
        selected={getSelected(callType, filters.callType)}
        onUpdate={(action, option) => {
          onChange({
            callType: getUpdated(action, callType, filters.callType, option),
          });
        }}
      />
    </>
  );
};

export default Filters;
