import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Text,
  Card,
  PageHeader,
  Dialog,
  Breadcrumbs,
  Button,
  PlaceholderParagraph,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "@innovaccer/design-system";
import axios from "../../../../helper/axios";
import { copyTextToClipboard } from "../../../../utils";
import AlertService, {
  defaultDissmissDelay,
} from "../../../../services/alertService";
import ContentEditable from "../../../../components/commons/ContentEditable";
import "../style.css";

const baseUrl = `/organizations/assessment-configurations/`;
const defaulGetErrorMsg = "Config not found";

const CustomizedOutreachMessage = () => {
  const [riskMsg, setRiskMsg] = useState("");
  const [riskMsgLoading, setRiskMsgLoading] = useState(false);
  const [previousRiskMsg, setPreviousRiskMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [linkHref, setLinkHref] = useState(null);
  const [linkName, setLinkName] = useState(null);
  const [edit, setEdit] = useState(false);

  const history = useHistory();
  const match = useRouteMatch();

  const { assessmentId, name, riskType } = match.params;

  const getRiskMsg = () => {
    if (riskType && assessmentId) {
      setRiskMsgLoading(true);
      axios
        .get(`${baseUrl}${assessmentId}/educationalMsg/${riskType}`)
        .then(
          ({
            data: {
              config: { value },
            },
          }) => {
            setRiskMsg(value || "");
            setRiskMsgLoading(false);
            setPreviousRiskMsg(value);
          }
        )
        .catch(errorData => {
          AlertService.showAlert({
            title: defaulGetErrorMsg,
            autoClearAfter: defaultDissmissDelay,
            appearance: "alert",
          });
          setRiskMsgLoading(false);
        });
    }
  };

  const onRiskMsgChange = html => {
    setRiskMsg(html);
  };

  const onSaveChanges = () => {
    setRiskMsgLoading(true);
    axios
      .patch(`${baseUrl}${assessmentId}/educationalMsg/${riskType}`, {
        value: riskMsg,
      })
      .then(({ data: { value } }) => {
        setRiskMsgLoading(false);
        setRiskMsg(value);
        setPreviousRiskMsg(value);
        AlertService.showAlert({
          title: "Changes saved",
          autoClearAfter: defaultDissmissDelay,
          appearance: "success",
        });
      })
      .catch(error => {
        setRiskMsgLoading(false);
        AlertService.showAlert({
          title: defaulGetErrorMsg,
          autoClearAfter: defaultDissmissDelay,
          appearance: "alert",
        });
      });
  };

  const onCancel = () => {
    if (previousRiskMsg === riskMsg) {
      setEdit(false);
      //history.push(`/settings/assessments/${assessmentId}/${name}`);
    } else {
      setLink(`/settings/assessments/${assessmentId}/${name}`);
      onClose();
    }
  };

  const isActionBtnDisabled = () => {
    let isRiskMsgChanged =
      riskMsg && previousRiskMsg && previousRiskMsg === riskMsg ? true : false;
    return riskMsgLoading || isRiskMsgChanged;
  };

  useEffect(() => {
    getRiskMsg();
  }, []);

  const breadcrumbData = [
    {
      label: "Assessment",
      link: `/settings/assessments`,
    },
    {
      label: name,
      link: `/settings/assessments/${assessmentId}/${name}`,
    },
  ];

  const options = {
    breadcrumbs: (
      <Breadcrumbs
        list={breadcrumbData}
        onClick={link => onBreadcrumbClick(link)}
      />
    ),
  };

  const onBreadcrumbClick = link => {
    if (previousRiskMsg === riskMsg) {
      history.push(link);
    } else {
      setLink(link);
      onClose();
    }
  };
  const onClose = () => {
    setOpen(!open);
  };

  const onStayBack = () => {
    onClose();
  };

  const onDiscard = () => {
    onClose();
    history.push(link);
  };

  const dialogOptions = {
    open,
    onClose,
    dimension: "small",
    heading: "Unsaved changes",
    description:
      "You have some unsaved changes on this page. Do you want to discard these changes and leave or stay on this page?",
    primaryButtonLabel: "Stay back",
    primaryButtonCallback: onStayBack,
    secondaryButtonLabel: "Discard Changes",
    secondaryButtonCallback: onDiscard,
  };

  const onInsertLink = () => {
    setRiskMsg(
      `${riskMsg}<br /><a style="color: blue; cursor: pointer;" target="_blank" href='${linkHref}'>${linkName}</a>`
    );
    onModalClose();
  };

  const onCopy = () => {
    const pre = document.createElement("pre");
    pre.innerHTML = riskMsg;
    const success = copyTextToClipboard(pre.innerText);
    if (success) {
      AlertService.showAlert({
        title: "Text copied",
        autoClearAfter: defaultDissmissDelay,
        appearance: "success",
      });
    }
  };

  const getRiskType = riskType => {
    let type = null;
    switch (riskType) {
      case "lowRisk":
        type = "Low Risk";
        break;
      case "mediumRisk":
        type = "Medium Risk";
        break;
      case "highRisk":
        type = "High Risk";
        break;
      default:
        break;
    }
    return type;
  };

  const onModalClose = () => {
    setLinkHref(null);
    setLinkName(null);
    setModalOpen(!modalOpen);
  };

  const modalOptions = {
    open: modalOpen,
    backdropClose: onModalClose,
  };

  const modalHeaderOptions = {
    onClose: onModalClose,
    heading: "Insert link",
  };

  const onEdit = () => {
    setEdit(true);
  };

  const getLoaders = () => {
    return (
      <div className='outreach-message'>
        <PlaceholderParagraph length='large' />
        <PlaceholderParagraph length='large' />
        <PlaceholderParagraph length='large' />
        <PlaceholderParagraph length='large' />
      </div>
    );
  };

  return (
    <div className='w-100 assessment-detail'>
      <Modal dimension='small' {...modalOptions}>
        <ModalHeader {...modalHeaderOptions} />
        <ModalBody>
          <Text weight='strong'>To what URL should this link go?</Text>
          <Input
            icon='lock'
            name='input'
            value={linkHref}
            placeholder='https://'
            onChange={evt => setLinkHref(evt.target.value)}
          />
          <div className='mt-8'>
            <Text weight='strong'>Text to display</Text>
            <Input
              name='input'
              value={linkName}
              onChange={evt => setLinkName(evt.target.value)}
            />
            <Text appearance='subtle'>
              Recipients will click on this text to open the linked URL
            </Text>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button appearance='basic' onClick={onModalClose}>
            Cancel
          </Button>
          <Button appearance='primary' onClick={onInsertLink}>
            Insert
          </Button>
        </ModalFooter>
      </Modal>
      <Dialog {...dialogOptions} />
      <PageHeader title={"Customized outreach"} {...options} />
      <div className='d-flex justify-content-center'>
        <Card className='assessment p-6 mb-6 mt-6' shadow='light'>
          <div className='pb-3'>
            <Text appearance='default' weight='strong' className='font-size-16'>
              {getRiskType(riskType)}
            </Text>
          </div>
          <div className='d-flex justify-content-between pt-8'>
            <Text appearance='default' weight='strong'>
              Message
            </Text>
            {!edit ? (
              <div className='outreach-message-edit'>
                {riskMsgLoading ? (
                  getLoaders()
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: riskMsg }}></div>
                )}
                <Button
                  appearance='primary'
                  size='regular'
                  onClick={onEdit}
                  className='mt-8 edit-button'>
                  Edit
                </Button>
              </div>
            ) : (
              <div className='outreach-message-edit'>
                {riskMsgLoading ? (
                  getLoaders()
                ) : (
                  <ContentEditable
                    message={riskMsg}
                    updateMessage={onRiskMsgChange}
                  />
                )}
                <div className='d-flex pt-4'>
                  <Button
                    appearance='basic'
                    size='tiny'
                    className='mr-4'
                    icon='link'
                    iconAlign='left'
                    onClick={onModalClose}>
                    Insert link
                  </Button>
                  <Button
                    appearance='basic'
                    size='tiny'
                    icon='file_copy'
                    iconAlign='left'
                    onClick={onCopy}>
                    Copy this text
                  </Button>
                </div>
                <div className='d-flex justify-content-end pt-8'>
                  <Button
                    appearance='basic'
                    size='regular'
                    className='mr-4'
                    onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button
                    appearance='primary'
                    size='regular'
                    onClick={onSaveChanges}
                    disabled={isActionBtnDisabled()}>
                    Save Changes
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CustomizedOutreachMessage;
