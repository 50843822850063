import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Text,
  Card,
  PageHeader,
  Dialog,
  Breadcrumbs,
  Textarea,
  Button,
  PlaceholderParagraph,
} from "@innovaccer/design-system";
import axios from "../../../../helper/axios";
import AlertService, {
  defaultDissmissDelay,
} from "../../../../services/alertService";
import "../style.css";

const baseUrl = `/organizations/assessment-configurations/`;
const defaulGetErrorMsg = "Config not found";

const OutreachMessage = () => {
  const [outreachMsg, setOutreachMsg] = useState("");
  const [outreachMsgLoading, setOutreachMsgLoading] = useState(false);
  const [previousOutReachMsg, setPreviousOutReachMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState(null);

  const history = useHistory();
  const match = useRouteMatch();

  const { assessmentId, name } = match.params;

  const getOutreachMsg = () => {
    setOutreachMsgLoading(true);
    axios
      .get(`${baseUrl}${assessmentId}/outReach/smsMssg`)
      .then(
        ({
          data: {
            config: { value },
          },
        }) => {
          setOutreachMsg(value || "");
          setOutreachMsgLoading(false);
          setPreviousOutReachMsg(value);
        }
      )
      .catch(errorData => {
        AlertService.showAlert({
          title: defaulGetErrorMsg,
          autoClearAfter: defaultDissmissDelay,
          appearance: "alert",
        });
        setOutreachMsgLoading(false);
      });
  };

  const onOutreachMsgChange = event => {
    const {
      target: { value },
    } = event;
    setOutreachMsg(value);
  };

  const onSaveChanges = () => {
    setOutreachMsgLoading(true);
    axios
      .patch(`${baseUrl}${assessmentId}/outReach/smsMssg`, {
        value: outreachMsg,
      })
      .then(({ data: { value } }) => {
        setOutreachMsgLoading(false);
        setOutreachMsg(value);
        setPreviousOutReachMsg(value);
        AlertService.showAlert({
          title: "Changes saved",
          autoClearAfter: defaultDissmissDelay,
          appearance: "success",
        });
      })
      .catch(error => {
        AlertService.showAlert({
          title: defaulGetErrorMsg,
          autoClearAfter: defaultDissmissDelay,
          appearance: "alert",
        });
        setOutreachMsgLoading(false);
      });
  };

  const onCancel = () => {
    if (previousOutReachMsg === outreachMsg) {
      history.push(`/settings/assessments/${assessmentId}/${name}`);
    } else {
      setLink(`/settings/assessments/${assessmentId}/${name}`);
      onClose();
    }
  };

  const isActionBtnDisabled = () => {
    let isOutreachMsgChanged =
      outreachMsg && previousOutReachMsg && previousOutReachMsg === outreachMsg
        ? true
        : false;
    return outreachMsgLoading || isOutreachMsgChanged;
  };

  useEffect(() => {
    getOutreachMsg();
  }, []);

  const breadcrumbData = [
    {
      label: "Assessment",
      link: `/settings/assessments`,
    },
    {
      label: name,
      link: `/settings/assessments/${assessmentId}/${name}`,
    },
  ];

  const options = {
    breadcrumbs: (
      <Breadcrumbs
        list={breadcrumbData}
        onClick={link => onBreadcrumbClick(link)}
      />
    ),
  };

  const onBreadcrumbClick = link => {
    if (previousOutReachMsg === outreachMsg) {
      history.push(link);
    } else {
      setLink(link);
      onClose();
    }
  };
  const onClose = () => {
    setOpen(!open);
  };

  const onStayBack = () => {
    onClose();
  };

  const onDiscard = () => {
    onClose();
    history.push(link);
  };

  const dialogOptions = {
    open,
    onClose,
    dimension: "small",
    heading: "Unsaved changes",
    description:
      "You have some unsaved changes on this page. Do you want to discard these changes and leave or stay on this page?",
    primaryButtonLabel: "Stay back",
    primaryButtonCallback: onStayBack,
    secondaryButtonLabel: "Discard Changes",
    secondaryButtonCallback: onDiscard,
  };

  return (
    <div className='w-100 assessment-detail'>
      <Dialog {...dialogOptions} />
      <PageHeader title={"Outreach message"} {...options} />
      <div className='d-flex justify-content-center'>
        <Card className='assessment p-6 mb-6 mt-6' shadow='light'>
          <div className='pb-3'>
            <Text appearance='default' weight='strong' className='font-size-16'>
              Default message
            </Text>
          </div>
          <div className='d-flex justify-content-between pt-8'>
            <Text appearance='default' weight='strong'>
              Message
            </Text>
            <div className='outreach-message-edit'>
              {outreachMsgLoading ? (
                <>
                  <PlaceholderParagraph length='large' />
                  <PlaceholderParagraph length='large' />
                  <PlaceholderParagraph length='large' />
                  <PlaceholderParagraph length='large' />
                </>
              ) : (
                <Textarea
                  name='Textarea'
                  placeholder='Enter your message'
                  resize
                  rows={4}
                  value={outreachMsg || ""}
                  onChange={onOutreachMsgChange}
                />
              )}

              <div className='d-flex justify-content-end pt-6'>
                <Button
                  appearance='basic'
                  size='regular'
                  className='mr-4'
                  onClick={onCancel}
                  disabled={isActionBtnDisabled()}>
                  Cancel
                </Button>
                <Button
                  appearance='primary'
                  size='regular'
                  onClick={onSaveChanges}
                  disabled={isActionBtnDisabled()}>
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default OutreachMessage;
