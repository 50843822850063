import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import PracticeList from "./list";
import CreatePractice from "./create";
import PracticeDetails from "./details";
import PageLoader from "../../components/pageLoader";
import axios from "helper/axios";

export default ({ match }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [config, setConfig] = useState(null);

  const GetPracticeConfig = () => {
    setIsLoading(true);
    axios
      .get("organizations/configurations/practice-navigation/settings")
      .then(({ data: { config } }) => {
        setIsLoading(false);
        setConfig(config.value);
      })
      .catch(error => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    GetPracticeConfig();
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Switch>
      {config &&
        config.rightButtons &&
        config.rightButtons.findIndex(e => e.name === "addPractice") > -1 && (
          <Route
            exact
            path={`${match.path}/create`}
            component={CreatePractice}
          />
        )}
      <Route path={`${match.path}/:practiceId`} component={PracticeDetails} />
      <Route
        path={`${match.path}/`}
        render={props => <PracticeList config={config} {...props} />}
      />
    </Switch>
  );
};
