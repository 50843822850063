import React, { useState, useEffect } from "react";
import {
  WidgetLoader,
  WidgetError,
  Legend,
  Header,
  transformFilters,
  WidgetCard,
} from ".";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import axios from "helper/axios";

export const getData = ({ filters }) => {
  return axios
    .get("/insights/graph/virtual-visit-distribution", {
      params: transformFilters(filters),
    })
    .then(({ data: { data = {} } }) => {
      return {
        values: [
          {
            key: "completed",
            value: data.completed,
            percentage: data.completed_percent,
            label: "Completed",
            labelText: `${data.completed_percent}% (${data.completed})`,
          },
          {
            key: "cancelled",
            value: data.cancelled,
            percentage: data.cancelled_percent,
            label: "Cancelled",
            labelText: `${data.cancelled_percent}% (${data.cancelled})`,
          },
          {
            key: "inqueue",
            value: data.inqueue,
            percentage: data.inqueue_percent,
            label: "In-queue",
            labelText: `${data.inqueue_percent}% (${data.inqueue})`,
          },
          {
            key: "readyForDoctor",
            value: data.ready_for_doctor,
            percentage: data.ready_for_doctor_percent,
            label: "Ready_for_doctor",
            labelText: `${data.ready_for_doctor_percent}% (${data.ready_for_doctor})`,
          },
          {
            key: "incomplete",
            value: data.incomplete,
            percentage: data.incompleted_percent,
            label: "Incomplete",
            labelText: `${data.incompleted_percent}% (${data.incomplete})`,
          },
        ],
        total: data.total || 0,
      };
    });
};

export const getXLSXData = ({ filters }) => {
  return getData({ filters }).then(({ values, total }) => {
    const schema = [
      { displayText: "Visits", key: "label" },
      { displayText: "Number of visits", key: "value" },
      { displayText: "Percentage (%)", key: "percentage" },
    ];
    return {
      data: [
        ...values,
        {
          label: "Total",
          percentage: total ? 100 : "",
          value: total,
        },
      ],
      schema,
      name: "Visits Distribution (all)",
    };
  });
};

const colorMap = {
  completed: "#71C077",
  cancelled: "#EB776C",
  inqueue: "#509DE7",
  readyForDoctor: "#F07D00",
  incomplete: "#743208",
};

const VisitsDistribution = ({ filters }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(undefined);

  const fetchData = () => {
    setLoading(true);
    setError(false);
    getData({ filters })
      .then(data => {
        setData(data);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  const getBody = () => {
    if (loading) {
      return <WidgetLoader />;
    }

    if (error || !data) {
      return <WidgetError onRetry={fetchData} />;
    }

    const { values, total } = data;

    return (
      <>
        <div className='d-flex w-100'>
          <Header style={{ paddingBottom: "8px", fontSize: "20px" }}>
            Visits distribution
          </Header>
        </div>
        <div className='d-flex h-100 w-100'>
          <ResponsiveContainer>
            <PieChart margin={{ top: 20, left: 60, right: 60, bottom: 20 }}>
              <text
                style={{ fontSize: "32px" }}
                x={"50%"}
                y={"45%"}
                textAnchor='middle'
                dominantBaseline='middle'>
                {total}
              </text>
              <text
                style={{ fontSize: "12px" }}
                fill='var(--text)'
                x={"50%"}
                y={"54%"}
                textAnchor='middle'
                dominantBaseline='middle'>
                Total Requested
              </text>
              <text
                style={{ fontSize: "12px" }}
                fill='var(--text)'
                x={"50%"}
                y={"59%"}
                textAnchor='middle'
                dominantBaseline='middle'>
                Visits
              </text>
              <Pie
                data={values}
                innerRadius={80}
                outerRadius={120}
                dataKey='value'
                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  labelText,
                }) => {
                  const RADIAN = Math.PI / 180;
                  const radius = 22 + innerRadius + (outerRadius - innerRadius);
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);

                  return (
                    <text
                      style={{ fontSize: "10px" }}
                      fill='#868686'
                      x={x}
                      y={y}
                      textAnchor={x > cx ? "start" : "end"}
                      dominantBaseline='central'>
                      {labelText}
                    </text>
                  );
                }}>
                {values.map(({ key }) => (
                  <Cell key={key} fill={colorMap[key]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Legend color={colorMap.completed} title='Complete' />
          <Legend color={colorMap.cancelled} title='Cancelled' />
        </div>
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Legend color={colorMap.inqueue} title='In-queue' />
          <Legend color={colorMap.readyForDoctor} title='Ready for doctor' />
          <Legend color={colorMap.incomplete} title='Incomplete' />
        </div>
      </>
    );
  };

  return <WidgetCard>{getBody()}</WidgetCard>;
};

export default VisitsDistribution;
