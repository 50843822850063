import mixpanel from "mixpanel-browser";
import { isProd } from "config";

const mixpanelConfiguration = {
  token: "1f66fba92339e439c01b9ee2853c58e9",
  isActive: isProd
};

const actions = {
  mixpanelInitiated: false,
  track: (name, props) => {
    if (mixpanelConfiguration.isActive) mixpanel.track(name, props);
  },
  register: defaultProps => {
    if (mixpanelConfiguration.isActive) mixpanel.register(defaultProps);
  },
  unregister: defaultProps => {
    if (mixpanelConfiguration.isActive) mixpanel.unregister(defaultProps);
  },
  identify: id => {
    if (mixpanelConfiguration.isActive) mixpanel.identify(id);
  },
  alias: id => {
    if (mixpanelConfiguration.isActive) mixpanel.alias(id);
  },
  people: {
    set: props => {
      if (mixpanelConfiguration.isActive) mixpanel.people.set(props);
    }
  },
  reset: () => {
    if (mixpanelConfiguration.isActive) mixpanel.reset();
  },
  init: () => {
    return new Promise(resolve => {
      const init = mixpanel.init(mixpanelConfiguration.token);
      resolve(init);
    });
  }
};

export { actions as Mixpanel };
