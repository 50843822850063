import React, { Component } from "react";
import {
  TabsWrapper,
  Tab,
  Text,
  PageHeader,
  Button,
} from "@innovaccer/design-system";
import CurrentUsers from "./current";
import InvitedUsers from "./invited";
import { Route, Switch, Redirect } from "react-router-dom";
import InactiveUsers from "./inactive";
import InviteModal from "./inviteModal";
import axios from "helper/axios";
import { RoutesWrapper, TableCard, SubRoutesWrapper } from "../commons";
import { Mixpanel } from "helper/mixpanelHelper";
import { exportData } from "../downloadHelper";

const tabs = [
  {
    key: "current",
    label: "Current",
    route: "current",
    component: CurrentUsers,
  },
  {
    key: "invited",
    label: "Invited",
    route: "invited",
    component: InvitedUsers,
  },
  {
    key: "inactive",
    label: "Inactive",
    route: "inactive",
    component: InactiveUsers,
  },
];

class Users extends Component {
  state = {
    showInviteModal: false,
    roles: [],
    practices: [],
    optionsLoading: false,
    config: null,
    isConfigLoading: false,
  };

  defaultTab = 0;

  handleTabChange = index => {
    const tab = tabs[index];
    const { history, match } = this.props;
    history.push(`${match.url}/${tab.route}`);
  };

  getActiveTab = () => {
    const { location, match } = this.props;
    const index = tabs.findIndex(
      ({ route }) =>
        location.pathname.replace(match.url, "").replace("/", "") === route
    );
    return index > -1 ? index : this.defaultTab;
  };

  inviteUsers = () => {
    this.setState({ showInviteModal: true });
    Mixpanel.track(`Invite Users clicked`, {
      category: "InOffice-Settings",
    });
  };

  getUserConfig = () => {
    axios
      .get("organizations/configurations/users-navigation/settings")
      .then(({ data: { config } }) => {
        this.setState({
          config: config.value,
          isConfigLoading: false,
        });
      })
      .catch(error => {
        this.setState({
          isConfigLoading: false,
        });
      });
  };

  render() {
    const {
      showInviteModal,
      roles,
      practices,
      optionsLoading,
      config,
      isConfigLoading,
    } = this.state;
    const { match } = this.props;

    return (
      <RoutesWrapper>
        <PageHeader
          title='Users'
          tabs={
            <TabsWrapper
              onTabChange={this.handleTabChange}
              active={this.getActiveTab() || this.defaultTab}>
              {tabs.map(({ key, label }) => {
                return <Tab key={key} label={<Text>{label}</Text>} />;
              })}
            </TabsWrapper>
          }
          actions={
            <div className='d-flex justify-content-end'>
              <Button className='mr-4' onClick={exportData} icon='get_app'>
                Download records
              </Button>
              <Button onClick={this.inviteUsers} appearance='primary'>
                Invite users
              </Button>
            </div>
          }
        />
        <SubRoutesWrapper>
          <TableCard>
            <Switch>
              {tabs.map(({ key, route, component: Child }) => {
                return (
                  <Route
                    key={key}
                    path={`${match.path}/${route}`}
                    render={props => (
                      <>
                        {route === "current" ? (
                          <Child
                            config={config}
                            isConfigLoading={isConfigLoading}
                            {...props}
                          />
                        ) : (
                          <Child {...props} />
                        )}
                      </>
                    )}
                    // component={Child}
                  />
                );
              })}
              <Route
                key='none'
                path='/'
                component={() => (
                  <Redirect
                    to={{
                      pathname: `${match.url}/${tabs[this.defaultTab].route}`,
                      state: {
                        config: config,
                        isConfigLoading: isConfigLoading,
                      },
                    }}
                  />
                )}
              />
            </Switch>
          </TableCard>
        </SubRoutesWrapper>
        {showInviteModal && (
          <InviteModal
            onClose={() => this.setState({ showInviteModal: false })}
            roles={roles}
            practices={practices}
            optionsLoading={optionsLoading}
          />
        )}
      </RoutesWrapper>
    );
  }

  componentDidMount() {
    this.setState(
      {
        isConfigLoading: true,
      },
      () => {
        this.getUserConfig();
      }
    );

    this.setState({
      optionsLoading: true,
    });
    axios
      .get("innote-survey/user/roles-practices")
      .then(({ data }) => {
        const { roles, practices } = data;
        this.setState({
          optionsLoading: false,
          roles,
          practices,
        });
      })
      .catch(() => {
        this.setState({
          optionsLoading: false,
        });
      });
  }
}

export default Users;
