import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";

ReactDOM.render(<App />, document.getElementById("root"));
// registerServiceWorker();

{
  React.Component.prototype.componentDidCatch = err => {
    console.log(err);
  };
}

{
  React.PureComponent.prototype.componentDidCatch = err => {
    console.log(err);
  };
}
