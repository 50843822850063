import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import OutreachList from "./list";
import OutreachDetails from "./details";

class Outreach extends Component {
  render() {
    const {
      match: { path }
    } = this.props;

    return (
      <Switch>
        <Route path={`${path}/:outreachId`} component={OutreachDetails} />
        <Route exact path={`${path}/`} component={OutreachList} />
      </Switch>
    );
  }
}

export default Outreach;
