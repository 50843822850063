import Axios from "axios";
import { get } from "lodash";
import { API_URL } from "config";
import authService from "services/authService";

const axios = Axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `${localStorage.getItem("token")}`,
    "X-TIMEZONE-OFFSET": new Date().getTimezoneOffset()
  }
});

axios.interceptors.response.use(
  res => res,
  function(error) {
    if (error.response.status == 401) {
      authService.dispatchLogoutEvent();
    }
    return Promise.reject(error);
  }
);

export default axios;

export const getErrorMessage = (error, defaultValue = "Something went wrong") =>
  get(error, "response.data.error.message", defaultValue);
