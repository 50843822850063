import React, { PureComponent } from "react";
import styled from "styled-components";

export const Dot = styled.span.attrs({
  children: "•"
})`
  font-size: 16px;
  margin: 0 4px;
  line-height: 16px;
`;

export default class Join extends PureComponent {
  render() {
    const { children, by = <Dot /> } = this.props;
    return React.Children.map(children, child =>
      React.isValidElement(child) ? child : null
    ).reduce((result, child) => {
      if (child) {
        if (result.length) {
          return result.concat([by, child]);
        }
        return result.concat([child]);
      }
      return result;
    }, []);
  }
}
