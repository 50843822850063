import React, { useState, useCallback, useEffect } from "react";
import { RoutesWrapper, SubRoutesWrapper, CardsWrapper } from "../commons";
import { PageHeader, Button, Text, Heading } from "@innovaccer/design-system";
import {
  NetworkCard,
  Name,
  Logo,
  SubDomain,
  Instructions,
  ErrorState,
  DetailsRow,
  CopyText,
  WelcomeMessage,
} from "./atoms";
import {
  getNetworkInfo,
  updateNetworkInfo,
  resetNetworkInfo,
} from "services/orgService";
import PageLoader from "components/commons/PageLoader";
import AlertService, { defaultDissmissDelay } from "services/alertService";
import ConfirmService from "services/confirmService";
import { getAdminUrl, getProviderUrl, getFDUrl } from "utils";
import { getErrorMessage } from "helper/axios";

const showAlert = err => {
  AlertService.showAlert({
    appearance: "alert",
    message: getErrorMessage(err, "Failed to update details"),
    autoClearAfter: defaultDissmissDelay,
  });
};

const DetailsCard = ({ details, updateDetails }) => {
  const { name: defaultName, logoUrl: defaultUrl } = details;
  const [editable, makeEditable] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [name, setName] = useState(defaultName);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    setName(defaultName);
    setLogo(null);
  }, [editable]);

  const saveDetails = () => {
    setUpdating(true);
    updateDetails({ logo, name })
      .then(() => {
        setUpdating(false);
        makeEditable(false);
      })
      .catch(err => {
        setUpdating(false);
        showAlert(err);
      });
  };

  return (
    <NetworkCard
      disabled={updating}
      title='Details'
      subtitle='Manage essential information related to your network.'
      footer={
        editable ? (
          <>
            <Button
              key='cancel'
              className='mr-4'
              onClick={() => makeEditable(false)}>
              Cancel
            </Button>
            <Button key='save' onClick={saveDetails} appearance='primary'>
              Save Changes
            </Button>
          </>
        ) : (
          <Button
            key='edit'
            onClick={() => makeEditable(true)}
            appearance='primary'>
            Edit
          </Button>
        )
      }>
      <Name
        placeholder='Network name'
        editable={editable}
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Logo
        editable={editable}
        defaultUrl={defaultUrl}
        file={logo}
        onChange={file => setLogo(file)}
      />
    </NetworkCard>
  );
};

const WebLinksCard = ({ details, updateDetails }) => {
  const { subDomain: defaultsubDomain } = details;
  const [editable, makeEditable] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [subDomain, setSubDomain] = useState(defaultsubDomain);

  useEffect(() => {
    setSubDomain(defaultsubDomain);
  }, [editable]);

  const saveDetails = () => {
    setUpdating(true);
    ConfirmService.confirm({
      dimension: "small",
      heading: "Set subdomain?",
      description: `You’re about to set the Subdomain for your network to ${subDomain}.innovacccer.com. Please note, later you won't be able to make changes to this subdomain.`,
      confirmationText: "Set subdomain",
    })
      .then(modal => {
        modal.setLoading(true);
        updateDetails({ subDomain })
          .then(() => {
            setUpdating(false);
            makeEditable(false);
          })
          .catch(err => {
            setUpdating(false);
            showAlert(err);
          })
          .finally(() => {
            modal.close();
            modal.setLoading(false);
          });
      })
      .catch(modal => {
        modal.close();
      });
  };

  return (
    <NetworkCard
      disabled={updating}
      title='Network web links'
      subtitle='Weblinks which will be used by your teammates and patients.'
      footer={
        subDomain && !editable ? null : editable ? (
          <>
            <Button
              key='cancel'
              className='mr-4'
              onClick={() => makeEditable(false)}>
              Cancel
            </Button>
            <Button
              disabled={!subDomain}
              key='save'
              onClick={saveDetails}
              appearance='primary'>
              Save Changes
            </Button>
          </>
        ) : (
          <Button
            key='edit'
            onClick={() => makeEditable(true)}
            appearance='primary'>
            Set subdomain
          </Button>
        )
      }>
      {!subDomain || editable ? (
        <SubDomain
          editable={editable}
          placeholder='subdomain'
          value={subDomain}
          onChange={e => setSubDomain(e.target.value)}></SubDomain>
      ) : null}
      {subDomain && (
        <>
          <DetailsRow
            label='Admin'
            subLabel='Web address for admins and network managers.'>
            <CopyText text={getAdminUrl(subDomain)} />
          </DetailsRow>
          <DetailsRow
            label='Provider'
            subLabel='Web address for providers in your team.'>
            <CopyText text={getProviderUrl(subDomain)} />
          </DetailsRow>
          <div className='w-100 mt-4'>
            <Heading size='s'>Front door</Heading>
            <Text appearance='subtle'>
              Weblink which will be used by your patients.
            </Text>
          </div>
          <DetailsRow
            label='Front door web address'
            subLabel={`This will be your network's web address which can be embedded to your website. It can also be used directly by your patients to schedule appointments.`}>
            <CopyText text={getFDUrl(subDomain)} />
          </DetailsRow>
        </>
      )}
    </NetworkCard>
  );
};

const PresenceCard = ({ details, updateDetails, resetNetwork }) => {
  const {
    welcomeMessage: defaultWelcomeMessage,
    examRoomLogoUrl,
    instructionVideoUrl,
    instructionVideo,
  } = details;
  const [editable, makeEditable] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState(defaultWelcomeMessage);
  const [instImage, setInstImage] = useState(null);
  const [instVideo, setInstVideo] = useState(instructionVideoUrl);
  const [isVideo, setIsVideo] = useState(instructionVideo);
  const [imageSetToDefault, setImageSetToDefault] = useState(false);
  const [videoSetToDefault, setVideoSetToDefault] = useState(false);

  const onImageSetToDefault = value => {
    setImageSetToDefault(value);
  };

  const onVideoSetToDefault = value => {
    setVideoSetToDefault(value);
  };

  useEffect(() => {
    setWelcomeMessage(defaultWelcomeMessage);
    setInstImage(null);
    setInstVideo(instructionVideoUrl);
    setIsVideo(instructionVideo);
  }, [editable]);

  const saveDetails = () => {
    setUpdating(true);
    if (imageSetToDefault) {
      resetNetwork(["examRoomLogoUrl"], false)
        .then(() => {
          setUpdating(false);
          makeEditable(false);
          onImageSetToDefault(false);
        })
        .catch(() => {
          setUpdating(false);
        });
    } else if (videoSetToDefault) {
      resetNetwork(["instructionVideoUrl"], true)
        .then(() => {
          makeEditable(false);
          setUpdating(false);
          onVideoSetToDefault(false);
        })
        .catch(() => {
          setUpdating(false);
        });
    } else {
      updateDetails({
        welcomeMessage,
        instructionVideoUrl: instVideo,
        examRoomLogoUrl: instImage,
        instructionVideo: isVideo,
      })
        .then(() => {
          setUpdating(false);
          makeEditable(false);
          onImageSetToDefault(false);
          onVideoSetToDefault(false);
        })
        .catch(err => {
          setUpdating(false);
          showAlert(err);
        });
    }
  };

  return (
    <NetworkCard
      disabled={updating}
      title='Waiting room presence'
      subtitle='Create a personalized experience for your network’s waiting room.'
      footer={
        editable ? (
          <>
            <Button
              key='cancel'
              className='mr-4'
              onClick={() => {
                makeEditable(false);
                setImageSetToDefault(false);
                setVideoSetToDefault(false);
              }}>
              Cancel
            </Button>
            <Button key='save' onClick={saveDetails} appearance='primary'>
              Save Changes
            </Button>
          </>
        ) : (
          <Button
            key='edit'
            onClick={() => {
              setImageSetToDefault(false);
              setVideoSetToDefault(false);
              makeEditable(true);
            }}
            appearance='primary'>
            Edit
          </Button>
        )
      }>
      <WelcomeMessage
        editable={editable}
        value={welcomeMessage}
        onChange={e => setWelcomeMessage(e.target.value)}
      />
      <Instructions
        editable={editable}
        isVideo={isVideo}
        file={instImage}
        defaultImageUrl={examRoomLogoUrl}
        defaultVideoUrl={instVideo}
        imageSetToDefault={imageSetToDefault}
        videoSetToDefault={videoSetToDefault}
        onImageSetToDefault={onImageSetToDefault}
        onVideoSetToDefault={onVideoSetToDefault}
        onChange={(content, video) => {
          if (video) {
            setInstVideo(content);
            setIsVideo(content && video);
          } else {
            setIsVideo(content ? false : !!instVideo);
            setInstImage(content);
          }
        }}
      />
    </NetworkCard>
  );
};

const Network = ({}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [details, setDetails] = useState({});

  const fetchDetails = useCallback(() => {
    setLoading(true);
    setError(false);
    getNetworkInfo()
      .then(({ data }) => {
        setDetails(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  const resetNetwork = useCallback((payload, instructionVideo) => {
    return resetNetworkInfo(payload, instructionVideo).then(({ data }) => {
      setDetails(data);
    });
  }, []);

  const updateDetails = useCallback(payload => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (![null, undefined].includes(value)) {
        formData.append(key, value);
      }
    });
    // const newDetails = { ...details, ...payload };

    return updateNetworkInfo(formData, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then(({ data }) => {
      setDetails(data);
    });
  }, []);

  useEffect(() => {
    fetchDetails();
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorState onRetry={fetchDetails} />;
  }

  return (
    <RoutesWrapper>
      <PageHeader title='Network' />
      <SubRoutesWrapper overflow='auto'>
        <CardsWrapper>
          <DetailsCard details={details} updateDetails={updateDetails} />
          <WebLinksCard details={details} updateDetails={updateDetails} />
          <PresenceCard
            details={details}
            updateDetails={updateDetails}
            resetNetwork={resetNetwork}
          />
        </CardsWrapper>
      </SubRoutesWrapper>
    </RoutesWrapper>
  );
};

export default Network;
