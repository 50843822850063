import React, { Component } from "react";
import { PageHeader, Button, Table, Text } from "@innovaccer/design-system";
import { RoutesWrapper, TableCard, SubRoutesWrapper } from "../commons";
import { getPracticeList } from "services/orgService";
import authService from "services/authService";
import Placeholder from "components/commons/Placeholder";
import { CopyText } from "../network/atoms";
import { formatPhone, getPracticeFDUrl } from "utils";

const EmptyPlaceholder = () => {
  return (
    <Placeholder
      title='No practices'
      subTitle='We found no practice records.'
    />
  );
};

const EmptyColumnPlaceholder = () => (
  <Text appearance='disabled'>Not Available</Text>
);

class PracticeList extends Component {
  state = {
    key: 0,
    data: null,
    showAddPracticeModal: false,
    updating: false,
  };

  columns = [
    {
      name: "name",
      displayName: "Name",
      sorting: false,
      width: "30%",
    },
    {
      name: "frontDoorWebUrl",
      displayName: "Front door web address",
      sorting: false,
      width: "40%",
      cellRenderer: ({ data }) => {
        let loggedInUser = authService.getUser(),
          { sub_domain } = loggedInUser;
        return (
          <CopyText text={getPracticeFDUrl(sub_domain, data.practiceKey)} />
        );
      },
    },
    {
      name: "phoneNo",
      displayName: "Phone",
      sorting: false,
      width: "30%",
      cellRenderer: ({ data }) => {
        return data.phoneNo ? (
          <Text>{formatPhone(data.phoneNo)}</Text>
        ) : (
          <EmptyColumnPlaceholder />
        );
      },
    },
  ];

  loaderSchema = this.columns.map(({ cellRenderer, ...rest }) => rest);

  getPracticeList = ({ searchTerm = "" }) => {
    const transformData = list => ({
      schema: this.columns,
      count: list.length,
      data: list.filter(({ name }) =>
        (name || "").toLowerCase().includes(searchTerm.toLowerCase())
      ),
    });
    if (!this.state.data) {
      return getPracticeList().then(({ data: { practiceData } }) => {
        this.setState({
          data: practiceData,
        });
        return transformData(practiceData);
      });
    }
    return Promise.resolve(transformData(this.state.data));
  };

  addPractice = () => {
    const { history, match } = this.props;
    history.push(`${match.url}/create`);
  };

  showPracticeDetails = practice => {
    const { history, match } = this.props;
    history.push(`${match.url}/${practice.id}`);
  };

  render() {
    const { key } = this.state;
    const { config } = this.props;
    return (
      <RoutesWrapper>
        <PageHeader
          title='Practices'
          actions={
            <div className='d-flex justify-content-end'>
              {config &&
                config.rightButtons &&
                config.rightButtons.map((button, index) => {
                  if (button.hidden) {
                    return;
                  }
                  return (
                    <Button
                      onClick={this.addPractice}
                      appearance='primary'
                      key={index}>
                      {button.displayName}
                    </Button>
                  );
                })}
            </div>
          }></PageHeader>
        <SubRoutesWrapper>
          <TableCard>
            <Table
              type='resource'
              onRowClick={this.showPracticeDetails}
              errorTemplate={EmptyPlaceholder}
              key={key}
              async
              withHeader
              headerOptions={{ dynamicColumn: false, withSearch: true }}
              showMenu={false}
              fetchData={this.getPracticeList}
              loaderSchema={this.loaderSchema}
              pageSize={20}
            />
          </TableCard>
        </SubRoutesWrapper>
      </RoutesWrapper>
    );
  }
}

export default PracticeList;
