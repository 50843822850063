import { chain } from "lodash";
import moment from "moment";

/**
 * Convert object to query string
 * @param  {Object} queryObject Query object
 * @returns {string} Query string
 */
export const objectToQueryString = queryObject =>
  chain(queryObject)
    .reduce(
      (queryStringList, value, key) => [...queryStringList, `${key}=${value}`],
      []
    )
    .join("&")
    .value();

export const formatDate = (date, format = "MM/DD/YYYY") => {
  return moment(date).format(format);
};

export const copyTextToClipboard = text => {
  const el = document.createElement("textarea");
  el.value = text;
  el.setAttribute("readonly", "");
  el.style.position = "hidden";
  document.body.appendChild(el);
  el.select();
  const successful = document.execCommand("copy");
  document.body.removeChild(el);
  return successful;
};

export const formatPhone = phoneNumber => {
  const unformatedString = String(phoneNumber).replace(/\D/g, "");
  return unformatedString.split("").reduce((result, char, currentIndex) => {
    if (currentIndex === 3) {
      result = `(${result}) ${char}`;
    } else if (currentIndex === 6) {
      result = `${result}-${char}`;
    } else {
      result = result + char;
    }
    return result;
  }, "");
};

export const getOrgSubdomain = () => {
  const hostParts = window.location.host.split(".");
  if (hostParts.includes("localhost:3000")) return hostParts[0];
  if (hostParts.length < 4) {
    return null;
  }
  return hostParts[0];
};

export const getBaseUrl = () => {
  const hostName = window.location.host.split("admin-");
  return hostName[hostName.length > 1 ? 1 : 0];
};

export const getEnvironment = () => {
  const isPreprod = window.location.host.includes("preprod");
  return isPreprod;
};

export const getAdminUrl = subDomain => {
  let hostName = window.location.host;
  let http = hostName === "localhost:3000" ? "http://" : "https://";
  return `${http}${
    subDomain && (hostName.match(/\./g) || []).length < 3 ? `${subDomain}.` : ""
  }${hostName}/`.toLowerCase();
};

export const getProviderUrl = subDomain =>
  `https://${subDomain ? `${subDomain}.` : ""}${
    getEnvironment() ? `preprod-` : ``
  }provider-${getBaseUrl()}/`.toLowerCase();

export const getFDUrl = subDomain =>
  `https://${subDomain ? `${subDomain}.` : ""}${
    getEnvironment() ? `preprod-` : ``
  }member-${getBaseUrl()}/`.toLowerCase();

export const getPracticeFDUrl = (subDomain, practiceKey) =>
  `https://${
    subDomain ? `${subDomain}.` : ""
  }member-${getBaseUrl()}/practices/${practiceKey}`.toLowerCase();

export const paramsSerializer = params => {
  let options = "";
  for (const key in params) {
    if (typeof params[key] !== "object" && params[key]) {
      options += `${key}=${params[key]}&`;
    } else if (
      typeof params[key] === "object" &&
      params[key] &&
      params[key].length
    ) {
      params[key].forEach(el => {
        options += `${key}=${el}&`;
      });
    }
  }
  return options ? options.slice(0, -1) : options;
};
