import React, { Component } from "react";
import ContentEditable from "react-contenteditable";

const mapping = [
  [
    "<firstName>",
    "< First Name >",
    `<a style="color: blue;" href="#"> < First Name > </a>`
  ],
  [
    "<lastName>",
    "< Last Name >",
    `<a style="color: blue;" href="#"> < Last Name > </a>`
  ],
  [
    "<patientName>",
    "< Patient Name >",
    `<a style="color: blue;" href="#"> < Patient Name > </a>`
  ],
  [
    "<shortUrl>",
    "< Assessment Url >",
    `<a style="color: blue;" href="#"> < Assessment Url > </a>`
  ]
];

export const replaceReservedTags = (string = "", withHtml = true) => {
  return mapping.reduce((newStrinng, [replace, by, html]) => {
    return newStrinng.replace(new RegExp(replace, "g"), withHtml ? html : by);
  }, string);
};

export const reInsertReservedTags = (string = "") => {
  return mapping.reduce((newStrinng, [by, replace]) => {
    return newStrinng.replace(replace, by);
  }, string);
};

class Message extends Component {
  constructor(props) {
    super(props);
    this.messageRef = React.createRef();
  }

  handleChange = event => {
    this.props.updateMessage(
      event.target.innerHTML,
      reInsertReservedTags(event.target.innerText)
    );
  };

  render() {
    const { message, replaceHtml = true, disabled } = this.props;

    return (
      <ContentEditable
        className='introMsg'
        placeholder='Your message goes here...'
        innerRef={this.messageRef}
        html={replaceReservedTags(message, replaceHtml)}
        onKeyUp={this.handleChange}
        tagName='article'
        disabled={disabled}
        style={{
          height: "94px",
          width: "100%",
          border: "1px solid #d5d5d5",
          borderRadius: "4px",
          backgroundColor: "#ffffff",
          overflowY: "scroll",
          padding: "12px 8px 0px 12px",
          margin: "8px 0 8px 0",
          fontFamily: "inherit"
        }}
      />
    );
  }
}

export default Message;
