import React from "react";
import { Spinner } from "@innovaccer/design-system";
import styled from "styled-components";

const Preloader = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const PageLoader = () => (
  <Preloader>
    <Spinner appearance='primary' size='large' />
  </Preloader>
);

export default PageLoader;
