import AlertService, { defaultDissmissDelay } from "services/alertService";
import { exportJson2Excel } from "helper/fileDownload";
import { getCurrentUsers, getInvitedUsers } from "services/userService";

const schema = [
  { displayText: "Name", key: "name" },
  { displayText: "Email", key: "email" },
  { displayText: "Mobile", key: "phoneNumber" },
  { displayText: "Role", key: "accessRole" },
  { displayText: "NPI", key: "npi" },
  { displayText: "Practice", key: "practices" },
  { displayText: "Admin (Yes or No)", key: "isAdmin" },
];

const getUsers = async status => {
  if (status === "invited") {
    return await getInvitedUsers().then(({ data: { data } }) => {
      data.map(d => {
        d.isAdmin = d.isAdmin == true ? "Yes" : "No";
        d.name = d.firstName + " " + d.lastName;
      });
      return {
        data: data,
        schema,
        name: "Invited",
      };
    });
  } else {
    return await getCurrentUsers({ status: status }).then(
      ({ data: { data } }) => {
        data.map(d => {
          d.isAdmin = d.isAdmin == true ? "Yes" : "No";
          d.name = d.patientFirstName + " " + d.patientLastName;
        });
        return {
          data: data,
          schema,
          name: status === "active" ? "Current" : "Inactive",
        };
      }
    );
  }
};

export const exportData = () => {
  AlertService.showAlert({
    title: "Preparing Download...",
    autoClearAfter: defaultDissmissDelay,
  });
  Promise.all([getUsers("active"), getUsers("invited"), getUsers("inactive")])
    .then(data => {
      exportJson2Excel(data, `Report-Users`);
    })
    .catch(err => {
      AlertService.showAlert({
        appearance: "alert",
        title: "Failed to export data...",
        autoClearAfter: defaultDissmissDelay,
      });
    });
};
