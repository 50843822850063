import React, { Component } from "react";
import Toast from "@datashop/toast";
import styled from "styled-components";
import { findIndex } from "lodash";
import Icon from "@datashop/icon";

const Wrapper = styled.div`
  height: 100%;
  position: fixed;
  z-index: 10000;
`;

const ToastsWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  bottom: 32px;
  left: 24px;
  > * {
    position: static !important;
    margin-bottom: 12px;
    :last-child {
      margin-bottom: 0px;
    }
  }
`;

const OtherOptions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ClearButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const MoreCount = styled.div``;

export const defaultDissmissDelay = 3000;
export const defaultStackLimit = 3;

class AlertService extends Component {
  static defaultToastProps = {
    appearance: "default"
  };

  static alerts = [];

  static addToast(options) {
    const { autoClearAfter, ...rest } = options;
    const timerOptions = {};
    if (autoClearAfter) {
      timerOptions.clearTimer = setTimeout(() => {
        toastObject.onDismissClick();
      }, autoClearAfter);
    }
    const toastObject = {
      ...AlertService.defaultToastProps,
      ...rest,
      onDismissClick: () => {
        AlertService.removeToast(options.key);
        if (autoClearAfter) {
          clearTimeout(+(timerOptions.clearTimer || 0));
        }
      }
    };

    AlertService.alerts.push(toastObject);
  }

  static removeToast(key) {
    const indexToRemove = findIndex(AlertService.alerts, { key });
    AlertService.alerts.splice(indexToRemove, 1);

    window.dispatchEvent(new Event("toastUpdated"));
  }

  static clearAll() {
    AlertService.alerts.splice(0);
    window.dispatchEvent(new Event("toastUpdated"));
  }

  static showAlert(props) {
    AlertService.addToast({ ...props, key: Date.now() });

    window.dispatchEvent(new Event("toastUpdated"));
  }

  render() {
    const {
      stackLimit = defaultStackLimit,
      clearAll,
      alertOverflowCount
    } = this.props;
    const itemsToRender = AlertService.alerts.slice(0, stackLimit).reverse();

    return (
      <Wrapper data-test='alert-service'>
        <ToastsWrapper>
          {clearAll && itemsToRender.length > 1 && (
            <OtherOptions>
              <ClearButton
                data-test='alert-service__clearAll'
                onClick={AlertService.clearAll}>
                <Icon
                  name='clear_all'
                  style={{ fontSize: 18, marginRight: 4 }}
                />{" "}
                Clear All
              </ClearButton>
              {alertOverflowCount &&
                AlertService.alerts.length > stackLimit && (
                  <MoreCount>
                    {AlertService.alerts.length - stackLimit} More above
                  </MoreCount>
                )}
            </OtherOptions>
          )}
          {itemsToRender.map(({ key, ...rest }) => (
            <Toast key={key} {...rest} />
          ))}
        </ToastsWrapper>
      </Wrapper>
    );
  }

  updateToasts = () => {
    this.forceUpdate();
  };

  componentDidMount() {
    window.addEventListener("toastUpdated", this.updateToasts);
  }

  componentWillUnmount() {
    window.removeEventListener("toastUpdated", this.updateToasts);
  }
}

export default AlertService;
