import React, { useState, useRef, useEffect, Fragment } from "react";
import { SettingsCard } from "../commons";
import {
  Heading,
  Text,
  Input,
  InputMask,
  Icon,
  Caption,
  Chip,
  Textarea,
  Button,
  Switch,
  Badge,
  Tooltip,
} from "@innovaccer/design-system";
import styled from "styled-components";
import { phoneNumberPlaceholder } from "config";
import { phoneNumberMask } from "config";
import { copyTextToClipboard } from "utils";
import { LogoConfig } from "config";
import AlertService from "services/alertService";
import { get, noop } from "lodash";
import Placeholder from "components/commons/Placeholder";
import { queryParamsToConfig } from "helper/queryParams";

const defaultInstructionImage = `${process.env.PUBLIC_URL}/img/instructions_placeholder.jpg`;
export const NetworkCard = ({
  title,
  subtitle,
  children,
  footer,
  disabled,
}) => {
  return (
    <SettingsCard disabled={disabled} className='p-6'>
      <div className='w-100'>
        <Heading size='s'>{title}</Heading>
        <Text appearance='subtle'>{subtitle}</Text>
      </div>
      <div className='w-100'>{children}</div>
      <div className='w-100 d-flex justify-content-end'>{footer}</div>
    </SettingsCard>
  );
};

const DetailsRowWrapper = styled.div`
  ${props => props.alignItems && `align-items: ${props.alignItems};`}

  display: flex;
  padding: 12px 0;

  :not(:last-child) {
    border-bottom: var(--border);
    border-color: var(--secondary-lighter);
  }

  .label-wrapper {
    display: flex;
    flex-direction: column;
    padding-right: 16px;
    width: 45%;
  }

  .children-wrapper {
    width: 55%;
    .Caption--hidden {
      display: none;
    }
  }

  .sub-domain-input {
    margin-left: 4px;
    margin-right: 4px;
    max-width: 160px;
    min-width: 128px;
  }
`;

export const DetailsRow = ({
  label,
  subLabel,
  meta = null,
  alignItems,
  children,
}) => {
  return (
    <DetailsRowWrapper alignItems={alignItems}>
      <div className='label-wrapper'>
        <Text weight='strong'>{label}</Text>
        {subLabel && typeof subLabel == "string" ? (
          <Text small appearance='subtle'>
            {subLabel}
          </Text>
        ) : (
          subLabel
        )}
        {meta && <div className='mt-5'>{meta}</div>}
      </div>
      <div className='children-wrapper'>{children}</div>
    </DetailsRowWrapper>
  );
};

const CopyTextWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  .toolip-wrapper {
    width: calc(100% - 28px);
  }
`;
export const CopyText = ({ text, copyText = text }) => {
  const [copyTTText, setCopyTTText] = useState("Copy");
  return (
    <CopyTextWrapper
      onMouseLeave={() => {
        setCopyTTText("Copy");
      }}>
      <Tooltip position='top' triggerClass='toolip-wrapper' tooltip={text}>
        <Text className='text-ellipsis'>{text}</Text>
      </Tooltip>
      <Tooltip position='top' tooltip={copyTTText}>
        <Icon
          className='cursor-pointer ml-5'
          name='content_copy'
          onClick={e => {
            e.stopPropagation();
            setCopyTTText("Copied! 🎉");
            copyTextToClipboard(copyText);
          }}
        />
      </Tooltip>
    </CopyTextWrapper>
  );
};

export const ErrorState = props => {
  return (
    <Placeholder
      error
      iconStyle={{ color: "var(--alert)" }}
      title='Something went wrong'
      subTitle='It may be because of technical failure or network reasons. Please try again'
      {...props}
    />
  );
};

// ======================= Plugables ==============================
const Uploader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: ${props => props.height}px;
  min-height: 64px;
  overflow: hidden;

  border: var(--border);
  border-color: var(--secondary-lighter);
`;

const ImageSelector = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  * {
    color: white !important;
  }
  height: 100%;
  width: 100%;
`;
export const ImageUploader = ({
  editable,
  maxSize,
  maxSizeBreachMessage = "File selected is above permitted size",
  height = 40,
  defaultUrl,
  file,
  onChange = noop,
  showResetOption,
  onReset = noop,
  imageSetToDefault,
  onImageSetToDefault = noop,
}) => {
  const [url, setUrl] = useState(defaultUrl);
  const fileInputRef = useRef();

  useEffect(() => {
    if (!file) {
      setUrl(defaultUrl);
    } else {
      var reader = new FileReader();

      reader.onload = function (e) {
        setUrl(e.target.result);
      };

      reader.readAsDataURL(file);
    }
    if (imageSetToDefault) {
      setUrl(defaultInstructionImage);
    }
  }, [file, imageSetToDefault]);

  const setFile = file => {
    onChange(file);
  };

  const selectFile = () => {
    fileInputRef.current.click();
    //setFile(null);
  };

  const clearInput = () => {
    fileInputRef.current.value = null;
  };

  const fileSelected = () => {
    const file = get(fileInputRef, "current.files[0]");

    if (!file) {
      AlertService.showAlert({
        appearance: "alert",
        title: "Invalid file format",
      });
      clearInput();
      return;
    }

    // Allowing file type
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

    if (!allowedExtensions.exec(file.name)) {
      AlertService.showAlert({
        appearance: "alert",
        title: "Invalid file type",
      });
      clearInput();
      return;
    }

    if (file.size / 1024 > maxSize) {
      AlertService.showAlert({
        appearance: "alert",
        title: maxSizeBreachMessage,
      });
      clearInput();
      return;
    }
    setFile(file);
  };

  return (
    <div className='d-flex align-items-end'>
      <Uploader height={height}>
        {url && (
          <img
            id='instruction_image'
            src={url}
            style={{ height }}
            alt='instruction_image'
          />
        )}
        {editable && (
          <ImageSelector>
            <div className='d-flex justify-content-center'>
              <Icon size={28} name='cloud_upload' />
            </div>
            <div
              onClick={selectFile}
              className='d-flex cursor-pointer justify-content-center'>
              <Text className='mr-5'>Drag & Drop</Text>
              <Text>or</Text>
              <Text className='ml-5 d-flex align-items-center'>
                <Icon name='folder_open' />
                &nbsp;Browse
              </Text>
            </div>
            <input
              style={{ display: "none" }}
              type='file'
              ref={fileInputRef}
              accept='.png,.jpg,.jpeg'
              onChange={e => {
                onImageSetToDefault(false);
                const { target } = e;
                if (target.value.length > 0) {
                  fileSelected();
                }
              }}
            />
          </ImageSelector>
        )}
      </Uploader>
      {showResetOption && editable && (
        <Button
          size='tiny'
          appearance='transparent'
          onClick={() => onImageSetToDefault(true)}>
          Set to default
        </Button>
      )}
    </div>
  );
};

const VideoUploaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 8px;
  border: var(--border);
  border-color: var(--secondary-lighter);
  .Input {
    min-width: 100%;
  }
`;

const Thumbnail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 144px;
  width: 100%;
  background-color: var(--stone);
  * {
    color: white !important;
  }
  > iframe {
    height: 100%;
    width: 100%;
  }
`;

const VideoUploader = ({
  editable,
  url: urlProp,
  onChange = noop,
  showResetOption,
  videoSetToDefault,
  onVideoSetToDefault = noop,
}) => {
  const url = videoSetToDefault ? "" : urlProp || "";
  const validUrl = url.includes("youtube") && url.includes("v=");

  const videoId =
    validUrl && queryParamsToConfig("?" + url.split("?").slice(1)[0]).v;

  return (
    <div className='d-flex align-items-end'>
      <VideoUploaderWrapper>
        {editable && (
          <Input
            error={url && !validUrl}
            className='mb-3'
            icon='link'
            value={url}
            onChange={e => onChange(e.target.value)}
          />
        )}
        <Thumbnail>
          {validUrl ? (
            <iframe
              width='100%'
              height='100%'
              src={`https://www.youtube.com/embed/${videoId}`}
              frameborder='0'
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen></iframe>
          ) : (
            <Icon size={36} name='slideshow' />
          )}
        </Thumbnail>
      </VideoUploaderWrapper>
      {showResetOption && editable && (
        <Button
          size='tiny'
          appearance='transparent'
          onClick={() => onVideoSetToDefault(true)}>
          Set to default
        </Button>
      )}
    </div>
  );
};

// ======================= Reusable rows ==============================
export const Name = ({ editable, labelProps = {}, ...rest }) => {
  return (
    <DetailsRow alignItems='center' label='Name' {...labelProps}>
      {editable && <Input {...rest} />}
      {!editable && <Text>{rest.value}</Text>}
    </DetailsRow>
  );
};

export const Phone = ({
  editable,
  labelProps = {},
  emptyPlaceholder,
  value,
  onChange = noop,
  ...rest
}) => {
  return (
    <DetailsRow alignItems='center' label='Phone' {...labelProps}>
      {editable && (
        <InputMask
          placeholder={phoneNumberPlaceholder}
          mask={phoneNumberMask}
          defaultValue={value}
          onChange={event =>
            onChange(event, (event.target.value || "").replace(/\D/g, ""))
          }
          {...rest}
        />
      )}
      {!editable &&
        (value ? (
          <Text>{value}</Text>
        ) : (
          <Text appearance='disabled'>
            {emptyPlaceholder || "Add your practice’s phone"}
          </Text>
        ))}
    </DetailsRow>
  );
};

export const Address = ({
  editable,
  labelProps = {},
  emptyPlaceholder,
  ...rest
}) => {
  return (
    <DetailsRow alignItems='center' label='Address' {...labelProps}>
      {editable && <Input {...rest} />}
      {!editable &&
        (rest.value ? (
          <Text>{rest.value}</Text>
        ) : (
          <Text appearance='disabled'>
            {emptyPlaceholder || "Add your practice’s address"}
          </Text>
        ))}
    </DetailsRow>
  );
};

export const Logo = ({ editable, labelProps = {}, ...rest }) => {
  const { file, onChange = noop, defaultUrl = LogoConfig.src } = rest;

  return (
    <DetailsRow
      label='Logo'
      subLabel='This will be visible across touchpoints to your
    teammates and patients.'
      meta={editable && <Caption>.jpg or .png; Max size of 800K</Caption>}
      {...labelProps}>
      <ImageUploader
        editable={editable}
        maxSize={800}
        height={40}
        defaultUrl={defaultUrl}
        onChange={onChange}
        file={file}
      />
    </DetailsRow>
  );
};

export const SubDomain = ({ editable, labelProps = {}, ...rest }) => {
  return (
    <DetailsRow
      label='Subdomain'
      subLabel={
        <div>
          <Text small appearance='subtle'>
            https://
          </Text>
          <Text small>subdomain</Text>
          <Text small appearance='subtle'>
            .innovaccer.com/
          </Text>
        </div>
      }
      {...labelProps}>
      {editable ? (
        <div className='d-flex align-items-center'>
          <Text appearance='subtle'>https://</Text>
          <Input className='sub-domain-input' {...rest} />
          <Text appearance='subtle'>.innovaccer.com/</Text>
        </div>
      ) : (
        <Text appearance='subtle'>
          Set a unique subdomain for your network and share it with your
          teammates to access Virtual Care Network. Please note, once you have
          set the domain you won't be able to make any further changes to it.
        </Text>
      )}
    </DetailsRow>
  );
};

export const WelcomeMessage = ({ labelProps = {}, editable, ...rest }) => {
  const subStrings = (rest.value || "").split("\n");
  return (
    <DetailsRow
      label='Welcome message'
      subLabel='Leave a welcome note for your patients when they land your virtual waiting room.'
      {...labelProps}>
      {editable && <Textarea {...rest} />}
      {!editable && (
        <div className='color-text'>
          {subStrings.map((text, i) => (
            <Fragment key={i}>
              {text}
              {i !== subStrings.length && <br />}
            </Fragment>
          ))}
        </div>
      )}
    </DetailsRow>
  );
};

export const Instructions = ({
  editable,
  labelProps = {},
  isVideo,
  defaultVideoUrl,
  imageSetToDefault,
  videoSetToDefault,
  onImageSetToDefault,
  onVideoSetToDefault,
  defaultImageUrl,
  file,
  onChange = noop,
}) => {
  const [video, setType] = useState(isVideo);

  useEffect(() => {
    setType(isVideo);
  }, [isVideo]);

  return (
    <DetailsRow
      label='Instructions'
      subLabel='Add instructions and guide your patients to make their virtual visit seamless.'
      meta={
        editable ? (
          !video ? (
            <Caption>.jpg or .png; Max size of 800K</Caption>
          ) : null
        ) : (
          <Chip
            icon={video ? "movie" : "insert_photo"}
            label={video ? "Video" : "Image"}
          />
        )
      }
      {...labelProps}>
      {editable && (
        <div className='mb-6 d-flex'>
          <Chip
            onClick={() => {
              setType(false);
              onVideoSetToDefault(false);
              onImageSetToDefault(false);
            }}
            selected={!video}
            type='selection'
            className='mr-5 cursor-pointer'
            icon='insert_photo'
            label='Image'
          />
          <Chip
            onClick={() => {
              setType(true);
              onVideoSetToDefault(false);
              onImageSetToDefault(false);
            }}
            selected={video}
            type='selection'
            className='cursor-pointer'
            icon='movie'
            label='Video'
          />
        </div>
      )}
      {!video ? (
        <ImageUploader
          showResetOption
          editable={editable}
          maxSize={800}
          height={182}
          defaultUrl={defaultImageUrl}
          onChange={file => onChange(file, false)}
          file={file}
          imageSetToDefault={imageSetToDefault}
          onImageSetToDefault={onImageSetToDefault}
        />
      ) : (
        <VideoUploader
          editable={editable}
          url={defaultVideoUrl}
          onChange={url => onChange(url, true)}
          showResetOption
          videoSetToDefault={videoSetToDefault}
          onVideoSetToDefault={onVideoSetToDefault}
        />
      )}
    </DetailsRow>
  );
};

export const CheckBoxRow = ({
  labelProps: { label, subLabel, meta },
  ...rest
}) => {
  return (
    <DetailsRowWrapper className='d-flex justify-content-between'>
      <div className='d-flex flex-column w-75'>
        <Text weight='strong'>{label}</Text>
        {subLabel && typeof subLabel == "string" ? (
          <Text small appearance='subtle'>
            {subLabel}
          </Text>
        ) : (
          subLabel
        )}
        {meta && <div className='mt-5'>{meta}</div>}
      </div>
      <div>
        <Switch size='tiny' {...rest} />
      </div>
    </DetailsRowWrapper>
  );
};
