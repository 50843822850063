import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  AuthRouteWrapper,
  passwordChecks,
  SmallErrorText,
  PasswordCheckList,
  CardSection,
  CommonCard,
} from "./commons";
import {
  StyledText,
  PasswordInput,
  PhoneNumberInput,
} from "components/commons/atoms";
import { Label, Text } from "@innovaccer/design-system";
import Input from "@datashop/input";
import Dropdown from "@datashop/dropdown";
import { emailRegex } from "config";
import Button from "@datashop/button";
import axios, { getErrorMessage } from "helper/axios";
import authService from "services/authService";
import Icon from "@datashop/icon";
import Message from "@datashop/message";
import Loader from "@datashop/loader";
import { handleKeyPress } from "helper/form";
import { Mixpanel } from "helper/mixpanelHelper";
import { titleOptions, orgTypes } from "config";
import Checkbox from "@datashop/checkbox";
import { queryParamsToConfig } from "helper/queryParams";

const CardWrapper = styled(CommonCard)`
  width: 520px;
`;

const FormRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0;
  }

  &.tnc-wrapper {
    > *:first-child {
      margin-top: 2px;
      align-items: flex-start;
      ${props =>
        props.error &&
        `i {
        color: ${props.theme.datashop.palette.red.main};
      }`}
    }
  }
`;

const StyledLink = styled.a.attrs({ target: "_blank" })`
  color: ${props => props.theme.datashop.palette.blue.main};
`;

const FormField = styled.div`
  :not(:last-child) {
    margin-right: 16px;
  }
`;

const StyledInput = styled(Input)`
  margin: 4px 0;
  ${props => props.large && `height: 40px;`}
`;

const StyledPwdInput = styled(PasswordInput)`
  margin: 4px 0;
`;

const OrgTypeCard = styled.div`
  border-radius: 3px;
  cursor: pointer;
  width: 50%;
  padding: 24px 16px;
  border: 1px solid
    ${props =>
      props.selected
        ? props.theme.datashop.palette.blue.main
        : props.theme.datashop.palette.stone.dark};
  text-align: center;
  :not(:last-child) {
    margin-right: 16px;
  }
  > * {
    :not(:last-child) {
      margin-bottom: 6px;
    }
  }
`;

const TitleDropdown = styled(Dropdown)`
  margin: 4px 0;
  width: 80px;
`;

const SignInLink = styled(Link)`
  color: var(--primary);
  font-weight: bold;
`;

const errors = {
  firstname: val => !val,
  lastname: val => !val,
  orgName: val => !val,
  phone: val => !val || val.replace(/\D/g, "").length < 10,
  email: val => !val || !emailRegex.test(val),
  password: val =>
    (passwordChecks.find(check => !check.test(val)) || {}).errorMsg,
  tnc: val => !val,
};

class Signup extends Component {
  queryParams = queryParamsToConfig(
    this.props.location && this.props.location.search
      ? this.props.location.search
      : ""
  );
  state = {
    // details state
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    password: "",
    tnc: false,
    // orgtype State
    orgType: "",
    // org Details State
    orgName: "",
    // common state
    loading: false,
    error: false,
    viewState: 0,
    formError: {},
    exist: false,
  };

  onChange = event => {
    const {
      target: { value, name },
    } = event;
    if (name === "phone") {
      this.setState({ [name]: value.replace(/\D/g, "") });
    } else {
      this.setState({ [name]: value });
    }
  };

  setFormError = event => {
    if (event instanceof Array) {
      this.setState({
        formError: event.reduce((error, key) => {
          error[key] = errors[key](this.state[key]);
          return error;
        }, {}),
      });
    } else if (event) {
      const {
        target: { value, name },
      } = event;
      this.setState({
        formError: { ...this.state.formError, [name]: errors[name](value) },
      });
    } else {
      this.setState({
        formError: Object.keys(errors).reduce((error, key) => {
          error[key] = errors[key](this.state[key]);
          return error;
        }, {}),
      });
    }
  };

  signUpUser = payload => {
    this.setState({
      formError: {},
      error: false,
      loading: true,
    });
    axios
      .post("/innote-survey/user/signup", payload)
      .then(({ data }) => {
        authService.logIn(data);
        this.setState({
          loading: false,
        });
      })
      .catch(error => {
        this.setState({
          error: getErrorMessage(error),
          loading: false,
        });
      });
  };

  validateUserDetails = () => {
    const { email } = this.state;
    const errorFileds = [
      "firstname",
      "lastname",
      "phone",
      "email",
      "password",
      "tnc",
    ];
    if (errorFileds.some(field => errors[field](this.state[field]))) {
      this.setFormError(errorFileds);
      return;
    }

    this.setState({
      formError: {},
      loading: true,
      error: false,
    });
    axios
      .get(`/innote-survey/user/email/exists?email=${email}`)
      .then(({ data }) => {
        Mixpanel.track("Sign up form submitted", {
          category: "InOffice-Signup",
          email,
        });
        if (!data.exist) {
          this.setState({
            loading: false,
            viewState: 1,
          });
        } else if (data.exist) {
          this.setState({
            loading: false,
            error: "Hey, that email looks familiar. Want to ",
            exist: true,
          });
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: getErrorMessage(error),
        });
      });
  };

  validateOrgType = () => {
    const {
      title,
      firstname,
      lastname,
      phone,
      email,
      password,
      orgType,
    } = this.state;
    if (!orgType) {
      return;
    }

    // track practice select attempt
    Mixpanel.track("Practice Selected", {
      category: "InOffice-Signup",
      practiceType: orgType,
      email,
    });

    if (orgType == orgTypes.small) {
      const payload = {
        title,
        firstName: firstname,
        lastName: lastname,
        phoneNo: phone,
        email,
        password,
        organizationType: orgType,
      };
      this.signUpUser(payload);
    } else {
      this.setState({
        viewState: 2,
        formError: {},
        error: "",
      });
    }
  };

  createOrganization = () => {
    const {
      title,
      firstname,
      lastname,
      phone,
      email,
      password,
      orgType,
      orgName,
    } = this.state;
    if (errors.orgName(orgName)) {
      this.setFormError(["orgName"]);
      return;
    }

    // track org name attempt
    Mixpanel.track("Organisation filled", {
      category: "InOffice-Signup",
      organizationName: orgName,
      email,
    });

    const payload = {
      title,
      firstName: firstname,
      lastName: lastname,
      phoneNo: phone,
      email,
      password,
      organizationType: orgType,
      organizationName: orgName,
    };
    this.signUpUser(payload);
  };

  render() {
    const {
      title,
      phone,
      password,
      tnc,
      orgType,
      loading,
      error,
      viewState,
      formError = {},
      exist,
    } = this.state;

    const getViewState = () => {
      if (!viewState) {
        return (
          <CardWrapper
            onKeyDown={e => handleKeyPress(e, [13], this.validateUserDetails)}>
            <CardSection>
              <StyledText size={20}>Get started</StyledText>
              <StyledText fontWeight='light' light>
                We’ll use this information to setup your profile
              </StyledText>
            </CardSection>
            {error && (
              <CardSection>
                <Message appearance='alert'>
                  {error}
                  {exist && (
                    <>
                      <SignInLink to='/login'>sign in</SignInLink>
                      <Text appearance='default' size='regular'>
                        ?
                      </Text>
                    </>
                  )}
                </Message>
              </CardSection>
            )}
            <CardSection>
              <FormRow style={{ flexWrap: "wrap" }}>
                <FormField>
                  <Label>Title</Label>
                  <TitleDropdown
                    placeholder='Title'
                    onChange={([s]) => {
                      this.setState({ title: s });
                    }}
                    options={titleOptions}
                    selected={[title]}
                  />
                </FormField>
                <FormField>
                  <Label>First name</Label>
                  <StyledInput
                    style={{ width: 171 }}
                    name='firstname'
                    placeholder='John'
                    onChange={this.onChange}
                    onBlur={this.setFormError}
                    error={formError.firstname}
                  />
                  {formError.firstname && (
                    <SmallErrorText>
                      <Icon name='error' />
                      &nbsp; First name is required
                    </SmallErrorText>
                  )}
                </FormField>
                <FormField>
                  <Label>Last name</Label>
                  <StyledInput
                    style={{ width: 171 }}
                    name='lastname'
                    placeholder='Doe'
                    onChange={this.onChange}
                    onBlur={this.setFormError}
                    error={formError.lastname}
                  />
                  {formError.lastname && (
                    <SmallErrorText>
                      <Icon name='error' />
                      &nbsp; Last name is required
                    </SmallErrorText>
                  )}
                </FormField>
              </FormRow>
              <FormRow>
                <FormField>
                  <Label>Mobile</Label>
                  <PhoneNumberInput
                    style={{ marginBottom: 4 }}
                    value={phone}
                    name='phone'
                    onChange={this.onChange}
                    onBlur={this.setFormError}
                    error={formError.phone}
                  />
                  {formError.phone && (
                    <SmallErrorText>
                      <Icon name='error' />
                      &nbsp; Mobile number should have 10 digits.
                    </SmallErrorText>
                  )}
                  <StyledText size='12' light>
                    This will not be visible to patients or other users
                  </StyledText>
                </FormField>
              </FormRow>
              <FormRow style={{ display: "block" }}>
                <FormField style={{ width: "100%" }}>
                  <Label>Email</Label>
                  <StyledInput
                    placeholder='john.doe@organization.com'
                    name='email'
                    type='email'
                    onChange={this.onChange}
                    onBlur={this.setFormError}
                    error={formError.email}
                  />
                </FormField>
                {formError.email && (
                  <SmallErrorText>
                    <Icon name='error' />
                    &nbsp; Sorry, but this is not valid email!
                  </SmallErrorText>
                )}
                <StyledText size='12' light>
                  This will also be your username
                </StyledText>
              </FormRow>
              <FormRow style={{ display: "block" }}>
                <FormField style={{ width: "100%" }}>
                  <Label>Setup a password</Label>
                  <StyledPwdInput
                    id='signup-password-input-field'
                    enableVisbilityToggle={true}
                    name='password'
                    type='password'
                    onChange={e => {
                      this.onChange(e);
                      this.setFormError(e);
                    }}
                    onBlur={this.setFormError}
                    error={formError.password}
                  />
                </FormField>
                {formError.password && (
                  <SmallErrorText>
                    <Icon name='error' />
                    &nbsp;
                    {formError.password}
                  </SmallErrorText>
                )}
              </FormRow>
              <FormRow>
                <PasswordCheckList password={password} />
              </FormRow>
              <FormRow className='tnc-wrapper' error={!tnc && formError.tnc}>
                <Checkbox
                  outline
                  style={{ alignSelf: "start" }}
                  checked={tnc}
                  onChange={e => {
                    this.setState(({ tnc }) => ({
                      tnc: !tnc,
                    }));
                  }}
                />
                <StyledText fontWeight='semiBold'>
                  I agree to accept the{" "}
                  <StyledLink href='https://innovaccer.com/privacy/'>
                    Privacy Policy
                  </StyledLink>
                  ,{" "}
                  <StyledLink href='https://innovaccer.com/media/virtual-care-network/terms-of-use.pdf/?redirect=false'>
                    Terms of Use
                  </StyledLink>
                  , and{" "}
                  <StyledLink href='https://innovaccer.com/media/covidapp/baa.pdf/?redirect=false'>
                    Business Associate Agreement
                  </StyledLink>
                  .
                </StyledText>
              </FormRow>
            </CardSection>
            <Button
              expanded
              dimension='large'
              appearance='primary'
              onClick={!loading && this.validateUserDetails}>
              {loading ? <Loader /> : "Continue"}
            </Button>
          </CardWrapper>
        );
      }

      // =========================================================================
      if (viewState == 1) {
        return (
          <CardWrapper
            onKeyDown={e => handleKeyPress(e, [13], this.validateOrgType)}>
            <CardSection>
              <StyledText size={20}>
                What do you closely recognize with
              </StyledText>
              <StyledText fontWeight='light' light>
                You can always change these preferences later.
              </StyledText>
            </CardSection>
            {error && (
              <CardSection>
                <Message appearance='alert'>{error}</Message>
              </CardSection>
            )}
            <CardSection>
              <FormRow>
                <OrgTypeCard
                  selected={orgType == orgTypes.small}
                  onClick={() =>
                    this.setState({
                      orgType: orgTypes.small,
                    })
                  }>
                  <img
                    style={{ height: 136 }}
                    src={`${process.env.PUBLIC_URL}/img/orgTypeIndividual.png`}
                  />
                  <StyledText fontWeight='bold'>Individual Practice</StyledText>
                  <StyledText size='12' light>
                    Independent or 2-4 practitioners working out of a clinic
                  </StyledText>
                </OrgTypeCard>
                <OrgTypeCard
                  selected={orgType == orgTypes.large}
                  onClick={() =>
                    this.setState({
                      orgType: orgTypes.large,
                    })
                  }>
                  <img
                    style={{ height: 136 }}
                    src={`${process.env.PUBLIC_URL}/img/orgTypeNetwork.png`}
                  />
                  <StyledText fontWeight='bold'>Network</StyledText>
                  <StyledText size='12' light>
                    Network with multiple providers and locations
                  </StyledText>
                </OrgTypeCard>
              </FormRow>
            </CardSection>
            <Button
              expanded
              disabled={!orgType}
              dimension='large'
              appearance='primary'
              onClick={!loading && this.validateOrgType}>
              {loading ? <Loader /> : "Continue"}
            </Button>
          </CardWrapper>
        );
      }

      if (viewState == 2) {
        return (
          <CardWrapper
            onKeyDown={e => handleKeyPress(e, [13], this.createOrganization)}>
            <CardSection>
              <StyledText size={20}>Tell us about your network</StyledText>
            </CardSection>
            {error && (
              <CardSection>
                <Message appearance='alert'>{error}</Message>
              </CardSection>
            )}
            <CardSection>
              <Label>Name of Network</Label>
              <StyledInput
                large
                name='orgName'
                onChange={this.onChange}
                onBlur={this.setFormError}
                error={formError.orgName}
                placeholder=' e.g., American Health Inc.'
              />
              {formError.orgName && (
                <SmallErrorText>
                  <Icon name='error' />
                  &nbsp; Please enter a valid network name
                </SmallErrorText>
              )}
            </CardSection>
            <Button
              expanded
              dimension='large'
              appearance='primary'
              onClick={!loading && this.createOrganization}>
              {loading ? <Loader /> : "Continue"}
            </Button>
          </CardWrapper>
        );
      }
    };

    return <AuthRouteWrapper logoOnly>{getViewState()}</AuthRouteWrapper>;
  }

  componentDidMount() {
    Mixpanel.track("Get Started", {
      category: "InOffice-Marketing",
      ...{
        email: this.queryParams.em || this.queryParams.email,
        source: this.queryParams.utm_source || this.queryParams.source,
      },
    });
  }
}

export default Signup;
