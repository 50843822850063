import React, { useState, useCallback, useEffect } from "react";
import { RoutesWrapper, SubRoutesWrapper, CardsWrapper } from "../commons";
import {
  PageHeader,
  Button,
  Text,
  Breadcrumbs,
} from "@innovaccer/design-system";
import {
  NetworkCard,
  Name,
  Instructions,
  DetailsRow,
  WelcomeMessage,
  Address,
  Phone,
  CheckBoxRow,
  ErrorState,
} from "../network/atoms";
import { getNetworkInfo, createPractice } from "services/orgService";
import AlertService, { defaultDissmissDelay } from "services/alertService";
import PageLoader from "components/commons/PageLoader";
import ConfirmService from "services/confirmService";
import AuthService from "../../../services/authService";

const DetailsCard = ({ details, onChange }) => {
  const editable = true;
  const { name, address, phoneNo } = details;

  const isPhoneInvalid = phoneNo && (phoneNo || "").length < 10;

  return (
    <NetworkCard
      title='Details'
      subtitle='Manage essential information related to your practice.'>
      <Name
        placeholder='Practice name'
        editable={editable}
        value={name}
        onChange={e => onChange({ name: e.target.value })}
      />
      <Phone
        editable={editable}
        error={isPhoneInvalid}
        value={phoneNo}
        onBlur={() => isPhoneInvalid && onChange({ phoneNo: "" })}
        onChange={(e, phoneNo) => {
          onChange({ phoneNo });
        }}
      />
      <Address
        placeholder='402 E Holt Blvd, CA 91761 United States '
        editable={editable}
        value={address}
        onChange={e => onChange({ address: e.target.value })}
      />
    </NetworkCard>
  );
};

const WebLinksCard = ({ networkDetails }) => {
  const { subDomain } = networkDetails;

  return (
    <NetworkCard
      title='Practice web links'
      subtitle='Weblinks which will be used by your teammates and patients.'>
      <DetailsRow
        label='Front door web address'
        subLabel='This is your clinic’s web address which can be embedded to your website or can be used directly to let your patients schedule appointments.'>
        {subDomain ? (
          <Text>Will be generated basis the practice name.</Text>
        ) : (
          <Text weight='medium' appearance='destructive'>
            Set subdomain first to enable practice level front door web address
          </Text>
        )}
      </DetailsRow>
    </NetworkCard>
  );
};

const PresenceCard = ({ networkDetails, details, onChange }) => {
  const {
    instructionVideoUrl: networkInstructionVideoUrl,
    examRoomLogoUrl: networkExamRoomLogoUrl,
    welcomeMessage: networkWelcomeMessage,
    instructionVideo: networkInstructionVideo,
  } = networkDetails;
  const {
    instructionVideo,
    welcomeMessage,
    overrideNetworkPresence,
    instructionVideoUrl,
    examRoomLogoUrl,
  } = details;

  const editable = overrideNetworkPresence;

  useEffect(() => {
    onChange({
      welcomeMessage: null,
      instructionVideo: null,
      examRoomLogoUrl: null,
      instructionVideoUrl: null,
    });
  }, [editable]);

  return (
    <NetworkCard
      title='Waiting room presence'
      subtitle='Create a personalized experience for your clinic’s waiting room.'>
      <CheckBoxRow
        labelProps={{
          label: "Override network presence settings",
          subLabel:
            "This will allow you to put a separate logo, message and instructions for this practice.",
        }}
        checked={editable}
        onChange={(___, selected) =>
          onChange({ overrideNetworkPresence: selected })
        }
      />
      <WelcomeMessage
        editable={editable}
        value={editable ? welcomeMessage || "" : networkWelcomeMessage}
        onChange={e => onChange({ welcomeMessage: e.target.value })}
      />
      <Instructions
        editable={editable}
        isVideo={editable ? !!instructionVideo : !!networkInstructionVideo}
        defaultImageUrl={networkExamRoomLogoUrl}
        file={examRoomLogoUrl}
        onChange={(content, video) => {
          onChange(
            video
              ? {
                  instructionVideoUrl: content,
                  instructionVideo: !!(content && true),
                }
              : {
                  examRoomLogoUrl: content,
                  instructionVideo: content ? false : !!instructionVideoUrl,
                }
          );
        }}
        defaultVideoUrl={
          instructionVideoUrl || networkInstructionVideoUrl
        }></Instructions>
    </NetworkCard>
  );
};

const CreatePractice = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(false);
  const [networkDetails, setNetworkDetails] = useState({});
  const [details, setDetails] = useState({});
  const currentUser = AuthService.getUser();

  const onDetailsChange = useCallback((patch = {}) => {
    setDetails({ ...details, ...patch });
  });

  const fetchNetworkDetails = useCallback(() => {
    setLoading(true);
    setError(false);
    getNetworkInfo()
      .then(({ data }) => {
        setNetworkDetails(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  const saveDetails = useCallback(payload => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (![null, undefined].includes(value)) {
        formData.append(key, value);
      }
    });

    ConfirmService.confirm({
      dimension: "small",
      heading: "Add practice?",
      description: `You’re adding a practice named ${payload.name}. The clinic subdomain will be generated based on that.`,
      confirmationText: "Add practice",
    })
      .then(modal => {
        modal.setLoading(true);
        setUpdating(true);
        return createPractice(formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(({ data }) => {
            setUpdating(false);
            AlertService.showAlert({
              appearance: "success",
              title: "Practice Created",
              autoClearAfter: defaultDissmissDelay,
            });

            history.push(`/settings/practices/${data.id || ""}`);
          })
          .catch(() => {
            setUpdating(false);
            AlertService.showAlert({
              appearance: "alert",
              message: "Failed to create practice",
              autoClearAfter: defaultDissmissDelay,
            });
          })
          .finally(() => {
            modal.setLoading(false);
            modal.close();
          });
      })
      .catch(modal => {
        modal.close();
      });
  }, []);

  useEffect(() => {
    fetchNetworkDetails();
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <ErrorState onRetry={fetchNetworkDetails} />;
  }

  return (
    <RoutesWrapper>
      <PageHeader
        breadcrumbs={
          <Breadcrumbs
            list={[
              {
                label: "Practices",
                link: "/settings/practices",
              },
            ]}
            onClick={link => history.push(link)}
          />
        }
        title='Add practice'
        actions={
          <div className='d-flex justify-content-end'>
            <Button
              disabled={!details.name}
              onClick={() => saveDetails(details)}
              appearance='primary'>
              Add practice
            </Button>
          </div>
        }
      />
      <SubRoutesWrapper overflow='auto'>
        <CardsWrapper disabled={updating}>
          <DetailsCard
            networkDetails={networkDetails}
            details={details}
            onChange={onDetailsChange}
          />
          {currentUser.organization_category !== "INDIVIDUAL" && (
            <WebLinksCard
              networkDetails={networkDetails}
              details={details}
              onChange={onDetailsChange}
            />
          )}
          {currentUser.organization_category !== "INDIVIDUAL" && (
            <PresenceCard
              networkDetails={networkDetails}
              details={details}
              onChange={onDetailsChange}
            />
          )}
        </CardsWrapper>
      </SubRoutesWrapper>
    </RoutesWrapper>
  );
};

export default CreatePractice;
