import React from "react";
import {
  Input,
  Dropdown,
  Button,
  Checkbox,
  PlaceholderParagraph,
  Placeholder,
  Label,
  Chip
} from "@innovaccer/design-system";
import { getLabel } from "components/commons/RangePicker";

const getOptionsWithLabel = (options = [], selected = []) =>
  options.filter(o => selected.includes(o.value));

const AppliedFilters = ({ filters, filterOptions, onChange }) => {
  const { startDate, endDate } = filters;
  const {
    providers = [],
    ageGroup = [],
    callType = [],
    paymentStatus = [],
    visitStatus = []
  } = filterOptions;

  const selectedProviders = filters.providers
    ? getOptionsWithLabel(providers, filters.providers)
    : [];
  const selectedAgeGroup = filters.ageGroup
    ? getOptionsWithLabel(ageGroup, filters.ageGroup)
    : [];
  const selectedCallType = filters.callType
    ? getOptionsWithLabel(callType, filters.callType)
    : [];
  const selectedPaymentStatus = filters.paymentStatus
    ? getOptionsWithLabel(paymentStatus, filters.paymentStatus)
    : [];
  const selectedVisitStatus = filters.visitStatus
    ? getOptionsWithLabel(visitStatus, filters.visitStatus)
    : [];

  return (
    <>
      {(startDate || endDate) && (
        <Chip
          key={`date`}
          className='mr-4 mb-6'
          clearButton
          onClose={() => {
            const { startDate, endDate, ...rest } = filters;
            onChange(rest, true);
          }}
          type='selection'
          selected
          label={getLabel(startDate, endDate)}
        />
      )}
      {!!selectedProviders.length && (
        <Chip
          key={`provider`}
          className='mr-4 mb-6'
          clearButton
          onClose={() => {
            const { providers, ...rest } = filters;
            onChange(rest, true);
          }}
          type='selection'
          selected
          label={`${selectedProviders[0].label}${
            selectedProviders.length > 1
              ? ` and ${selectedProviders.slice(1).length} other`
              : ""
          }`}
        />
      )}
      {selectedAgeGroup.map((age, i) => (
        <Chip
          key={`age-${i}`}
          className='mr-4 mb-6'
          clearButton
          onClose={() => {
            const { ageGroup } = filters;
            onChange({ ageGroup: ageGroup.filter(g => g != age.value) });
          }}
          type='selection'
          selected
          label={`${age.label} yrs`}
        />
      ))}
      {selectedCallType.map((type, i) => (
        <Chip
          key={`callType-${i}`}
          className='mr-4 mb-6'
          clearButton
          onClose={() => {
            const { callType } = filters;
            onChange({ callType: callType.filter(g => g != type.value) });
          }}
          type='selection'
          selected
          label={type.label}
        />
      ))}
      {selectedPaymentStatus.map((status, i) => (
        <Chip
          key={`paymentStatus-${i}`}
          className='mr-4 mb-6'
          clearButton
          onClose={() => {
            const { paymentStatus } = filters;
            onChange({
              paymentStatus: paymentStatus.filter(g => g != status.value)
            });
          }}
          type='selection'
          selected
          label={status.label}
        />
      ))}
      {selectedVisitStatus.map((status, i) => (
        <Chip
          key={`visitStatus-${i}`}
          className='mr-4 mb-6'
          clearButton
          onClose={() => {
            const { visitStatus } = filters;
            onChange({
              visitStatus: visitStatus.filter(g => g != status.value)
            });
          }}
          type='selection'
          selected
          label={status.label}
        />
      ))}
    </>
  );
};

const Header = props => {
  const {
    loading,
    error,
    data,
    schema,
    showHead,
    withPagination,
    withCheckbox,
    updateSchema,
    totalRecords = 0,
    onSelectAll,
    searchPlaceholder,
    selectAll,
    searchTerm,
    updateSearchTerm,
    allowSelectAll,
    filters = {},
    filterOptions = {},
    updateFilters
  } = props;

  const [selectAllRecords, setSelectAllRecords] = React.useState(false);
  const [flag, setFlag] = React.useState(true);

  React.useEffect(() => {
    setFlag(!flag);
  }, [schema]);

  React.useEffect(() => {
    if (selectAll && selectAll.checked) {
      if (onSelectAll) onSelectAll(true, selectAllRecords);
    }
  }, [selectAllRecords]);

  React.useEffect(() => {
    if (selectAll && !selectAll.checked) setSelectAllRecords(false);
  }, [selectAll]);

  const onSearchChange = e => {
    const value = e.target.value;
    if (updateSearchTerm) {
      updateSearchTerm(value);
    }
  };

  const onHideColumn = selected => {
    const newSchema = schema.map(s => ({
      ...s,
      hidden: selected.findIndex(val => val === s.name) === -1
    }));

    if (updateSchema) updateSchema(newSchema);
  };

  const pinnedSchema = schema ? schema.filter(s => s.pinned) : [];
  const leftPinnedSchema = pinnedSchema
    ? pinnedSchema.filter(s => s.pinned === "left")
    : [];
  const rightPinnedSchema = pinnedSchema
    ? pinnedSchema.filter(s => s.pinned === "right")
    : [];
  const unpinnedSchema = schema ? schema.filter(s => !s.pinned) : [];
  const renderedSchema = [
    ...leftPinnedSchema,
    ...unpinnedSchema,
    ...rightPinnedSchema
  ];

  const columnOptions =
    renderedSchema &&
    renderedSchema.map(s => ({
      label: s.displayName,
      value: s.name,
      selected: !s.hidden
    }));

  const selectedCount =
    data && data.length > 0 && data.filter(d => d._selected).length;
  const label =
    withCheckbox && selectedCount
      ? selectAllRecords
        ? `Selected all ${totalRecords} items`
        : `Selected ${selectedCount} items on this page`
      : `Showing ${!error ? totalRecords : 0} items`;

  return (
    <div className='Header'>
      <div className='Header-content Header-content--top'>
        <div className='Header-search'>
          <Input
            name='GridHeader-search'
            icon='search'
            placeholder={searchPlaceholder}
            onChange={onSearchChange}
            value={searchTerm}
            onClear={() => updateSearchTerm && updateSearchTerm("")}
            disabled={loading && !(schema && schema.length)}
          />
        </div>
        <div className='Header-dropdown'></div>
        <div className='Header-actions'></div>
      </div>
      <div className='d-flex w-100 flex-wrap'>
        <AppliedFilters
          filters={filters || {}}
          filterOptions={filterOptions || {}}
          onChange={updateFilters}
        />
      </div>
      <div className='Header-content Header-content--bottom'>
        <div className='Header-label'>
          {showHead && !loading && (
            <Checkbox
              {...selectAll}
              onChange={event => {
                if (onSelectAll) onSelectAll(event.target.checked);
              }}
            />
          )}
          {loading ? (
            <Placeholder withImage={!showHead && withCheckbox}>
              <PlaceholderParagraph length={"small"} size={"s"} />
            </Placeholder>
          ) : (
            <>
              <Label>{label}</Label>
              {withPagination && allowSelectAll && selectAll.checked && (
                <div className='ml-4'>
                  {!selectAllRecords ? (
                    <Button
                      size='tiny'
                      onClick={() => setSelectAllRecords(true)}>
                      {`Select all ${totalRecords} items`}
                    </Button>
                  ) : (
                    <Button
                      size='tiny'
                      onClick={() => setSelectAllRecords(false)}>
                      Clear Selection
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        {/* <div className='Header-hideColumns'>
          <Dropdown
            key={`${flag}`}
            triggerSize={"tiny"}
            withCheckbox={true}
            showApplyButton={true}
            options={columnOptions}
            totalOptions={columnOptions.length}
            align={"left"}
            triggerOptions={{
              labelLimit: 0,
              customLabel: (selected, totalOptions) =>
                `Showing ${selected} of ${totalOptions} columns`,
              customTrigger: triggerLabel => (
                <Button
                  size='tiny'
                  appearance='transparent'
                  icon='keyboard_arrow_down_filled'
                  iconAlign='right'>
                  {triggerLabel
                    ? triggerLabel
                    : `Showing 0 of ${columnOptions.length} columns`}
                </Button>
              )
            }}
            onChange={selected => onHideColumn(selected)}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Header;
