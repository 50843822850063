import React, { Component } from "react";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
import authService from "services/authService";
import { queryParamsToConfig } from "helper/queryParams";
import axios from "helper/axios";
import PageLoader from "./commons/PageLoader";
import AlertService from "services/alertService";
import { Mixpanel } from "helper/mixpanelHelper";

class Main extends Component {
  queryParams = queryParamsToConfig(this.props.location.search || "");

  state = {
    loading: !!(this.queryParams && this.queryParams.OTT),
    surveyStarted: false,
    showLogin: !authService.isAuthenticated(),
  };

  logIn = () => {
    this.setState(
      {
        showLogin: !authService.isAuthenticated(),
      },
      () => {
        this.props.history.push(`/`);
      }
    );
  };

  logOut = () => {
    this.setState({ showLogin: true }, () => {
      this.props.history.push(`/`);
    });
  };

  signInUsingOTT = OTT => {
    this.setState({
      loading: true,
    });
    axios
      .post("/innote-survey/user/auth/_validate", {
        token: OTT,
        loginType: "OTT_ONLY",
      })
      .then(async ({ data }) => {
        if (authService.isAuthenticated()) {
          try {
            await authService.logOut();
          } catch (e) {
            AlertService.showAlert({
              title: `Already llogged in as ${authService.getUserName()}`,
              message: "Could't switch user. try loging out first.",
            });
          }
        }
        authService.logIn(data);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const { loading, showLogin } = this.state;
    if (loading) {
      return <PageLoader />;
    }

    return showLogin ? <PublicRoutes /> : <ProtectedRoutes {...this.props} />;
  }

  componentDidMount() {
    window.addEventListener("login-user", this.logIn);
    window.addEventListener("logout-user", this.logOut);

    if (this.queryParams && this.queryParams.OTT) {
      // track signup
      const {
        source = "",
        email = "",
        n = "",
        hsf = "",
        vlm = "",
        vpc = "",
      } = this.queryParams;
      try {
        Mixpanel.track("Signin", {
          category: "InOffice-OTT-Signin-Request",
          source: source,
          signin_date: new Date().toISOString(),
          email: email,
          n: n,
          hsf: hsf,
          vlm: vlm,
          vpc: vpc,
        });
      } catch (error) {
        console.log("unable to set mixpanel user ott signin request", error);
      }

      this.signInUsingOTT(this.queryParams.OTT);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("login-user", this.logIn);
    window.removeEventListener("logout-user", this.logOut);
  }
}

export default Main;
