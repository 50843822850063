import React, { PureComponent } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Column,
  Label,
  Input,
  InputMask,
  Dropdown,
  Icon,
  Link,
  Caption,
} from "@innovaccer/design-system";
import styled from "styled-components";
import { phoneNumberMask } from "config";
import { phoneNumberPlaceholder } from "config";
import { titleOptions } from "config";
import { adminOptions } from "./templates";
import { debounce, noop } from "lodash";
import { emailRegex } from "config";
import { sendUserInvites } from "services/userService";
import AlertService, { defaultDissmissDelay } from "services/alertService";
import { Mixpanel } from "helper/mixpanelHelper";
import { getErrorMessage } from "../../../helper/axios";
import "./style.css";

const StyledModal = styled(Modal)`
  width: 100% !important;
  max-width: 95% !important;
  .Col {
    .Row {
      .Col {
        padding-right: 8px;
        .Input,
        button {
          max-width: 100%;
          min-width: 100% !important;
        }
      }
    }
  }
  .add-button i {
    color: var(--link-color);
  }
`;

const getNewDefaultRow = () => ({
  id: Date.now(),
  error: false,
  isAdmin: false,
});

const errors = {
  email: val => (!val || !emailRegex.test(val)) && "Please enter a valid email",
  phoneNumber: val =>
    (!val || val.replace(/\D/g, "").length < 10) &&
    "Phone Number should be at least 10 digits long",
  title: val => false,
  firstName: val => !val && "First name is required",
  lastName: val => !val && "Last name is required",
  accessRole: val => !val && "Please select a role",
  practices: val => (!val || !val.length) && "Please select practices",
  isAdmin: val =>
    typeof val === "undefined" && "Please select admin permission",
  npi: val =>
    val &&
    val.replace(/\D/g, "").length !== 10 &&
    "Please enter a 10 digits NPI",
};

class InviteModal extends PureComponent {
  state = {
    sendingInvites: false,
    list: [
      {
        id: "1",
        error: false,
        isAdmin: false,
      },
    ],
  };

  addRow = () =>
    this.setState({
      list: [...this.state.list, getNewDefaultRow()],
    });

  deleteRow = index => {
    this.setState({
      list: [
        ...this.state.list.slice(0, index),
        ...this.state.list.slice(index + 1),
      ],
    });
  };

  setValue = (rowIndex, patch, cb = noop) => {
    this.setState(({ list }) => {
      const newList = [...list];
      const newRow = { ...newList[rowIndex], ...patch };
      newList[rowIndex] = newRow;
      return { list: newList };
    }, cb);
  };

  isRowValid = rowIndex => {
    const row = this.state.list[rowIndex];
    let error = false;
    Object.entries(errors).some(([key, test]) => {
      error = test(row[key]);
      return error;
    });

    this.setValue(rowIndex, { error });
    return !error;
  };

  onSubmit = () => {
    const { list } = this.state;
    let formValid = true;
    list.forEach((__, i) => {
      formValid = this.isRowValid(i) && formValid;
    });
    if (formValid) {
      this.setState({
        sendingInvites: true,
      });
      sendUserInvites(list.map(({ id, error, ...rest }) => rest))
        .then(({ data: { errors } }) => {
          if (errors && errors.length) {
            list.forEach(({ email, npi }, rowIndex) => {
              const error = errors.find(err => err.email == email);
              if (error) {
                this.setValue(rowIndex, { error: !!error && error.reason });
              } else {
                const npiError = errors.find(err => err.npi == npi);
                this.setValue(rowIndex, {
                  error: !!npiError && npiError.reason,
                });
              }
            });
            return;
          }
          this.props.onClose();
          AlertService.showAlert({
            appearance: "success",
            title: "Invite sent",
            autoClearAfter: defaultDissmissDelay,
          });
        })
        .catch(err => {
          AlertService.showAlert({
            appearance: "alert",
            message: getErrorMessage(err, "Failed to send invite"),
            autoClearAfter: defaultDissmissDelay,
          });
        })
        .finally(() => {
          this.setState({
            sendingInvites: false,
          });
        });

      Mixpanel.track("Send Invite clicked", {
        category: "InOffice-Settings",
      });
    }
  };

  onChange = debounce((rowIndex, event) => {
    const {
      target: { value, name },
    } = event;
    if (name === "phoneNumber") {
      this.setValue(rowIndex, { [name]: value.replace(/\D/g, "") });
    } else {
      this.setValue(rowIndex, { [name]: value });
    }
  }, 300);

  render() {
    const {
      onClose,
      roles = [],
      practices = [],
      optionsLoading,
      sendingInvites,
    } = this.props;
    const { list } = this.state;

    return (
      <StyledModal open={true} className='invite'>
        <ModalHeader heading='Invite your teammates' onClose={onClose} />
        <div className='divider' />
        <ModalBody className='py-6'>
          {list.map((row, rowIndex) => {
            return (
              <>
                <Row key={row.id}>
                  <Column size={3}>
                    <Row>
                      <Column size='6'>
                        <Label withInput>Email</Label>
                        <Input
                          defaultValue={row.email}
                          type='email'
                          name='email'
                          placeholder='john@doe.com'
                          onChange={e =>
                            e.persist() || this.onChange(rowIndex, e)
                          }
                        />
                      </Column>
                      <Column size='6'>
                        <Label withInput>Phone number</Label>
                        <InputMask
                          value={row.phoneNumber} // perf: should go as default vallue, but not supported during implementation
                          mask={phoneNumberMask}
                          name='phoneNumber'
                          placeholder={phoneNumberPlaceholder}
                          onChange={e =>
                            e.persist() || this.onChange(rowIndex, e)
                          }
                        />
                      </Column>
                    </Row>
                  </Column>
                  <Column size={4}>
                    <Row>
                      <Column size='3'>
                        <Label withInput>Title</Label>
                        <Dropdown
                          onChange={title => this.setValue(rowIndex, { title })}
                          options={titleOptions.map(option => ({
                            ...option,
                            selected: option.value == row.title,
                          }))}></Dropdown>
                      </Column>
                      <Column size='5'>
                        <Label withInput>First name</Label>
                        <Input
                          defaultValue={row.firstName}
                          name='firstName'
                          placeholder='John'
                          onChange={e =>
                            e.persist() || this.onChange(rowIndex, e)
                          }
                        />
                      </Column>
                      <Column size='4'>
                        <Label withInput>Last name</Label>
                        <Input
                          defaultValue={row.lastName}
                          name='lastName'
                          placeholder='Doe'
                          onChange={e =>
                            e.persist() || this.onChange(rowIndex, e)
                          }
                        />
                      </Column>
                    </Row>
                  </Column>
                  <Column size={5}>
                    <Row>
                      <Column size='3'>
                        <Label withInput>Role</Label>
                        <Dropdown
                          loading={optionsLoading}
                          onChange={role => {
                            if (role !== "PROVIDER") {
                              this.setValue(rowIndex, {
                                npi: undefined,
                                id: Date.now(), // perf: re-rendering row to update selected
                              });
                            }

                            if (role == "PRACTICE_MANAGER") {
                              this.setValue(rowIndex, {
                                practices: practices.map(({ value }) => value),
                                id: Date.now(), // perf: re-rendering row to update selected
                                accessRole: role,
                              });
                            } else {
                              this.setValue(rowIndex, { accessRole: role });
                            }
                          }}
                          options={roles.map(option => ({
                            ...option,
                            selected: option.value == row.accessRole,
                          }))}></Dropdown>
                      </Column>
                      <Column size='3'>
                        <Label withInput>NPI</Label>
                        <Input
                          defaultValue={row.npi}
                          name='npi'
                          type='number'
                          placeholder='Eg. 1346336807'
                          disabled={row.accessRole !== "PROVIDER"}
                          onChange={e =>
                            e.persist() || this.onChange(rowIndex, e)
                          }
                        />
                      </Column>
                      <Column size='3'>
                        <Label withInput>Practice</Label>
                        <Dropdown
                          disabled={row.accessRole == "PRACTICE_MANAGER"}
                          loading={optionsLoading}
                          onChange={practices =>
                            this.setValue(rowIndex, { practices })
                          }
                          withCheckbox
                          showApplyButton
                          options={practices.map(option => ({
                            ...option,
                            selected:
                              row.practices &&
                              row.practices.includes(option.value),
                          }))}></Dropdown>
                      </Column>
                      <Column size='2'>
                        <Label withInput>Admin</Label>
                        <Dropdown
                          onChange={admin =>
                            this.setValue(rowIndex, { isAdmin: admin })
                          }
                          options={adminOptions.map(option => ({
                            ...option,
                            selected: option.value == row.isAdmin,
                          }))}></Dropdown>
                      </Column>
                      <Column className='d-flex align-items-end' size='1'>
                        {list.length > 1 && (
                          <div
                            className='d-flex align-items-center'
                            style={{ height: 32, cursor: "pointer" }}
                            onClick={() => this.deleteRow(rowIndex)}>
                            <Icon size={20} name='close' />
                          </div>
                        )}
                      </Column>
                    </Row>
                  </Column>
                </Row>
                {row.error && (
                  <div style={{ marginBottom: 12 }}>
                    <Caption error>{row.error}</Caption>
                  </div>
                )}
              </>
            );
          })}
          <Row className='mt-6'>
            <Link
              onClick={this.addRow}
              className='d-flex add-button'
              href='javascript:void 0'>
              <Icon name='add_circle_outline' />
              &nbsp; Add another
            </Link>
          </Row>
        </ModalBody>
        <div className='divider' />
        <ModalFooter>
          <Button
            disabled={sendingInvites}
            appearance='primary'
            onClick={this.onSubmit}>
            Send invite
          </Button>
        </ModalFooter>
      </StyledModal>
    );
  }
}

export default InviteModal;
