import React, { PureComponent } from "react";
import createReactClass from "create-react-class";
import Text from "@datashop/text";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Cell,
  ResponsiveContainer,
  LabelList,
  Label,
  ReferenceLine,
} from "recharts";

import axios from "helper/axios";
import {
  DivWrapper,
  WidgetCard,
  WidgetLoader,
  WidgetError,
  Header,
  LegendLabel,
  transformFilters,
} from ".";

const CustomizedAxisTick = createReactClass({
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={10}
          // dx={40}
          textAnchor='middle'
          fill='#666'
          transform='rotate(0)'>
          {/*{payload.value}*/}
          {this._reactInternalFiber.stateNode.props.visibleTicksCount - 1 ==
          this._reactInternalFiber.stateNode.props.index
            ? "> " + this._reactInternalFiber.stateNode.props.index * 5
            : this._reactInternalFiber.stateNode.props.index * 5 +
              "-" +
              (this._reactInternalFiber.stateNode.props.index + 1) * 5}
        </text>
      </g>
    );
  },
});

export const getData = ({ filters }) => {
  return axios.get("/insights/graph/call-durations", {
    params: transformFilters(filters),
  });
};

export const getXLSXData = ({ filters }) => {
  return getData({ filters }).then(
    ({
      data: {
        data = [],
        stats: {
          totalCallsCompleted,
          averageCallDurationDisplay,
          maxCallDurationDisplay,
        },
      },
    }) => {
      const schema = [
        { displayText: "Duration", key: "label" },
        {
          displayText: "Number of calls",
          key: "callCount",
          emptyValue: 0,
        },
      ];
      return {
        data: [
          ...data,
          { label: "Total", callCount: totalCallsCompleted },
          {
            label: "Average Duration",
            callCount: averageCallDurationDisplay,
          },
          { label: "Maximum Duration", callCount: maxCallDurationDisplay },
        ],
        schema,
        name: "Call Summary",
      };
    }
  );
};

class CallSummary extends PureComponent {
  state = {
    callData: [],
    callStats: false,
    callsDataLoading: false,
    callDataError: false,
  };

  getCallsData = (filters = {}) => {
    this.setState(
      {
        callsDataLoading: true,
        callDataError: false,
      },
      () => {
        getData({ filters })
          .then(data => {
            let result = data.data;
            let callData = result.data;
            let callStats = result.stats;
            this.setState({
              callData,
              callStats,
              callsDataLoading: false,
            });
          })
          .catch(error => {
            this.setState({
              callsDataLoading: false,
              callDataError: error,
            });
          });
      }
    );
  };

  renderCallSummary = (avg, max) => {
    return (
      <DivWrapper>
        <div style={{ padding: "0 0 8px 0", marginRight: 16 }}>
          <DivWrapper>
            <LegendLabel
              color='#F58865'
              height='14px'
              width='14px'
              margin='0px 8px 0 0'
            />
            <DivWrapper
              style={{ flexDirection: "column", justifyContent: "flex-start" }}>
              <Text>Average Call Duration</Text>
              <Text>{avg}</Text>
            </DivWrapper>
          </DivWrapper>
        </div>
        <div style={{ padding: "0 0 8px 0" }}>
          <DivWrapper>
            <LegendLabel
              color='#7A53B2'
              height='14px'
              width='14px'
              margin='0px 8px 0 0'
            />
            <DivWrapper
              style={{ flexDirection: "column", justifyContent: "flex-start" }}>
              <Text>Maximum Duration</Text>
              <Text>{max}</Text>
            </DivWrapper>
          </DivWrapper>
        </div>
      </DivWrapper>
    );
  };

  render() {
    const { callsDataLoading, callData, callDataError, callStats } = this.state;

    return (
      <WidgetCard>
        {callsDataLoading ? (
          <WidgetLoader />
        ) : callDataError ? (
          <WidgetError onRetry={() => this.getCallsData(this.props.filters)} />
        ) : (
          <>
            <div className='d-flex w-100 justify-content-between mb-5'>
              <div className='d-flex flex-column'>
                <Header style={{ fontSize: "20px" }}>
                  {`${callStats && callStats.totalCallDuration} mins spent in ${
                    callStats && callStats.totalCallsCompleted
                  } visits`}
                </Header>
                <Text
                  style={{
                    fontSize: "14px",
                    color: "#868686",
                  }}>{`for ${
                  callStats && callStats.totalPatientAttended
                } patients on virtual visits`}</Text>
              </div>
              <div>
                {callStats &&
                  this.renderCallSummary(
                    callStats.averageCallDurationDisplay,
                    callStats.maxCallDurationDisplay
                  )}
              </div>
            </div>
            <DivWrapper style={{ width: "100%", height: "100%" }}>
              <ResponsiveContainer>
                <BarChart
                  data={callData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 30 }}>
                  <CartesianGrid
                    tickMargin={0}
                    horizontal={false}
                    vertical={false}
                    // width={150}
                  />
                  <XAxis
                    dataKey='value'
                    // padding={{ left: 20 }}
                    tickLine={false}
                    minTickGap={0}
                    position='right'
                    tickCount={callData.length}
                    tick={<CustomizedAxisTick />}
                    type='number'>
                    <Label
                      value='Call duration interval(minutes)'
                      offset={16}
                      position='bottom'
                      fontWeight={700}
                    />
                  </XAxis>
                  <YAxis
                    allowDecimals={false}
                    dataKey='callCount'
                    dx={-30}
                    label={{
                      value: "No of calls",
                      angle: -90,
                      position: "center",
                      fontWeight: 700,
                      dx: -30,
                    }}
                    tickLine={false}
                    axisLine={false}
                    // type='number'
                  />

                  <Bar
                    radius={3}
                    dataKey='callCount'
                    position='right'
                    // barGap={1}
                    barSize={60}
                    fill='var(--primary-light)'>
                    {callData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill='var(--primary-lightest)'
                        stroke='var(--primary)'
                        strokeWidth={1}
                      />
                    ))}
                    <LabelList dataKey='callCount' position='top' />
                  </Bar>

                  {callStats && (
                    <ReferenceLine
                      x={callStats.maxCallDuration - 2.5}
                      ifOverflow={"visible"}
                      stroke='var(--accent2)'
                      label={{
                        value: callStats.maxCallDurationDisplay,
                        offset: -1,
                        dy: -10,
                        position: "right",
                      }}
                    />
                  )}
                  {callStats && (
                    <ReferenceLine
                      x={callStats.averageCallDuration - 2.5}
                      ifOverflow={"visible"}
                      stroke='var(--accent1)'
                      label={{
                        value: callStats.averageCallDurationDisplay,
                        offset: -1,
                        dy: 10,
                        position: "right",
                      }}
                    />
                  )}
                </BarChart>
              </ResponsiveContainer>
            </DivWrapper>
          </>
        )}
      </WidgetCard>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.filters != prevProps.filters) {
      this.getCallsData(this.props.filters);
    }
  }

  componentDidMount() {
    this.getCallsData(this.props.filters);
  }
}

export default CallSummary;
