import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Text from "@datashop/text";
import Input from "@datashop/input";
import Message from "@datashop/message";
import { Label } from "@innovaccer/design-system";
import Button from "@datashop/button";
import Icon from "@datashop/icon";
import If from "components/commons/If";
import authService from "services/authService";
import axios, { getErrorMessage } from "helper/axios";
import { emailRegex, logInStrategyMap } from "config";
import OTPForm from "components/commons/OTPForm";
import {
  AuthRouteWrapper,
  SmallErrorText,
  CardSection,
  PasswordCheckList,
  passwordChecks
} from "./commons";
import Loader from "@datashop/loader";
import { handleKeyPress } from "helper/form";
import { PasswordInput, StyledText } from "components/commons/atoms";
import { queryParamsToConfig } from "helper/queryParams";
import AlertService, { defaultDissmissDelay } from "services/alertService";
import PageLoader from "components/commons/PageLoader";

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.datashop.palette.white.main};
`;

const LoginImage = styled.img`
  @media (max-width: 800px) {
    display: none;
  }
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
`;

const FormWrapper = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

const Form = styled.div`
  width: 400px;
  max-width: 100%;
`;

const StylePwdInput = styled(PasswordInput)`
  height: 40px;
  margin: 4px 0;
`;

const errors = {
  password: val =>
    (passwordChecks.find(check => !check.test(val)) || {}).errorMsg
};

class Onboarding extends Component {
  state = {
    password: "",
    loading: false,
    error: false,
    formError: {},
    loadingInvite: true
  };

  inviteDetails = queryParamsToConfig(
    this.props.location && this.props.location.search
      ? this.props.location.search
      : ""
  );

  onChange = event => {
    const {
      target: { value, name }
    } = event;
    this.setState({ [name]: value });
  };

  setFormError = event => {
    if (event) {
      const {
        target: { value, name }
      } = event;
      this.setState({
        formError: { ...this.state.formError, [name]: errors[name](value) }
      });
    } else {
      this.setState({
        formError: Object.keys(errors).reduce((error, key) => {
          error[key] = errors[key](this.state[key]);
          return error;
        }, {})
      });
    }
  };

  activateUser = () => {
    const { password } = this.state;
    const { invite_id } = this.inviteDetails;

    this.setState({
      formError: {},
      loading: true,
      error: false
    });
    axios
      .post(`/innote-survey/user/invite/${invite_id}/activate`, {
        password
      })
      .then(({ data }) => {
        authService.logIn(data);
        this.setState({
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: getErrorMessage(error)
        });
      });
  };

  validateInvite = () => {
    const { invite_id } = this.inviteDetails;

    this.setState({
      loadingInvite: true
    });
    axios
      .get(`/innote-survey/user/invite/${invite_id}/validate`)
      .then(({ data }) => {
        this.setState({
          userDetails: data,
          loadingInvite: false,
          error: false
        });
      })
      .catch(error => {
        const message = getErrorMessage(error);
        AlertService.showAlert({
          title: message,
          autoClearAfter: defaultDissmissDelay
        });
        this.props.history.push("/login");
        this.setState({
          loadingInvite: false,
          error: true
        });
      });
  };

  isFormInValid = () => {
    const { password } = this.state;
    return errors.password(password);
  };

  submitForm = () => {
    if (this.isFormInValid()) {
      this.setFormError();
      return;
    }
    this.activateUser();
  };

  render() {
    const {
      userDetails,
      loadingInvite,
      password,
      loading,
      error,
      formError = {}
    } = this.state;

    if (loadingInvite) {
      return <PageLoader />;
    }

    return (
      <Wrapper>
        <LoginImage src={`${process.env.PUBLIC_URL}/img/login.png`} />
        <FormWrapper>
          <Form onKeyDown={e => handleKeyPress(e, [13], this.activateUser)}>
            <CardSection>
              <StyledText size='24'>
                {[
                  "Hi,",
                  userDetails.firstName || "",
                  userDetails.lastName || ""
                ].join(" ")}
              </StyledText>
              <StyledText size='16' light>
                {userDetails.email}
              </StyledText>
            </CardSection>
            <CardSection>
              {error && <Message appearance='alert'>{error}</Message>}
            </CardSection>
            <CardSection>
              <Label>Setup a password</Label>
              <StylePwdInput
                id='onboarding-form-password-input-field'
                enableVisbilityToggle={true}
                name='password'
                type='password'
                onChange={e => {
                  this.onChange(e);
                  this.setFormError(e);
                }}
                onBlur={this.setFormError}
                error={formError.password}
                placeholder='***********'
              />
              {formError.password && (
                <SmallErrorText>
                  <Icon name='error' />
                  &nbsp;{formError.password}
                </SmallErrorText>
              )}
            </CardSection>
            <CardSection>
              <PasswordCheckList password={password} />
            </CardSection>
            <Button
              expanded
              dimension='large'
              appearance='primary'
              onClick={!loading && this.submitForm}>
              {loading ? <Loader /> : "Continue"}
            </Button>
          </Form>
        </FormWrapper>
      </Wrapper>
    );
  }

  componentDidMount() {
    this.validateInvite();
  }
}

export default Onboarding;
