/* eslint-disable react/prop-types */
import React, { Component } from "react";
import moment from "moment";
import { formatDate } from "../../../utils";
// eslint-disable-next-line import/no-cycle
import { secsToMins } from ".";
import Join, { Dot } from "../Join";
import authService from "../../../services/authService";

export const Separator = () => (
  <span style={{ fontSize: "20px", margin: "0 4px 0 1px", lineHeight: "16px" }}>
    ,
  </span>
);

const getAssessmentHistoryTable = (data = []) => {
  if (!data || !data.length) {
    return (
      <div style={{ padding: 8, justifyContent: "center", display: "flex" }}>
        <i>No Data</i>
      </div>
    );
  }
  return (
    <table
      style={{
        width: "100%",
        border: `1px solid rgb(191, 192, 192)`,
        borderRadius: 4,
        borderSpacing: 0,
      }}>
      <thead
        style={{
          display: "table-row-group",
          fontWeight: "bold",
          backgroundColor: "rgb(237, 237, 237)",
        }}>
        <tr>
          <td style={{ padding: 8 }} />
          <td style={{ padding: 8 }}>Assessment</td>
          <td style={{ padding: 8 }}>Assessment taken</td>
          <td style={{ padding: 8 }}>Outcome</td>
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => (
          <tr
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            style={
              i < data.length - 1
                ? {
                    borderBottom: `1px solid rgb(210, 210, 210)`,
                  }
                : {}
            }>
            <td style={{ padding: 8 }}>{i + 1}.</td>
            <td style={{ padding: 8 }}>{row.assessmentName}</td>
            <td style={{ padding: 8 }}>
              {formatDate(row.assessmentDate, "MM/DD/YYYY, hh:mm A")}
            </td>
            <td style={{ padding: 8 }}>{row.outcome}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const getTelemedicineHistoryTable = (data = []) => {
  if (!data || !data.length) {
    return (
      <div style={{ padding: 8, justifyContent: "center", display: "flex" }}>
        <i>No Data</i>
      </div>
    );
  }
  return (
    <table
      style={{
        width: "100%",
        border: `1px solid rgb(191, 192, 192)`,
        borderRadius: 4,
        borderSpacing: 0,
      }}>
      <thead
        style={{
          display: "table-row-group",
          fontWeight: "bold",
          backgroundColor: "rgb(237, 237, 237)",
        }}>
        <tr>
          <td style={{ padding: 8 }} />
          <td style={{ padding: 8 }}>Provider</td>
          <td style={{ padding: 8 }}>Timestamp</td>
          <td style={{ padding: 8 }}>Duration</td>
        </tr>
      </thead>
      <tbody>
        {data.map(({ providerName = {}, timestamp, callDuration }, i) => (
          <tr
            key={timestamp}
            style={
              i < data.length - 1
                ? {
                    borderBottom: `1px solid rgb(210, 210, 210)`,
                  }
                : {}
            }>
            <td style={{ padding: 8 }}>{i + 1}.</td>
            <td style={{ padding: 8 }}>
              {providerName.firstName} {providerName.lastName}
            </td>
            <td style={{ padding: 8 }}>
              {formatDate(timestamp, "MM/DD/YYYY, hh:mm A")}
            </td>
            <td style={{ padding: 8 }}>{secsToMins(callDuration)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ValueWithLabel = ({ label, children }) => {
  return (
    <span>
      <strong>{label}:</strong>&nbsp;<span>{children || "NA"}</span>
    </span>
  );
};

const getAge = dob => {
  const years = moment().diff(dob, "years");
  if (years > 0) {
    return `${years}yrs`;
  }
  const months = moment().diff(dob, "months");
  if (months > 0) {
    return `${months} months`;
  }
  return `${moment().diff(dob, "days")} days`;
};

// eslint-disable-next-line react/prefer-stateless-function
class PatientDetailsExport extends Component {
  render() {
    const {
      patientInfo,
      assessmentDetails,
      telemedicineDetails,
      currentDate,
    } = this.props;

    const {
      firstName,
      lastName,
      gender,
      dob,
      insurance,
      phoneNo,
      email,
    } = patientInfo;
    const { total: totalAssessments, assessmentHistory } = assessmentDetails;
    const {
      total: totalTelemedicines,
      telemedicineHistory,
    } = telemedicineDetails;

    const userName = authService.getUserName();

    return (
      <div style={{ padding: 16 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <img
            style={{ width: 200 }}
            src='https://s3.us-east-2.amazonaws.com/applayer-resources/pd@_qa2x/images/default_logo.png'
            alt='not found'
          />
          <span
            style={{
              position: "absolute",
              fontSize: 20,
              textAlign: "center",
              margin: "0px 20%",
            }}>
            Patient Profile
          </span>
          <div
            style={{ float: "right", display: "inline-block", fontSize: 14 }}>
            {userName && (
              <div style={{ textAlign: "right" }}>
                <p style={{ color: "rgb(210, 210, 210)" }}>
                  Printed by {userName}
                </p>
              </div>
            )}
            <div style={{ textAlign: "right" }}>
              <p style={{ color: "rgb(210, 210, 210)" }}>
                {currentDate || formatDate(moment(), "MM/DD/YYYY hh:mm A")}
              </p>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 48 }}>
          <div>
            <p
              style={{
                textTransform: "capitalize",
                fontSize: 18,
                fontWeight: "bold",
              }}>
              {lastName}, {firstName}
            </p>
          </div>
          <div>
            <div style={{ display: "flex" }}>
              <Join
                by={
                  <>
                    &nbsp;
                    <Dot />
                    &nbsp;
                  </>
                }>
                {gender && <span>{gender}</span>}
                <ValueWithLabel label='DOB'>
                  {dob ? `${formatDate(dob)} (${getAge(dob)})` : "NA"}
                </ValueWithLabel>
                <ValueWithLabel label='Insurance'>
                  {insurance || "NA"}
                </ValueWithLabel>
              </Join>
            </div>
          </div>
          <div>
            <div style={{ display: "flex" }}>
              <Join
                by={
                  <>
                    &nbsp;
                    <Dot />
                    &nbsp;
                  </>
                }>
                <ValueWithLabel label='Contact Number'>
                  {phoneNo || "NA"}
                </ValueWithLabel>
                <ValueWithLabel label='E-mail'>{email || "NA"}</ValueWithLabel>
              </Join>
            </div>
          </div>
        </div>

        {/* Assessments history */}
        <div
          style={{
            marginTop: 48,
            display: "flex",
            flexDirection: "column",
            overflow: "visible !important",
          }}>
          <div
            style={{ marginBottom: 16, display: "flex", alignItems: "center" }}>
            <span style={{ flexShrink: 0, fontWeight: "bold" }}>
              Assessment history
            </span>
            &nbsp;&nbsp;
            <span
              style={{
                padding: "2px 6px",
                border: `1px solid rgb(191, 192, 192)`,
                borderRadius: 2,
              }}>
              {totalAssessments}
            </span>
            &nbsp;&nbsp;
            <hr
              style={{ width: "60%", display: "inline-block", marginTop: 4 }}
            />
          </div>
        </div>
        {getAssessmentHistoryTable(assessmentHistory)}

        {/* Telemedicine history */}
        <div
          style={{
            marginTop: 48,
            display: "flex",
            flexDirection: "column",
            overflow: "visible !important",
          }}>
          <div
            style={{ marginBottom: 16, display: "flex", alignItems: "center" }}>
            <span style={{ flexShrink: 0, fontWeight: "bold" }}>
              Telemedicine history
            </span>
            &nbsp;&nbsp;
            <span
              style={{
                padding: "2px 6px",
                border: `1px solid rgb(191, 192, 192)`,
                borderRadius: 2,
              }}>
              {totalTelemedicines}
            </span>
            &nbsp;&nbsp;
            <hr
              style={{ width: "60%", display: "inline-block", marginTop: 4 }}
            />
          </div>
        </div>
        {getTelemedicineHistoryTable(telemedicineHistory)}
      </div>
    );
  }
}

export default PatientDetailsExport;
