import React, { Component, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import styled from "styled-components";
import { find } from "lodash";

import DashboardHeader from "./commons/NavHeader";
import Patients from "routes/patient";
import Outreach from "routes/outreach";
import Settings from "routes/settings";
import Dashboard from "routes/dashboard";
import Assessments from "./Assessment";
import VirtualVisits from "routes/virtualVisits";

import axios from "helper/axios";

import authService from "services/authService";
import { LogoConfig } from "config";
import Home from "./Home";
import Spinner from "@datashop/spinner";

const Overlay = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  background-color: white;
  opacity: 0.5;
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f4f4f4;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const getUserDdConfig = () => {
  return {
    title: `${authService.getUserName() || "You"}`,
    key: "user",
    submenus: [
      {
        title: "Log Out",
        key: "logout",
      },
    ],
  };
};

const defaultHomeConfig = {
  title: "Home",
  link: "/",
  key: "home",
};

const navConfig = {
  leftMenus: [
    {
      title: "Insights",
      link: "insights",
      key: "insights",
    },
    {
      isDefault: true,
      title: "Outreach",
      link: "outreach",
      key: "outreach",
    },
    {
      title: "Patients",
      link: "patients",
      key: "patients",
    },
    {
      title: "Assessments",
      link: "assessments",
      key: "assessments",
    },
    {
      title: "Virtual visits",
      link: "virtualVisits",
      key: "virtualVisits",
    },
    {
      title: "Settings",
      link: "settings",
      key: "settings",
    },
  ],
  rightMenus: [],
};

const routeComponents = {
  assessments: Assessments,
  insights: Dashboard,
  settings: Settings,
  outreach: Outreach,
  patients: Patients,
  virtualVisits: VirtualVisits,
};

class DashboardContainer extends Component {
  state = {
    showOverlay: false,
    navLoading: true,
    routedToDefault: false,
    leftMenus: [],
    rightMenus: [],
  };

  logOutUser = () => {
    this.setState(
      {
        showOverlay: true,
      },
      () =>
        authService.logOut().finally(() => {
          this.setState({
            showOverlay: false,
          });
        })
    );
  };

  updateTabIndex = (navItem, event) => {
    event.preventDefault();
    event.stopPropagation();
    if (navItem.key === "logout") {
      this.logOutUser();
    }
    if (navItem.link) {
      this.props.history.push(
        navItem.link.startsWith("/") ? navItem.link : `/${navItem.link}`
      );
    }
  };

  getNavConfig = () => {
    this.setState({
      navLoading: true,
    });
    axios
      .get(`/organizations/configurations/dashboard/nav-menu`)
      .then(
        ({
          data: {
            config: { value },
          },
        }) => {
          localStorage.setItem("navConfig", JSON.stringify(value));
          let { leftMenus, rightMenus } = { ...navConfig, ...value };

          this.setState({
            leftMenus,
            rightMenus,
            navLoading: false,
          });
        }
      )
      .catch(error => {
        const storedConfig =
          JSON.parse(localStorage.getItem("navConfig")) || {};
        let { leftMenus, rightMenus } = { ...navConfig, ...storedConfig };
        this.setState({
          navLoading: false,
          leftMenus,
          rightMenus,
        });
      });
  };

  render() {
    const { showOverlay, leftMenus, rightMenus, navLoading } = this.state;

    const defaultRoute =
      find([...leftMenus, ...rightMenus], {
        isDefault: true,
      }) || leftMenus[0];

    const showLandingScreen = !["GOH_INDIANA", "MARATHON_HEALTH"].includes(
      authService.getUserKey("organization")
    );

    return (
      <Wrapper>
        {showOverlay && <Overlay />}
        {navLoading ? (
          <LoaderWrapper>
            <Spinner size={48} />
          </LoaderWrapper>
        ) : (
          <Fragment>
            <DashboardHeader
              leftMenuList={[
                LogoConfig,
                ...(showLandingScreen ? [defaultHomeConfig] : []),
                ...leftMenus,
              ]}
              rightMenuList={[...rightMenus, getUserDdConfig()]}
              onClick={this.updateTabIndex}
              currentUrl={window.location.hash.slice(
                2,
                window.location.hash.length
              )}
            />
            <Switch>
              {[...leftMenus, ...rightMenus].map(menu => {
                return (
                  <Route
                    key={menu.key}
                    path={`/${menu.link}`}
                    component={routeComponents[menu.key]}
                  />
                );
              })}
              <Route
                path='/'
                component={
                  showLandingScreen
                    ? Home
                    : () => <Redirect to={`/${defaultRoute.link}`} />
                }
              />
            </Switch>
          </Fragment>
        )}
      </Wrapper>
    );
  }

  componentDidMount() {
    this.getNavConfig();
  }
}

export default DashboardContainer;
