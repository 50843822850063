import React, { Component } from "react";
import {
  Row,
  Column,
  Grid,
  Pagination,
  Card,
  Toast,
} from "@innovaccer/design-system";
import { exportJson2Excel } from "helper/fileDownload";
import axios from "helper/axios";
import { get } from "lodash";
import CustomPlaceholder from "components/commons/Placeholder";
import moment from "moment";
import { Mixpanel } from "../../helper/mixpanelHelper";
import { stringifyQueryParams } from "../../helper/queryParams";
import PatientPageHeader from "./pageHeader";
import PatientTableHeader from "./patientTableHeader";
import Filters from "./filters";
import PatientDetails from "../../components/commons/patientDetail";
import OutreachReveal from "../../components/commons/outreachRevealPanel";
import { Template } from "./template";
import "../outreach/style.css";
import "./style.css";

const defaultFilters = {};
// Default pagination
const defaultPagination = {
  page: 1,
  size: 20,
};

// Get total no of pages
const getTotalPages = (totalRecords, pageSize) =>
  Math.ceil(totalRecords / pageSize);

// Error template
const ErrorTemplate = ({ error, onRetry }) => (
  <CustomPlaceholder
    {...(error
      ? {
          error: true,
          iconStyle: { color: "var(--alert)" },
          title: "Something went wrong",
          subTitle:
            "It may be because of technical failure or network reasons. Please try again",
          onRetry,
        }
      : {
          title: "We're off record!",
          subTitle:
            "Because there is no record available on patients at the moment.",
        })}
  />
);

const getSelectAll = (data, commingFromNewReq = false, scope = null) => {
  // Handle rendering for page change and first time

  if (data.length > 0 && commingFromNewReq) {
    scope.state.selectedRows.forEach(selected => {
      const index = data.findIndex(
        el =>
          el.patientName === selected.patientName &&
          el.contactNumber === selected.contactNumber
      );
      if (index > -1) {
        data[index]._selected = true;
      }
    });
  }
  // Handle rendering for page change and first time ends here

  if (data.length) {
    const anyUnSelected = data.some(d => !d._selected);
    const allUnSelected = data.every(d => !d._selected);

    const indeterminate = data.length >= 0 && anyUnSelected && !allUnSelected;
    const checked = !indeterminate && !allUnSelected;

    return { indeterminate, checked };
  }
  return { indeterminate: false, checked: false };
};

const updateBatchData = (data, rowIndexes, dataUpdate) => {
  const updatedData = [...data];
  for (const rowIndex of rowIndexes) {
    updatedData[rowIndex] = {
      ...updatedData[rowIndex],
      ...dataUpdate,
    };
  }

  return updatedData;
};

export const getColumns = () => [
  {
    name: `Name`,
    selector: "patientName",
  },
  {
    name: "Gender",
    selector: "gender",
  },
  {
    name: "DOB",
    selector: "dob",
  },
  {
    name: "Contact number",
    selector: "displayContactNumber",
    width: "138px",
  },
  {
    name: "Last Assessment",
    selector: "lastAssessment",
  },
  {
    name: "Assessment Date",
    selector: "assessmentTakenDate",
  },
  {
    name: "Last Virtual Visit Provider",
    selector: "telemedicineProvider",
  },
  {
    name: "Virtual Visit Date",
    selector: "telemedicineDate",
  },
];

const membershipStatus = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
  {
    label: "Unpaid",
    value: "unpaid",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Archived",
    value: "archived",
  },
];
class Patients extends Component {
  constructor(props) {
    super(props);

    const defaultFilters = {
      selectedAssessments: [],
      selectedProviders: [],
    };

    const customFilters = get(this.props, "location.state.filters", {});

    this.state = {
      isLoading: false,
      data: [],
      filterOptions: {},
      filters: {
        ...defaultFilters,
        ...customFilters,
      },
      pagination: {
        ...defaultPagination,
      },
      sort: [{ name: "telemedicineDate", type: "desc" }],
      key: 0,
      schema: [],
      totalPatients: 0,
      searchKeyword: "",
      firstCall: true,
      isDownloadLoading: false,
      showPreparingToDownload: false,
      selectedRows: [],
      selectAll: getSelectAll([]),
      showOutreachReavel: false,
      patientDetails: null,
      selectedVisits: [],
      clearSelectedRows: false,
      isLoadingAssessments: false,
      isLoadingProviders: false,
      config: null,
      patientProfileConfig: null,
    };
  }

  transformColumn = column => {
    const cellRenderer = props =>
      column.name === "patientName"
        ? Template[column.template](
            props,
            this.showDetailsModal,
            this.state.isLoading
          )
        : Template[column.template](props, this.state.isLoading);
    const newSchema = {
      ...column,
      cellRenderer,
    };
    return newSchema;
  };

  // Prepare API urls
  prepareUrl = () => {
    const {
      pagination: { page, size },
      sort,
      searchKeyword,
      filters: {
        assessmentDate = {},
        visitDate = {},
        selectedAssessments = [],
        selectedProviders = [],
        selectedAttributedProviders = [],
        selectedMembershipStatus = [],
      },
    } = this.state;
    const options = {
      from_page: page,
      per_page_limit: size,
      search: searchKeyword,
    };
    if (assessmentDate) {
      if (assessmentDate.startDate) {
        options.assessment_date_start = moment(assessmentDate.startDate).format(
          "MM-DD-YYYY"
        );
      }
      if (assessmentDate.endDate) {
        options.assessment_date_end = moment(assessmentDate.endDate).format(
          "MM-DD-YYYY"
        );
      }
    }
    if (visitDate) {
      if (visitDate.startDate) {
        options.virtual_visit_date_start = moment(visitDate.startDate).format(
          "MM-DD-YYYY"
        );
      }
      if (visitDate.endDate) {
        options.virtual_visit_date_end = moment(visitDate.endDate).format(
          "MM-DD-YYYY"
        );
      }
    }
    if (selectedAssessments.length > 0) {
      options.assessment_template_ids = selectedAssessments;
    }
    if (selectedProviders.length > 0) {
      options.virtual_visit_providers = selectedProviders;
    }

    if (selectedAttributedProviders.length > 0) {
      options.attributed_providers = selectedAttributedProviders;
    }

    if (selectedMembershipStatus.length > 0) {
      options.membership_status = selectedMembershipStatus;
    }

    if (sort.length > 0 && sort.length < 2) {
      options.sort_fields = sort[0].name;
      options.sort_orders = sort[0].type;
    } else if (sort.length > 0) {
      options.sort_fields = sort[1].name;
      options.sort_orders = sort[1].type;
    }
    const params = stringifyQueryParams(options);
    return `insights/patients?${params}`;
  };

  // Get assessment list
  lastFetched = 0;

  getPatientConfig = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        axios
          .get("organizations/configurations/patient-tab/layout")
          .then(({ data: { config } }) => {
            const schema = config.value.schema.map(this.transformColumn);
            this.setState({
              config: config.value,
              schema: [...schema],
              patientProfileConfig: config.value.patientProfile,
            });
          })
          .catch(error => {
            this.setState({
              isLoading: false,
              error,
            });
          });
      }
    );
  };

  getAssessments = () => {
    const apiTime = Date.now();
    this.lastFetched = apiTime;
    const url = this.prepareUrl();
    this.setState(
      {
        isLoading: true,
      },
      () => {
        axios
          .get(url)
          .then(({ data }) => {
            if (this.lastFetched > apiTime) {
              return;
            }
            this.setState({
              data: data.patient_details,
              totalPatients: data.totalCount,
              isLoading: false,
              firstCall: false,
              selectAll: getSelectAll(data.patient_details, true, this),
            });
          })
          .catch(error => {
            if (this.lastFetched > apiTime) {
              return;
            }
            console.log(error);
            this.setState({
              isLoading: false,
              firstCall: false,
              error,
            });
          });
      }
    );
  };

  // Get assessment types
  getAssessmentTypes = () => {
    this.setState({ isLoadingAssessments: true });
    axios
      .get("insights/assessment/types")
      .then(({ data: { data = [] } }) => {
        data.push({ name: "(Blank)", _id: "(blank)" });
        this.setState({
          filterOptions: {
            ...this.state.filterOptions,
            assessments: (data || []).map(({ name, _id }) => ({
              label: name,
              value: _id,
            })),
          },
          isLoadingAssessments: false,
        });
      })
      .catch(err => {
        this.setState({ isLoadingAssessments: false });
        console.log(err);
      });
  };

  // Get virtual providers list
  getVirtualProvider = () => {
    this.setState({ isLoadingProviders: true });
    axios
      .get(`/organizations/providers`)
      .then(({ data }) => {
        this.setState({
          filterOptions: {
            ...this.state.filterOptions,
            visitProviders: [...data.providers],
          },
          isLoadingProviders: false,
        });
      })
      .catch(err => {
        this.setState({ isLoadingProviders: false });
        console.log(err);
      });
  };

  // Get attributed providers list
  getAttributedProviders = () => {
    this.setState({ isLoadingAttributedProviders: true });
    axios
      .get(`/organizations/providers?tab=patient-attribution`)
      .then(({ data }) => {
        this.setState({
          filterOptions: {
            ...this.state.filterOptions,
            attributedProviders: [...data.providers],
          },
          isLoadingAttributedProviders: false,
        });
      })
      .catch(err => {
        this.setState({ isLoadingAttributedProviders: false });
      });
  };

  getMembershipStatus = () => {
    this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        membershipStatus: membershipStatus,
      },
    });
  };

  componentDidMount() {
    this.getPatientConfig();
    this.getVirtualProvider();
    this.getAttributedProviders();
    this.getAssessmentTypes();
    this.getMembershipStatus();
    this.getAssessments();
  }

  // Handle Filter Change
  onFilterChange = (patch = {}) => {
    this.setState(
      {
        filters: { ...this.state.filters, ...patch },
        pagination: { ...defaultPagination },
      },
      () => {
        this.getAssessments();
        const mixPanelEvent = "filter";
        this.trackEvent(mixPanelEvent);
      }
    );
  };

  // Handle Search
  onSearchChange = searchKeyword => {
    this.setState(
      {
        searchKeyword,
        pagination: { ...defaultPagination },
      },
      () => {
        this.getAssessments();
        const mixPanelEvent = "search";
        this.trackEvent(mixPanelEvent);
      }
    );
  };

  // Handle Sorting
  onSortChange = newSortingList => {
    const { multipleSorting } = this.props;
    this.setState(
      {
        sort: multipleSorting ? [...newSortingList] : newSortingList.slice(-1),
        pagination: { ...defaultPagination },
      },
      () => {
        this.getAssessments();
      }
    );
  };

  trackEvent = mixPanelEvent => {
    const eventName = {
      search: "Search Accessed",
      filter: "Filters Used",
    };
    Mixpanel.track(eventName[mixPanelEvent], {
      category: "TA-Patients",
      ...this.state.filters,
      page: this.state.pagination,
    });
  };

  onPageChange = page => {
    this.setState({ pagination: { ...this.state.pagination, page } }, () => {
      this.trackEvent("filter");
      this.getAssessments();
    });
  };

  removeItem = newData => {
    newData.forEach(e => {
      const index = this.state.selectedRows.findIndex(
        el =>
          el.patientName === e.patientName &&
          el.contactNumber === e.contactNumber
      );
      if (index > -1) {
        this.state.selectedRows.splice(index, 1);
      }
    });
  };

  onSelect = (rowIndexes, selected) => {
    const { data } = this.state;
    const indexes = [rowIndexes];
    let newData = data;
    if (rowIndexes >= 0) {
      newData = updateBatchData(data, indexes, {
        _selected: selected,
      });

      // Add and Remove item
      if (selected) {
        this.setState(prevState => ({
          selectedRows: [...prevState.selectedRows, newData[indexes]],
        }));
      } else {
        const index = this.state.selectedRows.findIndex(
          el =>
            el.patientName === data[rowIndexes].patientName &&
            el.contactNumber === data[rowIndexes].contactNumber
        );
        if (index > -1) {
          this.state.selectedRows.splice(index, 1);
        }
      }

      // Add and Remove item ends here
      this.setState({
        data: newData,
        selectAll: getSelectAll(newData),
      });
    }
  };

  onSelectAll = (selected, selectAll) => {
    const { data } = this.state;

    const indexes = Array.from({ length: data.length }, (_, i) => i);

    const newData = updateBatchData(data, indexes, {
      _selected: selected,
    });

    if (selected) {
      this.removeItem(newData);
      this.setState(prevState => ({
        selectedRows: [...prevState.selectedRows, ...newData],
      }));
    } else {
      this.removeItem(newData);
    }

    this.setState({
      data: newData,
      selectAll: getSelectAll(newData),
    });
  };

  removeAllSelection = () => {
    // Remove _selected key from current data
    this.state.data.forEach(function (v) {
      delete v._selected;
    });
    // Reset selected rows
    this.setState(() => ({
      selectedRows: [],
      selectAll: getSelectAll([]),
    }));
  };

  downLoad = () => {
    const { assessmentDate = {} } = this.state.filters;
    this.setState({ isDownloadLoading: true, showPreparingToDownload: true });
    const url = this.prepareUrl();
    axios
      .get(`${url}&task=download`)
      // eslint-disable-next-line camelcase
      .then(({ data: { patient_details } }) => {
        const schema = getColumns().map(({ name, selector }) => ({
          displayText: name,
          key: selector,
        }));
        if (
          patient_details.length > 0 &&
          patient_details[0].hasOwnProperty("attributedProviders")
        ) {
          schema.push({
            displayText: "Attributed Providers",
            key: "attributedProviders",
          });
        }
        if (
          patient_details.length > 0 &&
          patient_details[0].hasOwnProperty("membershipStatus")
        ) {
          schema.push({
            displayText: "Membership Status",
            key: "membershipStatus",
          });
        }
        exportJson2Excel(
          [
            {
              data: patient_details,
              schema,
            },
          ],
          `Assessment Report ${assessmentDate.startDate} ${assessmentDate.endDate}`
        );
      })
      .finally(() => {
        Mixpanel.track("Download", { category: "TA-Patients" });
        this.setState({
          isDownloadLoading: false,
          showPreparingToDownload: false,
        });
      });
  };

  showDetailsModal = details => {
    this.setState({
      patientDetails: details,
    });
    Mixpanel.track("Patient Click", { category: "TA-Patients" });
  };

  closeDetailsModal = () => {
    this.setState({
      patientDetails: null,
    });
  };

  toggleOutreachReveal = () => {
    this.setState({
      showOutreachReavel: !this.state.showOutreachReavel,
    });
  };

  render() {
    const {
      totalPatients,
      isLoading,
      sort,
      pagination,
      key,
      data,
      schema,
      error,
      searchKeyword,
      filters,
      filterOptions,
      isLoadingAssessments,
      isLoadingProviders,
      isLoadingAttributedProviders,
      selectedRows,
      isDownloadLoading,
      showPreparingToDownload,
      patientDetails,
      showOutreachReavel,
      config,
      patientProfileConfig,
    } = this.state;
    const withCheckbox = true;
    const withPagination = true;
    return (
      <>
        <OutreachReveal
          open={showOutreachReavel}
          backdrop
          closeOnEscape
          closeButton
          onClose={() => this.setState({ showOutreachReavel: false })}
          selectedRows={this.state.selectedRows}
          onSendSucess={() => {
            this.setState({
              showOutreachReavel: false,
              showSuccessModal: true,
            });
          }}
          parent='patients'
        />
        <Row className='remove-flex-grow'>
          <Column>
            <PatientPageHeader
              isLoading={isLoading}
              isDownloadLoading={isDownloadLoading}
              data={data}
              onDownloadClick={this.downLoad}
              selectedRows={selectedRows}
              onSendOutreachClick={this.toggleOutreachReveal}
              config={config ? config.button : []}
            />
          </Column>
        </Row>
        <Row className='p-5'>
          <Column
            sizeXS='9'
            sizeXL='9'
            sizeS='9'
            sizeM='9'
            sizeL='9'
            className='table-col-width'>
            <div className='Table-container'>
              <div className='table-height-with-header'>
                <Card className='Table'>
                  <div className='Table-header'>
                    <PatientTableHeader
                      {...this.state}
                      searchPlaceholder='Search'
                      withPagination
                      withCheckbox
                      showHead
                      updateSchema={() => ""}
                      loading={isLoading}
                      error={error}
                      searchTerm={searchKeyword}
                      totalRecords={totalPatients}
                      schema={schema}
                      data={data}
                      updateSearchTerm={this.onSearchChange}
                      filters={filters}
                      filterOptions={filterOptions}
                      updateFilters={this.onFilterChange}
                      onSelectAll={this.onSelectAll}
                      totalSelected={selectedRows}
                      removeAllSelection={this.removeAllSelection}
                    />
                  </div>
                  <div className='Table-grid'>
                    <Grid
                      {...this.state}
                      key={key}
                      errorTemplate={props => (
                        <ErrorTemplate
                          {...props}
                          error={error}
                          onRetry={this.getAssessments.bind(this)}
                        />
                      )}
                      type='data'
                      size='comfortable'
                      error={error || data.length < 1}
                      sortingList={sort}
                      updateSortingList={this.onSortChange}
                      showMenu={false}
                      pageSize={pagination.size}
                      page={pagination.page}
                      loading={isLoading}
                      schema={schema}
                      data={data}
                      withCheckbox={withCheckbox}
                      totalRecords={totalPatients}
                      withPagination={
                        withPagination &&
                        getTotalPages(totalPatients, pagination.size) > 1
                      }
                      onSelect={this.onSelect}
                      onSelectAll={this.onSelectAll}
                      separator
                    />
                  </div>
                  <div className='Table-pagination'>
                    <Pagination
                      page={pagination.page}
                      totalPages={getTotalPages(totalPatients, pagination.size)}
                      type='jump'
                      onPageChange={this.onPageChange.bind(this)}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </Column>
          <Column
            sizeXS='3'
            sizeXL='3'
            sizeS='3'
            sizeM='3'
            sizeL='3'
            className='pl-6 pr-3 filter-col-width'>
            <Filters
              isLoadingAssessments={isLoadingAssessments}
              isLoadingProviders={isLoadingProviders}
              isLoadingAttributedProviders={isLoadingAttributedProviders}
              filters={filters}
              filterOptions={filterOptions}
              onChange={this.onFilterChange}
              config={config ? config.filter : []}
            />
          </Column>
        </Row>
        {patientDetails && (
          <PatientDetails
            patientDetails={patientDetails}
            onClose={this.closeDetailsModal}
            parent='patients'
            patientProfileConfig={patientProfileConfig}
          />
        )}
        {showPreparingToDownload && (
          <Toast
            className='download-toast'
            title='Preparing Download...'
            onDismissClick={() =>
              this.setState({ showPreparingToDownload: false })
            }
          />
        )}
      </>
    );
  }
}

export default Patients;
