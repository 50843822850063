import React, { Component } from "react";
import {
  ColumnWrapper,
  RowWrapper,
  MessageContentWrapper,
  Dot,
  StyledBadge
} from "../../Patients/styles";

import Badge from "@datashop/badge";
import Icon from "@datashop/icon";
import Text from "@datashop/text";
import Button from "@datashop/button";
import { replaceReservedTags } from "components/commons/ContentEditable";

class OutreachReview extends Component {
  renderHeader() {
    const { toggleReview, config, outreachType } = this.props;
    return (
      <RowWrapper
        style={{ justifyContent: "space-between", marginBottom: "16px" }}>
        <RowWrapper>
          <Icon
            style={{
              fontSize: "20px",
              color: "#2EA843",
              marginRight: "8px",
              lineHeight: "20px"
            }}
            name='check_circle'
          />
          <ColumnWrapper>
            <Text
              style={{
                fontSize: "16px",
                color: "#151414",
                fontWeight: "bold",
                lineHeight: "24px",
                marginBottom: "4px"
              }}>
              {config.name}
            </Text>
            <RowWrapper>
              {outreachType === 1 ? (
                <Text style={{ marginRight: "4px" }}>
                  {[
                    ...(config.send_sms ? ["Text"] : []),
                    ...(config.send_email ? ["E-mail"] : [])
                  ].join(" + ")}
                  {/* {`Text + E-mail `} */}
                </Text>
              ) : (
                <RowWrapper style={{ justifyContent: "space-between" }}>
                  <Text
                    style={{
                      lineHeight: "20px"
                    }}>{`${config.category} Assessment`}</Text>
                  <Dot />
                  <Text style={{ marginRight: "4px" }}>
                    {[
                      ...(config.send_sms ? ["Text"] : []),
                      ...(config.send_email ? ["E-mail"] : [])
                    ].join(" + ")}
                  </Text>
                </RowWrapper>
              )}
              <Badge style={{ background: "#D7EFDF", marginTop: "-4px" }}>
                <Icon
                  style={{
                    fontSize: "12px !important",
                    color: "#227934",
                    paddingRight: "2px"
                  }}
                  name='lock'
                />
                <span style={{ color: "#227934", fontWeight: "bold" }}>
                  SECURED
                </span>
              </Badge>
            </RowWrapper>
          </ColumnWrapper>
        </RowWrapper>
        <Button disabled={this.props.sending} onClick={() => toggleReview()}>
          Edit
        </Button>
      </RowWrapper>
    );
  }
  renderBody() {
    return (
      <MessageContentWrapper
        dangerouslySetInnerHTML={{
          __html: replaceReservedTags(this.props.html)
        }}></MessageContentWrapper>
    );
  }
  render() {
    const { sending } = this.props;
    return (
      <ColumnWrapper
        style={{
          alignItems: "space-between",
          flex: 1,
          height: "100%",
          overflowY: "scroll",
          paddingRight: "8px"
        }}>
        <ColumnWrapper style={{ flexGrow: 3 }}>
          {this.renderHeader()}
          {this.renderBody()}
        </ColumnWrapper>
        <RowWrapper
          style={{
            alignItems: "flex-end",
            justifyContent: "flex-end",
            flexGrow: 1,
            marginTop: "16px"
          }}>
          <Button
            disabled={sending}
            onClick={() => this.props.resetSendToOutreach()}
            style={{ marginRight: "12px" }}>
            Close
          </Button>
          <Button
            disabled={sending}
            appearance='success'
            onClick={this.props.sendToOutreach}>
            Send Outreach
          </Button>
        </RowWrapper>
      </ColumnWrapper>
    );
  }
}

export default OutreachReview;
