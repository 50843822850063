import React, { Component, Fragment } from "react";
import styled from "styled-components";
import StyledCard from "@datashop/card";

import Button from "@datashop/button";
import moment from "moment";

import { DateRangePicker } from "@innovaccer/design-system";

const Wrapper = styled(StyledCard)`
  background-color: #ffffff;
  z-index: 100;
  display: flex;
`;

const Radio = styled.input`
  margin-right: 8px;
`;

const PeriodItemWrapper = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
`;

const PeriodHeader = styled.div`
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 700;
`;

const PeriodWrapper = styled.div`
  padding-top: 24px;
  border-right: 1px solid #e5e5e5;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 16px 16px;
`;

const DatePickerWrapper = styled.div`
  .Calendar-header {
    align-items: center;
  }
`;

const dateFormat = "MM/DD/YYYY";

const getPeriodsList = () => {
  const start = moment("01-02-1990", "MM-DD-YYYY").format(dateFormat);
  const today = moment().format(dateFormat);
  const yesterday = moment()
    .subtract(1, "days")
    .format(dateFormat);

  return [
    {
      value: {
        startDate: start,
        endDate: today
      },
      label: "Until Today"
    },
    {
      value: {
        startDate: today,
        endDate: today
      },
      label: "Today"
    },
    {
      value: {
        startDate: yesterday,
        endDate: yesterday
      },
      label: "Yesterday"
    },
    {
      value: {
        startDate: moment()
          .subtract(6, "days")
          .format(dateFormat),
        endDate: today
      },
      label: "Last 7 Days"
    },
    {
      value: {
        startDate: moment()
          .subtract(14, "days")
          .format(dateFormat),
        endDate: today
      },
      label: "Last 15 Days"
    },
    {
      value: {
        startDate: moment()
          .subtract(29, "days")
          .format(dateFormat),
        endDate: today
      },
      label: "Last 30 Days"
    }
  ];
};

export const getLabel = (startDate, endDate, outputFormat = "MM/DD/YYYY") => {
  if (!startDate && !endDate) {
    return undefined; //  leting them handle
  }
  const internalStartDate = moment(startDate).format(dateFormat);
  const internalEnd = moment(endDate).format(dateFormat);
  const periodList = getPeriodsList();
  const selectedPeriod = periodList.findIndex(({ value }) => {
    return value.startDate == internalStartDate && value.endDate == internalEnd;
  });
  return periodList[selectedPeriod]
    ? periodList[selectedPeriod].label
    : `${moment(startDate).format(outputFormat)} to ${moment(endDate).format(
        outputFormat
      )}`;
};

class RangePickerComponent extends Component {
  static defaultProps = {
    outputFormat: dateFormat,
    showCustomPeriods: true
  };

  pickerRef = React.createRef();

  periodList = getPeriodsList() || [];

  getState = (start, end) => {
    const startDate = moment(start).format(dateFormat);
    const endDate = moment(end).format(dateFormat);
    const selectedPeriod = this.periodList.findIndex(({ value }) => {
      return value.startDate == startDate && value.endDate == endDate;
    });

    return {
      startDate,
      endDate,
      selectedPeriod
    };
  };

  state = this.getState(this.props.startDate, this.props.endDate);

  onRangeChange = (startDate, endDate) => {
    this.setState(this.getState(startDate, endDate));
  };

  onApplyDate = () => {
    const { startDate, endDate, selectedPeriod } = this.state;

    let validStartDate = moment(startDate);
    let validEndDate = moment(endDate);

    const outputStartDate = validStartDate.format(this.props.outputFormat);
    const outputEndDate = validEndDate.format(this.props.outputFormat);
    this.props.onApplyDate(
      outputStartDate,
      outputEndDate,
      this.periodList[selectedPeriod]
        ? this.periodList[selectedPeriod].label
        : `${outputStartDate} to ${outputEndDate}`
    );
  };

  render() {
    const { startDate, endDate, selectedPeriod } = this.state;
    const { showCustomPeriods } = this.props;

    return (
      <Wrapper shadow='medium'>
        {showCustomPeriods && (
          <PeriodWrapper>
            <PeriodHeader>Period:</PeriodHeader>
            <div>
              {this.periodList.map((period, index) => {
                return (
                  <PeriodItemWrapper
                    onClick={() => {
                      this.onRangeChange(
                        period.value.startDate,
                        period.value.endDate
                      );
                    }}>
                    <Radio checked={selectedPeriod == index} type='radio' />
                    {period.label}
                  </PeriodItemWrapper>
                );
              })}
              <PeriodItemWrapper
                onClick={() =>
                  this.setState({
                    selectedPeriod: -1
                  })
                }>
                <Radio checked={selectedPeriod == -1} type='radio' />
                Custom
              </PeriodItemWrapper>
            </div>
          </PeriodWrapper>
        )}
        <DatePickerWrapper>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onRangeChange={this.onRangeChange}
            disabledAfter={moment().format(dateFormat)}
            inputFormat='mm/dd/yyyy'
            outputFormat='mm/dd/yyyy'
          />
          <ButtonWrapper>
            <Button
              disabled={
                !moment(startDate).isValid() || !moment(endDate).isValid()
              }
              appearance='primary'
              onClick={this.onApplyDate}>
              Apply
            </Button>
          </ButtonWrapper>
        </DatePickerWrapper>
      </Wrapper>
    );
  }
}

export default RangePickerComponent;
