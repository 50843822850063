import React, { useState, useEffect } from "react";
import {
  Text,
  Card,
  Switch,
  PageHeader,
  Spinner,
  Dialog,
} from "@innovaccer/design-system";
import axios from "../../../helper/axios";
import AlertService, {
  defaultDissmissDelay,
} from "../../../services/alertService";
import "./style.css";

const ChatConfiguration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [enableGlobalChat, setEnableGlobalChat] = useState(false);
  const [enablePrcaticeManagerChat, setEnablePrcaticeManagerChat] = useState(
    false
  );
  const [enableStaffMemberChat, setEnableStaffMemberChat] = useState(false);
  const [enableProviderChat, setEnableProviderChat] = useState(false);

  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(!open);
  };

  const onConfirm = () => {
    SetChatConfig("enableGlobalChat", false);
    onClose();
  };

  const OnGlobalChatSwitchToggle = (event, selected) => {
    if (!selected) {
      onClose();
    } else {
      SetChatConfig("enableGlobalChat", selected);
    }
  };
  const OnPrcaticeManagerSwitchToggle = (event, selected) => {
    SetChatConfig("enablePrcaticeManagerChat", selected);
  };
  const OnStaffMemberSwitchToggle = (event, selected) => {
    SetChatConfig("enableStaffMemberChat", selected);
  };
  const OnProviderToggle = (event, selected) => {
    SetChatConfig("enableProviderChat", selected);
  };

  const SetChatConfig = (commingFrom, selected) => {
    if (commingFrom) {
      const value = {
        chatEnabled:
          commingFrom === "enableGlobalChat" ? selected : enableGlobalChat,
        practiceManagerAccess:
          commingFrom === "enablePrcaticeManagerChat"
            ? selected
            : enablePrcaticeManagerChat,
        staffMemberAccess:
          commingFrom === "enableStaffMemberChat"
            ? selected
            : enableStaffMemberChat,
        providerAccess:
          commingFrom === "enableProviderChat" ? selected : enableProviderChat,
      };
      axios
        .patch("/organizations/configurations/chat/config", { value })
        .then(res => {
          if (res && res.data && res.data.value) {
            const {
              chatEnabled,
              practiceManagerAccess,
              providerAccess,
              staffMemberAccess,
            } = res.data.value;
            setEnableGlobalChat(chatEnabled);
            setEnablePrcaticeManagerChat(practiceManagerAccess);
            setEnableStaffMemberChat(staffMemberAccess);
            setEnableProviderChat(providerAccess);
            AlertService.showAlert({
              appearance: "success",
              title: "Config saved successfully",
              autoClearAfter: defaultDissmissDelay,
            });
          }
        })
        .catch(() => {
          AlertService.showAlert({
            appearance: "alert",
            title: "Something went wrong",
            autoClearAfter: defaultDissmissDelay,
          });
        });
    }
  };

  const GetChatConfig = () => {
    setIsLoading(true);
    axios
      .get("/organizations/configurations/chat/config")
      .then(res => {
        const { config } = res && res.data;
        if (config && config.value) {
          const {
            chatEnabled,
            practiceManagerAccess,
            providerAccess,
            staffMemberAccess,
          } = config.value;
          setEnableGlobalChat(chatEnabled);
          setEnablePrcaticeManagerChat(practiceManagerAccess);
          setEnableStaffMemberChat(staffMemberAccess);
          setEnableProviderChat(providerAccess);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        AlertService.showAlert({
          appearance: "alert",
          title: "Something went wrong",
          autoClearAfter: defaultDissmissDelay,
        });
      });
  };

  useEffect(() => {
    GetChatConfig();
  }, []);

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center w-100 h-100'>
        <Spinner appearance='primary' size='medium' />
      </div>
    );
  }

  const options = {
    open,
    onClose,
    dimension: "small",
    heading: "Disable Chat",
    description:
      "This will disable the chat feature for your organization. No roles will be able to connect to any patients via chat.",
    primaryButtonLabel: "Confirm",
    primaryButtonCallback: onConfirm,
    secondaryButtonLabel: "Cancel",
    secondaryButtonCallback: onClose,
  };

  return (
    <div className='w-100'>
      <Dialog {...options} />
      <PageHeader title='Chat' />
      <div className='d-flex justify-content-center'>
        <Card className='chat global-chat p-6 mb-6 mt-6' shadow='light'>
          <div className='d-flex justify-content-between'>
            <Text appearance='default' weight='strong' className='font-size-16'>
              Chat
            </Text>
            <Switch
              appearance='success'
              size='tiny'
              checked={enableGlobalChat}
              onChange={OnGlobalChatSwitchToggle}
            />
          </div>
          <Text appearance='subtle'>
            Use it to enable or disable patient-provider chat
          </Text>
        </Card>
      </div>
      <div className='d-flex justify-content-center'>
        <Card className='role-based-chat chat p-6' shadow='light'>
          <div>
            <Text appearance='default' weight='strong' className='font-size-16'>
              Role specific access
            </Text>
          </div>
          <div className='pb-6'>
            <Text appearance='subtle'>
              Manage roles who will have chat access
            </Text>
          </div>
          <div className='d-flex justify-content-between pb-6 border-bottom mb-6'>
            <Text appearance='default' weight='strong' className='font-size-16'>
              Practice manager
            </Text>
            <Switch
              appearance='success'
              disabled={!enableGlobalChat}
              size='tiny'
              checked={enablePrcaticeManagerChat}
              onChange={OnPrcaticeManagerSwitchToggle}
            />
          </div>
          <div className='d-flex justify-content-between pb-6 border-bottom mb-6'>
            <Text appearance='default' weight='strong' className='font-size-16'>
              Staff member
            </Text>
            <Switch
              appearance='success'
              size='tiny'
              disabled={!enableGlobalChat}
              checked={enableStaffMemberChat}
              onChange={OnStaffMemberSwitchToggle}
            />
          </div>
          <div className='d-flex justify-content-between pb-6 mb-6'>
            <Text appearance='default' weight='strong' className='font-size-16'>
              Provider
            </Text>
            <Switch
              appearance='success'
              size='tiny'
              disabled={!enableGlobalChat}
              checked={enableProviderChat}
              onChange={OnProviderToggle}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ChatConfiguration;
