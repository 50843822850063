import React from "react";
import styled from "styled-components";
import { LogoConfig } from "config";
import NavHeader from "components/commons/NavHeader";
import Text from "@datashop/text";
import If from "components/commons/If";
import { StyledText } from "components/commons/atoms";
import Icon from "@datashop/icon";
import Card from "@datashop/card";

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.datashop.palette.stone.lighter};

  .NavMenu--right {
    .NavMenu-item {
      align-self: center;
      padding: 0px !important;
    }
  }
`;

export const SmallErrorText = styled(Text)`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${props => props.theme.datashop.palette.red.main};
`;

export const CardSection = styled.div`
  margin-bottom: 32px;
`;

export const CommonCard = styled(Card)`
  max-width: 100%;
  padding: 32px;
  background-color: ${props => props.theme.datashop.palette.white.main};
`;

const BodyWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoWrapper = styled.div`
  width: 100%;
  padding: 32px 0px;
  display: flex;
  justify-content: center;
  img {
    height: 30px;
  }
`;

export const AuthRouteWrapper = ({ actionList = [], children, logoOnly }) => (
  <Wrapper>
    <If
      condition={!logoOnly}
      fallback={
        <LogoWrapper>
          <img src={LogoConfig.src} />
        </LogoWrapper>
      }>
      <NavHeader
        leftMenuList={[LogoConfig]}
        rightMenuList={actionList}></NavHeader>
    </If>
    <BodyWrapper style={{ padding: logoOnly ? "0px 12px" : "32px 12px" }}>
      {children}
    </BodyWrapper>
  </Wrapper>
);

export const passwordChecks = [
  {
    displayText: "8 characters",
    test: val => val.length > 7,
    errorMsg: "Password should be at least 8 characters long",
  },
  {
    displayText: "An uppercase letter",
    test: val => /[A-Z]/.test(val),
    errorMsg: "Please include an uppercase letter.",
  },
  {
    displayText: "A lowercase letter",
    test: val => /[a-z]/.test(val),
    errorMsg: "Password should include a lowercase letter",
  },
  {
    displayText: "A symbol (@, #, /, etc)",
    test: val => /[^\w\s]/.test(val),
    errorMsg: "Password should include a symbol",
  },
  {
    displayText: "A number",
    test: val => /\d/.test(val),
    errorMsg: "Password should include a number",
  },
];

const PasswordCheck = styled(StyledText)`
  padding: 4px 0;
  width: 50%;
  i {
    vertical-align: -2px;
    ${props => props.fail && `color: ${props.theme.datashop.palette.red.main};`}
    ${props =>
      props.success && `color: ${props.theme.datashop.palette.green.main};`}
  }
`;

export const PasswordCheckList = ({ password }) => (
  <div>
    {passwordChecks.map((check, i) => {
      const success = check.test(password);
      const fail = password && !check.test(password);
      return (
        <PasswordCheck
          key={i}
          inline
          light={!fail && !success}
          fail={fail}
          success={success}>
          <Icon name={fail ? "cancel" : "check_circle"} />
          &nbsp;
          {check.displayText}
        </PasswordCheck>
      );
    })}
  </div>
);
