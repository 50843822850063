/* eslint-disable no-extend-native */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { Component } from "react";
import moment from "moment";
import Reveal from "@datashop/reveal";
import {
  Text,
  Toast,
  Spinner,
  Button,
  TabsWrapper,
  Avatar,
  Icon,
  Pagination,
} from "@innovaccer/design-system";
import { emailRegex } from "../../../config";
import { formatDate } from "../../../utils";
import axios from "../../../helper/axios";
import { Mixpanel } from "../../../helper/mixpanelHelper";
// eslint-disable-next-line import/no-cycle
import PatientDetailsExport from "./exportTemplate";
import { getExportableTemplate } from "./renderToString";
import { Template } from "./template";
import "./style.css";

const getFormat = s => {
  const m = s / 60;
  if (m < 1) return `ss[sec]`;
  if (m < 60) return `mm[min] ss[sec]`;
  return `hh[hr] mm[min] ss[sec]`;
};

export const secsToMins = s =>
  moment().startOf("day").seconds(s).format(getFormat(s));

const patientDetailCategory = {
  patients: "TA-Patients",
  assessments: "TA-Assessments",
};

// eslint-disable-next-line no-multi-assign
const Capitalize = (String.prototype.capitalize = function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
});

export const getAssessmentHistoryTable = (
  total,
  currentPage,
  startIndex,
  onPageChange,
  data = []
) => {
  if (!data || !data.length) {
    return (
      <div className='d-flex justify-content-center p-4'>
        <Text appearance='default'>No Data</Text>
      </div>
    );
  }
  return (
    <>
      <table>
        <thead>
          <tr>
            <td />
            <td>Assessment</td>
            <td>Assessment taken</td>
            <td>Outcome</td>
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={i}>
              <td>{startIndex + i + 1}.</td>
              <td>{row.assessmentName}</td>
              <td>{formatDate(row.assessmentDate, "MM/DD/YYYY, hh:mm A")}</td>
              <td>{row.outcome}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='d-flex justify-content-center mt-5'>
        <Pagination
          onPageChange={onPageChange}
          page={currentPage}
          totalPages={total}
          type='jump'
        />
      </div>
    </>
  );
};

export const getTelemedicineHistoryTable = (
  total,
  currentPage,
  startIndex,
  onPageChange,
  data = []
) => {
  if (!data || !data.length) {
    return (
      <div className='d-flex justify-content-center p-4'>
        <Text appearance='default'>No Data</Text>
      </div>
    );
  }
  return (
    <>
      <table>
        <thead>
          <tr>
            <td />
            <td>Provider</td>
            <td>Timestamp</td>
            <td>Duration</td>
          </tr>
        </thead>
        <tbody>
          {data.map(({ providerName = {}, timestamp, callDuration }, i) => (
            <tr key={timestamp}>
              <td>{startIndex + i + 1}.</td>
              <td>
                {providerName.firstName} {providerName.lastName}
              </td>
              <td>{formatDate(timestamp, "MM/DD/YYYY, hh:mm A")}</td>
              <td>{secsToMins(callDuration)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='d-flex justify-content-center mt-5'>
        <Pagination
          onPageChange={onPageChange}
          page={currentPage}
          totalPages={total}
          type='jump'
        />
      </div>
    </>
  );
};

class PatientDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      details: null,
      showSharePopup: false,
      shareEmail: "",
      toast: false,
      sharingProfile: false,
      shareEmailValid: true,
      currentAssessmentPageNumber: 1,
      currentTelemedicinePageNumber: 1,
    };
  }

  componentDidMount() {
    this.fetchPatientDetails();
  }

  showToast = props => {
    this.setState({
      toast: { ...{ title: "", appearance: "success" }, ...props },
    });
    const timer = setTimeout(() => {
      this.hideToast();
      clearTimeout(timer);
    }, 5000);
  };

  hideToast = () => {
    this.setState({
      toast: false,
    });
  };

  fetchPatientDetails = () => {
    const {
      patientFirstName,
      patientLastName,
      contactNumber,
      // eslint-disable-next-line react/destructuring-assignment
    } = this.props.patientDetails;
    this.setState({ loading: true });
    axios
      .get(
        `/insights/patient/profile?first_name=${patientFirstName}&last_name=${patientLastName}&phone_no=${contactNumber}`
      )
      .then(({ data }) => {
        this.setState({
          loading: false,
          error: false,
          details: data,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  };

  shareProfile = ({ patientInfo, assessmentDetails, telemedicineDetails }) => {
    const { shareEmail } = this.state;
    const { parent } = this.props;
    this.setState({
      sharingProfile: true,
    });
    const template = getExportableTemplate(
      <PatientDetailsExport
        patientInfo={patientInfo}
        assessmentDetails={assessmentDetails}
        telemedicineDetails={telemedicineDetails}
      />
    );

    axios
      .post("insights/patient/profile/pdf", {
        html_data: template,
        email_id: shareEmail,
        patient_info: patientInfo,
      })
      .then(() => {
        this.showToast({
          message: `Profile shared with ${shareEmail}`,
        });
        this.toggleSharePopup();
        Mixpanel.track("Profile Shared", {
          category: patientDetailCategory[parent],
        });
      })
      .catch(() => {
        this.showToast({
          title: `Couldn't share profile`,
          message: "Please confirm, the email belongs to the same organization",
          appearance: "alert",
        });
      })
      .finally(() => {
        this.setState({
          sharingProfile: false,
        });
      });
  };

  onShareEmailChange = e => {
    const email = e.target.value;
    this.setState({
      shareEmail: email,
      shareEmailValid: emailRegex.test(email),
    });
  };

  toggleSharePopup = () => {
    this.setState(({ showSharePopup }) => ({
      showSharePopup: !showSharePopup,
      shareEmail: "",
    }));
  };

  transformPatientInfo = patientInfo => {
    return (
      patientInfo.length > 0 &&
      patientInfo.reduce(
        (result, pInfo, index) => {
          if (index % 2 == 0) {
            result.leftColumn.push(pInfo);
          } else {
            result.rightColumn.push(pInfo);
          }
          return result;
        },
        { leftColumn: [], rightColumn: [] }
      )
    );
  };

  setAssessmentPageNumber = pageNo => {
    this.setState({
      currentAssessmentPageNumber: pageNo,
    });
  };

  setTelemedicinePageNumber = pageNo => {
    this.setState({
      currentTelemedicinePageNumber: pageNo,
    });
  };

  render() {
    const {
      loading,
      error,
      details,
      showSharePopup,
      sharingProfile,
      shareEmail,
      toast,
      shareEmailValid,
      currentAssessmentPageNumber,
      currentTelemedicinePageNumber,
    } = this.state;
    const { onClose, patientProfileConfig } = this.props;
    const availableTab = patientProfileConfig.tab.filter(
      e => e.hidden === false
    );
    const availableButton = patientProfileConfig.button.filter(
      e => e.hidden === false
    );

    const patientConfigInfo = this.transformPatientInfo(
      patientProfileConfig.patientInfo.filter(e => e.hidden === false)
    );
    const { leftColumn = [], rightColumn = [] } = patientConfigInfo;

    const getChildren = () => {
      if (loading) {
        return (
          <div className='d-flex align-items-center justify-content-center h-100'>
            <Spinner appearance='primary' size='medium' />
          </div>
        );
      }
      if (error || !details) {
        return (
          <div className='d-flex align-items-center justify-content-center flex-column h-100'>
            <Text
              appearance='subtle'
              size='large'
              className='mb-4 unable-to-fetch'>
              Unable to fetch Patient details.
            </Text>
            <Button
              appearance='basic'
              icon='refresh'
              iconAlign='left'
              size='regular'
              onClick={this.fetchPatientDetails}>
              Retry
            </Button>
          </div>
        );
      }

      const patientInfo = details.patientInfo || {};
      const assessmentDetails = details.assessmentDetails || {
        total: 0,
        assessmentHistory: [],
      };
      const telemedicineDetails = details.telemedicineDetails || {
        total: 0,
        telemedicineHistory: [],
      };

      return (
        <>
          <div className='d-flex align-items-center justify-content-between pr-3 mb-8 pop'>
            <div>
              <Text appearance='default' weight='strong' className='font-18'>
                Patient Profile
              </Text>
            </div>
            {availableButton.length > 0 &&
              availableButton.map((b, i) => {
                return b.name === "shareProfile"
                  ? Template[b.template](
                      b,
                      showSharePopup,
                      sharingProfile,
                      !shareEmail,
                      !shareEmailValid,
                      this.toggleSharePopup,
                      this.onShareEmailChange,
                      this.shareProfile,
                      patientInfo,
                      assessmentDetails,
                      telemedicineDetails
                    )
                  : null;
              })}
          </div>
          <div className='mb-4'>
            <div className='patient-detail p-6'>
              <div className='d-flex align-items-center'>
                <Avatar
                  firstName={patientInfo.firstName}
                  lastName={patientInfo.lastName}
                  size='regular'
                  tooltipPosition='bottom'
                  withTooltip
                  appearance='primary'
                />
                <Text appearance='default' className='font-16 bold-font ml-5'>
                  {Capitalize(patientInfo.lastName)},{" "}
                  {Capitalize(patientInfo.firstName)}
                </Text>
              </div>
              <div className='d-flex'>
                <div>
                  {leftColumn.length > 0 &&
                    leftColumn.map((col, index) => (
                      <div className='d-flex mt-6' key={index + col.name}>
                        <Icon appearance='disabled' name={col.icon} size={20} />
                        <div className='d-flex flex-column ml-5'>
                          <Text appearance='subtle'>{col.displayName}</Text>
                          <Text appearance='default'>
                            {patientInfo[col.name]
                              ? patientInfo[col.name]
                              : "N/A"}
                          </Text>
                        </div>
                      </div>
                    ))}
                </div>
                <div>
                  {rightColumn.length > 0 &&
                    rightColumn.map((col, index) => (
                      <div className='d-flex mt-6 ml-12' key={index + col.name}>
                        <Icon appearance='disabled' name={col.icon} size={20} />
                        <div className='d-flex flex-column ml-5'>
                          <Text appearance='subtle'>{col.displayName}</Text>
                          <Text appearance='default'>
                            {patientInfo[col.name]
                              ? col.name === "dob"
                                ? formatDate(patientInfo[col.name])
                                : patientInfo[col.name]
                              : "N/A"}
                          </Text>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <TabsWrapper active={0} onTabChange={() => {}} className='pt-5'>
            {availableTab.length > 0 &&
              availableTab.map((t, i) =>
                Template[t.template](
                  t,
                  t.template === "assessment"
                    ? assessmentDetails
                    : telemedicineDetails,
                  t.template === "assessment"
                    ? currentAssessmentPageNumber
                    : currentTelemedicinePageNumber,
                  t.template === "assessment"
                    ? this.setAssessmentPageNumber
                    : this.setTelemedicinePageNumber,
                  t.template === "assessment"
                    ? getAssessmentHistoryTable
                    : getTelemedicineHistoryTable
                )
              )}
          </TabsWrapper>
        </>
      );
    };
    return (
      <div>
        <Reveal
          backdrop
          closeButton
          open
          onClose={onClose}
          style={{
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            width: "45%",
            padding: "12px 16px",
          }}>
          {getChildren()}
          {toast && (
            <Toast
              title={toast.title}
              className='toast-reveal'
              message={toast.message}
              appearance={toast.appearance}
              onDismissClick={this.hideToast}
            />
          )}
        </Reveal>
      </div>
    );
  }
}

export default PatientDetails;
