import React, { Component } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import datashopTheme from "@datashop/theme";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import history from "./history";
import MainContainer from "./components";
import AlertService from "services/alertService";
import { Mixpanel } from "helper/mixpanelHelper";
import { isProd } from "config";

import "@innovaccer/design-system/css";
import "./App.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import ConfirmService from "services/confirmService";

// Define store
export const store = configureStore();

class App extends Component {
  componentWillMount() {
    if (isProd) {
      Mixpanel.init().then(() => {
        Mixpanel.mixpanelInitiated = true;
      });
    }
  }

  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <ThemeProvider theme={datashopTheme}>
            <>
              <Route path='/' component={MainContainer} />
              <AlertService clearAll={true} />
              <ConfirmService />
            </>
          </ThemeProvider>
        </Router>
      </Provider>
    );
  }
}

export default App;
