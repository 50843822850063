import React, { Fragment, useState, useEffect } from "react";
import axios from "helper/axios";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import Text from "@datashop/text";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
} from "react-simple-maps";
import { scaleThreshold } from "d3-scale";

import {
  WidgetLoader,
  Header,
  LegendLabel,
  WidgetCard,
  transformFilters,
  Legend,
} from "./index";
import Icon from "@datashop/icon";

const ZoomIcon = styled(Icon)`
  font-size: 28px;
  margin-right: 0px;
  cursor: pointer;
  :last-child {
    margin-right: 0;
  }
`;
const maxZoom = 6;
const minZoom = 1;

function palette(min, max) {
  const d = (max - min) / 9;
  return scaleThreshold()
    .range(
      [
        "#ff7f76",
        "#ff9070",
        "#ff9f6a",
        "#ffae65",
        "#fdbc60",
        "#f9cb5d",
        "#f3da5d",
        "#e8ea64",
        "#baff98",
      ].reverse()
    )
    .domain([
      min + d * 1,
      min + d * 2,
      min + d * 3,
      min + d * 4,
      min + d * 5,
      min + d * 6,
      min + d * 7,
      min + d * 8,
    ]);
}

const geoUrl = `${process.env.PUBLIC_URL}/topojson/indiana.json`;

function defaultPalette(min, max) {
  const d = Math.abs(max - min) / 5;
  return scaleThreshold().range(["#d3d3d3"]).domain([-2, -1]);
}
const colorScale = palette(1, 40);
const colorScaleUnmapped = defaultPalette(-2, -1);

const PROJECTION_CONFIG = {
  scale: 78550,
};

const MapTooltip = ({
  cityZipCode,
  screeningCount,
  highRisk,
  mediumRisk,
  lowRisk,
}) => {
  if (!cityZipCode && !screeningCount) {
    return "";
  }
  return (
    <div
      style={{
        width: "200px",
        display: "flex",
        flexDirection: "column",
        padding: "8px",
        background: "#fff",
        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.12)",
      }}>
      <table style={{ textAlign: "start" }}>
        <tbody style={{ color: "black" }}>
          <tr style={{ fontWeight: 700 }}>
            <td colSpan='2'>{`Zip: ${cityZipCode}`}</td>
          </tr>
          {screeningCount && (
            <tr>
              <td>Patient screened</td>
              <td>{screeningCount}</td>
            </tr>
          )}
          {highRisk && (
            <tr>
              <td>High Risk (%)</td>
              <td>{highRisk + "%"}</td>
            </tr>
          )}
          {mediumRisk && (
            <tr>
              <td>Medium Risk (%)</td>
              <td>{mediumRisk + "%"}</td>
            </tr>
          )}
          {lowRisk && (
            <tr>
              <td>Low Risk (%)</td>
              <td>{lowRisk + "%"}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const transformMapData = (arr = []) => {
  return arr.map(data => {
    const { riskDistribution = [] } = data;
    const highestRisk = riskDistribution.sort((a, b) => {
      return b.percentage - a.percentage;
    })[0];
    const { percentage: highestRiskPercentage, category: highRiskCategory } =
      highestRisk || {};
    return {
      ...data,
      riskDistribution,
      highestRiskPercentage,
      highRiskCategory,
    };
  });
};

export const getData = ({ filters }) => {
  return axios.get(`/insights/graph/zipcode-risk-distribution`, {
    params: transformFilters(filters),
  });
};

export const getXLSXData = ({ filters }) => {
  return getData({ filters }).then(({ data: { data = [] } }) => {
    const schema = [
      { displayText: "Zipcode", key: "zip" },
      { displayText: "Number of Patients", key: "patients" },
      { displayText: "High", key: "HIGH" },
      { displayText: "Medium", key: "MEDIUM" },
      { displayText: "Low", key: "LOW" },
    ];
    return {
      data: [
        ...data.map(({ id, riskDistribution, total }) => {
          const data = { zip: id, patients: total };
          riskDistribution.forEach(
            ({ category, percentage }) => (data[category] = `${percentage}%`)
          );
          return data;
        }),
        {
          zip: "Total",
          patients: data.reduce(
            (totalCount, { total }) => totalCount + total,
            0
          ),
        },
      ],
      schema,
      name: "Risk Distribution (by Zip-code)",
    };
  });
};

const AssessmentsZipMap = ({ filters }) => {
  const [tooltipContent, setTooltipContent] = useState("");
  const [mapData, setMapData] = useState([]);
  const [isVisibleLoader, setLoaderVisibility] = useState(true);
  const [zoom, setZoom] = useState(4);
  const [coordinates, setCoordinates] = useState([-87.28999, 41.58977]);

  useEffect(() => {
    getData({ filters }).then(({ data }) => {
      setMapData(transformMapData(data.data));
      setLoaderVisibility(false);
    });
  }, [filters]);

  if (isVisibleLoader) {
    return (
      <WidgetCard isLoading={true}>
        <WidgetLoader />
      </WidgetCard>
    );
  }

  return (
    <WidgetCard isCardTitle={true}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
          marginBottom: "8px",
        }}>
        <Header style={{ flex: 1, fontSize: "20px" }}>
          Risk distribution (by Zip-code)
        </Header>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-start",
            marginTop: "4px",
          }}>
          <Legend color='var(--alert)' title='High' />
          <Legend color='var(--warning)' title='Medium' />
          <Legend color='var(--success)' title='Low' />
        </div>
        <div>
          <ZoomIcon
            title='Zoom In'
            name='zoom_in'
            onClick={() => {
              if (zoom < maxZoom) {
                setZoom(zoom + 1);
              }
            }}
          />
          <ZoomIcon
            title='Zoom Out'
            name='zoom_out'
            onClick={() => {
              if (zoom > minZoom) {
                setZoom(zoom - 1);
              }
            }}
          />
        </div>
      </div>
      <Fragment>
        <ComposableMap
          data-tip
          projectionConfig={PROJECTION_CONFIG}
          projection='geoAlbers'
          className='choropleth-map'>
          <ZoomableGroup
            center={coordinates}
            zoom={zoom}
            maxZoom={zoom}
            minZoom={zoom}
            onMoveEnd={({ coordinates }) => {
              setCoordinates(coordinates);
            }}>
            <Geographies geography={geoUrl}>
              {({ geographies }) => {
                return geographies.map(geo => {
                  const cur = mapData.find(s => {
                    return s.id == geo.properties.zip;
                  });
                  let colorShade = cur
                    ? colorScale(cur.highestRiskPercentage)
                    : colorScaleUnmapped(-1);
                  switch (cur && cur.highRiskCategory) {
                    case "HIGH":
                      colorShade = "var(--alert)";
                      break;
                    case "MEDIUM":
                      colorShade = "var(--success)";
                      break;
                    case "LOW":
                      colorShade = "var(--success)";
                      break;
                    default:
                      colorShade = cur
                        ? colorScale(cur.highestRiskPercentage)
                        : colorScaleUnmapped(-1);
                  }

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      stroke='#fff'
                      fill={colorShade}
                      onMouseEnter={() => {
                        if (!cur) {
                          return "";
                        }
                        const getCategoryValue = (riskCategory = "") => {
                          return (
                            cur.riskDistribution.find(
                              ({ category }) => category === riskCategory
                            ) || {}
                          ).percentage;
                        };
                        setTooltipContent(
                          <MapTooltip
                            cityZipCode={cur.id}
                            screeningCount={cur.total}
                            highRisk={getCategoryValue("HIGH")}
                            lowRisk={getCategoryValue("LOW")}
                            mediumRisk={getCategoryValue("MEDIUM")}
                          />
                        );
                      }}
                      onMouseLeave={() => {
                        setTooltipContent("");
                      }}
                      style={{
                        default: {
                          fill: colorShade,
                          outline: "none",
                        },
                        hover: {
                          fill: colorShade,
                          outline: "none",
                        },
                        pressed: {
                          fill: colorShade,
                          outline: "none",
                        },
                      }}
                    />
                  );
                });
              }}
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
        <ReactTooltip place='top' className='map-tool-tip'>
          {tooltipContent || <p></p>}
        </ReactTooltip>
      </Fragment>
    </WidgetCard>
  );
};

export default AssessmentsZipMap;
