/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import {
  Input,
  Button,
  Checkbox,
  PlaceholderParagraph,
  Placeholder,
  Label,
  Chip,
} from "@innovaccer/design-system";
import { getLabel } from "../../components/commons/RangePicker";

const getOptionsWithLabel = (options = [], selected = []) =>
  options.filter(o => selected.includes(o.value));

const AppliedFilters = ({ filters, filterOptions, onChange }) => {
  const {
    assessments = [],
    visitProviders = [],
    attributedProviders = [],
    membershipStatus = [],
  } = filterOptions;
  const { assessmentDate = {}, visitDate = {} } = filters;
  const selectedAssessments = filters.selectedAssessments
    ? getOptionsWithLabel(assessments, filters.selectedAssessments)
    : [];

  const selectedProviders = filters.selectedProviders
    ? getOptionsWithLabel(visitProviders, filters.selectedProviders)
    : [];

  const selectedAttributedProviders = filters.selectedAttributedProviders
    ? getOptionsWithLabel(
        attributedProviders,
        filters.selectedAttributedProviders
      )
    : [];
  const selectedMembershipStatus = filters.selectedMembershipStatus
    ? getOptionsWithLabel(membershipStatus, filters.selectedMembershipStatus)
    : [];

  return (
    <>
      {selectedProviders.length > 0 && (
        <Chip
          key='providers'
          className='mr-4 mb-6'
          clearButton
          onClose={() => {
            onChange({ selectedProviders: [] });
          }}
          type='selection'
          selected
          label={`${selectedProviders[0].label}${
            selectedProviders.length > 1
              ? ` and ${selectedProviders.slice(1).length} other`
              : ""
          }`}
        />
      )}
      {(visitDate.startDate || visitDate.endDate) && (
        <Chip
          key='visit-date'
          className='mr-4 mb-6'
          clearButton
          onClose={() => {
            onChange({ visitDate: {} });
          }}
          type='selection'
          selected
          label={getLabel(visitDate.startDate, visitDate.endDate)}
        />
      )}
      {selectedAssessments.map((st, i) => (
        <Chip
          // eslint-disable-next-line react/no-array-index-key
          key={`assessment-${i}`}
          className='mr-4 mb-6'
          clearButton
          onClose={() => {
            // eslint-disable-next-line no-shadow
            const { selectedAssessments } = filters;
            onChange({
              selectedAssessments: selectedAssessments.filter(
                s => s !== st.value
              ),
            });
          }}
          type='selection'
          selected
          label={`${st.label}`}
        />
      ))}
      {(assessmentDate.startDate || assessmentDate.endDate) && (
        <Chip
          key='assessment-date'
          className='mr-4 mb-6'
          clearButton
          onClose={() => {
            onChange({ assessmentDate: {} });
          }}
          type='selection'
          selected
          label={getLabel(assessmentDate.startDate, assessmentDate.endDate)}
        />
      )}
      {selectedAttributedProviders.length > 0 && (
        <Chip
          key='attributed-providers'
          className='mr-4 mb-6'
          clearButton
          onClose={() => {
            onChange({ selectedAttributedProviders: [] });
          }}
          type='selection'
          selected
          label={`${selectedAttributedProviders[0].label}${
            selectedAttributedProviders.length > 1
              ? ` and ${selectedAttributedProviders.slice(1).length} other`
              : ""
          }`}
        />
      )}
      {selectedMembershipStatus.map((mStatus, i) => (
        <Chip
          key={`membership-status-${i}`}
          className='mr-4 mb-6'
          clearButton
          onClose={() => {
            const { selectedMembershipStatus } = filters;
            onChange({
              selectedMembershipStatus: selectedMembershipStatus.filter(
                g => g != mStatus.value
              ),
            });
          }}
          type='selection'
          selected
          label={mStatus.label}
        />
      ))}
    </>
  );
};

const Header = props => {
  const {
    loading,
    error,
    data,
    schema,
    showHead,
    withPagination,
    withCheckbox,
    totalRecords = 0,
    onSelectAll,
    searchPlaceholder,
    selectAll,
    searchTerm,
    updateSearchTerm,
    allowSelectAll,
    filters = {},
    filterOptions = {},
    updateFilters,
    totalSelected,
    removeAllSelection,
  } = props;

  const [selectAllRecords, setSelectAllRecords] = React.useState(false);
  const [flag, setFlag] = React.useState(true);

  React.useEffect(() => {
    setFlag(!flag);
  }, [schema]);

  React.useEffect(() => {
    if (selectAll && selectAll.checked) {
      if (onSelectAll) onSelectAll(true, selectAllRecords);
    }
  }, [selectAllRecords]);

  React.useEffect(() => {
    if (selectAll && !selectAll.checked) setSelectAllRecords(false);
  }, [selectAll]);

  const onSearchChange = e => {
    const { value } = e.target;
    if (updateSearchTerm) {
      updateSearchTerm(value);
    }
  };

  const selectedCount =
    // eslint-disable-next-line no-underscore-dangle
    data && data.length > 0 && data.filter(d => d._selected).length;
  const label =
    withCheckbox && selectedCount
      ? selectAllRecords
        ? `Selected all ${totalRecords} items`
        : `Selected ${selectedCount} items on this page`
      : `Showing ${!error ? totalRecords : 0} items`;

  return (
    <div className='Header'>
      <div className='Header-content Header-content--top'>
        <div className='Header-search'>
          <Input
            name='GridHeader-search'
            icon='search'
            placeholder={searchPlaceholder}
            onChange={onSearchChange}
            value={searchTerm}
            onClear={() => updateSearchTerm && updateSearchTerm("")}
            disabled={loading && !(schema && schema.length)}
            autocomplete='off'
          />
        </div>
        <div className='Header-dropdown' />
        <div className='Header-actions' />
      </div>
      <div className='d-flex w-100 flex-wrap'>
        <AppliedFilters
          filters={filters || {}}
          filterOptions={filterOptions || {}}
          onChange={updateFilters}
        />
      </div>
      <div className='Header-content Header-content--bottom'>
        <div className='Header-label'>
          {showHead && !loading && (
            <Checkbox
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...selectAll}
              onChange={event => {
                if (onSelectAll) onSelectAll(event.target.checked);
              }}
            />
          )}
          {loading ? (
            <Placeholder withImage={!showHead && withCheckbox}>
              <PlaceholderParagraph length='small' size='s' />
            </Placeholder>
          ) : (
            <>
              <div className='d-flex'>
                <Label className='pt-3'>{label}</Label>
                {totalSelected && totalSelected.length > 0 && (
                  <Button
                    appearance='basic'
                    size='tiny'
                    className='ml-4'
                    onClick={removeAllSelection}>
                    {`Deselect all (${totalSelected.length})`}
                  </Button>
                )}
              </div>
              {withPagination && allowSelectAll && selectAll.checked && (
                <div className='ml-4'>
                  {!selectAllRecords ? (
                    <Button
                      size='tiny'
                      onClick={() => setSelectAllRecords(true)}>
                      {`Select all ${totalRecords} items`}
                    </Button>
                  ) : (
                    <Button
                      size='tiny'
                      onClick={() => setSelectAllRecords(false)}>
                      Clear Selection
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
