import React, { PureComponent } from "react";
import { Table } from "@innovaccer/design-system";
import {
  getInvitedUsers,
  resendUserInvite,
  removeUserInvite,
} from "services/userService";
import {
  commonSchemaOptions,
  cellRenderers,
  defaultPagination,
  adminOptions,
} from "./templates";
import AlertService, { defaultDissmissDelay } from "services/alertService";
import { formatPhone } from "utils";
import Placeholder from "components/commons/Placeholder";
import { Mixpanel } from "helper/mixpanelHelper";
import TextWithIcon from "../../components/textWithIcon";

const EmptyPlaceholder = () => {
  return (
    <Placeholder
      title={`Invite your users over`}
      subTitle={`Let them fill in all the blanks here.`}
    />
  );
};

class InvitedUsers extends PureComponent {
  state = {
    key: 0,
    loading: false,
    error: false,
  };

  rerenderTable = () => this.setState({ key: this.state.key + 1 });

  resendInvite = id => {
    Mixpanel.track("Resend Invite clicked", {
      category: "InOffice-Settings",
    });
    resendUserInvite(id)
      .then(() => {
        AlertService.showAlert({
          appearance: "success",
          title: "Invite sent",
          autoClearAfter: defaultDissmissDelay,
        });
      })
      .catch(() => {
        AlertService.showAlert({
          appearance: "alert",
          title: "Failed to invite user",
          autoClearAfter: defaultDissmissDelay,
        });
      });
  };

  removeInvite = id => {
    Mixpanel.track("Remove Invite clicked", {
      category: "InOffice-Settings",
    });
    removeUserInvite(id)
      .then(() => {
        AlertService.showAlert({
          appearance: "success",
          title: "Invite removed",
          autoClearAfter: defaultDissmissDelay,
        });
        this.rerenderTable();
      })
      .catch(() => {
        AlertService.showAlert({
          appearance: "alert",
          title: "Failed to remove invite",
          autoClearAfter: defaultDissmissDelay,
        });
      });
  };

  columns = [
    {
      displayName: `Name and Email`,
      name: "name",
      cellRenderer: props => {
        const { data } = props;
        return (
          <TextWithIcon
            data={data}
            appearance={"subtle"}
            iconName={"schedule"}
            iconSize={20}
          />
        );
      },
      width: "28%",
    },
    {
      displayName: "Role",
      name: "role",
      width: "17%",
      cellRenderer: opts => {
        const { data } = opts;
        return cellRenderers.role({
          ...opts,
          props: {
            disabled: true,
            options: [
              {
                value: data.accessRole,
                label: data.accessRole,
                selected: true,
              },
            ],
          },
        });
      },
    },
    {
      displayName: "NPI",
      name: "npi",
      width: "15%",
    },
    {
      displayName: "Practice",
      name: "practices",
      width: "19%",
      cellRenderer: opts => {
        const { data } = opts;
        return cellRenderers.practice({
          ...opts,
          props: {
            disabled: true,
            options: (data.practices || []).map(name => ({
              value: name,
              label: name,
              selected: true,
            })),
          },
        });
      },
    },
    {
      displayName: "Admin",
      name: "isAdmin",
      width: "10%",
      cellRenderer: opts => {
        const { data } = opts;
        return cellRenderers.admin({
          ...opts,
          props: {
            disabled: true,
            options: [
              {
                ...adminOptions.find(({ value }) => value === !!data.isAdmin),
                selected: true,
              },
            ],
          },
        });
      },
    },
    {
      displayName: "",
      name: "options",
      width: "10%",
      cellRenderer: opts =>
        cellRenderers.options({
          ...opts,
          props: {
            options: [
              {
                label: "Resend Invite",
                onClick: () => this.resendInvite(opts.data.inviteId),
              },
              {
                label: "Remove Invite",
                onClick: () => this.removeInvite(opts.data.inviteId),
              },
            ],
          },
        }),
    },
  ];

  schema = this.columns.map(column => ({
    ...commonSchemaOptions,
    ...column,
  }));

  loaderSchema = this.columns.map(({ cellRenderer, ...rest }) => rest);

  getInvitedUsers = () => {
    return getInvitedUsers().then(({ data: { data, totalInvited } }) => {
      return {
        count: totalInvited,
        data,
        schema: this.schema,
      };
    });
  };

  render() {
    return (
      <Table
        errorTemplate={EmptyPlaceholder}
        key={this.state.key}
        async
        withHeader
        headerOptions={{ dynamicColumn: false }}
        showMenu={false}
        fetchData={this.getInvitedUsers}
        loaderSchema={this.loaderSchema}
        pageSize={defaultPagination.size}
      />
    );
  }
}

export default InvitedUsers;
