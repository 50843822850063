import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import AssessmentList from "./assessmentList";
import Admin from "./Admin";
import OutreachMessage from "./assessmentSettings/outreachMessage";
import CustomizedOutreach from "./assessmentSettings/customizedOutreach";
class Assessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assessmentName: "",
    };
  }

  render() {
    const {
      match: { path },
    } = this.props;
    return (
      <Switch>
        <Route
          exact
          path={`${path}/:assessmentId/:name/customizedoutreach/:riskType`}
          render={props => <CustomizedOutreach {...props} />}
        />
        <Route
          exact
          path={`${path}/:assessmentId/:name/outreachmessage`}
          render={props => <OutreachMessage {...props} />}
        />
        <Route
          path={`${path}/:assessmentId/:name`}
          render={props => <Admin {...props} />}
        />
        <Route
          exact
          path={`${path}`}
          render={props => (
            <AssessmentList
              {...props}
              handleAssessmentSelect={this.onAssessmentSelect}
            />
          )}
        />
      </Switch>
    );
  }
}

export default Assessment;
