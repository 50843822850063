import React, { Fragment, PureComponent } from "react";
import Badge from "@datashop/badge";
import styled from "styled-components";
import DataTable, { createTheme } from "react-data-table-component";
import { Loader } from "packages/loader";
import Tooltip from "@datashop/tooltip";
import Placeholder from "components/commons/Placeholder";

const TextLight = styled.div`
  color: ${props => props.theme.datashop.palette.neutral.lightest};
`;

const riskCategoryAppearance = {
  HIGH: "alert",
  MEDIUM: "warning",
  LOW: "success",
};

const getColumns = ({ onNameClick }) => {
  return {
    patientName: {
      cell: row => (
        <Tooltip
          appendToBody={true}
          position='Bottom'
          tooltip={
            <div style={{ textTransform: "capitalize" }}>{row.patientName}</div>
          }>
          <div
            style={{ cursor: "pointer", textTransform: "capitalize" }}
            onClick={() => onNameClick(row)}>
            {row.patientName}
          </div>
        </Tooltip>
      ),
      width: "150px",
      style: {
        color: "#0070DD",
      },
    },
    dob: {
      width: "120px",
    },
    displayContactNumber: {
      width: "150px",
    },
    riskCategory: {
      width: "110px",
      sortable: true,
      cell: row =>
        row.riskCategory ? (
          <StyledBadge
            subtle
            appearance={`${
              riskCategoryAppearance[(row.riskCategory || "LOW").toUpperCase()]
            }`}>
            {row.riskCategory}
          </StyledBadge>
        ) : (
          <TextLight>Not Available</TextLight>
        ),
    },
    zipcode: {
      cell: row =>
        row.zipcode ? (
          <div>{row.zipcode}</div>
        ) : (
          <TextLight>Not Available</TextLight>
        ),
    },
  };
};

export const createColumnSchema = (column, additionalColumnDetails) => {
  const newColumn = {
    name: column.displayText,
    selector: column.key,
    ...additionalColumnDetails,
  };
  if (!newColumn.cell && !newColumn.width) {
    newColumn.cell = row => {
      return (
        <Tooltip
          appendToBody={true}
          position='Bottom'
          tooltip={row[newColumn.selector]}>
          <div>{row[newColumn.selector]}</div>
        </Tooltip>
      );
    };
  }
  return newColumn;
};

const customStyles = {
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRight: "1px solid #D5D5D5",
      },
      "&:first-of-type": {
        borderRight: "0",
      },
      "&:nth-child(2)": {
        paddingLeft: "0",
      },
      borderTop: "1px solid #D5D5D5",
      fontSize: "14px",
      color: "#151414",
      fontWeight: "bold",
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRight: "1px solid #D5D5D5",
      },
      "&:first-of-type": {
        borderRight: "0",
      },
      "&:nth-child(2)": {
        paddingLeft: "0",
      },
      width: "auto",
      minHeight: "10px",
      fontSize: "14px",
    },
  },
  headRow: {
    style: {
      minHeight: "40px",
    },
  },
};

const StyledBadge = styled(Badge)`
  font-weight: 600;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const NoRecords = styled.p`
  margin-top: 16px;
  color: #2f2f2f;
  height: 48px;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
`;

const StyledDataTable = styled(DataTable)`
  > div {
    display: block;
  }

  .rdt_TableRow {
    min-height: 40px;
    border-bottom: 1px solid #d5d5d5;
  }

  .rdt_TableBody {
    overflow-x: hidden !important;
  }
`;

class Table extends PureComponent {
  onSelectedRowsChange = newRows => {
    const nonVisibleRows = this.props.selectedRows.filter(
      selectedRow => !this.props.data.some(row => selectedRow._id === row._id)
    );
    this.props.onSelectRows([...nonVisibleRows, ...newRows.selectedRows]);
  };

  preSelectRow = row => {
    return this.props.selectedRows.some(
      selectedRow => selectedRow._id === row._id
    );
  };

  getSortIcon() {
    return <i className='material-icons'>sort</i>;
  }

  columnMapping = getColumns({ onNameClick: this.props.showPatientDetails });

  render() {
    const { data, isLoading, clearSelectedRows, schema = [] } = this.props;
    const columns = schema.map(column =>
      createColumnSchema(column, this.columnMapping[column.key] || {})
    );

    return (
      <Fragment>
        {isLoading || (data && data.length === 0) ? (
          <LoaderWrapper>
            {isLoading ? (
              <Loader />
            ) : (
              <Placeholder
                title='Leave no stone unturned'
                subTitle={`Monitor and improve your patient's health by conducting an assessment. View all the results of assessments conducted by your clinic here.`}
              />
            )}
          </LoaderWrapper>
        ) : (
          <StyledDataTable
            customStyles={customStyles}
            title='Patient List'
            columns={columns}
            responsive
            highlightOnHover
            data={data}
            clearSelectedRows={clearSelectedRows}
            selectableRows
            noHeader
            fixedHeader
            fixedHeaderScrollHeight={`${window.innerHeight - 230}px`}
            sortIcon={this.getSortIcon()}
            selectableRowsHighlight
            selectableRowSelected={row => this.preSelectRow(row)}
            selectableRows
            selectableRowSelected={this.preSelectRow}
            onSelectedRowsChange={this.onSelectedRowsChange}
          />
        )}
      </Fragment>
    );
  }
}

export default Table;
