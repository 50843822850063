import React from "react";
import {
  Text,
  Button,
  Popover,
  Card,
  Input,
  Tab,
  Badge,
} from "@innovaccer/design-system";

const getTotalPages = (totalRecords, pageSize) =>
  Math.ceil(totalRecords / pageSize);
const pageSize = 10;

export const Template = {
  assessment: (
    tab,
    assessmentDetails,
    currentAssessmentPageNumber,
    setAssessmentPageNumber,
    getAssessmentHistoryTable
  ) => {
    const startIndex = currentAssessmentPageNumber * pageSize - pageSize;
    const assessmentHistory = [...assessmentDetails.assessmentHistory];
    const paginatedAssessmentDetails =
      (assessmentHistory && assessmentHistory.splice(startIndex, pageSize)) ||
      [];
    return (
      <Tab
        label={
          <>
            <div className='Tab-count'>
              <Badge appearance='secondary'>{assessmentDetails.total}</Badge>
            </div>
            <Text appearance='default' size='regular'>
              {tab.displayName}
            </Text>
          </>
        }>
        {getAssessmentHistoryTable(
          getTotalPages(assessmentDetails.total, pageSize),
          currentAssessmentPageNumber,
          startIndex,
          setAssessmentPageNumber,
          paginatedAssessmentDetails
        )}
      </Tab>
    );
  },
  telemedicine: (
    tab,
    telemedicineDetails,
    currentTelemedicinePageNumber,
    setTelemedicinePageNumber,
    getTelemedicineHistoryTable
  ) => {
    const startIndex = currentTelemedicinePageNumber * pageSize - pageSize;
    const telemedicineHistory = [...telemedicineDetails.telemedicineHistory];
    const paginatedTelemedicineDetails =
      (telemedicineHistory &&
        telemedicineHistory.splice(startIndex, pageSize)) ||
      [];
    return (
      <Tab
        label={
          <>
            <div className='Tab-count'>
              <Badge appearance='secondary'>{telemedicineDetails.total}</Badge>
            </div>
            <Text appearance='default' size='regular'>
              {tab.displayName}
            </Text>
          </>
        }>
        {getTelemedicineHistoryTable(
          getTotalPages(telemedicineDetails.total, pageSize),
          currentTelemedicinePageNumber,
          startIndex,
          setTelemedicinePageNumber,
          paginatedTelemedicineDetails
        )}
      </Tab>
    );
  },
  shareProfile: (
    button,
    showSharePopup,
    sharingProfile,
    shareEmail,
    shareEmailValid,
    toggleSharePopup,
    onShareEmailChange,
    shareProfile,
    patientInfo,
    assessmentDetails,
    telemedicineDetails
  ) => {
    return (
      <Popover
        verticalOffset={10}
        closeOnBackdropClick
        on='click'
        open={showSharePopup}
        onToggle={toggleSharePopup}
        position='bottom-end'
        className='popover-style'
        trigger={
          <Button appearance='basic' icon='mail' iconAlign='left' size='small'>
            {button.displayName}
          </Button>
        }>
        <Card className='popover-card'>
          <Text
            className='mb-6 d-block popover-header'
            weight='strong'
            appearance='default'>
            Share patient profile via email
          </Text>
          <Text size='regular' className='font-14'>
            Recipient's mail
          </Text>
          <Input
            placeholder='john.doe@xyz.com'
            type='email'
            onChange={onShareEmailChange}
            error={shareEmail && shareEmailValid}
            style={{ fontFamily: "inherit", margin: "4px 0" }}
          />
          <Text appearance='subtle' className='font-12'>
            Recipient should be part of your organization
          </Text>
          <div className='d-flex mt-6 justify-content-end'>
            <Button
              appearance='success'
              onClick={() =>
                shareProfile({
                  patientInfo,
                  assessmentDetails,
                  telemedicineDetails,
                })
              }
              disabled={sharingProfile || shareEmail || shareEmailValid}>
              Share profile
            </Button>
          </div>
        </Card>
      </Popover>
    );
  },
};
