import React from "react";
import { Text } from "@innovaccer/design-system";
import styled from "styled-components";

const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EllipsisText = ({ children }) => <StyledText>{children}</StyledText>;

export default EllipsisText;
